<template>
    <div>
        <div class="modulo_page_header">
            <div class="container">
                <div class="row flex justify-center md:justify-start">
                    <div class="title_head_interna">
                        <div class="btn_voltar">
                            <a href="javascript:history.back()"><span class="jam jam-arrow-left"></span></a>
                        </div>
                            <div>
                            <h1 class="leading-relaxed">Últimas notícias</h1>
                        </div>
        
                    </div>
                </div>
            </div>
        </div>

        <div class="page_internas" v-if="!error">
            <div class="container" v-if='!waitingNoticia'>
                <div class="row mb-3">
                    <div class="col-md-12">
                        <h1 class="textsize">{{ noticia.data.nm_titulo }}</h1>
                        <p class="chamada_data d-flex justify-content-start align-items-center">
                            <span class="jam jam-calendar"></span>{{ noticia.data.dt_publicacao }}
                        </p>
                    </div>

                </div>
                <div class="row">
                    <!-- <h2>Dados da noticia</h2>
                    <pre>{{ noticia.data.ds_noticia }}</pre> -->
                    <div class="col-md-12">
                        <div v-html="noticia.data.ds_noticia"></div>
                    </div>
                </div>
                <hr style="height: 1px; margin-top: 20px; margin-bottom: 10px;">
            </div>
            <div v-else>
                <center>
                    <div id="loading" class="loader"></div>
                </center>
            </div>
            <div class="container" v-if='!waitingOutras'>
                <div class="row">
                    <div class="col-md-12">

                        <div class="mais_noticias">
                            <h2 class="textsize">Mais notícias</h2>
                            <ul>
                                <li v-for="noticia in outrasNoticias" :key="noticia.id">
                                    <a @click="$router.push(`/noticia/${noticia.id}/`)">
                                        {{ noticia.nm_titulo }}
                                    </a>
                                    <p class="chamada_data d-flex justify-content-start align-items-center">
                                        <span class="jam jam-calendar"></span>{{ noticia.dt_publicacao }}
                                    </p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container" v-if='!waitingOutras'>
                <div class="row flex justify-center text-white">
                        <router-link to="/todas-as-noticias">
                            <a class="btn btn-primary btn_custom">
                                <div class="text-uppercase">Todas as notícias</div>
                            </a>
                        </router-link>
                </div>
          </div>
        </div>
        <div v-else>
            <!-- <div class="w-full text-center" style="height: 10rem;"> -->
            <h1 style='text-align: center; font-size: 100px; font-weight: 500'>404</h1>
                <!-- <lottie-animation path="lottie/error.json" :loop='false' style="width: 20rem;"></lottie-animation> -->
            <!-- </div> -->
            <p style='text-align: center; margin-bottom: 120px !important' class="textsize">Não encontramos essa página :(</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .textsize {
        font-size: 2rem;
        font-weight: 800;
    }
    .loader {
        border: 16px solid #f3f3f3; /* Light grey */
        border-top: 16px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 120px;
        height: 120px;
        animation: spin 2s linear infinite;
    }
</style>

<script>
import Noticias from '../../middleware/controllers/Noticias';
import moment from 'moment';
// import configs from '../../middleware/configs';
// import NovaAPI from './../../middleware/controllers/NovaAPI.js'
//import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
    //components: { LottieAnimation },
    data() {
        return {
            noticia: {},
            outrasNoticias: [],
            waitingNoticia: true,
            waitingOutras: true,
            // token: '',
            error: false
        }
    },
    created() {
        let id = this.$route.params.id;
        if(id) {
            this.getNoticias(id);
            this.getTodasAsNoticias();
        }
    },
    watch: {
        '$route.params.id' (value) {
            this.getNoticias(value, this.token)
        }
    },
    methods: {
        getNoticias(id) {
            Noticias.getNoticias(id).then(result => {
                this.waitingNoticia = false;
                if (result){
                    this.noticia = result
                    this.noticia.dt_publicacao = moment(this.noticia.dt_publicacao, 'YYYY-MM-DD').format('DD/MM/YYYY');
                    this.waitingOutras = false;
                } else {
                    this.error = true
                }
            })
        },
        getTodasAsNoticias() {
            const params = {paginacao: true, page: 1, per_page: 5}
            Noticias.getTodasAsNoticias(params)
            .then(result => {
                if (result.status === 200) {
                    this.outrasNoticias = result.data.results;
                    this.waitingOutras = false;
                } else {
                    this.error = true
                }
            })
        }
    }
}
</script>
