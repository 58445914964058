<template lang="pug">
.carrinho
        b-modal(v-model="modalPedido" :width="400" scroll="keep" aria-label="Confirmar Pedidos")
            .card
                .card-content
                    .content.flex.items-center.justify-center.flex-wrap
                        p Deseja confirmar o pedido do carrinho?
                footer.flex.justify-center.w-full(v-if='loading')
                    b-notification.p-8.bg-white(:closable='false')
                        b-loading(:is-full-page='false' v-model='loading')
                footer.flex.justify-center.w-full(v-else)
                    .p-2(class='w-1/4 ')
                        b-button.w-full.rounded.p-0(@click="modalPedido = false")
                            .flex.items-center
                                span.text-xs Não

                    .p-2(class='w-1/4')
                        b-button.w-full.rounded.p-0(type='is-primary' @click="finalizarPedido()")
                            .flex.items-center
                                span.text-xs Sim
        b-modal(v-model="modalConfirmacao" :width="500" scroll="keep" aria-label="Confirmar Pedidos" :can-cancel="false")
            .card
                .card-content
                    .contents
                        .flex.items-center.justify-center.flex-wrap.text-center
                            p  Seu pedido está sendo processado e logo {{this.carrinho.length > 1 ? 'suas guias estarão disponiveis' : 'sua guia estará disponível'}} nos seus agendamentos
                footer.flex.justify-center.w-full
                    .p-2(class='w-1/3')
                        b-button.w-full.rounded.p-0(type='is-primary' @click="$router.push({ name: 'meusAgendamentos' })")
                            .flex.items-center
                                span.text-xs Meus agendamentos
                            
        .py-56(v-if='waiting.carrinho')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.carrinho')

        .flex.items-center.justify-center.flex-wrap(v-else)
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')

                .pt-4.pb-3.text-center.w-full
                    h1.font-bold.text-medclub-blue-300.text-xl Meu carrinho
                    
                div(v-if='!tem_compra_pix && carrinho.length' @click="confirmLimparCarrinho()" style="padding-bottom:0px !important; ")
                    .absolute.top-0.right-0.p-3.cursor-pointer
                        b-icon.text-md(style="color:#3a356d; font-size:27px" class="mdi mdi-delete-forever")

                .p-2.py-16(v-if='!carrinho.length')
                    p.text-sm.text-center.text-gray-600 Carrinho vazio.

                div(v-else)

                    .p-1
                        .shadow-md.rounded.border.relative.flex.items-center.justify-between.cursor-pointer.overflow-hidden(@click="$router.push({ name: 'meusCreditos' })")
                            .p-4(style='border-left:6px solid #3a356d')
                                .flex.items-center.text-sm.font-bold.text-gray-700
                                    //- b-icon(pack='jam' icon='jam jam-coin' style="font-size:25px;")
                                    img(class='' src='./../../assets/img/carteirinha.png' style="width: 30px; height: auto;")
                                    span.ml-2(style="font-size:16px; color:black")  Créditos Medclub
                            .px-4.text-center
                                p.text-xs Saldo atual
                                p.text-md.font-bold.text-medclub-blue-400 {{ $root.formatPrice(saldoMedclub) }}
                    
                    .p-1.w-full(v-for='agendamento, idx in carrinho' :key='idx')
                        .p-4.shadow-md.rounded.border.relative
                            p.text-sm.text-medclub-400.font-semibold.mb-2(v-if="agendamento.cd_agenda.ie_telemedicina" style="font-size:20px; color: #020f3d;") Consulta por Vídeo
                            .absolute.top-0.right-0.p-2.cursor-pointer(v-if='! tem_compra_pix && agendamento.cd_checkup === null' @click='confirmRemover(idx)')
                                v-icon.text-red-600(class="mdi mdi-close-circle-outline" style="font-size:25px;")

                            .nome-paciente
                                p.text-xs.text-gray-600 PACIENTE
                                p.text-sm.text-gray-700.font-semibold {{ agendamento.cd_paciente.nm_pessoa_fisica }}


                            div(v-if="agendamento.cd_agenda.ie_tipo_agenda !== 'C' || !agendamento.cd_agenda.cd_especialista")
                                p.text-xs.text-gray-600.mt-4
                                    span PROCEDIMENTO
                                    span(v-if='agendamento.cd_procedimentos_horario.length > 1') S
                                div(v-if='agendamento.cd_procedimentos_horario.length > 1')
                                    p.text-sm.text-gray-700.font-semibold(v-for='exame, idx in agendamento.cd_procedimentos_horario' :key='idx') {{ idx + 1 }}.&nbsp; {{ exame.ds_procedimento ? exame.ds_procedimento : exame.nm_procedimento }}
                                p.text-sm.text-gray-700.font-semibold(v-else-if='agendamento.cd_procedimento') {{ `${agendamento.cd_procedimento.ds_procedimento ? agendamento.cd_procedimento.ds_procedimento : agendamento.cd_procedimento.nm_procedimento} ${agendamento.nm_subgrupo ? `(${agendamento.nm_subgrupo})` : ''}`.toUpperCase() }}
                                p.text-sm.text-gray-700.font-semibold(v-else) {{ `${agendamento.cd_procedimento.ds_procedimento ? agendamento.cd_procedimento.ds_procedimento : agendamento.cd_procedimento.nm_procedimento} ${agendamento.nm_subgrupo ? `(${agendamento.nm_subgrupo})` : ''}`.toUpperCase() }}


                            .mt-4(v-if="agendamento.cd_agenda.ie_tipo_agenda !== 'C' && agendamento.cd_agenda.cd_especialista")
                                p.text-xs.text-gray-600.mt-4 ESPECIALISTA
                                .flex.mt-1
                                    .w-10.h-10.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat( v-if="agendamento.cd_agenda.cd_especialista.aq_foto "
                                        :style="`background-image: url('${ agendamento.cd_agenda.cd_especialista.aq_foto }')`")
                                    .p-1.pl-4(class='w-5/6 md:w-11/12')
                                        .ml-2(class='w-5/6')
                                            p.text-sm.text-medclub-blue-400.font-semibold  {{ agendamento.cd_agenda.cd_especialista.ie_sexo == 'F' ? 'Dra.' : 'Dr.' }} {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}
                                            p.text-sm.text-gray-600
                                                span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.nm_conselho }} &nbsp;
                                                span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.uf_conselho }} -&nbsp;
                                                span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.nr_conselho }}&nbsp; 
                                                    //- p.text-sm.font-bold.text-gray-600(v-for='especialidade, idx in agendamento.cd_agenda.cd_especialista.nm_especialidades' :key='idx') {{ especialidade}}
                                            .flex.items-center(v-if="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao")
                                                star-rating(
                                                :rating="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao"
                                                :read-only="true"
                                                :show-rating="false"
                                                :round-start-rating="false"
                                                :star-size="20"
                                                active-color="#ffd600"
                                                )
                                            
                                                
                                                .text-sm.align-center ({{ agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao }})
                            

                            div(v-if="agendamento.cd_agenda.ie_tipo_agenda === 'C' && agendamento.cd_agenda.cd_especialista")
                                p.text-xs.text-gray-900.mt-4.minhaclass(style="color: #718096;") ESPECIALISTA
                                .flex.mt-1
                                    .w-10.h-10.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat( v-if="agendamento.cd_agenda.cd_especialista.aq_foto "
                                        :style="`background-image: url('${ agendamento.cd_agenda.cd_especialista.aq_foto }')`")
                                    .ml-2(class='w-5/6')
                                        p.text-sm.text-medclub-400.font-semibold.nome-medico {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}
                                        p.text-sm.text-gray-600
                                            span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.nm_conselho }} &nbsp;
                                            span {{ agendamento.cd_agenda.cd_especialista.ds_uf_conselho}} -&nbsp;
                                            span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.nr_conselho }}&nbsp; / 
                                            span {{ agendamento.cd_especialidade.nm_especialidade }}
                                        p.text-sm.text-medclub-400.font-semibold(style="color: #020f3d;" v-if="agendamento.cd_agenda.ie_telemedicina") 
                                            span(class="mdi mdi-video") Consulta por vídeo
                                    .flex.items-center(class='star-rating' v-if="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao")
                                    
                                        //- star-rating(
                                            :rating="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao"
                                            :read-only="true"
                                            :show-rating="false"
                                            :round-start-rating="false"
                                            :star-size="20")
                                        //- .text-sm.align-center ({{ agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao }})
                            //- div(v-else)
                            //-     p.text-xs.text-gray-600.mt-4
                            //-         span PROCEDIMENTO
                            //-         span(v-if='agendamento.cd_procedimentos_horario.length > 1') S
                            //-     div(v-if='agendamento.cd_procedimentos_horario.length > 1')
                            //-         p.text-sm.text-gray-700.font-semibold(v-for='exame, idx in agendamento.cd_procedimentos_horario' :key='idx') {{ idx + 1 }}.&nbsp; {{ exame.ds_procedimento ? exame.ds_procedimento : exame.nm_procedimento }}
                            //-     p.text-sm.text-gray-700.font-semibold(v-else-if='agendamento.cd_procedimento') {{ agendamento.cd_procedimento.ds_procedimento ? agendamento.cd_procedimento.ds_procedimento : agendamento.cd_procedimento.nm_procedimento }}
                            //-     p.text-sm.text-gray-700.font-semibold(v-else) {{ agendamento.cd_procedimentos_horario[0].ds_procedimento ? agendamento.cd_procedimentos_horario[0].ds_procedimento : agendamento.ds_procedimentos_horario[0].nm_procedimento }}

                            div.flex

                                div(class='w-1/2 md:w-1/3' v-if='! agendamento.cd_agenda.ie_agenda_presencial')
                                    p.text-xs.text-gray-600.mt-4 DATA
                                    .flex.items-center.text-sm.text-gray-700
                                        v-icon(class='mdi mdi-calendar' style="font-size:25px;")
                                        p.text-black-300(style="color:#020f3d") {{ $root.moment(agendamento.dt_agenda, 'YYYY-MM-DD', true).format('DD [de ] MMM') }}

                                div(v-if="agendamento.cd_especialidade.id === 57")
                                
                                //- div(v-else-if='! agendamento.cd_agenda.ie_agenda_presencial && ! agendamento.cd_agenda.ie_hora_marcada && Object.values(agendamento.cd_dia_atendimento).length' class='w-1/2 md:w-1/3')
                                //-     p.text-xs.text-gray-600.mt-4 POR ORDEM DE CHEGADA
                                //-     .flex.items-center.text-sm.text-gray-700.font-semibold
                                //-         //- b-icon(class="mdi mdi-clock-outline")
                                //-         p(v-if='agendamento.cd_dia_atendimento')
                                //-             span(style="color:#df1111" v-if='agendamento.cd_dia_atendimento.hr_inicio') {{ agendamento.cd_dia_atendimento.hr_inicio.substr(0,5) }}
                                //-         p(v-else) -

                                div(v-else-if='agendamento.cd_agenda.ie_hora_marcada' class='w-1/2 md:w-1/3')
                                    p.text-xs.text-gray-600.mt-4 HORÁRIO
                                    .flex.items-center.text-sm.text-medclub-blue-400
                                        //- b-icon(pack='jam' icon='jam-clock')
                                        v-icon.text-xl(class="mdi mdi-clock-outline" style="font-size:25px;")
                                        p(v-if='agendamento.hr_agenda' style="color:#020f3d") {{ agendamento.hr_agenda.substr(0,5) }}

                                .hidden(class='md:block w-1/3')
                                    p.text-xs.text-gray-600.mt-4.mb-1 PREÇO
                                    .flex.items-center.text-lg.text-medclub-blue-400
                                        b-icon(pack='mdi' icon='wallet' style="color: #007F2D;")
                                        p(style="color: #007F2D;") R$ {{ agendamento.nr_valor }}

                                div(class='md:hidden')
                                    p.text-xs.text-gray-600.mt-4.mb-1 PREÇO
                                    .flex.items-center.text-xs.text-medclub-blue-400
                                        b-icon(pack='mdi' icon='wallet' style="color: #007F2D;")
                                        p.text-xs.text-medclub-blue-400(style="color: #007F2D;") R${{ agendamento.nr_valor }}
                                        div(v-if="agendamento.cd_especialidade.id === 57")
                                
                            div( v-if="agendamento.cd_especialidade.id === 57")
                            div(class="md:hidden" v-else-if='!agendamento.cd_agenda.ie_agenda_presencial && ! agendamento.cd_agenda.ie_hora_marcada && Object.values(agendamento.cd_dia_atendimento).length' class='w-1/2 md:w-1/3')
                                p.text-xs.text-gray-600.mt-4 POR ORDEM DE CHEGADA
                                .flex.items-center.text-sm
                                    //- b-icon(class="mdi mdi-clock-outline")
                                    p(v-if='agendamento.cd_dia_atendimento')
                                        span(class="mdi mdi-clock-outline" style="color:#df1111" v-if='agendamento.cd_dia_atendimento.hr_inicio')  A partir das {{ agendamento.cd_dia_atendimento.hr_inicio.substr(0,5) }}
                                    p(v-else) -

                            .mt-4(v-if="!agendamento.cd_agenda.ie_telemedicina")
                                p.text-xs.text-gray-600.mt-2 LOCAL DE ATENDIMENTO
                                .flex.mt-1
                                    //- p 1: {{ agendamento.cd_agenda }}
                                    .w-10.h-10.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat(v-if="agendamento.cd_estabelecimento.aq_foto"
                                        :style="`background-image: url('${ agendamento.cd_estabelecimento.aq_foto }')`")
                                    .ml-2(class='w-5/6')
                                        p.text-sm.text-gray-700.nm_estabelecimento {{ agendamento.cd_estabelecimento.nm_fantasia }}
                                        p.text-sm.text-gray-600
                                            span {{ agendamento.cd_estabelecimento.nm_rua }}
                                            span , {{ agendamento.cd_estabelecimento.nr_estabelecimento }}
                                            span &nbsp;- {{ agendamento.cd_estabelecimento.nm_bairro }}

                            //- .my-2.text-xs.bg-yellow-200.p-2.border.border-yellow-300(v-if="agendamento.cd_especialidade.id !== 57 && !agendamento.cd_agenda.ie_agenda_presencial") <b>Atenção!</b> Os horários são agendados após o pagamento.
                            //- .my-2.text-xs.bg-yellow-200.p-2.border.border-yellow-300.text-xl(style="color:yellow" class="mdi mdi-alert" v-if="agendamento.cd_especialidade.id !== 57 && !agendamento.cd_agenda.ie_agenda_presencial")  É necessário agendar com a clínica
                            
                            //- .my-2.p-2.border.border-yellow-300.flex.items-center(style="color:#333333; background-color:#FFEF00;" v-if="agendamento.cd_especialidade.id !== 57 && !agendamento.cd_agenda.ie_agenda_presencial")
                            //-     b-icon.text-xl(style="color:#ffba00;" class="mdi mdi-alert" ) 
                            //-     span.text-md.pl-2 <b>Atenção!</b> Os horários são agendados após o pagamento.
                            //- my-2.text-xs.bg-yellow-200.p-2.border.border-yellow-300(v-if="agendamento.cd_especialidade.id !== 57") <b>Atenção!</b> Os horários são agendados após o pagamento.
                            
                            .my-2.text-md.bg-yellow-200.p-2.border.border-yellow-300.flex.items-center(style="color:#333333; background-color:#FFEF00;" v-if="agendamento.cd_agenda.ie_agenda_presencial")
                                b-icon.text-xl(style="color:#ffba00;" class="mdi mdi-alert") 
                                
                                span.text-md.pl-2 É necessário agendar com a clínica.


                .p-1.w-full(v-if='carrinho.length > 1')
                    .p-4.shadow-md.rounded.border.flex.items-center.text-gray-700.text-sm
                        div(class='w-1/2')
                            b-button.rounded.p-4(v-if='! tem_compra_pix' type='is-danger' @click="confirmLimparCarrinho()")
                                .flex.items-center
                                    b-icon.text-xl(pack='jam' icon='jam-trash')
                                    span.text-xs Limpar carrinho
                        .text-right(class='w-1/2')
                            p.text-xs.text-gray-600 VALOR TOTAL
                            p.text-xl.text-medclub-blue-400.font-semibold {{ $root.formatPrice(valorTotal()) }}

                .p-2.pb-12(v-if='carrinho.length')
                    .p-2.border.border-red-300.bg-red-200(v-if='tem_compra_antifraude')
                        p.text-xs <b>Atenção!</b> Você já possui um pagamento com aprovação pendente, após a liberação você poderá prosseguir para uma nova compra.
                    .p-2.border.border-red-300.bg-red-200(v-if='tem_compra_pix')
                        p.text-xs
                            span <b>Atenção!</b> Você já possui uma <b>transação com Pix</b> em processamento. Após a confirmação, você poderá prosseguir para uma nova compra, ou <router-link class='underline text-blue-700' :to="{ name: 'pagamentoPix' }">clique aqui</router-link> para visualizar novamente o QR Code.
                    div(v-else)
                        .flex.justify-center.w-full
                            .p-2(class='w-1/2 md:w-56')
                                b-button.w-full.rounded.p-0.semi-bold(style="background-color:#cccccc; color:#242121" type='is-info' @click="$router.push({ name: 'home' })")
                                    .flex.items-center
                                        span.text-md Adicionar outros

                            .p-2(class='w-1/2 md:w-56')
                                b-button.w-full.rounded.p-0(style="background-color:#00802d;" type='is-primary' @click="confirmarCarrinho()")
                                    .flex.items-center
                                        //- span.text-md(v-if='medempresa') Confirmar pedido
                                        span.text-md Pagar
                                        //- b-icon.text-xl(pack='jam' icon='jam-chevron-right')
</template>


<style lang="scss" scoped>

// .flex.items-center.star-rating{
//     display: none;
//     }
@media (max-width: 668px) {
    p.text-xs.text-gray-600 {
        // display: none;
        font-size: 12px !important;
        font-weight: 842;
        color: #5b5b5b;
    }
    .text-sm.text-gray-600{
        font-size: 15px !important;
        font-weight: 842;
        color: #5b5b5b;
    }
    .text-xs.text-gray-900.mt-4.minhaclass{
        font-size: 12px !important;
        font-weight: 842;
        color: #5b5b5b !important;
    }
    p.text-sm.text-medclub-400.font-semibold.nome-medico{
        color: #020f3d;
    }
    .flex.items-center.text-sm.text-gray-700.font-semibold p{
        color: #020f3d;
    }
    .p.text-sm.text-gray-700.nm_estabelecimento {
        color: #31bb0f;
    }

}
</style>



<script>
    import { Pagamento } from '../../middleware'
    import StarRating from 'vue-star-rating';
    export default {
        components: { StarRating },
        created () {
            // Pagamento.limparCarrinho()
            if (this.$parent.$parent.usuario) this.getCarrinho()
            else this.$router.push({ name: 'home' })
        },
        watch: {
            carrinho: function(carrinho) {
                this.$parent.$parent.carrinho = carrinho
            }
        },
        data () {
            return {
                carrinho: [],
                tem_compra_antifraude: false,
                tem_compra_pix: false,
                saldoMedclub: 0,
                medempresa: false,
                modalPedido: false,
                modalConfirmacao: false,
                loading: false,
                waiting: { carrinho: false },
                valorTotal () {
                    return this.carrinho.reduce((ac, val) => ac + parseFloat(val.nr_valor), 0).toFixed(2)
                }
            }
        },
        methods: {
            getCarrinho (redirect) {
                this.waiting.carrinho = true
                this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                Pagamento.getSaldoCreditos({ie_associado_med_empresa: this.medempresa}).then(response => {
                    if (response.status === 200) this.saldoMedclub = response.data.saldo_empresa ? response.data.saldo_empresa[0].nr_saldo : response.data.saldo
                    Pagamento.getCarrinho({ie_associado_med_empresa: this.medempresa, novo: true}).then(response => {
                        this.waiting.carrinho = false
                        if (response.status === 200) {
                            this.carrinho = response.data.carrinho
                            this.tem_compra_antifraude = response.data.tem_compra_antifraude
                            this.tem_compra_pix = response.data.tem_compra_pix
                            if (! this.carrinho.length && redirect) {
                                window.setTimeout(() => { this.$router.push({ name: 'home' }) })
                            }
                        }
                    })
                })
            },
            confirmRemover (idx) {
                this.$buefy.dialog.confirm({
                    message: 'Deseja remover o item do carrinho?',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    confirmText: 'Remover',
                    onConfirm: () => this.remover(idx)
                })
            },
            remover (idx) {
                this.waiting.carrinho = true
                const done = () => {

                    let item = Object.assign({}, this.carrinho[idx])
                    this.$gtag.event('removeu_do_carrinho', { item })

                    this.carrinho.splice(idx, 1)
                    if (! this.carrinho.length) {
                        this.$router.push({ name: 'home' })
                        this.$buefy.toast.open('Carrinho vazio')
                    } else {
                        this.$buefy.toast.open('Agendamento removido')
                        this.getCarrinho()
                    }
                }
                let body = { ie_reservar: 'L', cd_estabelecimento: this.carrinho[idx].cd_estabelecimento.id }
                if (this.carrinho[idx].cd_agenda.ie_tipo_agenda === 'C') {
                    body.cd_especialidade = this.carrinho[idx].cd_especialidade.id
                    body.cd_especialista = this.carrinho[idx].cd_agenda.cd_especialista.id
                    Pagamento.addToCarrinhoConsulta(this.carrinho[idx].id, body).then(response => {
                        if (response.status === 200) done()
                        else if (response.status === 400) {
                            if (response.data.detail)
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: response.data.detail })
                            else if (typeof response.data == 'object') {
                                Object.values(response.data).forEach(msg => {
                                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: msg })
                                })
                            }
                        }
                    })
                } else {
                    Pagamento.addToCarrinhoExame(this.carrinho[idx].id, body).then(response => {
                        if (response.status === 200) done()
                        else if (response.status === 400) {
                            if (response.data.detail)
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: response.data.detail })
                            else if (typeof response.data == 'object') {
                                Object.values(response.data).forEach(msg => {
                                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: msg })
                                })
                            }
                        }
                    })
                }
            },
            confirmLimparCarrinho () {
                this.$buefy.dialog.confirm({
                    message: 'Deseja limpar o carrinho?',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    confirmText: 'Limpar',
                    onConfirm: () => this.limparCarrinho()
                })
            },
            limparCarrinho () {
                this.waiting.carrinho = true
                let params = {}
                if(this.medempresa) params['ie_associado_med_empresa'] = this.medempresa
                Pagamento.limparCarrinho(params).then(response => {
                    if (response.status === 200) {
                        this.carrinho.forEach(item => { this.$gtag.event('removeu_do_carrinho', { item }) })
                        this.$buefy.toast.open('Carrinho vazio')
                        this.getCarrinho(true)
                    } else if (response.status === 400) {
                        if (response.data.detail)
                            this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: response.data.detail })
                        else if (typeof response.data == 'object') {
                            Object.values(response.data).forEach(msg => {
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: msg })
                            })
                        }
                    }
                })
            },
            confirmarCarrinho () {
                 this.$router.push({ name: 'pagamento' })
                // if(!this.medempresa) {
                //     this.$router.push({ name: 'pagamento' })
                // } else {
                //     this.modalPedido = true
                // }
            },
            confirmarPedido() {
                this.$buefy.dialog.confirm({
                    title: 'Confirmar Pedidos',
                    message: `Deseja confirmar os pedidos no carrinho`,
                    cancelText: 'Não',
                    confirmText: 'Sim',
                    type: 'is-success',
                    onConfirm: () => this.$buefy.toast.open('User agreed')
                })
            },
            finalizarPedido() {
                let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
                let dataSend = {
                    cd_empresa: usuario.empresas_associado[0].cd_empresa,
                    cd_horario_agendamento: this.carrinho.map(item => item.id)
                }
                this.loading = true
                Pagamento.confimarPedido(dataSend).then(response => {
                    if (response.status === 200) {
                        this.modalPedido = false
                        this.modalConfirmacao = true
                        this.loading = false
                    }
                })
            },
        }
    }
</script>