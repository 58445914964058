<template lang="pug">
.w-full
        .absolute.top-0.left-0.p-2
            a.text-3xl(@click='back()')
                i.jam.jam-chevron-left

        .w-full
            .pt-4.pb-3.text-center.w-full
                h1.font-bold.text-medclub-blue-300.text-sm Escolha o estabelecimento

        .py-56(v-if='waiting.estabelecimentos')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.estabelecimentos')
        
        .w-full.text-center(v-else-if='! list.length')
            p.text-sm.my-6.text-gray-600 Nenhum estabelecimento encontrado.

        .w-full.px-2(v-else)
            .border.my-1.text-sm(v-for='item, idx in list' :key='idx')
                .p-2
                    .flex
                        .w-full.flex.items-center(class='md:w-4/6')

                            .text-center.pr-2
                                .w-10.h-10.bg-gray-300.bg-center.bg-cover.bg-no-repeat(
                                    :style="`background-image: url('${ item.cd_estabelecimento.aq_foto }'); border-radius: 10%`")

                            div(class='w-10/12')
                                p.font-bold.text-gray-700.text-lg {{ item.cd_estabelecimento.nm_fantasia }}
                                p.text-xs.text-medclub-blue-300
                                    span {{ item.cd_estabelecimento.nm_rua }}
                                    span , {{ item.cd_estabelecimento.nr_numero }}
                                    span &nbsp;- {{ item.cd_estabelecimento.nm_bairro }}
                                    span(v-if='item.cd_estabelecimento.ds_complemento') &nbsp;- {{ item.cd_estabelecimento.ds_complemento }}
                                p.text-xs.text-medclub-blue-300(v-if='item.cd_estabelecimento.nr_telefone') {{ $root.applyTelefoneMask(item.cd_estabelecimento.nr_telefone) }}
                                .p-1.w-full
                                            map-button(:query='item.cd_estabelecimento.nm_fantasia + " " + item.cd_estabelecimento.nm_rua + " " + item.cd_estabelecimento.nr_numero + " " + item.cd_estabelecimento.nm_bairro')

                        .hidden.text-right(class='md:block w-2/6')
                            p.text-sm.text-gray-600 VALOR
                            p.text-2xl.font-bold.text-medclub-blue-400 {{ $root.formatPrice(item.nr_preco) }}
                    .mt-2
                        h1.font-bold Check-up
                        p {{ item.cd_tipo_checkup.ds_checkup }}

                    .my-2
                        h1.font-bold Procedimentos
                        div(v-for='procedimento, idx in item.cd_procedimentos' :key='idx')
                            p {{ idx + 1 }}. {{ procedimento.ds_procedimento }}

                .flex.flex-wrap.items-center.p-2.pb-0.border-t(class='md:hidden')
                    div(class='w-1/2')
                        p.font-bold.text-green-700 VALOR
                    .text-right(class='w-1/2')
                        p.text-xl.font-bold.text-green-700 {{ $root.formatPrice(item.nr_preco) }}

                .flex.justify-center.p-2.pt-0.w-full.mt-2
                    b-button.text-sm(type='is-primary' expanded class='md:max-w-xs' @click='select(item)')
                        .flex.items-center
                          b-icon.text-xl(pack='jam' icon='jam-shopping-cart')
                          span.text-sm Adicionar ao carrinho
</template>

<script>
    import { Agendamento } from '../../../middleware'
    import MapButton from '../../global/MapButton.vue'

    export default {
        components: { MapButton },
        created () {
            this.waiting.estabelecimentos = true
            const params = {
                cd_cidade: this.$store.state.cdCidade,
            }
            if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))

            Agendamento.getTipoCheckup(this.$parent.agendamento.tipo_checkup.id, params).then(response => {
                this.waiting.estabelecimentos = false
                if (response.status === 200) this.list = response.data
            })
        },
        data () {
            return {
                list: [],
                waiting: { estabelecimentos: false }
            }
        },
        methods: {
            back () {
                this.$parent.agendamento.tipo_checkup = null
            },
            select (item) {
              let message = "";
              message += `<div class='whitespace-pre-wrap text-sm'><h4>⚠️Após pagamento, agende pelo WhatsApp <strong>(86) 98810-1140</strong>.</h4></div>`

              this.$buefy.dialog.alert({
                title: "Observações",
                message: message,
                confirmText: 'Entendi',
                onConfirm: () => {
                  this.$parent.agendamento.estabelecimento = item
                  // if (! item.cd_estabelecimento.cd_agendas) this.$parent.agendamentoCriado = true
                  this.$parent.agendamentoCriado = true
                }
              })

            }
        }
    }
</script>