<template>
  <b-modal v-model='dialog' has-modal-card trap-focus :destroy-on-hide='false' aria-role="dialog" aria-modal>
    <template>
      <div class="modal-card p-4 md:p-0">
        <header class="modal-card-head">
          <p class="modal-card-title text-xl font-bold">Avaliar</p>
          <button class="delete" type='button' @click='close'></button>
        </header>
        <section class="modal-card-body">
          <div v-for="(pergunta, idx) in perguntas" :key="pergunta.id" class="flex flex-col items-center">
            <h1 class="font-bold justify-self-center align-middle my-2">{{pergunta.ds_pergunta}}</h1>

            <div v-if="pergunta.ie_tipo === 'N'" class="my-2 flex flex-col items-center w-100">
              <StarRating
                v-model="respostas[idx].nr_avaliacao"
                :increment="1"
                :show-rating="false"
                :star-size="30"
                :clearable="true"
                class="justify-self-center my-2"
              />
              <div class="text-sm text-gray-600 justify-self-center">Avalie de uma a cinco estrelas, onde uma estrela é ruim e cinco estrelas é muito bom.</div>
            </div>

            <div v-else class="flex flex-col items-center w-100">
              <textarea
                class="border p-2 w-100"
                placeholder="Toque para digitar"
                rows="5"
                v-model="respostas[idx].ds_resposta"
                />
            </div>
          </div>
        </section>
        <footer class="modal-card-foot flex flex-wrap justify-center p-4">
          <b-button type="is-primary" class="w-full sm:w-40 my-0 mx-auto" @click="handleSubmit">Pronto</b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
textarea::placeholder {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
</style>
<script>
import StarRating from 'vue-star-rating';
import Agendamento from '../../middleware/controllers/Agendamento.js';

export default {
  components: { StarRating },
  props: [ 'horario' ],
  data() {
    return {
      dialog: true,
      perguntas: [],
      respostas: []
    }
  },
  created() {
    Agendamento.getPerguntasAvaliacao().then(response => {

      if(this.horario.cd_agenda.cd_especialista) {
          this.perguntas = response.data;
      } else {
          this.perguntas = response.data.filter(i => i.ie_tipo_avaliado !== 'ME');
      }


      this.respostas = this.perguntas.map(() => ({
          nr_avaliacao: 0,
          ds_resposta: '',
          cd_pergunta: ''
      }))
    })
  },
  methods: {
    close() {
      this.$emit('close');
    },
    handleSubmit() {
      const respostas = this.respostas.map((item, index) => ({
        ...item,
        cd_pergunta: index + 1
      }))

      const body = {
        cd_horario: this.horario.id,
        respostas: respostas
      }

      Agendamento.avaliar(body).then(() => {
        this.$store.commit('setAvaliou', true);

        this.$buefy.toast.open({
          type: 'is-success', position: 'is-bottom',
          message: 'Obrigado pelo feedback!'
        })
      })

      this.$emit('close');
    }
  }
}
</script>