<template>
  <div class="modulo modulo_servicos" id="servicos">
    <!-- Título -->
    <div :class="{ container: windowWidth < 1000 }" style="justify-items: center;">
      <h1 class="title-servicos">Agende suas consultas, exames e cirurgias.</h1>

      <div class="flex-container">
        <div class="flex-item medclub-creditos">
          <!-- Créditos -->
          <div v-if='usuario && usuario.ie_associado_med_empresa'>
            <b-button
              v-if="medempresa"
              @click="setTipoUsuario(false)"
              class="minha-conta-mobile"
              :style="{ backgroundColor: '#ffba01', color: '#0e5fd6' }"
              icon-left="account-convert-outline"
            >
              Empresa
            </b-button>
            <b-button
              v-else-if="usuario && !medempresa"
              @click="setTipoUsuario(true)"
              class="minha-conta-mobile"
              :style="{ backgroundColor: '#0e5fd6', color: 'white' }"
              icon-left="account-convert-outline"
            >
              Pessoal
            </b-button>
          </div>
          <div class="credit-info">
            <span>Créditos Medclub</span>
            <span><b>{{ $root.formatPrice(saldo) }}</b></span>
          </div>
        </div>
  
        <!-- WhatsApp -->
        <div class="flex-item button-whatsapp-item">
          <button class="button-whatsapp" @click="gotoWhatsapp('558631311881')">
            <img src="./../../assets/img/whatsapp.png" width="23px" />
            <div class="linha-vertical"></div>
            <span>Precisando de ajuda? Acesse nosso Whatsapp!</span>
            <span class="jam jam-chevron-right"></span>
          </button>
        </div>
        
        <!-- Serviços -->
        <div class="grid-container">
          
          <div class="grid-item" v-for="(servico, index) in servicos" :key="index">
            <router-link :to="servico.link">
              <a class="card_servico">
                <span v-if="servico.icon" :class="servico.icon"></span>
                <img v-if="servico.img" :src="servico.img" class="jam-servicos jam" />
                <span class="text-base servico-name">{{ servico.name }}</span>
              </a>
            </router-link>
          </div>
        </div>
      </div>
      <div class="read_more">Role para ver mais <span class="jam jam-arrow-down"></span></div>
    </div>
  </div>
</template>

<style lang="scss">
*:not(.decorated-text) {
  text-decoration: none !important;
}

.flex-container {
  display: flex;
  max-width: 100%;
  width: 1200px;
  padding: 16px;
  margin: 0 auto;
  gap: 10px;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.flex-item {
  flex: 1 1 100%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 10px);
  }

  @media (max-width: 480px) {
    flex: 1 1 100%;
  }
}

.carousel-item-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.button-whatsapp {
  display: none;
}
.medclub-creditos {
  display: none
}

@media only screen and (max-width: 653px) {
  .quebra-linha {
    display: none;
  }
}

@media only screen and (max-width: 769px) { 
  .title-servicos {
    display: none;
  }
  .card_servico {
    span {
      font-size: 14px;
    }
  }
  .row {
    padding: 0px !important;
  }
  .col-4 {
    height: 160px !important;
    padding: 2px !important;
  }
  .modulo_servicos {
    padding: 0px !important;
  }
  .medclub-creditos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    padding: 15px;
    margin: 8px auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 1px 1px 6px 2px rgba(0, 0, 0, 0.1);
    height: 65px !important;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    .credit-info {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      text-align: center;

      span:first-child {
        font-size: 0.9rem;
        color: #656565
      }

      span:last-child {
        font-size: 1.2rem;
        font-weight: bold;
        color: #213f99;
      }
    }

    @media (max-width: 425px) {
      span:first-child {
        font-size: 0.6rem;
      }

      span:last-child {
        font-size: 0.8rem;
      }
    }
  }

  .button-whatsapp-item {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
  
  .button-whatsapp {
    display: flex;
    width: 100%;
    max-width: 600px;
    height: auto;
    align-items: center;
    justify-content: center;
    padding: 15px;
    margin: 8px auto;
    background-color: #007F2D;
    color: white;
    border-radius: 8px;

    img {
      margin-right: 10px;
      width: 24px;
    }

    .linha-vertical {
      display: block;
      width: 1px;
      height: 14px;
      background-color: white;
      margin: 0 10px;
    }

    span {
      font-size: 1rem;
    }

    @media (max-width: 480px) {
      flex-direction: wrap;
      text-align: center;
      img {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (min-width: 769px) {
  .card-desktop {
    width: 150px !important;
    height: 150px !important;
    margin-left: 10px !important;
  }

  .class-router-link {
    height: 100%;
  }

  #servicos {
    padding-top: 10px !important;
  }

  .jam-servicos {
    width: 45px !important;
    font-size: 45px !important;
  }

  .title-servicos {
    font-size: 1.7rem !important;
    font-weight: 500 !important;
  }

  .modulo_servicos {
    .carousel-control-prev {
      left: 5%;

      .carousel-control-prev-icon {
        width: 50px !important;
        height: 50px !important;
      }
    }

    .carousel-control-next {
      right: 3%;

      .carousel-control-next-icon {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }

  .router-link a {
    display: flex;
    align-items: center;
  }
}

@media only screen and (min-width: 996px) {
  .card-desktop {
    width: 112px !important;
    height: 112px !important;
    margin-left: 10px !important;
  }

  .carousel-item-card {
    width: 126px;
  }

  .servico-name {
    font-size: 0.8rem !important;
  }

  .card-desktop img {
    width: 38px !important;
  }

  .card-desktop .jam {
    font-size: 38px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .carousel-item-card {
    width: 120px;
  }

  .servico-name {
    font-size: 0.8rem !important;
  }

  .card-desktop img {
    width: 38px !important;
  }

  .card-desktop .jam {
    font-size: 38px !important;
  }
}

@media only screen and (min-width: 1240px) {
  .carousel-item-card {
    width: 150px;
  }
}

@media only screen and (min-width: 1440px) {
  .carousel-item-card {
    width: 164px;
  }
}

@media only screen and (max-width: 430px) {
  .button-whatsapp {
    width: 100%;
    padding: 15px 10px;
    margin: 8px auto;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #007F2D;
    color: white;

    img {
      width: 18px;
      margin-left: 8px;
    }

    .linha-vertical {
      height: 14px;
      border-left: 1px solid white;
      margin: 0 8px;
    }

    span {
      font-size: 0.75rem;
      font-weight: 400;
      text-align: center;
    }

    .jam-chevron-right {
      font-size: 1rem;
      margin-right: 5px;
    }
  }

  .medclub-creditos .credit-info span:first-child {
    font-size: 0.8rem;
  }
  .medclub-creditos .credit-info span:last-child {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 375px) {
  .button-whatsapp span {
      font-size: 0.65rem;
      font-weight: 400;
      text-align: center;
    }
  }

</style>

<script>
export default {
  props: ["saldo"],
  data() {
    return {
      windowWidth: window.innerWidth,
      usuario: JSON.parse(localStorage.getItem("MEDCLUBSITE_USER")) || null,
      medempresa: JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER")) || null,
      servicos: [
        { name: "Consultas", link: "/agendar/consulta", icon: "jam-servicos jam jam-medical" },
        { name: "Exames", link: "/agendar/exames", icon: "jam-servicos jam jam-activity" },
        { name: "Procedimentos", link: "/agendar/procedimentos", icon: "jam-servicos jam jam-bandage" },
        { name: "Cirurgias", link: "/agendar/cirurgia", icon: "jam-servicos jam jam-cutter" },
        { name: "Odonto", link: "/agendar/odonto", img: require('@/assets/img/odonto/odonto.png') },
        { name: "Pronto Socorro", link: "/pronto-socorro", img: require('@/assets/img/pronto.png') },
        { name: "Check-up", link: "/agendar/checkup", icon: "jam-servicos jam jam-task-list" },
        { name: "Resultados de Exames", link: "/agendar/resultados", img: require('@/assets/img/resultados-icon.png') },
        { name: "Medclub Vantagens", link: "/medclub-vantagens", img: require('@/assets/img/vantagens-icon.png') },
        { name: "Ver Preços", link: "/verpreco", icon: "jam-servicos jam jam-coin" },
        { name: "Pergunte ao Doutor", link: "https://oiaqui.com/", icon: "jam-servicos jam jam-message" },
        { name: "Dúvidas?", link: "/duvidas-frequentes", icon: "jam-servicos jam jam-help" },
      ],
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    openLogin() {
      this.$parent.$parent.$parent.dialog.login = true;
    },
    setTipoUsuario(valor) {
      let usuario = JSON.parse(localStorage.getItem("MEDCLUBSITE_USER"));
      if (valor && usuario?.ie_associado_med_empresa)
        localStorage.setItem("MEDCLUB_TYPE_USER", usuario.ie_associado_med_empresa);
      else localStorage.setItem("MEDCLUB_TYPE_USER", valor);
      this.$router.go();
    },
    gotoWhatsapp(number) {
      const url = `https://api.whatsapp.com/send?phone=${number}`;
      window.open(url, "_blank");
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
