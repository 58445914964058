import GenericDAO from './../generic-dao'

const getCarrinho = (params) => GenericDAO.find(`/appuse/carrinho/`, params, { auth: true })
const addToCarrinhoConsulta = (idHorario, body) => GenericDAO.save(`/appuse/reservar-horario/${ idHorario }/`, body, { method: 'PATCH' })
const addToCarrinhoExame = (idHorario, body) => GenericDAO.save(`/appuse/reservar-horario-exame/${ idHorario }/`, body, { method: 'PATCH' })
const limparCarrinho = (params) => GenericDAO.find(`/appuse/limpar-carrinho/`, params, { auth: true })
const getCartoes = () => GenericDAO.find(`/appuse/pessoa-fisica/cartao/`, null, { auth: true })
const addCartao = (body) => GenericDAO.save(`/appuse/pessoa-fisica/cartao/`, body, { auth: true })
const removeCartao = (idCartao) => GenericDAO.remove(`/appuse/pessoa-fisica/cartao/${ idCartao }/`, null, null, { auth: true })
const checkParcelas = (valor) => GenericDAO.find(`/appuse/transacao/installments/`, { nr_valor: valor },  { auth: true })
const pagar = (body) => GenericDAO.save(`/appuse/transacao/`, body, { auth: true })
const comprarCreditos = (body) => GenericDAO.save(`/appuse/medcred/comprar/`, body, { auth: true })
const getSaldoCreditos = (params) => GenericDAO.find(`/appuse/medcred/saldo/`, params,  { auth: true })
const getExtratoCreditos = (params) => GenericDAO.find(`/appuse/credito-debito/extrato/`, params,  { auth: true })
const resgate = (body) => GenericDAO.save(`/appuse/resgate-creditos/`, body,  { auth: true })
const getBancos = () => GenericDAO.find('/appuse/bancos')
const confimarPedido = (body) => GenericDAO.save(`/appuse/confirmar-horario-med-empresa/`, body, { auth: true })

export default {
    getCarrinho,
    addToCarrinhoConsulta,
    addToCarrinhoExame,
    limparCarrinho,
    getCartoes,
    addCartao,
    removeCartao,
    checkParcelas,
    pagar,
    comprarCreditos,
    getSaldoCreditos,
    getExtratoCreditos,
    resgate,
    getBancos,
    confimarPedido
}