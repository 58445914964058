import Vue from "vue"
import VueRouter from "vue-router"
Vue.use(VueRouter)

const routes = [
    { name: 'convite', path: '/convite/:code', component: () => import('./components/Home/Convite.vue') },
    {
        path: '/',
        component: () => import('./components/index.vue'),
        redirect: '/home',
        children: [
            { name: 'home', path: 'home', component: () => import('./components/Home/Home.vue') },
            { name: 'cadastrar', path: 'cadastrar', component: () => import('./components/Cadastro/Form.vue') },
            //Cadastro a partir do amigo medclub
            { name: 'cadastrarAmigo', path: 'cadastrar/:ds_code', component: () => import('./components/Cadastro/Form.vue') },
            { name: 'editarPerfil', path: 'editar-perfil', component: () => import('./components/Cadastro/Editar.vue') },
            { name: 'agendarConsulta', path: 'agendar/consulta', component: () => import('./components/Agendamento/Consultas.vue') },
            { name: 'agendarOdonto', path: 'agendar/odonto', component: () => import('./components/Agendamento/Odonto.vue') },
            { name: 'resultadoExames', path: 'agendar/resultados', component: () => import('./components/Agendamento/ResultadosExames.vue') },
            //Penúltima etapa do agendamento:
            { name: 'agendarConsultaCompartilhar', path: 'agendar/consulta/:ds_telemedicina/:pesquisar_por?/:slug?', component: () => import('./components/Agendamento/Consultas.vue') },
            { name: 'agendarOdontoCompartilhar', path: 'agendar/odonto/:ds_telemedicina/:pesquisar_por?/:slug?', component: () => import('./components/Agendamento/Odonto.vue') },
            { name: 'agendarExames', path: 'agendar/exames', component: () => import('./components/Agendamento/Exames.vue') },
            { name: 'agendarExamesLaboratoriais', path: 'agendar/exames-laboratoriais/:slug?', component: () => import('./components/Agendamento/Etapas/ExamesLaboratoriais.vue') },
            { name: 'agendarOutrosExames', path: 'agendar/outros-exames/:procedimento?/:slug?/:query?', component: () => import('./components/Agendamento/Etapas/OutrosExames.vue') },
            // { name: 'agendarOutrosExames', path: 'agendar/outros-exames', component: () => import('./components/Agendamento/Etapas/OutrosExames.vue') },
            { name: 'agendarProcedimentos', path: 'agendar/procedimentos', component: () => import('./components/Agendamento/Procedimentos.vue') },
            { name: 'agendarCheckup', path: 'agendar/checkup', component: () => import('./components/Agendamento/Checkup.vue') },
            { name: 'agendarCirurgia', path: 'agendar/cirurgia', component: () => import('./components/Agendamento/Cirurgia.vue') },
            { name: 'carrinho', path: 'carrinho', component: () => import('./components/Pagamento/Carrinho.vue') },
            { name: 'pagamento', path: 'pagamento', component: () => import('./components/Pagamento/Pagamento.vue') },
            { name: 'pagamentoPix', path: 'pagamento-pix', component: () => import('./components/Pagamento/PixQrCode.vue') },
            { name: 'meusCartoes', path: 'meus-cartoes', component: () => import('./components/Pagamento/MeusCartoes.vue') },
            { name: 'meusAgendamentos', path: 'meus-agendamentos', component: () => import('./components/Agendamento/MeusAgendamentos.vue') },
            { name: 'outrasPessoas', path: 'outras-pessoas', component: () => import('./components/Cadastro/OutrasPessoas.vue') },
            { name: 'esqueciMinhaSenha', path: 'esqueci-minha-senha', component: () => import('./components/Cadastro/EsqueciMinhaSenha.vue') },
            { name: 'duvidasFrequentes', path: 'duvidas-frequentes', component: () => import('./components/Home/DuvidasFrequentes.vue') },
            { name: 'meusCreditos', path: 'meus-creditos', component: () => import('./components/Pagamento/MeusCreditos.vue') },
            { name: 'verPreco', path: 'verpreco', component: () => import('./components/Orcamento/EscolherCategoria.vue') },
            { name: 'orcamento', path: 'orcamento', component: () => import('./components/Orcamento/Selecionar.vue') },
            { name: 'orcamentoCompartilhar', path: 'orcamento/:ids', component: () => import('./components/Orcamento/Selecionar.vue') },
            { name: 'consultaPorVideo', path: 'consulta-por-video', component: () => import('./components/ConsultaPorVideo/ConsultaPorVideo.vue') },
            { name: 'termosECondicoes', path: 'termos-e-condicoes', beforeEnter() { window.location.href = 'https://medclub.com.br/public/termos-e-condicoes.pdf' } },
            { name: 'carteiraMedclub', path: 'carteira-medclub', component: () => import('./components/Home/Carteira.vue') },
            { name: 'medclubVantagens', path: 'medclub-vantagens', component: () => import('./components/Home/Vantagens.vue') },
            { name: 'areaAmigoMedclub', path: 'area-amigo-medclub', component: () => import('./components/Home/AreaAmigoMedclub.vue') },
            { name: 'locaisAtendimento', path: 'locaisAtendimento', component: () => import('./components/Home/LocaisAtendimento.vue') },
            { name: 'prontoSocorro', path: 'pronto-socorro', component: () => import('./components/Agendamento/ProntoSocorro.vue') },
            { name: 'regulamento', path: 'public/regulamento-amigo-medclub', beforeEnter: () => { window.location.href = 'https://medclub.com.br/public/regulamento-amigo-medclub.pdf' } },
            { name: 'noticia', path: 'noticia/:id', component: () => import('./components/Home/Noticia.vue') },
            { name: 'localatendimento', path: 'local/:id', component: () => import('./components/Home/LocalAtendimento.vue') },
            { name: 'todasAsNoticias', path: 'todas-as-noticias', component: () => import('./components/Home/TodasAsNoticias.vue') },
            { name: 'quemSomos', path: 'quem-somos', component: () => import('./components/Home/QuemSomos.vue') },
            { name: 'paraEmpresas', path: 'para-empresas', component: () => import('./components/Home/ParaEmpresas.vue') },
        ]
    },
    { name: 'manutencao', path: '/manutencao', component: () => import('./components/MaintenanceMode.vue') },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({ 
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router