<template>
    <b-modal v-model='dialog' has-modal-card trap-focus :destroy-on-hide='false' aria-role="dialog" aria-modal>
    <template>
      <div class="modal-card p-4 md:p-0">
        <header class="modal-card-head">
          <p class="modal-card-title text-xl font-bold">Como foi sua última consulta?</p>
          <button class="delete" type='button' @click="() => $emit('close')"></button>
        </header>
        <section class="modal-card-body">
          <div class="mx-auto text-center mb-4">Avalie a consulta e agradeça à {{especialista ? especialista.nm_especialista : estabelecimento.nm_fantasia}}</div>

          <div class="flex flex-col items-center mb-4" v-if="especialista">
            <div class="mb-4">Especialista</div>
            <img :src="especialista.aq_foto" width="100" class="rounded-full"/>
          </div>

          <div class="flex flex-col items-center mb-4" v-if="especialista">
            <div class="font-bold">{{especialista.nm_especialista}}</div>
            <div>{{especialista.nm_conselho}} {{especialista.nr_conselho}} / {{especialidade.nm_especialidade}}</div>
          </div>

          <div class="flex flex-col items-center">
            <img  v-if="!especialista" :src="estabelecimento.aq_foto" width="100" class="" style='border-radius: 10%'/>
            <div class="font-bold">{{estabelecimento.nm_fantasia}}</div>
            <div>{{estabelecimento.nm_rua}}, {{estabelecimento.nr_estabelecimento}}</div>
          </div>
        </section>
        <footer class="modal-card-foot flex flex-wrap justify-center p-4">
          <b-button type="is-primary" class="w-full sm:w-40 my-0 mx-auto" @click="() => $emit('avaliar')">Avaliar Agora</b-button>
        </footer>
      </div>
    </template>
  </b-modal>
</template>
<style lang="scss" scoped>
</style>
<script>
export default {
  props: ['consulta'],
  data() {
    return {
      dialog: true,
      especialista: {},
      especialidade: {},
      estabelecimento: {}
    } 
  },
  created() {
    this.especialista = this.consulta.cd_agenda.cd_especialista;
    this.especialidade = this.consulta.cd_especialidade;
    this.estabelecimento = this.consulta.cd_estabelecimento;
  },
}
</script>