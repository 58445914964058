import GenericDAO from './../generic-dao'

const getAnexos = (cdGuiaEletronica, idPaciente) => GenericDAO.find(`/appuse/guia-eletronica/${ cdGuiaEletronica }/paciente/${ idPaciente }/anexo/`, null, { auth: true })
const removeAnexo = (cdGuiaEletronica, idPaciente, cdAnexo) => GenericDAO.remove(`/appuse/anexo/${ cdGuiaEletronica }/${ idPaciente }/`, { cd_anexo: cdAnexo }, null, { auth: true })
const executarGuia = (cdHorario, body) => GenericDAO.save(`/appuse/guia-eletronica-paciente/horario-agendamento/${ cdHorario }/`, body, { method: 'PATCH' })
const atualizarStatusGuia = (cdHorario, status) => GenericDAO.save(`/appuse/guia-eletronica-paciente/horario-agendamento/${ cdHorario }/status/`, { status }, { method: 'PATCH' })

export default {
    getAnexos,
    removeAnexo,
    executarGuia,
    atualizarStatusGuia
}