<template>
    <div class="flex justify-center items-center flex-wrap">
        <b-modal  v-model="dialog" :can-cancel="['x']" has-modal-card trap-focus :destroy-on-hide="false" :aria-modal="true">
            <template>
                <div class="modal-card md:p-0" style="width: 400px">
                    <header class="modal-card-head flex justify-between">
                        <p class="modal-card-title text-center"><b>Entrar</b></p>
                        <button
                            type="button"
                            class="delete"
                            @click="handleModal()"/>
                    </header>
                    <section class="modal-card-body p-6">
                         <div class='mt-2 mb-4 text-xs bg-yellow-200 p-2 border border-yellow-300 w-full'>
                            <span class='text-sm'>Informe o usuário e a senha do seu comprovante de coleta.</span>
                        </div>
                        <div v-if='errorMessage != ""' class='mt-2 mb-4 text-xs bg-red-200 p-2 border border-yellow-300 w-full'>
                            <span class='text-sm'>{{errorMessage}}</span>
                        </div>
                       
                        <!-- <form name="formulario" :method="isShift ? 'post': 'dialog'" @submit="validaFrm(this)" :action="isShift ? 'https://neolabteresina.shiftcloud.com.br/shift/lis/neolabteresina/elis/s01.iu.web.Login.cls?config=UNICO' : '/'" target="_blank"> -->
                        <form name="formulario">
                            <fieldset id="SHIFT" >
                                <label for="UserName"><b>Usuário:</b></label> 
                                <b-input type="text" name="UserName" maxlength="20" v-model='model.username'/>
                                <label for="password"><b>Senha:</b></label> 
                                <b-input  type=password name="Password" maxlength="20" v-model='model.password'/>
                                <!-- <div style="width: 100%; display: flex; flex-direction: column; align-items: center">
                                    <div class="b-utton" type="submit" >
                                        <input name="botao" type="submit" value="Acessar" />
                                            <b-icon class='text-lg' pack='jam' icon='jam-gps'></b-icon>
                                    </div>
                                </div> -->
                                <div style="width: 100%; display: flex; flex-direction: column; align-items: center">
                                    <b-button class='b-utton' type="is-primary" @click="validaFrm()">
                                        Acessar
                                        <b-icon style="margin-left: 1px" class='text-lg' pack='jam' icon='jam-gps'></b-icon>
                                    </b-button>
                                </div>
                            </fieldset>
                        </form>

                    </section>
                    <footer class="modal-card-foot justify-center">
                        
                        <!-- <div class='visao-desktop' style='display: contents !important'>
                            <b-button class="w-1/2" type="is-warning" native-type="button" rounded @click="goToLinkResultadoAntigo()">Resultados <b>antes</b> de 15/10/2024</b-button>
                            <b-button class="w-1/2" type="is-primary" native-type="button" rounded @click="goToLinkResultadoNovo()">Resultados <b>a partir</b> de 15/10/2024</b-button>
                        </div> -->
                    </footer>
                </div>
            </template>
        </b-modal>

        <div class="max-w-screen-lg w-full relative" style="min-height:75vh">
            <div class="px-4 pb-8 md:py-36">
                <div class="absolute top-0 left-0 p-2">
                    <a class="text-3xl" @click="back()">
                        <i class="jam jam-chevron-left"></i>
                    </a>
                </div>
                <div style="margin-top: 100px"> 
                    <h1 class="text-4xl text-gray-700 font-semibold text-center">Resultados de Exames</h1>
                    <p class="text-2xl text-gray-700 text-center" >No momento, só temos resultados disponíveis para o estabelecimento Medclub 2</p>

                </div>
                <div class="flex flex-wrap justify-center">
                    <div class="w-full text-center" style="height: 20rem;">
                        <lottie-animation path="lottie/resultados.json" :loop='false' style="width: 20rem;"></lottie-animation>
                    </div>
                    <section class="mx-auto w-full md:w-1/3" style='margin-top: -40px'>
                        <div class="p-2 w-full">
                            <b-button class="w-full" size='is-medium' type="is-primary" @click="handleModal()" rounded>
                                <div class="text-xl">
                                    <span class="jam jam-flask"></span>
                                    Laboratoriais
                                </div>
                            </b-button>
                        </div>
                        <div class="p-2 w-full">
                            <b-button class="w-full bg-red-600" style="color: #FFF" size='is-medium' @click="goToLinkResultadoImagem()" rounded >
                                    <div class="text-xl">
                                        <span class="jam jam-activity"></span>
                                        Imagem
                                    </div> 
                            </b-button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
    .error { 
        color: red
    }
    .b-utton {
        background-color: #213F99;
        padding: 5px;
        border-radius: 5px;
        color: white;
        width: 180px;
        display: flex;
        justify-content: center;
        input {
            background-color: transparent;
        }
    }
    @media (min-width: 1001px) {
        .visao-mobile {
            display: none;
        }
    }
    @media (max-width: 1000px) {
        .visao-desktop {
            display: none;
        }
    }

</style>
<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { Agendamento } from '../../middleware'

export default {
    name: 'ResultadosExames',
    components: { LottieAnimation },
    data () {
            return {
                dialog: false,
                agendamentoCriado: false,
                link_url: null,
                username: '',
                password: '',
                model: {
                    username: '',
                    password: '',
                },
                errorMessage: '',
                waiting: false,
            }
        },
     methods: {
        back() {
            this.$router.push({name:"home"})
        },
        handleModal() {
            this.dialog = !this.dialog
        },
        goToLinkResultadoAntigo() {
            window.open(`http://dexlab.ddns.net:82/`, '_blank');

        },
        goToLinkResultadoNovo() {
            window.open(`https://neolabteresina.shiftcloud.com.br/shift/lis/neolabteresina/elis/s01.iu.web.Login.cls`, '_blank');

        },
        goToLinkResultadoImagem() {
            window.open(`http://resultado.medclub.com.br/`, '_blank');

        },

        async validaFrm() {
            if(this.model.username == ''){
                this.errorMessage = 'Preencha o usuário.'
                return ;
            } 
            if(this.model.password == '') {
                this.errorMessage = 'Informe sua senha..'
                return ;
            }
            if(this.model.username[0] !== 'P') {
                if(this.verificaDexLab()) {
                    this.loginDexlab()
                    this.errorMessage = ''
                    this.dialog = false
                }
                else {
                    this.errorMessage = 'Falha no login. Verifique suas credenciais.'
                    return ;
                    
                }
            } else {
                if(await this.verificaShift()) {
                    this.loginShift('https://neolabteresina.shiftcloud.com.br/shift/lis/neolabteresina/elis/s01.iu.web.Login.cls?config=UNICO', this.model)
                    this.errorMessage = ''
                } else {
                    this.errorMessage = 'Falha no login. Verifique suas credenciais.'
                    return 
                }

                
            }

        },

        async verificaDexLab() {
            this.waiting = true
            return await Agendamento.validaUsuarioExame({username: this.model.username, senha: this.model.password}).then(response => {
                this.waiting = false
                return response.status === 200
                
            })
        },
        async verificaShift() {
            this.waiting = true
            return await Agendamento.validaUsuarioExameShift({username: this.model.username, senha: this.model.password}).then(response => {
                this.waiting = false
                return response.status === 200
                
            })
        },
        loginDexlab() {
            window.open(`http://dexlab.ddns.net:82?U=${this.model.username}&S=${this.model.password}`, '_blank')
        },

        loginShift(url, data) {
            var form = document.createElement("form");
            form.target = "_blank";
            form.method = "POST";
            form.action = url;
            form.style.display = "none";

            for (var key in data) {
                var input = document.createElement("input");
                input.type = "hidden";
                input.name = key == 'username' ? 'UserName' : 'Password';
                input.value = data[key];
                form.appendChild(input);
            }
            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }
     }
}
</script>

<style scoped>
    a:hover {
        color: white
    }
</style>