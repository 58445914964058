<template lang="pug">
    .w-full
        .absolute.top-0.left-0.p-2(v-if='! waiting.carrinho')
            a.text-3xl(@click='$parent.agendamentoCriado = false; back()')
                i.jam.jam-chevron-left

        b-modal(v-model='dialog.cadastrar' :can-cancel="[ 'x' ]")
            FormCadastro(:isModal='true')

        b-modal(v-model='dialog.logar' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal)
            template(#default='props')
                Login(ref='loginComponent' :modalProps='props' :cadastro='false' :redirecionar='false')

        b-modal(v-model='dialog.outrasPessoas' has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal)
            template(#default='props')
                OutrasPessoasForm(ref='outrasPessoasComponent' :modalProps='props')

        .py-56(v-if='waiting.pessoas')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.pessoas')
                
        .py-56(v-else-if='waiting.carrinho')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.carrinho')

        .w-full(v-else-if='! usuario')
            .my-10.p-6.text-center
                h1.font-bold.text-md Cadastre-se ou entre na sua conta
                p.text-sm.mt-4.mx-auto(class='w-5/6') Para continuar, você precisa se cadastrar ou entrar na sua conta. Não se preocupe, o cadastro é grátis.
            .flex.flex-wrap.justify-center.px-10
                b-button.w-full.rounded-full(class='md:w-1/3' type='is-primary' @click="dialog.cadastrar = true") Cadastrar grátis
                p.my-4.w-full.text-center ou
                b-button.w-full.rounded-full(class='md:w-1/3' type='is-info' @click='dialog.logar = true') Já tenho uma conta
        
        .w-full(v-else)
            .pt-4.pb-3.text-center.w-full
                h1.font-bold.text-medclub-blue-300.text-sm Escolha o paciente
            .p-2.flex.flex-wrap
                .p-2(class='w-1/2 md:w-1/5')
                    .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border.cursor-pointer(@click='goToCarrinho(usuario)')
                        .w-full.text-center
                            b-icon.text-4xl.my-4(pack='jam' icon='jam-user')
                            p.titulo-nome(:class="{grande: usuario.nm_pessoa_fisica.length <=20, medio: usuario.nm_pessoa_fisica.length > 20 && usuario.nm_pessoa_fisica.length <= 35, pequeno: usuario.nm_pessoa_fisica.length > 35 }") Eu ({{ usuario.nm_pessoa_fisica }})
                .p-2(class='w-1/2 md:w-1/5' v-for='pessoa in pessoas')
                    .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border.cursor-pointer(@click='goToCarrinho(pessoa)')
                        .w-full.text-center
                            b-icon.text-4xl.my-4(pack='jam' icon='jam-user')
                            p.titulo-nome(:class="{grande: pessoa.tamanho <=20, medio: pessoa.tamanho > 20 && pessoa.tamanho <= 35, pequeno: pessoa.tamanho > 35 }") {{ pessoa.nm_pessoa_fisica }}
                .p-2(class='w-1/2 md:w-1/5' v-if='!medempresa')
                    .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border.cursor-pointer(@click='dialog.outrasPessoas = true')
                        .w-full.text-center
                            b-icon.text-4xl.my-4(pack='jam' icon='jam-user-plus')
                            p.text-sm Outra pessoa

</template>

<style lang="scss">
    .titulo-nome {
        text-transform: uppercase !important;
        &.pequeno {
            font-size: 12px !important;
        }
        &.medio {
            font-size: 14px !important;
        }
        &.grande {
            font-size: 15px !important;
        }
        
    }
</style>


<script>
    import moment from 'moment'
import { Pagamento, OutrasPessoas, Agendamento } from '../../../middleware'
    import FormCadastro from './../../Cadastro/Form'
    import Login from './../../Cadastro/Login'
    import OutrasPessoasForm from './../../Cadastro/OutrasPessoasForm'
    export default {
        components: { FormCadastro, Login, OutrasPessoasForm },
        created () {
            if (this.$parent.$parent.$parent.usuario)
                this.usuario = this.$parent.$parent.$parent.usuario
            else if (this.$parent.$parent.$parent.$parent.$parent.usuario)
                this.usuario = this.$parent.$parent.$parent.$parent.$parent.usuario
            this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            
            if (this.usuario) this.getPessoas()
        },
        watch: {
            usuario: function (usuario) {
                if (usuario) {
                    
                    if (this.$parent.$parent.$parent.usuario)
                        this.usuario = this.$parent.$parent.$parent.usuario
                    else if (this.$parent.$parent.$parent.$parent.$parent.usuario)
                        this.usuario = this.$parent.$parent.$parent.$parent.$parent.usuario

                    this.dialog.cadastrar = false
                    this.dialog.logar = false
                    this.getPessoas()
                    this.$forceUpdate()
                }
            },
            'dialog.logar': function (opened) {
                if (this.$refs.loginComponent) this.$refs.loginComponent.model.reset()
                this.toggleHelpButton(opened)
            },
            'dialog.cadastrar': function (opened) {
                this.toggleHelpButton(opened)
            },
            'dialog.outrasPessoas': function (opened) {
               /*  if (this.$refs.outrasPessoasComponent) this.$refs.outrasPessoasComponent.model.reset() */
                this.toggleHelpButton(opened)
            },
        },
        data () {
            return {
                usuario: null,
                pessoas: [],
                dateList: [],
                data: [],
                medempresa: false,
                waiting: { pessoas: false, carrinho: false },
                dialog: { cadastrar: false, logar: false, outrasPessoas: false }
            }
        },
        methods: {
            openPessoa (pessoa, params) { // eslint-disable-line
                this.dialog.outrasPessoas = true

                let model = {}
                model.id = pessoa.id
                model.nm_pessoa_fisica = pessoa.nm_pessoa_fisica
                model.nr_telefone = this.$root.applyTelefoneMask(pessoa.nr_telefone)
                model.dt_nascimento = moment(pessoa.dt_nascimento, 'YYYY-MM-DD', true).format('DD/MM/YYYY')
                model.ie_sexo = pessoa.ie_sexo

                if(this.$refs.outrasPessoasComponent){
                    this.$refs.outrasPessoasComponent.updateModel(model, params.titular)
                }else{
                    setTimeout(()=>{this.$refs.outrasPessoasComponent.updateModel(model, params.titular)}, 0)
                }
            
            },
            back() {
                if(this.$parent.pesquisarPor == 'sintoma') {
                    this.$parent.agendamento.estabelecimento = null
                    this.$parent.agendamento.horario = null
                    // if(this.$route.name !== 'agendarConsulta') this.$router.push({ name: 'agendarConsulta' })
                }
                if(this.$route.name == 'agendarCheckup') this.$parent.agendamento.estabelecimento = null
            },
            toggleHelpButton (trigger) {
                if (trigger) {
                    const helpButtonMobile = document.getElementById('helpButtonMobile')
                    if (helpButtonMobile) helpButtonMobile.classList.add('hidden')
                } else {
                    const helpButtonMobile = document.getElementById('helpButtonMobile')
                    if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')
                }
            },
            getPessoas () {
                this.waiting.pessoas = true
                let params = {
                    ie_atende_medclub_empresa: false,
                }
                if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))


                OutrasPessoas.getOutrasPessoas(params).then(response => {
                    this.waiting.pessoas = false
                    if (response.status === 200) this.pessoas = response.data
                    this.pessoas.forEach(pessoa => {
                        pessoa['tamanho'] = pessoa.nm_pessoa_fisica?.length
                    })
                })
            },
            goToCarrinho (paciente) {
                this.$parent.agendamento.paciente = paciente
                this.waiting.carrinho = true
                var usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
                if (this.$route.name === 'agendarCheckup') {
                    const params = {
                        ie_tipo_agenda: 'EL',
                        cd_estabelecimento: this.$parent.agendamento.estabelecimento.cd_estabelecimento.id,
                        cd_cidade: this.$store.state.cdCidade,
                        cd_estado: this.$store.state.cdEstado,
                        ie_atende_medclub_empresa: false,
                    }
                    if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))
                    Agendamento.getCalendario(params).then(response => {
                        if (response.status === 200) {
                        let datas = response.data

                        datas.forEach(data => {
                            this.dateList.push(this.$root.moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY'))
                        })
                        this.data = this.dateList[0]
                        params['dt_agenda'] = this.$root.moment(this.dateList[0], 'DD/MM/YYYY').format('YYYY-MM-DD');
                        Agendamento.getEstabelecimentos2(params).then(response => {
                        if (response.status === 200) {
                            if (Object.values(response.data).length) {
                                let horario = Object.values(response.data)[0][0]
                                let procedimentos = this.$parent.agendamento.estabelecimento.cd_procedimentos
                                let isMedEmpresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                                let body = {
                                    ie_associado_med_empresa: isMedEmpresa ? true : false,
                                    cd_empresa: usuario.empresas_associado[0]?.cd_empresa,
                                    cd_pessoa_fisica: this.$parent.agendamento.paciente.id,
                                    cd_procedimento: procedimentos.map(p => p.id),
                                    cd_estabelecimento: this.$parent.agendamento.estabelecimento.cd_estabelecimento.id,
                                    cd_checkup: this.$parent.agendamento.estabelecimento.id,
                                    ie_reservar: 'R',
                                }
                                Pagamento.addToCarrinhoExame(horario.hr_horario[0].id, body).then(response => {
                                    if (response.status === 200) {
                                        this.$gtag.event('adicionou_ao_carrinho', { item: body })
                                        this.$analytics.fbq.event('AddToCart')
                                        this.$router.push({ name: 'carrinho' })
                                    

                                        /*if (this.$parent.agendamento.especialista) {

                                            let body = {
                                                cd_pessoa_fisica: this.$parent.agendamento.paciente.id,
                                                cd_especialista: this.$parent.agendamento.especialista.id,
                                                cd_especialidade: this.$parent.agendamento.especialidade.id,
                                                cd_estabelecimento: this.$parent.agendamento.estabelecimento.cd_estabelecimento.id,
                                                cd_checkup: this.$parent.agendamento.estabelecimento.id,
                                                ie_reservar: 'R'
                                            }
                                            Pagamento.addToCarrinhoConsulta(this.$parent.agendamento.horario.id, body).then(response => {
                                                if (response.status === 200) {
                                                    this.$gtag.event('adicionou_ao_carrinho', { item: body })
                                                    this.$analytics.fbq.event('AddToCart')
                                                    this.$router.push({ name: 'carrinho' })
                                                } else if (response.status === 400) {
                                                    Object.values(response.data).forEach(message => {
                                                                                                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message })
                                                    })
                                                    this.waiting.carrinho = false
                                                }
                                            })

                                        } else */

                                    } else if (response.status === 400) {
                                        if(response.data.cd_empresa){
                                            this.$buefy.toast.open({
                                                duration: 8000,
                                                position: 'is-bottom',
                                                message: response.data.cd_empresa[0],
                                                type: 'is-danger',
                                            })
                                        }
                                        if(response.data.cd_pessoa_fisica){
                                            this.$buefy.toast.open({
                                                duration: 8000,
                                                position: 'is-bottom',
                                                message: response.data.cd_pessoa_fisica[0],
                                                type: 'is-danger',
                                            })

                                        }
                                        if(response.data.detail) {
                                            this.$buefy.toast.open({
                                                duration: 8000,
                                                position: 'is-bottom',
                                                message: response.data.detail,
                                                type: 'is-danger',
                                            })
                                        }
                                        this.waiting.carrinho = false
                                    }
                                })

                            }
                            else {
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Nenhum horário disponível' })
                                this.waiting.carrinho = false
                            }
                        }
                    })
                        }
                    
                    })
                
                    

                } else if (this.$parent.agendamento.ie_tipo_agenda === 'C') {
                    let isMedEmpresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                    let body = {
                        ie_associado_med_empresa: isMedEmpresa ? true : false,
                        cd_pessoa_fisica: this.$parent.agendamento.paciente.id,
                        cd_especialista: this.$parent.agendamento.especialista.id,
                        cd_especialidade: this.$parent.agendamento.especialidade.id,
                        cd_estabelecimento: this.$parent.agendamento.estabelecimento.id,
                        cd_empresa: usuario.empresas_associado[0]?.cd_empresa,
                        ie_reservar: 'R'
                    }
                    Pagamento.addToCarrinhoConsulta(this.$parent.agendamento.horario.id, body).then(response => {
                        if (response.status === 200) {
                            this.$gtag.event('adicionou_ao_carrinho', { item: body })
                            this.$analytics.fbq.event('AddToCart')
                            this.$router.push({ name: 'carrinho' })
                        } else if (response.status === 400) {
                            this.waiting.carrinho = false
                            if(response.data.cd_horario_agendamento){
                                this.$buefy.toast.open({
                                    position: 'is-bottom',
                                    message: response.data.cd_horario_agendamento[0],
                                    type: 'is-danger',
                                })
                            }
                            if(response.data.cd_empresa){
                                this.$buefy.toast.open({
                                    duration: 8000,
                                    position: 'is-bottom',
                                    message: response.data.cd_empresa[0],
                                    type: 'is-danger',
                                })

                            }
                            if(response.data.cd_pessoa_fisica){
                                this.$buefy.toast.open({
                                    duration: 8000,
                                    position: 'is-bottom',
                                    message: response.data.cd_pessoa_fisica[0],
                                    type: 'is-danger',
                                })

                            }
                            if(response.data.detail) {
                                this.$buefy.toast.open({
                                    duration: 8000,
                                    position: 'is-bottom',
                                    message: response.data.detail,
                                    type: 'is-danger',
                                })
                            }
                            const errorMessages = Object.values(response.data).map(i => i[0])
                            if(errorMessages.find(e=> e ==='missing_cpf' )){
                                this.$buefy.dialog.alert({
                                    title: "Ação necessária",
                                    message: 'Para realizar este agendamento é necessário o cadastro do CPF no perfil.',
                                    confirmText: 'Atualizar agora.',
                                    type: 'is-warning',
                                    onConfirm: () => {
                                        const helpButtonMobile = document.getElementById('helpButtonMobile')
                                        if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')

                                        this.openPessoa(this.$parent.agendamento.paciente, {titular:  true})
                                    }
                                })
                            }
                            if(errorMessages.find(e=> e ==='missing_cpf_dependente' )){
                                this.$buefy.dialog.alert({
                                    title: "Ação necessária",
                                    message: 'Para realizar este agendamento é necessário cadastrar o <b>CPF</b> no perfil do dependente.',
                                    confirmText: 'Atualizar agora.',
                                    type: 'is-warning',
                                    onConfirm: () => {
                                        const helpButtonMobile = document.getElementById('helpButtonMobile')
                                        if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')

                                        this.openPessoa(this.$parent.agendamento.paciente, {titular: false})
                                    }
                                })
                            }

                            /* Object.values(response.data).forEach(message => {
                                if(message[0] === 'missing_cpf'){
                                    this.$router.push({ name: 'editarPerfil' })
                                }else if (message[0] === 'missing_cpf_dependente'){
                                    this.$router.push({ name: 'outrasPessoas' })
                                }
                                else{
                                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message })
                                }
                            }) */
                            this.waiting.carrinho = false
                        }
                    })
                } else if (this.$parent.agendamento.ie_tipo_agenda === 'EL') {
                    const keys = Object.keys(this.$parent.agendamento.agenda)
                    const reservar = (idx) => {
                        if (idx === keys.length) {
                            this.$router.push({ name: 'carrinho' })
                            return 0
                        }
                        let isMedEmpresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                        let body = {
                            ie_associado_med_empresa: isMedEmpresa ? true : false,
                            cd_pessoa_fisica: this.$parent.agendamento.paciente.id,
                            cd_procedimento: this.$parent.agendamento.agenda[keys[idx]],
                            cd_estabelecimento: this.$parent.agendamento.estabelecimento.id,
                            ie_reservar: 'R',
                            cd_empresa: usuario.empresas_associado[0]?.cd_empresa,
                        }
                        Pagamento.addToCarrinhoExame(keys[idx], body).then(response => {
                            if (response.status === 200) {
                                this.$gtag.event('adicionou_ao_carrinho', { item: body })
                                this.$analytics.fbq.event('AddToCart')
                                reservar(++idx)
                            } else if (response.status === 400) {
                                if(response.data.cd_empresa){
                                    this.$buefy.toast.open({
                                        duration: 8000,
                                        position: 'is-bottom',
                                        message: response.data.cd_empresa[0],
                                        type: 'is-danger',
                                    })
                                }
                                if(response.data.detail) {
                                    this.$buefy.toast.open({
                                        duration: 8000,
                                        position: 'is-bottom',
                                        message: response.data.detail,
                                        type: 'is-danger',
                                    })
                                }
                                this.waiting.carrinho = false
                            }
                        })
                }
                reservar(0)
                } else if (this.$parent.agendamento.ie_tipo_agenda === 'VE') {
                    const reservar = (idx) => {
                        if (idx === this.$parent.agendamento.procedimentos.length) {
                            this.$router.push({ name: 'carrinho' })
                            return 0
                        }
                        let isMedEmpresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                        let body = {
                            ie_associado_med_empresa: isMedEmpresa ? true : false,
                            cd_pessoa_fisica: this.$parent.agendamento.paciente.id,
                            cd_procedimento: this.$parent.agendamento.procedimentos[idx].cd_procedimento,
                            cd_estabelecimento: this.$parent.agendamento.estabelecimento.cd_estabelecimento,
                            ie_reservar: 'R',
                            cd_empresa: usuario.empresas_associado[0]?.cd_empresa,
                        }
                        Pagamento.addToCarrinhoExame(this.$parent.agendamento.procedimentos[idx].idHorario, body).then(response => {
                            if (response.status === 200) {
                                this.$gtag.event('adicionou_ao_carrinho', { item: body })
                                reservar(++idx)
                            } else if (response.status === 400) {
                                    if(response.data.cd_empresa){
                                        this.$buefy.toast.open({
                                            duration: 8000,
                                            position: 'is-bottom',
                                            message: response.data.cd_empresa[0],
                                            type: 'is-danger',
                                        })
                                }
                                Object.values(response.data).forEach(message => {
                                    this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message })
                                })
                                this.waiting.carrinho = false
                            }
                        })
                }
                reservar(0)
                } else {
                    let isMedEmpresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                    let body = {
                        ie_associado_med_empresa: isMedEmpresa ? true : false,
                        cd_pessoa_fisica: this.$parent.agendamento.paciente.id,
                        cd_procedimento: this.$parent.agendamento.exames.map(exame => exame.id),
                        cd_estabelecimento: this.$parent.agendamento.estabelecimento.id,
                        ie_reservar: 'R', 
                        cd_empresa: usuario.empresas_associado[0]?.cd_empresa,
                    }
                    Pagamento.addToCarrinhoExame(this.$parent.agendamento.horario.id, body).then(response => {
                        if (response.status === 200) {
                            this.$gtag.event('adicionou_ao_carrinho', { item: body })
                            this.$router.push({ name: 'carrinho' })
                        } else if (response.status === 400) {
                            if(response.data.cd_empresa){
                                this.$buefy.toast.open({
                                    duration: 8000,
                                    position: 'is-bottom',
                                    message: response.data.cd_empresa[0],
                                    type: 'is-danger',
                                })
                            }
                            Object.values(response.data).forEach(message => {
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message })
                            })
                            this.waiting.carrinho = false
                        }
                    })
                }
            }
        }
    }
</script>