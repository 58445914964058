import '@mdi/font/css/materialdesignicons.css'
import 'animate.css/animate.min.css'
import Buefy from 'buefy'
import VueMask from 'v-mask'
import Vue from 'vue'
import VueFacebookPixel from 'vue-analytics-facebook-pixel'
import VueGtag from "vue-gtag"
import VuePageTransition from 'vue-page-transition'
import MaskedInput from 'vue-text-mask'
import Vuelidate from 'vuelidate'
import Vuetify from 'vuetify'
import Vuex from 'vuex'
import './../node_modules/jam-icons/css/jam.min.css'
import './../node_modules/jam-icons/js/jam.min'
import App from './App.vue'

import './assets/scss/style.css'
/* import './assets/scss/style_system.css' */

import './assets/scss/base.scss'
import './assets/tailwind.css'

import mixins from './mixins'
import router from './router'
import store from './store'

/* import './assets/js/locais_atendimento' */
import './assets/js/script'
/* import './assets/js/local_atendimento' */
Vue.use(Buefy)
Vue.use(Vuex)
Vue.use(Vuetify, { iconfont: 'mdi' })
Vue.use(Vuelidate)
Vue.use(VuePageTransition)
Vue.use(VueMask)
Vue.component('masked-input', MaskedInput)
Vue.use(VueGtag, { config: { id: 'G-0VH6L2TT1Z' } })
Vue.use(VueFacebookPixel)

// if is dev
if (process.env.NODE_ENV === 'development') {
    Vue.prototype.console = console
}

Vue.analytics.fbq.init('253321332818387', { em: 'matheus@a7participacoes.com' })

Vue.config.productionTip = false

const vuexInstance = new Vuex.Store(store)

let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
if (usuario) {
    usuario.sessionWarning = false
    localStorage.setItem('MEDCLUBSITE_USER', JSON.stringify(usuario))
}

new Vue({
    mixins,
    router,
    store: vuexInstance,
    render: h => h(App),
}).$mount('#app')