<template lang="pug">
    .pagamento

        div(v-if='confirmacao')
            Confirmacao

        .py-56(v-else-if='waiting.parcelas')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.parcelas')

        div(v-else)

            MeusCartoes.animate__animated.animate__fadeIn(v-if='meusCartoes' :compact='true' :valorTotal='valorTotal' :formasPagamento='true')

            .animate__animated.animate__fadeIn.flex.items-center.justify-center.flex-wrap(v-else)
                .max-w-screen-lg.w-full.relative(style='min-height:75vh')

                    .pt-4.pb-3.text-center.w-full
                        h1.font-bold.text-medclub-blue-300.text-sm Resumo

                    .border-t
                        .flex.flex-wrap.p-1.w-full.border-b.mt-2(v-for='agendamento, idx in carrinho' :key='idx')
                            .px-2(class='w-8/12')
                                p.text-sm.font-bold.text-gray-700 {{ idx + 1 }}. {{ agendamento.cd_agenda.ie_tipo_agenda === 'C' ? 'Consulta' : 'Exame' }} em {{ agendamento.cd_estabelecimento.nm_fantasia }}
                            .px-2(class='w-4/12')
                                p.text-md.font-bold.text-medclub-blue-400.text-right {{ $root.formatPrice(agendamento.nr_valor) }}

                            .px-2.w-full(v-if="agendamento.cd_agenda.ie_tipo_agenda === 'C'")
                                p.text-xs.text-gray-600.mt-2 ESPECIALIDADE
                                p.text-sm.text-medclub-blue-300 {{ agendamento.cd_especialidade.nm_especialidade }}
                                p.text-xs.text-gray-600.mt-2 ESPECIALISTA
                                p.text-sm.text-medclub-blue-300 {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}


                            .px-2.w-full(v-else)
                                p.text-xs.text-gray-600.mt-2
                                    span PROCEDIMENTO
                                    span(v-if='agendamento.cd_procedimentos_horario.length > 1') S
                                div(v-if='agendamento.cd_procedimentos_horario.length > 1')
                                    p.text-sm.text-gray-700(v-for='exame, idx in agendamento.cd_procedimentos_horario' :key='idx') {{ idx + 1 }}.&nbsp; {{ exame.nm_procedimento }}
                                p.text-sm.text-gray-700(v-else-if='agendamento.cd_procedimentos_horario.length') {{ `${agendamento.cd_procedimento.ds_procedimento ? agendamento.cd_procedimento.ds_procedimento : agendamento.cd_procedimento.nm_procedimento} ${agendamento.nm_subgrupo ? `(${agendamento.nm_subgrupo})` : ''}`.toUpperCase() }}
                                p.text-sm.text-gray-700(v-else) {{ `${agendamento.cd_procedimento.ds_procedimento ? agendamento.cd_procedimento.ds_procedimento : agendamento.cd_procedimento.nm_procedimento} ${agendamento.nm_subgrupo ? `(${agendamento.nm_subgrupo})` : ''}`.toUpperCase() }}
                                p.text-xs.text-gray-600.mt-4 ESPECIALISTA
                                .flex.mt-1(v-if='agendamento.cd_agenda.cd_especialista')
                                    .w-10.h-10.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat(
                                        :style="`background-image: url('${ agendamento.cd_agenda.cd_especialista.aq_foto }')`")
                                    .p-1.pl-4(class='w-5/6 md:w-11/12')
                                        .ml-2(class='w-5/6')
                                            p.text-sm.text-medclub-blue-400.font-semibold {{ agendamento.cd_agenda.cd_especialista.ie_sexo == 'F' ? 'Dra. ' : 'Dr. ' }} {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}
                                            p.text-sm.text-gray-600
                                                span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.nm_conselho }} &nbsp;
                                                span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.uf_conselho }} -&nbsp;
                                                span(v-if="agendamento.cd_especialidade.id !== 57") {{ agendamento.cd_agenda.cd_especialista.nr_conselho }}&nbsp;
                                                span(v-if="agendamento.cd_especialidade.id !== 57 &&  agendamento.cd_agenda.cd_especialista.nm_especialidade") /
                                                span(v-if="agendamento.cd_especialidade.id !== 57 &&  agendamento.cd_agenda.cd_especialista.nm_especialidades") /
                                                span(v-if='agendamento.cd_especialidade.nm_especialidade') {{ agendamento.cd_especialidade.nm_especialidade }}
                                                span(v-if='agendamento.cd_agenda.cd_especialista.nm_especialidades') &nbsp;{{ agendamento.cd_agenda.cd_especialista.nm_especialidades[0] }}
                                            .flex.items-center(v-if="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao")
                                                star-rating(
                                                    :rating="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao"
                                                    :read-only="true"
                                                    :show-rating="false"
                                                    :round-start-rating="false"
                                                    :star-size="20")
                                                .text-sm.align-center ({{ agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao }})

                    .p-2
                        .p-4.shadow-md.rounded.border.flex.items-center.font-bold
                            div(class='w-1/2')
                                p.text-sm.text-gray-700 Valor total:
                            div(class='w-1/2')
                                p.text-right.text-lg.text-medclub-blue-400 {{ $root.formatPrice(valorTotal) }}

                    .pb-16(style='min-width: 100px !important')
                        .flex.justify-center.p-2

                            .border.shadow-md.w-full.pb-2(class='md:w-auto' style='width: 450px;')

                                .py-4.text-center.w-full.border-b.mb-2
                                    h1.font-bold.text-gray-700 Pagamento

                                .px-2(v-if='! cartao')
                                    b-button.w-full.rounded.p-0(type='is-info' @click='meusCartoes = true')
                                        .flex.items-center
                                            b-icon.text-xl(pack='jam' icon='jam-credit-card')
                                            span.text-sm Escolher forma de pagamento

                                .px-2(v-else-if="cartao.brand === 'medcred'")
                                    b-button.w-full.rounded.p-0(type='is-info' outlined @click='meusCartoes = true')
                                        .flex.items-center
                                            b-icon.text-xl(pack='jam' icon='jam-credit-card')
                                            span.text-sm Créditos Medclub - Trocar forma de pagamento
                                   
                                .px-2(v-else-if="cartao.brand === 'medcred_empresa'")
                                    b-button.w-full.rounded.p-0(type='is-info' @click='meusCartoes = true')
                                        .flex.items-center
                                            b-icon.text-xl(pack='jam' icon='jam-credit-card')
                                            span.text-sm Créditos Medclub Empresa

                                .px-2(v-else-if="cartao.brand === 'pix'")
                                    b-button.w-full.rounded.p-0(type='is-info' outlined @click='meusCartoes = true')
                                        .flex.items-center
                                            b-icon.text-xl(pack='mdi' icon='cash-check')
                                            span.text-sm Pix - Trocar forma de pagamento

                                .px-2(v-else)
                                    b-button.w-full.rounded.p-0(type='is-info' outlined @click='meusCartoes = true')
                                        .flex.items-center
                                            b-icon.text-xl(pack='jam' icon='jam-credit-card')
                                            span.text-sm {{ ['visa', 'mastercard', 'hipercard'].includes(cartao.brand) ? $root.textCapitalize(`${ cartao.brand }`) : 'Cartão' }} terminado em {{ cartao.last_digits }}
                                    .mt-2.w-full.flex.items-center(v-if="!['pix', 'medcred'].includes(cartao.brand)" style='justify-content: space-between' )
                                        p(style='font-size: 14px !important; font-weight: bold') Usar Saldo Medclub?<br/>
                                            span(style='font-size: 12px !important; font-weight: normal') Saldo disponível: 
                                                span.text-medclub-blue-400.font-bold {{ $root.formatPrice(saldoMedclub) }}
                                        b-switch(v-model="useCreditoMedclub" :disabled='saldoMedclub == 0')
                                    .mt-2.w-full.flex.items-center(v-if="medempresa && !['pix', 'medcred_empresa'].includes(cartao.brand)" style='justify-content: space-between')
                                        p(style='font-size: 14px !important; font-weight: bold') Usar Saldo Medclub Empresa?<br/>
                                            span(style='font-size: 12px !important; font-weight: normal') Saldo disponível: 
                                                span.text-medclub-blue-400.font-bold {{ $root.formatPrice(saldoMedEmpresa) }}
                                        b-switch(v-model="useCreditoMedclubEmpresa" :disabled='saldoMedEmpresa == 0')
                                            
                                .px-2.text-center

                                    .flex.items-center.pt-2.mx-auto.w-full(v-if='parcelaOptions.length > 1')
                                        div(class='w-1/4')
                                            p.font-bold.text-sm Parcelas:
                                        div(class='w-3/4')
                                            b-select(placeholder='Selecione' v-model='parcelas' :disabled="!cartao || ['pix', 'medcred', 'medcred_empresa'].includes(cartao.brand)")
                                                option(v-for='option, index in parcelaOptions' :value='option.installment' :key='option.installment')
                                                    span.text-sm {{ option.installment }}x de {{ $root.formatPrice(parseFloat(option.installment_amount) / 100) }}
                                                        span(v-if='index === 0')  (à vista)
                                                        span(v-else-if='index < quantidadeParcelasSemJuros')  (sem juros {{ $root.formatPrice(parseFloat(option.amount) / 100) }})
                                                        span(v-else)  (com juros {{ $root.formatPrice(parseFloat(option.amount) / 100) }})
                                    .pt-2.pr-2.pl-2.text-center(v-else-if="saldoMedclubCobriu")
                                        p.text-sm.text-gray-600 Seu saldo Medclub pagará o valor total
                                    .pt-2.pr-2.pl-2.text-center(v-else-if="saldoMedclubEmpresaCobriu")
                                        p.text-sm.text-gray-600 Seu saldo Medclub Empresa pagará o valor total
                                    .pt-2.pr-2.pl-2.text-center(v-else-if="cartao && ! ['pix', 'medcred'].includes(cartao.brand)")
                                        p.text-sm.text-gray-600 Pagamento à vista<br>no cartão de crédito.

                                .px-2.text-center.mt-4
                                    //- b-button.w-full.rounded.p-0(type='is-primary' @click='pagar()' :loading='waiting.pagamento')
                                    b-button.w-full.rounded.p-0(type='is-primary' size="is-large" :disabled='!cartao || !parcelas ' @click='pagar()' :loading='waiting.pagamento')
                                        .flex.items-center
                                            b-icon.text-xl(pack='jam' icon='jam-check')
                                            span.text-sm Pagar

</template>
<script>
    import { Pagamento } from '../../middleware'
    import MeusCartoes from './MeusCartoes'
    import Confirmacao from './Confirmacao'
    import StarRating from 'vue-star-rating'
    export default {
        components: { MeusCartoes, Confirmacao, StarRating },
        created () {
            this.waiting.parcelas = true
            this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            this.valorTotal = this.carrinho.reduce((ac, val) => ac + parseFloat(val.nr_valor), 0)
            if (! this.carrinho.length) this.$router.push({ name: 'home' })
            else {
                if(this.valorTotal >= 100) {
                    Pagamento.checkParcelas(parseFloat(this.valorTotal.toFixed(2))).then(response => {
                    this.waiting.parcelas = false
                    this.parcelaOptions = Object.values(response.data.installments)
                    this.quantidadeParcelasSemJuros = response.data.free_installments
                })
                } else {
                    this.parcelas = 1
                }
                Pagamento.getSaldoCreditos({ie_associado_med_empresa: this.medempresa}).then((response) => {
                    if (response.status === 200) {
                    this.saldoMedclub = response.data.saldo
                    this.saldoMedEmpresa = response.data.saldo_empresa ? response.data.saldo_empresa[0].nr_saldo : 0
                    }
                })
                this.waiting.parcelas = false
            }
        },
        watch: {
            meusCartoes: function (opened) {
                if (opened) {
                    this.cartao = null
                    this.saldoMedclubCobriu = false
                    const helpButtonMobile = document.getElementById('helpButtonMobile')
                    if (helpButtonMobile) helpButtonMobile.classList.add('hidden')
                } else {
                    const helpButtonMobile = document.getElementById('helpButtonMobile')
                    if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')
                }
            },
            useCreditoMedclub: function(value) {
                var total = this.valorTotal
                if(value) {
                    total = this.valorTotal - this.saldoMedclub
                    
                } else {
                    this.saldoMedclubEmpresaCobriu = false
                }
                if(this.useCreditoMedclubEmpresa) {
                    total = total - this.saldoMedEmpresa
                }
                if(this.valorTotal <= this.saldoMedclub) {
                    this.saldoMedclubCobriu = true
                    this.parcelas = 1
                }
                Pagamento.checkParcelas(parseFloat(total.toFixed(2))).then(response => {
                    this.waiting.parcelas = false
                    this.parcelaOptions = Object.values(response.data.installments)
                    this.quantidadeParcelasSemJuros = response.data.free_installments
                })
                
            },
            useCreditoMedclubEmpresa: function(value) {
                var total = this.valorTotal
                console.log("TOTAL", total)
                if(value) {
                    total = this.valorTotal - this.saldoMedEmpresa
                    
                } else {
                    this.saldoMedclubEmpresaCobriu = false
                }
                if(this.useCreditoMedclub) {
                    total = total - this.saldoMedclub
                }
                if(this.valorTotal <= this.saldoMedEmpresa){
                    this.saldoMedclubEmpresaCobriu = true
                    this.parcelas = 1
                } 

                Pagamento.checkParcelas(parseFloat(total.toFixed(2))).then(response => {
                    this.waiting.parcelas = false
                    this.parcelaOptions = Object.values(response.data.installments)
                    this.quantidadeParcelasSemJuros = response.data.free_installments
                })
                
            }
        },
        data () {
            return {
                carrinho: this.$parent.$parent.carrinho,
                meusCartoes: false,
                cartao: null,
                parcelaOptions: [],
                parcelas: 1,
                saldoMedclubCobriu: false,
                saldoMedclubEmpresaCobriu: false,
                saldoMedclub: 0,
                saldoMedEmpresa: 0,
                quantidadeParcelasSemJuros: 2,
                medempresa: false,
                confirmacao: false,
                confirmaValorRecente: false,
                waiting: { parcelas: true, pagamento: false },
                useCreditoMedclub: false,
                useCreditoMedclubEmpresa: false,
                valorTotal : 0
            }
        },
        methods: {

            pagar () {

                if (! this.cartao) { this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Selecione a forma de pagamento' }); return 0 }
                else if (! this.parcelas) { this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Selecione o número de parcelas' }); return 0 }
                else if (this.$parent.$parent.carrinhoInfo && this.$parent.$parent.carrinhoInfo.vlr_compra_recente === this.valorTotal && ! this.confirmaValorRecente) {

                    this.$buefy.dialog.confirm({
                        title: 'Atenção!',
                        message: `Você realizou recentemente uma compra neste mesmo valor <b>(${ this.$root.formatPrice(this.valorTotal) })</b>. Deseja efetuar este novo pagamento?`,
                        confirmText: 'Pagar',
                        cancelText: 'Cancelar',
                        onConfirm: () => {
                            this.confirmaValorRecente = true
                            this.pagar()
                        }
                    })
                    return 0

                }

                let items = []
                let gtagActions = { value: 0, items: [] }

                this.carrinho.forEach(agendamento => {

                    let item = { quantity: 1, tangible: false }
                    let action = {
                        value: parseFloat(agendamento.nr_valor),
                        items: []
                    }

                    if (agendamento.cd_agenda.ie_tipo_agenda === 'C') {

                        let i = Object.assign({}, item)
                        i.id = agendamento.id.toString()
                        i.unit_price = parseFloat(agendamento.nr_valor)
                        i.title = `${agendamento.cd_procedimento.ds_procedimento ? agendamento.cd_procedimento.ds_procedimento : agendamento.cd_procedimento.nm_procedimento} ${agendamento.nm_subgrupo ? `(${agendamento.nm_subgrupo})` : ''} em ${ agendamento.cd_estabelecimento.nm_fantasia }`.toUpperCase()
                        items.push(i)
                        action.items.push({
                            id: i.id,
                            name: agendamento.cd_especialidade.nm_especialidade,
                            brand: agendamento.cd_estabelecimento.nm_fantasia,
                            quantity: i.quantity,
                            price: i.unit_price,
                            category: 'Consultas'
                        })

                    } else {

                        if (agendamento.cd_procedimentos_horario.length) {

                            let i = Object.assign({}, item)
                            i.id = agendamento.id.toString()
                            i.unit_price = parseFloat(agendamento.nr_valor)
                            i.title = `Exames em ${ agendamento.cd_estabelecimento.nm_fantasia }`
                            items.push(i)
                            action.items.push({
                                id: i.id,
                                name: `${ agendamento.cd_procedimentos_horario.length } ${ agendamento.cd_procedimentos_horario.length === 1 ? 'exame' : 'exames' }`,
                                brand: agendamento.cd_estabelecimento.nm_fantasia,
                                quantity: i.quantity,
                                price: i.unit_price,
                                category: 'Exames'
                            })
                        
                        } else {

                            let i = Object.assign({}, item)
                            i.id = agendamento.id.toString()
                            i.unit_price = parseFloat(agendamento.nr_valor)
                            i.title = `${ agendamento.cd_procedimento.ds_procedimento } em ${ agendamento.cd_estabelecimento.nm_fantasia }`
                            items.push(i)
                            action.items.push({
                                id: i.id,
                                name: agendamento.cd_procedimento.ds_procedimento,
                                brand: agendamento.cd_estabelecimento.nm_fantasia,
                                quantity: i.quantity,
                                price: i.unit_price,
                                category: 'Procedimentos'
                            })

                        }

                    }

                    gtagActions.value += action.value
                    gtagActions.items = gtagActions.items.concat(action.items)

                })

                let dataSend = {
                    usar_saldo: this.useCreditoMedclub ? 1 : 0,
                    usar_saldo_empresa: this.useCreditoMedclubEmpresa ? 1 : 0 ,
                    nr_valor_total: parseFloat(this.valorTotal.toFixed(2)),
                    parcelas: this.parcelas,
                    items
                }
                if(this.medempresa) dataSend.cd_empresa = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER')).empresas_associado[0].cd_empresa

                if (this.cartao.brand === 'medcred') dataSend.forma_pagamento = 'medcred'
                else if (this.cartao.brand === 'medcred_empresa') dataSend.forma_pagamento = 'medcred_empresa'
                else if (this.cartao.brand === 'pix') dataSend.forma_pagamento = 'pix'
                else dataSend.cd_cartao = this.cartao.id

                this.waiting.pagamento = true
            
                Pagamento.pagar(dataSend).then(response => {
                    this.waiting.pagamento = false

                    if ([200, 201, 204].includes(response.status)) {

                        this.$gtag.purchase({ value: gtagActions.value, items: gtagActions.items })
                        this.$analytics.fbq.event('Purchase', { value: gtagActions.value, currency: 'BRL', items: gtagActions.items })
                        
                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: 'Pagamento aprovado'
                        })
                        this.$parent.$parent.carrinho = []
                        
                        // Quando o usuário comprar uma consulta, o modal para avaliar
                        // a última consulta já irá aparecer
                        this.$store.commit('setAvaliou', false);
                        this.confirmacao = true
                    
                    } else if (response.status === 400) {

                        if (dataSend.forma_pagamento === 'pix') {
                            this.$buefy.toast.open({ type: 'is-info', position: 'is-bottom', message: response.data.detail, duration: 5000 })
                            this.$router.push({ name: 'pagamentoPix' })
                            return 0
                        }

                        let motivo = ''
                        if (response.data.detail) {
                            this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: response.data.detail })
                            motivo = response.data.detail
                        } else if (typeof response.data == 'object') {
                            Object.values(response.data).forEach(msg => {
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: msg })
                                if (motivo.length) motivo += '; '
                                motivo += msg
                            })
                        }
                        this.$gtag.event('pagamento_negado', { motivo })
                    }
                })
            }
        }
    }
</script>