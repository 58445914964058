<template lang="pug">
    .flex.items-center.justify-center.flex-wrap
        .max-w-screen-lg.w-full.relative(style='min-height:75vh')

            //- Modal para adicionar créditos
            b-modal(v-model='dialog.adicionarCreditos' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal @close="model.valor = 'R$ 0'; pagamentoConcluido = null")
                template(#default='props')
                    form(@submit.prevent='submitCreditos()')
                        .modal-card.max-w-sm.p-4(class='md:p-0')
                            header.modal-card-head
                                p.modal-card-title(v-if='pagamentoConcluido') Pronto!
                                p.modal-card-title(v-else) Adicionar créditos
                                button.delete(type='button' @click='props.close()')
                            section.modal-card-body.p-2

                                .p-2(v-if='pagamentoConcluido')

                                    p.text-sm.text-justify Agora é só pagar o boleto. Baixe o aplicativo do Medclub no seu <a class='ietelemedicina-store-link' target='_blank' href='https://play.google.com/store/apps/details?id=com.medclub'>Android</a> ou <a class='ietelemedicina-store-link' target='_blank' href='https://apps.apple.com/br/app/medclub/id1508392465'>iPhone</a> para ser notificado quando os créditos estiverem disponíveis.
                                    .text-center.p-4
                                        p.text-2xl.font-bold.text-gray-700 {{ $root.formatPrice(pagamentoConcluido.valor) }}
                                        p.text-xs.text-gray-600 VALOR DO BOLETO
                                    .p-2
                                        p.text-xs.text-gray-600 CÓDIGO DE BARRAS
                                        input#barcode.text-md.text-gray-700.outline-none(readonly :value='pagamentoConcluido.boleto_barcode')

                                    .flex.justify-center.py-4
                                        b-button.rounded-full.text-sm.mx-2(@click='copyBarCode()') Copiar código
                                        a.flex.items-center.px-4.border.rounded-full.text-sm.mx-2(style='border-color:#dbdbdb' :href='pagamentoConcluido.boleto_url' target='_blank') Ver boleto

                                div(v-else)
                            
                                    .py-6
                                        p.text-center.font-bold.text-lg.text-gray-700 Digite o valor
                                        .text-center.pt-2
                                            masked-input.w-full.text-4xl.font-bold.outline-none.text-center(type='cel' v-model='model.valor' :mask="integerCurrencyMask" :guide='false' @focus='onFocus()' @blur='onBlur()')
                                        p.text-center.text-xs.text-red-500.mt-2 *O valor mínimo é de R$ 30.
                                        
                                    .flex
                                        .p-1(v-for='value in buttonValues' class='w-1/4')
                                            .border.border-medclub-blue-400.rounded-full.text-center.w-full.py-2.text-sm.font-bold.text-medclub-blue-400.cursor-pointer(@click='addValue(value)')
                                                span + R$ {{ value }}

                            footer.modal-card-foot
                                .w-full.text-center(v-if='! pagamentoConcluido')
                                    b-button(class='w-2/3' type='is-primary' native-type='submit' icon-pack='jam' icon-left='jam-document' rounded :loading='waiting.adicionarCreditos') Gerar boleto
                                    .flex.items-center.justify-center.mt-4
                                        img.w-6.h-5(src='./../../assets/img/boleto-icon.png')
                                        .text-left.px-4
                                            p.text-sm.font-bold Pagável com boleto bancário
                                            p.text-xs Valor disponível na sua conta em até 3 dias úteis.
                                .w-full.p-2(v-else)
                                    p.text-sm.text-gray-700.font-bold.text-justify Após o pagamento, o valor ficará disponível na sua conta em até 3 dias úteis.

            //- Modal para resgatar créditos
            b-modal(v-model='dialog.adicionarConta' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal)
                template(#default='props')
                    .modal-card.max-w-sm.p-4(class='md:p-0')
                        header.modal-card-head.flex.justify-between
                            div(v-if="[2, 3].includes(etapaBanco) && !dialog.avisoResgate")
                                a.text-3xl(@click='etapaBanco = etapaBanco - 1')
                                    i.jam.jam-chevron-left

                            p.modal-card-title.text-center Resgatar Créditos
                            button.delete(type='button' @click='props.close(); etapaBanco = 1; dialog.avisoResgate = false')
                        section.modal-card-body.p-2
                        
                            //- Etapa 1
                            div(v-if="etapaBanco === 1")
                                p.m-2.mb-6.text-center Adicione sua conta bancária para realizar o saque do seu saldo Medclub

                            //- Etapa 2
                            div(v-else-if="etapaBanco === 2")
                                //- h1.text-center Selecione sua Conta Bancária:
                                .p-2(v-if="!contaBancaria.agencia")
                                    .flex.items-center.justify-center.shadow-md.rounded.w-40.h-40.mx-auto.border.cursor-pointer(@click='modalConta("save")')
                                        .w-full.text-center
                                            b-icon.text-4xl(pack='jam' icon='jam-plus')
                                            p.text-xs Adicionar<br/>Conta Bancária

                                .p-2(v-else)
                                    .flex.justify-between.items-center.shadow-md.rounded.w-full.p-4.mx-auto.border
                                        .w-full.text-left
                                            p.text-xl.font-bold {{ contaBancaria.tipo === 'CC' ? 'Conta Corrente' : 'Poupança' }}
                                            p.text-md Banco: {{ bancos.filter(banco => banco.id === contaBancaria.bancoId)[0].nm_banco }}
                                            p.text-md Agência: {{ contaBancaria.agencia }}
                                            p.text-md Conta: {{ contaBancaria.conta }}
                                        .bg-blue-700.rounded-full.text-center.h-full.p-2.cursor-pointer.filter(class="active:brightness-75" @click='modalConta("edit")')
                                            .jam.jam-pencil-f.text-3xl.text-white
                            //- Etapa 3
                            div(v-else-if="!dialog.avisoResgate")
                                .py-6
                                    p.text-center.font-bold.text-lg.text-gray-700 Digite o valor
                                    .text-center.pt-2
                                        masked-input.w-full.text-4xl.font-bold.outline-none.text-center(type='cel' v-model='valorResgate' :mask="integerCurrencyMask" :guide='false' @focus='onFocus()' @blur='onBlur()')
                                        p.text-center.text-xs.text-red-500.mt-2 *O valor máximo é de R$ {{ saldo }}.

                                .flex
                                    .p-1(v-for='value in buttonValues' class='w-1/4')
                                        .border.border-medclub-blue-400.rounded-full.text-center.w-full.py-2.text-sm.font-bold.text-medclub-blue-400.cursor-pointer(@click='addValueResgate(value)')
                                            span + R$ {{ value }}

                            div.flex.flex-col.justify-center.items-center(v-if="dialog.avisoResgate")
                                LottieAnimation(path='lottie/present.json' :loop="false" :width="256" :height="256")
                                h1.text-2xl.text-center Saque solicitado com sucesso!
                                p.p-4.text-center Você será notificado assim que o valor estiver disponível na sua conta.
                        footer.modal-card-foot
                            .w-full.text-center(v-if='etapaBanco === 1')
                                b-button(type='is-primary' native-type='submit' icon-pack='jam' icon-left='jam-building-f' rounded @click="etapaBanco = 2") Adicionar Conta Bancária
                            .w-full.text-center(v-else-if="etapaBanco === 2")
                                b-button(class='w-2/3' :disabled="contaBancaria.agencia === null" type='is-primary' native-type='submit' rounded @click="etapaBanco = 3") Continuar
                            .w-full.text-center(v-else-if="etapaBanco === 3 && !dialog.avisoResgate")
                                b-button(class='w-2/3' type='is-primary' native-type='submit' rounded @click="resgatar" :disabled="parseInt(valorResgate.replace('R$ ', ''), 10) <= 0 || parseInt(valorResgate.replace('R$ ', ''), 10) > saldo") Continuar
                            .w-full.text-center(v-if="dialog.avisoResgate")
                                b-button(class='w-2/3' type='is-primary' native-type='submit' rounded @click="dialog.avisoResgate = false; dialog.adicionarConta = false; etapaBanco = 1") Entendi

            //- Modal para adicionar dados da conta
            b-modal(v-model='dialog.adicionarDadosConta' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal)
                template(#default='props')
                    .modal-card.max-w-sm(class='md:p-0')
                        header.modal-card-head.flex.justify-between
                            p.modal-card-title.text-center {{ novaContaBancaria.title }}
                            button.delete(type='button' @click='props.close(); etapaBanco = 2')
                        section.modal-card-body.p-6
                            b-field(label='Banco:')
                                b-select(v-model="novaContaBancaria.bancoId" rounded expanded required)
                                    option(v-for="banco in bancos" :key="banco.ds_codigo" :value="banco.id") {{ banco.nm_banco }}
                            b-field(label='Tipo da Conta:')
                                b-select(v-model="novaContaBancaria.tipo" rounded expanded required)
                                    option(value='CC') Conta Corrente
                                    option(value='CP') Conta Poupança
                            b-field(label='Agencia *:')
                                b-input(v-model="novaContaBancaria.agencia" rounded required)
                            b-field(label='Conta *:' message='*insira apenas números')
                                b-input(v-model="novaContaBancaria.conta" rounded required)

                        footer.modal-card-foot
                            b-button.mx-auto(class='w-2/3' type='is-primary' native-type='submit' rounded @click="adicionarContaAPI") {{ novaContaBancaria.button }}
                            
            
            

            .py-56(v-if='waiting.saldo')
                b-notification.p-8.bg-white(:closable='false')
                    b-loading(:is-full-page='false' v-model='waiting.saldo')

            div(v-else)
                div(v-if='!verExtrato')
                    .absolute.top-0.left-0.p-2
                        a.text-3xl(@click='$router.go(-1)')
                            i.jam.jam-chevron-left
                    .pt-4.pb-3.text-center.w-full
                        h1.font-bold.text-medclub-blue-300.text-sm Seus Créditos Medclub


                    .text-center.mt-5.py-4
                        p.text-sm.font-gray-500 Seu saldo
                        p.text-4xl.font-bold.text-gray-700 {{ $root.formatPrice(saldo) }}

                    .px-6.py-12.flex.justify-center.flex-wrap
                        .flex.items-center(v-if='!medempresa' style='flex-direction: column; margin-right: 20px')
                            b-button.rounded-full(type='is-primary' icon-pack='jam' icon-left='jam-plus-circle-f'  @click='dialog.adicionarCreditos = true')
                            p.text-sm.text-justify.font-bold.text-medclub-blue-300(style='font-size: 14px !important; text-align: left !important;') Adicionar
                        .flex.items-center(style='flex-direction: column')
                            b-button.rounded-full(icon-pack='jam' type='is-primary' icon-left='jam-document-f'  @click='verExtrato = true')
                            p.text-sm.text-justify.font-bold.text-medclub-blue-300(style='font-size: 14px !important; text-align: left !important;' ) Extrato
                        //- b-button.text-sm.rounded-full.m-1(v-if='!medempresa' type='is-primary' icon-pack='jam' icon-left='jam-plus-circle-f' @click='dialog.adicionarCreditos = true') Adicionar créditos
                        //- b-button.text-sm.rounded-full.m-1(v-if='!medempresa' type='is-primary' icon-pack='jam' icon-left='jam-building-f' @click='dialog.adicionarConta = true') Resgatar créditos
                    
                    .p-4.w-full(v-if='!medempresa')
                        .p-2.border.border-blue-200.bg-blue-100.rounded
                            p.text-sm.font-bold Recarregue com boleto
                            .flex.items-center
                                p.text-sm.text-justify(style='font-size: 14px !important; text-align: left !important;') Agora você pode adicionar créditos via boleto para pagar suas consultas e exames com facilidade e segurança.
                                img.mx-auto.mb-4(style='width:9em' src='./../../assets/img/think-boleto.png')
                div(v-else)
                    .absolute.top-0.left-0.p-2
                        a.text-3xl(@click='verExtrato = false')
                            i.jam.jam-chevron-left
                    .pt-4.pb-3.text-center.w-full
                        h1.font-bold.text-medclub-blue-300.text-sm Extrato
                    .p-2
                        .border.rounded.mb-4(v-if='extrato')
                            div(v-for='key, idx in Object.keys(extrato)' :key='idx')
                                p.bg-gray-300.px-2.py-1.font-bold.text-xs {{ key }}
                                .flex.items-center.border.border-gray-300.border-t-0.p-2.cursor-pointer(v-for='item, idx in extrato[key]' :key='idx' @click='openBoleto(item)')
                                    .text-sm.text-center.font-bold(class='w-2/12') {{ $root.moment(item.dt_criado).format('DD/MM') }}
                                    .px-2(class='w-6/12')
                                        p.text-xs.font-bold(style='font-size: 12px !important' ) {{ item.ds_informacoes }}
                                        p.text-xs(:class="{ 'text-yellow-600': item.ie_status === 'P', 'text-green-600': item.ie_tipo === 'A' }" style='font-size: 12px !important' ) {{ options.ie_status[item.ie_status] }}
                                    .text-right(class='w-3/12')
                                        p.text-sm.font-bold(:class="{ 'text-medclub-blue-400': item.ie_tipo === 'C', 'text-red-500': item.ie_tipo === 'D' }" style='font-size: 12px !important') {{ $root.formatPrice(item.nr_valor) }}
                                    .text-right(class='w-1/12')
                                        b-icon(v-if="item.ie_status === 'P'" pack='jam' icon='jam-chevron-right')
</template>

<script>
    import { Pagamento } from '../../middleware'
    import { Usuario } from './../../middleware'
    import { integerCurrencyMask } from './../../utils'
    import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
    export default {
        components: { LottieAnimation },
        created () {
            this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            if (! this.$parent.$parent.usuario) this.$router.push({ name: 'home' })
            else this.getSaldoCreditos()

            Usuario.getUser(this.$parent.$parent.usuario.id).then(response => {
                this.contaBancaria.tipo = response.data.ie_tipo_conta; 
                this.contaBancaria.agencia = response.data.nr_agencia;
                this.contaBancaria.conta = response.data.nr_conta;
                this.contaBancaria.bancoId = response.data.cd_banco;
            })

            this.etapaBanco = 1

            Pagamento.getBancos().then(response => {
                this.bancos = response.data
            })
        },
        data () {
            return {
                model: { valor: '0' },
                saldo: 0,
                medEmpresa: false,
                extrato: null,
                buttonValues: [ 10, 20, 100, 200 ],
                pagamentoConcluido: null,
                etapaBanco: 2,
                verExtrato: false, 
                integerCurrencyMask,
                bancos: [],
                dialog: {
                    adicionarCreditos: false,
                    //Primeiro passo do modal de resgate
                    adicionarConta: false,
                    //Segundo passo do modal de resgate
                    selecionarConta: false,
                    //Passo adicional para inserir os dados da conta
                    adicionarDadosConta: false,
                    //Terceiro passo do modal de resgate
                    resgatarCreditos: false,
                    //Aviso Final
                    avisoResgate: false
                },
                waiting: { saldo: false, adicionarCreditos: false },
                options: {
                    ie_status: { 'P': 'Aguardando o pagamento.', 'A': 'Aprovado.' }
                },
                contaBancaria: {
                    tipo: '',
                    bancoId: '',
                    agencia: '',
                    conta: ''
                },
                novaContaBancaria: {
                    title: '',
                    button: '',
                    tipo: '',
                    bancoId: '',
                    agencia: '',
                    conta: ''
                },
                valorResgate: '0'
            }
        },
        methods: {
            getSaldoCreditos () {
                this.waiting.saldo = true
                var medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                Pagamento.getExtratoCreditos({ie_associado_med_empresa: medempresa}).then(response => {
                    if (response.status === 200) {
                        if (response.data.length) this.extrato = {}
                        response.data.forEach(item => {
                            let key = this.$root.moment(item.dt_criado).format('MMM/YYYY').toUpperCase()
                            if (! this.extrato[key]) this.extrato[key] = []
                            this.extrato[key].push(item)
                        })
                    }
                    Pagamento.getSaldoCreditos({ie_associado_med_empresa: medempresa}).then(response => {
                        this.waiting.saldo = false
                        if (response.status === 200) this.saldo = response.data.saldo_empresa ? response.data.saldo_empresa[0].nr_saldo : response.data.saldo
                    })
                })
            },
            submitCreditos () {
                let split = this.model.valor.split(' ')
                if (parseInt(split[1]) < 30) {
                    this.$buefy.toast.open({
                        type: 'is-danger', position: 'is-bottom',
                        message: 'Valor mínimo é de R$ 30'
                    })
                    return 0
                }

                this.waiting.adicionarCreditos = true
                Pagamento.comprarCreditos({ cd_forma_pagamento: 1, valor: parseInt(split[1]) }).then(response => {
                    this.waiting.adicionarCreditos = false
                    if (response.status === 200) {
                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: 'Boleto gerado'
                        })
                        this.pagamentoConcluido = {
                            boleto_barcode: response.data.result.body.boleto_barcode,
                            boleto_url: response.data.result.body.boleto_url,
                            valor: parseInt(split[1])
                        }
                        this.model.valor = 'R$ 0'
                    }
                })
            },
            onBlur() {
                if (! this.model.valor.length) this.model.valor = 'R$ 0'
            },
            onFocus () {
                if (this.model.valor === 'R$ 0' || this.model.valor === '0') this.model.valor = ''
            },
            copyBarCode () {
                let elem = document.getElementById('barcode')
                elem.select()
                elem.setSelectionRange(0, 99999)
                document.execCommand('copy')
                this.$buefy.toast.open({
                    type: 'is-info', position: 'is-bottom',
                    message: 'Código copiado'
                })
            },
            openBoleto (item) {
                if (item.ie_status === 'P') window.open(item.boleto_url)
            },
            addValue (value) {
                if (this.model.valor === '0') this.model.valor = 'R$ 0'
                let split = this.model.valor.split(' ')
                this.model.valor = `${ split[0] } ${ value + parseInt(split[1]) }`
            },
            addValueResgate (value) {
                if (this.valorResgate === '0') this.valorResgate = 'R$ 0'
                let split = this.valorResgate.split(' ')
                this.valorResgate = `${ split[0] } ${ value + parseInt(split[1]) }`
            },
            adicionarContaAPI() {
                const params = {
                    ie_tipo_conta: this.novaContaBancaria.tipo,
                    nr_agencia: this.novaContaBancaria.agencia,
                    nr_conta: this.novaContaBancaria.conta,
                    cd_banco: this.novaContaBancaria.bancoId
                }

                Usuario.saveContaBancaria(params, this.$parent.$parent.usuario.id).then(response => {
                    if([200, 201, 204].includes(response.status)) {
                        this.dialog.adicionarDadosConta = false;
                        [this.contaBancaria] = [this.novaContaBancaria]
                    } else {
                        this.$buefy.toast.open({
                            type: 'is-danger', position: 'is-bottom',
                            message: 'Erro interno no servidor'
                        })
                    }
                }).catch(error => {
                    console.error(error)
                    this.$buefy.toast.open({
                        type: 'is-danger', position: 'is-bottom',
                        message: 'Sem conexão com o servidor'
                    })
                })
            },
            modalConta(type) {
                //Type: save quando for uma conta nova
                //Type: edit quando for edição
                if(type === 'edit') {
                    [this.novaContaBancaria] = [this.contaBancaria]
                    this.novaContaBancaria.title = 'Editar Conta Bancária'
                    this.novaContaBancaria.button = 'Salvar'
                } else {
                    this.novaContaBancaria.title = 'Adicionar Conta Bancária'
                    this.novaContaBancaria.button = 'Adicionar'
                }

                this.dialog.adicionarDadosConta = true
            },
            resgatar() {
                const data = new Date();
                const dia = String(data.getDate()).padStart(2, '0');
                const mes = String(data.getMonth() + 1).padStart(2, '0');
                const ano = data.getFullYear();
                const dataAtual = dia + '/' + mes + '/' + ano;
                
                const body = {
                    nr_valor: parseFloat(this.valorResgate.replace('R$ ', '')),
                    ds_observacao: `Saque ${dataAtual}`
                }

                Pagamento.resgate(body).then(response => {
                    if(response.status === 400) {
                        this.$buefy.toast.open({
                            type: 'is-danger', position: 'is-bottom',
                            message: 'Já existe uma solicitação pendente'
                        })
                    } else {
                        if(![200, 201, 204].includes(response.status)) {
                            this.$buefy.toast.open({
                                type: 'is-danger', position: 'is-bottom',
                                message: 'Erro interno no servidor'
                            })
                        } else {
                            this.dialog.avisoResgate = true
                        }
                    }
                }).catch(error => {
                    console.error(error)
                    this.$buefy.toast.open({
                        type: 'is-danger', position: 'is-bottom',
                        message: 'Sem conexão com o servidor'
                    })
                })
                
            }
        }
    }
</script>