<template lang="pug">
    .flex.items-center.justify-center.flex-wrap
        .max-w-screen-lg.w-full.relative(style='min-height:75vh')

            b-modal(v-model='dialog.cadastrarCartao' has-modal-card trap-focus :destroy-on-hide='false'
                aria-role='dialog' aria-modal @close='$refs.formCartao.model.reset()' :can-cancel="[ 'x' ]")
                template(#default='props')
                    FormCartao(ref='formCartao' :modalProps='props')

            .absolute.top-0.left-0.p-2
                a.text-3xl(@click='compact ? $parent.meusCartoes = false : $router.go(-1)')
                    i.jam.jam-chevron-left

            .py-56(v-if='waiting.cartoes')
                b-notification.p-8.bg-white(:closable='false')
                    b-loading(:is-full-page='false' v-model='waiting.cartoes')

            .w-full(v-else)
                .pt-4.pb-3.text-center.w-full
                    h1.font-bold.text-medclub-blue-300.text-sm {{ formasPagamento ? 'Formas de pagamento' : 'Meus cartões' }}
                    .flex.w-full.p-4.justify-center.flex-wrap
                        .flex.flex-wrap.justify-center.animate__animated.animate__fadeIn(v-if='cartoes.length == 0 && !formasPagamento')
                            .w-full.text-center.mb-4()
                              img.mx-auto.mb-4(style='width:28em' src='./../../assets/img/cartao_de_credito.jpeg')
                              b-button.text-sm.m-1(type='is-primary' icon-pack='jam' icon-left='jam-plus' @click='dialog.cadastrarCartao = true') Adicionar Cartão

                        .p-2.flex.flex-wrap.w-full(v-else)

                            .p-2.relative(v-for='cartao in cartoes' class='w-1/2 md:w-1/5')
                                .absolute.right-0.top-0.p-2(v-if='! compact')
                                    .p-2.cursor-pointer(@click='confirmRemover(cartao)')
                                        b-icon.text-red-600(pack='jam' icon='jam-trash-f')
                                .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border(:class="{ 'cursor-pointer': compact }" @click='selecionarCartao(cartao)')
                                    .w-full.text-center
                                        .inline-block(v-if="creditCardBrands.includes(cartao.brand)")
                                            img(style='width: 3.6em; margin: .3rem 0' :src="require(`./../../assets/img/credit-card-brands/logo-${ cartao.brand }.png`)")
                                        b-icon.text-4xl.my-4(v-else pack='jam' icon='jam-credit-card-f')
                                        p.text-sm.font-bold {{ creditCardBrands.includes(cartao.brand) ? $root.textCapitalize(cartao.brand) : 'Cartão' }}
                                        p.text-xs terminado em {{ cartao.last_digits }}

                            .p-2(v-if='formasPagamento' class='w-1/2 md:w-1/5')
                                .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border.cursor-pointer(:class="{ 'cursor-pointer': compact }" @click='selecionarMedclub()')
                                    .w-full.text-center
                                        .inline-block
                                            img(style='width: 7em; margin: .7rem 0' :src="require(`./../../assets/img/credit-card-brands/logo-medclub.png`)")
                                        p.text-sm.font-bold.text-medclub-blue-400 {{ $root.formatPrice(saldoMedclub) }}
                                        p.text-xs Créditos Medclub
                            
                            .p-2(v-if='formasPagamento && medempresa' class='w-1/2 md:w-1/5')
                                .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border.cursor-pointer(:class="{ 'cursor-pointer': compact }" @click='selecionarMedclubEmpresa()')
                                    .w-full.text-center
                                        .inline-block
                                            img(style='width: 7em; margin: .7rem 0' :src="require(`./../../assets/img/logo-medclub-empresa.png`)")
                                        p.text-sm.font-bold.text-medclub-blue-400 {{ $root.formatPrice(saldoMedEmpresa) }}
                                        p(style='font-size: 12px !important') Créditos Medclub Empresa

                            .p-2(v-if='formasPagamento' class='w-1/2 md:w-1/5')
                                .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border(:class="{ 'cursor-pointer': compact }" @click='selecionarPix()')
                                    .w-full.text-center
                                        .inline-block
                                            img(style='width: 3.6em; margin: .3rem 0' :src="require(`./../../assets/img/credit-card-brands/logo-pix.png`)")
                                        p.text-xs Pagar com Pix

                            .p-2(class='w-1/2 md:w-1/5')
                                .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-40.border.cursor-pointer(:class="{ 'cursor-pointer': compact }" @click='dialog.cadastrarCartao = true')
                                    .w-full.text-center
                                        b-icon.text-4xl.my-4(pack='jam' icon='jam-plus')
                                        p.text-xs Adicionar Cartão
</template>

<script>
import { Pagamento } from "./../../middleware";
import FormCartao from "./FormCartao";
export default {
  components: { FormCartao },
  created() {
    if (!this.$parent.$parent.usuario && !this.formasPagamento)
      this.$router.push({ name: "home" });
    else this.getCartoes();
  },
  props: ["compact", "formasPagamento", "valorTotal"],
  data() {
    return {
      cartoes: [],
      saldoMedclub: 0,
      saldoMedEmpresa: 0,
      waiting: { cartoes: true },
      dialog: { cadastrarCartao: false },
      creditCardBrands: ["visa", "mastercard", "hipercard", "amex", "diners"],
      medempresa: false,
    };
  },
  methods: {
    getCartoes() {
      this.waiting.cartoes = true;
      this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
      Pagamento.getSaldoCreditos({ie_associado_med_empresa: this.medempresa}).then((response) => {
        if (response.status === 200) {
          this.saldoMedclub = response.data.saldo
          this.saldoMedEmpresa = response.data.saldo_empresa ? response.data.saldo_empresa[0].nr_saldo : 0
        } 
        Pagamento.getCartoes().then((response) => {
          this.waiting.cartoes = false;
          if (response.status === 200) this.cartoes = response.data;
        });
      });
    },
    confirmRemover(cartao) {
      this.$buefy.dialog.confirm({
        message: `Deseja remover o cartão terminado em <b>${cartao.last_digits}</b>?`,
        cancelText: "Cancelar",
        type: "is-danger",
        confirmText: "Remover",
        onConfirm: () => this.remover(cartao),
      });
    },
    remover(cartao) {
      this.waiting.cartoes = true;
      Pagamento.removeCartao(cartao.id).then((response) => {
        if (response.status === 200) {
          this.$buefy.toast.open("Cartão removido");
          this.getCartoes();
        }
      });
    },
    selecionarCartao(cartao) {
      if (this.compact) {
        this.$parent.cartao = cartao;
        if (this.$parent.parcelaOptions.length > 1)
          this.$parent.parcelas = null;
        else this.$parent.parcelas = 1;
        this.$parent.meusCartoes = false;
      }
    },
    selecionarMedclub() {
      this.$parent.cartao = { brand: "medcred" };
      this.$parent.parcelas = 1;
      this.$parent.meusCartoes = false;
    },
    selecionarMedclubEmpresa() {
      this.$parent.cartao = { brand: "medcred_empresa" };
      this.$parent.parcelas = 1;
      this.$parent.meusCartoes = false;
    },
    selecionarPix() {
      this.$parent.cartao = { brand: "pix" };
      this.$parent.parcelas = 1;
      this.$parent.meusCartoes = false;
    },
  },
};
</script>
