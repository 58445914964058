<template lang="pug">
    .w-full
        div
            .absolute.top-0.left-0.p-2
                a.text-3xl(@click='back()')
                    i.jam.jam-chevron-left
            .py-56(v-if='waiting.datas')
                b-notification.p-8.bg-white(:closable='false')
                    b-loading(:is-full-page='false' v-model='waiting.datas')
            .w-full(v-show='! waiting.especialistas && ! waiting.datas')
                .py-24.px-4(v-if='!list.length')
                    a.bg-green-500.border.border-yellow-300.flex.max-w-xl.mx-auto.items-center.rounded(href="https://api.whatsapp.com/send?phone=558631311881" style="text-decoration: none; color: #fff")
                        .text-center(class='w-1/6')
                            b-icon(icon='whatsapp' size="is-medium" )
                        .p-2(class='w-5/6')
                            span.text-sm(v-if="$parent.agendamento.retonar_por_video") Não há nenhuma data disponível para <b>{{ $parent.agendamento.ie_telemedicina ? 'consulta por vídeo' : 'consulta presencial' }} </b>
                            span.text-sm(v-else) Não há nenhuma data disponível para <b> consulta </b>
                            span.text-sm(v-if='$parent.agendamento.especialista') com o profissional <b>{{ $parent.agendamento.especialista.nm_especialista }}</b>
                            span.text-sm(v-else-if='$parent.agendamento.especialidade') na especialidade <b>{{ $parent.agendamento.especialidade.nm_especialidade }}</b>
                            span.text-sm . Por favor, entre em contato com o MEDCLUB através do nosso whatsapp <b>(86) 3131-1881</b>

                div(v-else)
                    .py-56(v-if='waiting.transferirHorario')
                        b-notification.p-8.bg-white(:closable='false')
                            b-loading(:is-full-page='false' v-model='waiting.transferirHorario')
                    div(v-else)
                        .w-full.flex.flex-wrap.justify-end.px-2(class='py-4 md:py-5')
                            .text-right(class='w-4/6 md:w-full')
                                h1.text-gray-700
                                    span {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
                                    span(v-if='$parent.agendamento.estabelecimento && ! $parent.agendamento.ie_telemedicina') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.estabelecimento.nm_estabelecimento || $parent.agendamento.estabelecimento.nm_fantasia }}
                                    span(v-if='$parent.agendamento.especialidade') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialidade.nm_especialidade }}
                                    span(v-if='$parent.agendamento.especialista') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialista.nm_especialista }}
                div(v-if="list.length" )
                    .pb-3.text-center.w-full
                        h1.font-bold.text-gray-700.text-md(v-if="$route.name !== 'prontoSocorro'") Escolha data e {{ $parent.agendamento.ie_hora_marcada ? 'horário' : 'especialista' }}
                        h1.font-bold.text-gray-700.text-md(v-else) Escolha {{ $parent.agendamento.ie_hora_marcada ? 'horário' : 'especialista' }}

                    .p-2.pb-16(v-if='! waiting.especialistas')
                        h1.px-2.text-gray-700(v-if='list.length > 1') Selecione o {{ $parent.agendamento.ie_hora_marcada ? 'horário' : 'especialista' }}:
                        .p-1.w-full.text-center(v-if='! list.length')
                            p.text-sm.my-6 Nenhum horário encontrado.
                        .p-1.w-full(v-else v-for='especialista, idx in list' :key='idx')
                            .p-4.shadow-md.rounded.border

                                .flex.items-center

                                    .p-1.hidden(class='md:block')
                                        .w-16.h-16.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(:style="`background-image: url('${ especialista.aq_foto }')`")

                                    .w-full(class='md:w-11/12 md:pl-4 md:p-1')
                                        .flex.flex-wrap.w-full
                                            .w-full(class='md:text-left md:w-5/6')
                                                .mx-auto.mb-2.w-16.h-16.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(class='md:hidden' :style="`background-image: url('${ especialista.aq_foto }')`")
                                                p.text-sm.font-bold.text-gray-700(class='md:text-lg') {{ especialista.nm_especialista }}
                                                p.text-sm.text-gray-600(v-if="$route.name !== 'prontoSocorro'") {{ especialista.nm_conselho }} {{ especialista.uf_conselho }} - {{ especialista.nr_conselho }}
                                                .flex.items-center(v-if="especialista.nr_avaliacao")
                                                    star-rating(
                                                        :rating="especialista.nr_avaliacao"
                                                        :read-only="true"
                                                        :show-rating="false"
                                                        :round-start-rating="false"
                                                        :star-size="20")
                                                    .text-sm.align-center ({{ especialista.nr_avaliacao }})

                                            .w-full(class='text-center md:text-right md:w-1/6')
                                                p.font-bold.text-medclub-blue-400.text-xl(class='md:text-2xl') {{ $root.formatPrice(especialista.nr_valor) }}
                                                // p.font-bold.text-xs.text-red-600 {{ especialista.qtd_vaga }} VAGA{{ especialista.qtd_vaga === 1 ? '' : 'S' }}

                                .w-full.my-2(v-if='especialista.ds_observacao')
                                    p.text-sm.font-bold.text-medclub-blue-400.mb-2 Observações
                                    .text-sm.text-gray-700.whitespace-pre-wrap(v-html='especialista.ds_observacao')
                                .mt-2.mb-4.text-xs.bg-yellow-200.p-2.border.border-yellow-300.w-full(v-if='especialista.ie_agenda_presencial') <b>Atenção!</b> É necessário agendar com a clínica.

                                .p-1.w-full(class='md:w-56' v-if="!agenda[especialista.agenda_id]")
                                  b-button.w-full.rounded.p-0(type='is-primary')
                                    .flex.items-center
                                      b-icon.text-xl(pack='jam' icon='jam-circle')
                                      span.text-sm Carregando...

                                .p-1.w-full(class='md:w-56' v-if="agenda[especialista.agenda_id] && agenda[especialista.agenda_id].tipo === 'agenda_presencial'")
                                  b-button.w-full.rounded.p-0(type='is-primary'  @click='selectHorario(especialista, {id: agenda[especialista.agenda_id].horarios[0], dt_agenda: $root.moment().format("YYYY-MM-DD")})')
                                    .flex.items-center(v-if="show_calendar[especialista.agenda_id] === false")
                                      b-icon.text-xl(pack='jam' icon='jam-shopping-cart')
                                      span.text-sm Adicionar ao carrinho

                                .hora-marcada-wrapper(class='md:w-100%' v-if="agenda[especialista.agenda_id] && agenda[especialista.agenda_id].tipo === 'hora_marcada'")
                                  b-button.hora-marcada-button(class='rounded.p-0' style="width: 30%" type='is-primary'  @click='toggleCalendar(especialista.agenda_id)')
                                    .flex.items-center(v-if="show_calendar[especialista.agenda_id] === false")
                                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-down')
                                      span.text-sm Ver horários
                                    .flex.items-center(v-if="show_calendar[especialista.agenda_id] === true")
                                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-up')
                                      span.text-sm Ocultar horários
                                    .flex.items-center(v-if="show_calendar[especialista.agenda_id] === 'loading'")
                                      b-icon.text-xl(pack='jam' icon='jam-circle' spin)
                                      span.text-sm Carregando horários...
                                  div(class="hora-marcada-container")
                                    b-icon.text-xl(pack='jam' icon='jam-calendar-alt')
                                    span(class="hora-marcada-text") Atendimento por HORA MARCADA 

                                .p-1.w-full(class='md:w-56' v-if="agenda[especialista.agenda_id] && agenda[especialista.agenda_id].tipo === 'ordem_de_chegada'")
                                  b-button.w-full.rounded.p-0(type='is-primary'  @click='toggleCalendar(especialista.agenda_id)')
                                    .flex.items-center(v-if="show_calendar[especialista.agenda_id] === false")
                                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-down')
                                      span.text-sm Ver horários
                                    .flex.items-center(v-if="show_calendar[especialista.agenda_id] === true")
                                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-up')
                                      span.text-sm Ocultar horários
                                    .flex.items-center(v-if="show_calendar[especialista.agenda_id] === 'loading'")
                                      b-icon.text-xl(pack='jam' icon='jam-circle' spin)
                                      span.text-sm Carregando horários...
                                transition
                                  .flex.w-full.overflow-x-auto.px-1.py-2.shadow-sm.rounded.border(v-if="show_calendar[especialista.agenda_id] === true" style="background-color:#fff;")
                                    body.bg-gray-100.w-full
                                      h2.text-center.font-semibold.mb-4 Escolha a data e horário:
                                      div.carousel.slide.como-funciona-carousel(data-ride="carousel", data-interval="false", v-bind:id=`'carouselExampleControls_' + especialista.agenda_id` )
                                        ol.carousel-indicators
                                          li(v-for="(chunk, index) in estabelecimentos_horarios_chunked[especialista.agenda_id]", :class="{'active':index === 0}",
                                          v-bind:data-target=`'carouselExampleControls_' + especialista.agenda_id`, :data-slide-to="index", style="background-color:#aaa;")
                                        div.carousel-inner
                                          div.carousel-item(v-for='items, idx in estabelecimentos_horarios_chunked[especialista.agenda_id]' :class="{ active: idx === 0 }")
                                            div.row.m-32(style="margin:80px !important;")
                                              div.col(v-for='horarios, idx in items' :key='idx', style="min-height: 120px;")
                                                div.card_como_funciona
                                                  .flex-1.text-center
                                                    .inline-block(:class="{'mx-2': horarios_chunks_size > 1}")
                                                      p.font-bold.uppercase {{ $root.moment(horarios[0]).format('ddd') }}
                                                      p {{ $root.moment(horarios[0]).format('DD/MM') }}
                                                      p.text-red-500.text-sm(v-if="agenda[especialista.agenda_id].tipo === 'ordem_de_chegada'") {{horarios[2].find(h => h.qtd_vagas).qtd_vagas }} VAGAS
                                                      .flex.justify-center
                                                        hr(style="border: 0.5px solid #515151;width:50%")

                                                      .bg-teal-500.text-white.rounded-full.px-4.py-1.mt-2.cursor-pointer(v-for='h in !collapse_horarios?horarios[1]:horarios[2]', v-if="agenda[especialista.agenda_id].tipo === 'ordem_de_chegada'")(@click="selectHorario(especialista,{...h, dt_agenda: horarios[0] })") A partir das {{formataHorario(h.hr_inicio)}}

                                                      .bg-teal-500.text-white.rounded-full.px-4.py-1.mt-2.cursor-pointer(v-for='h in !collapse_horarios?horarios[1]:horarios[2]', v-if="agenda[especialista.agenda_id].tipo === 'hora_marcada'")(@click="selectHorario(especialista,{...h, dt_agenda: horarios[0] })") {{formataHorario(h.hr_agenda)}}
                                              .col-12.mt-4(v-if="items.find(h => h[1].length > 3)") 
                                                .flex-1.mb-4.text-center.font-semibold.cursor-pointer(style="color:#5A688D;" @click="collapse_horarios=!collapse_horarios")  Exibir todos os horários
                                            .row


                                        a.carousel-control-prev(v-bind:href=`'#carouselExampleControls_' + especialista.agenda_id` role="button" data-slide="prev")(v-if="estabelecimentos_horarios_chunked[especialista.agenda_id].length > 1")
                                          span.carousel-control-prev-icon(aria-hidden="true")
                                          span.sr-only Anterior

                                        a.carousel-control-next(v-bind:href=`'#carouselExampleControls_' + especialista.agenda_id` role="button" data-slide="next")(v-if="estabelecimentos_horarios_chunked[especialista.agenda_id].length > 1")
                                          span.carousel-control-next-icon(aria-hidden="true")
                                          span.sr-only Próximo
</template>

<style scoped>
  /* Container principal do indicador de hora marcada */
  .hora-marcada-wrapper {
    display: flex;
    width: 100%;
    padding: 0.25rem;
    gap: 0.75rem;
  }

  /* Estilos do botão */
  .hora-marcada-button {
    width: 30%;
    border-radius: 0.25rem;
    padding: 0;
  }

  .hora-marcada-container {
    /* Layout */
    display: flex;
    align-items: center;
    padding: 0.5rem; /* reduzido de 0.75rem para 0.5rem */
    width: 30%;
    justify-content: space-evenly;
    
    /* Cores */
    background-color: #C6F6D5;
    color: #276749;
    
    /* Bordas e sombras */
    border-radius: 0.3rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05),
                0 1px 1px 0 rgba(0, 0, 0, 0.03);
  }

  /* Estilo do texto dentro do container */
  .hora-marcada-text {
    font-weight: 400;
    font-size: 0.875rem; /* adicionado para deixar o texto um pouco menor */
    line-height: 1; /* reduzido para diminuir a altura total */
  }


  /* Hover state opcional para interatividade */
  .hora-marcada-container:hover {
    background-color: #9AE6B4;
    transition: background-color 0.2s ease;
  }

  /* Media queries para responsividade */
  @media (max-width: 768px) {
    .hora-marcada-wrapper {
      flex-direction: column;
      gap: 0.5rem;
    }

    .hora-marcada-button {
      width: 100% !important;
    }

    .hora-marcada-container {
      padding: 0.375rem;
      width: 100% !important;
    }
    
    .hora-marcada-text {
      font-size: 0.9rem;
      font: bold;
    }

    .hora-marcada-container .b-icon {
      font-size: 1rem;
    }
  }
</style>

<script>
import { Agendamento } from "../../../middleware";
import StarRating from "vue-star-rating";
import { mergeArray } from "../../../utils";
import moment from "moment";
import { orderBy } from "lodash";
import { chunk } from "lodash";

import termoImg from '../../../assets/img/termo_consulta.png'
export default {
  components: { StarRating },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDetroy() {
    window.removeEventListener('resize', this.onResize);
  },
  created() {
    if (!["medico", "reagendamento", "retorno"].includes(this.$parent.pesquisarPor)) {
      this.$parent.agendamento.especialista = null;
    }

    let params = {
      cd_especialidade: this.$parent.agendamento.especialidade.id,
      ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
    };
    if (JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] = JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))



    if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;

    if (!["reagendamento", "retorno"].includes(this.$parent.pesquisarPor)) {
      if (this.$parent.agendamento.especialista && !this.$parent.agendamento.ie_telemedicina)
        this.$parent.agendamento.estabelecimento = null;
    }

    if (this.$parent.agendamento.estabelecimento && this.$parent.agendamento.ie_telemedicina == false)
      params.cd_estabelecimento = this.$parent.agendamento.estabelecimento.id;
    else params.cd_estabelecimento = 13;

    this.waiting.datas = true;

    params.modo_novo_calendario = true;

    Agendamento.getEspecialistasDatas(params).then((response) => {
      if (response.status === 200) {
        const resposta = response.data;
        const agenda = {};
        for (const agenda_id in resposta) {
          const tipo_agenda = resposta[agenda_id].tipo; // ordem_de_chegada, hora_marcada, agenda_presencial
          const horarios = resposta[agenda_id].agenda;

          agenda[agenda_id] = {
            tipo: tipo_agenda,
            horarios
          };
        }

        this.agenda = agenda

        /*         let datas = response.data.datas;
                let dt_agenda = this.$root.moment(this.$parent.agendamento.dt_agenda).format("YYYY-MM-DD");
                let dt_execucao = this.$parent.agendamento.guiaeletronica_set?.dt_execucao;
                if (dt_execucao) dt_execucao = this.$root.moment(dt_execucao).format("YYYY-MM-DD");
                if (datas) {
                  datas.forEach((data) => {
                    if (this.$parent.pesquisarPor === "retorno") {
                      if (this.$root.moment(data) < this.$root.moment(dt_execucao).add(30, "d")) {
                        this.dateList.push(this.$root.moment(data)._d);
                      }
                    } else if (
                      (this.$parent.pesquisarPor === "reagendamento" || dt_execucao != data) &&
                      (this.$parent.agendamento.ie_hora_marcada || dt_agenda != data)
                    ) {
                      this.dateList.push(this.$root.moment(data)._d);
                    }
                  });
        
                  this.data = this.$parent.agendamento._data ? this.$parent.agendamento._data : this.dateList[0];
                } */
      }
      this.getEspecialistas();
    });
  },
  computed: {
    estabelecimentos_horarios_chunked: function () {
      let formattedData = {};

      for (const id in this.agenda) {
        formattedData[id] = [];
        for (const date in this.agenda[id].horarios) {
          const arrayHorariosOrderedByTime = orderBy(this.agenda[id].horarios[date], [(item) => {
            return item.hr_inicio
          }], ['asc'])
          formattedData[id].push([date, arrayHorariosOrderedByTime, arrayHorariosOrderedByTime.slice(0, 3)]);
        }

        // sort by date
        formattedData[id] = orderBy(formattedData[id], [(item) => {
          return moment(item[0]).format('YYYY-MM-DD')
        }], ['asc'])

        // chunks of 3 for carrousel pagination
        formattedData[id] = chunk(formattedData[id], this.horarios_chunks_size)
      }

      return formattedData
    },
    horarios_chunks_size: function () {
      return this.windowWidth < 768 ? 1 : 3
    },
  },
  data() {
    return {
      list: [],
      data: null,
      dateList: [],
      transferirHorario: false,
      waiting: { especialistas: true, datas: true, transferirHorario: false },
      dialog: { prontoSocorro: false },
      termoAceito: false,
      horario: "",
      especialista: "",
      // Novo calendario
      collapse_horarios: true,
      show_calendar: {},
      agenda: {},
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    back() {
      delete this.$parent.agendamento._data;
      if (this.$parent.pesquisarPor === "reagendamento" || this.$parent.pesquisarPor === "retorno") {
        this.$parent.agendamento = null;
        this.$parent.pesquisarPor = null;
      } else if (this.$parent.agendamento.ie_telemedicina) {
        this.$parent.agendamento.especialidade = null;
        if (this.$route.name !== "agendarConsulta") this.$router.push({ name: "agendarConsulta" });
      } else this.$parent.agendamento.estabelecimento = null;
    },
    listaTeste(arr) {
      let teste = mergeArray(arr.hr_horario, "hr_inicio_atendimento");

      return teste;
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    getEspecialistas() {
      // eslint-disable-next-line no-unused-vars
      let params = {}
      params.dt_consulta = moment().format('YYYY-MM-DD')
      params.cd_especialidade = this.$parent.agendamento.especialidade.id
      if (this.$route.name !== "prontoSocorro") {
        const [slug_doctor_name, slug_doctor_id, slug_id_especialidade] = this.$route.params.slug.split("_");

        params = {
          cd_especialidade: this.$parent.agendamento.especialidade.id,
          ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
        };

        const pesquisar_por = this.$route.params.pesquisar_por;

        if (pesquisar_por == "medico") {
          const ds_telemedicina = this.$route.params.ds_telemedicina;
          const slug =
            slug_doctor_name +
            "_" +
            slug_doctor_id +
            "_" +
            slug_id_especialidade;

          if (!this.$parent.agendamento.is_odonto) {
            this.$router.replace({
              name: "agendarConsultaCompartilhar",
              params: { ds_telemedicina, pesquisar_por, slug },
            });
          } else {
            this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
          }
        }

        if (pesquisar_por == "data") {
          const cd_especialidade = this.$parent.agendamento.especialidade.id;
          const cd_estabelecimento = this.$parent.agendamento.estabelecimento.id;
          const estabelecimento_name = this.$parent.agendamento.estabelecimento.nm_estabelecimento
            ? String(this.$parent.agendamento.estabelecimento.nm_estabelecimento)
              .replace(/\s/g, "-")
              .toLowerCase()
            : slug_doctor_name;
          const ds_telemedicina = this.$route.params.ds_telemedicina;
          const slug =
            estabelecimento_name +
            "_" +
            cd_especialidade +
            "_" +
            cd_estabelecimento +
            "_" +
            this.$root.moment(this.data).format("YYYY-MM-DD"); // slug should have cd_especialidade, cd_estabelecimento

          if (!this.$parent.agendamento.is_odonto) {
            this.$router.replace({
              name: "agendarConsultaCompartilhar",
              params: { ds_telemedicina, pesquisar_por, slug },
            });
          } else {
            this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
          }
        }
      }

      if (JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] = JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))

      if (this.$parent.agendamento.estabelecimento && this.$parent.agendamento.ie_telemedicina)
        params.cd_estabelecimento = 13;
      else if (this.$parent.agendamento.estabelecimento && this.$parent.agendamento.ie_telemedicina == false)
        params.cd_estabelecimento = this.$parent.agendamento.estabelecimento.id;
      if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;

      this.waiting.especialistas = true;
      Agendamento.getEspecialistasCompleto(params).then((response) => {
        this.waiting.especialistas = false;
        this.waiting.datas = false;

        if (response.status === 200) {
          let result = response.data;
          if (result.length) {
            for (const especialista of result) {
              this.show_calendar[especialista.agenda_id] = false
            }
            result.map((item) => {
              if (!item.ie_hora_marcada && !item.ie_agenda_presencial) {
                item["hr_turnos"] = mergeArray(item.hr_horario, "hr_horario_funcionamento");
              }
            });
            this.list = result;
          } else {
            this.list = [];
          }

          // this.list = Object.values(response.data).length ? Object.values(response.data)[0] : []
        }
      });
    },
    async toggleCalendar(id) {
      const toggled = !this.show_calendar[id]
      this.show_calendar[id] = 'loading'
      this.$forceUpdate();

      if (toggled) {
        this.show_calendar[id] = toggled
      } else {
        this.show_calendar[id] = toggled
      }
      this.$forceUpdate();
    },
    selectHorario(especialista, horario) {
      this.horario = horario;
      this.especialista = especialista;
      // Obs 1: Caso seja pronto socorro, primeiro deve aparecer o modal de
      // termos e condições, antes da pessoa poder adicionar ao carrinho
      // Obs 2: O modal é exibido apenas caso exista um termo para aquele estabelecimento
      especialista.ds_termo_pronto_socorro = especialista.ds_termo_pronto_socorro.replace(/^"|"$/g, '')
      if ((this.$route.name === "prontoSocorro" || especialista.ds_termo_pronto_socorro) &&  this.termoAceito === false) {
        this.$buefy.dialog.confirm({
          title: "Termos e Condições",
          message: `<div class="text-gray-700" style='display: flex; align-items: center; justify-content: center; flex-direction: column; '>
                       <img src=${termoImg} 
                       style="height: 200px; width: 200px;" />
                      <p style='text-align: justify;'>${this.especialista.ds_termo_pronto_socorro }</p>
                    </div>`,
          confirmText: "Li e aceito o termo",
          cancelText: "Cancelar",
          onConfirm: () => {
            this.termoAceito = true;
            this.selectHorario(horario, especialista);
          },
        });
      } else {
        let message = "";
        if (this.especialista.ie_hora_marcada) {
          message += "Horário: ";
          message += this.horario.hr_agenda;
        } else {
          message += "A partir de ";
          message += this.especialista.hr_horario[0].hr_inicio_atendimento;
        }
        if (this.$parent.pesquisarPor === "reagendamento") {
          this.$buefy.dialog.confirm({
            title: "Confirmar reagendamento?",
            //message: `<div class="flex justify-center my-2"><i class="mdi mdi-calendar-sync text-6xl"></i><div class="text-gray-700 whitespace-pre-wrap">${this.especialista.ds_termo_pronto_socorro ? this.especialista.ds_termo_pronto_socorro : ''}</div></div>`,
            message: `
                                <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center">

                                        <img src=${this.especialista.aq_foto
              } style="height: 80px; width: 80px; border-radius: 40px" />
                                        <p class="font-bold mt-2 ">Dr(a). ${this.especialista.nm_especialista}</p>
                                        <p>${this.$root.dataPorExtenso(
                this.$root.moment(this.data, "DD/MM/YYYY", true).format("YYYY-MM-DD")
              )}</p>
                                        <p >${message}</p>

                                </div>`,
            confirmText: "Sim",
            cancelText: "Não",
            onConfirm: () => {
              this.waiting.transferirHorario = true;
              this.transferirHorario = true;
              Agendamento.transferirHorario(this.$parent.agendamento.cd_horario_agendamento, horario.id).then(() => {
                this.waiting.transferirHorario = false;
                this.$router.go();
              });
              this.$parent.pesquisarPor = null;
            },
          });
        } else if (this.$parent.pesquisarPor === "retorno") {
          // <i class="mdi mdi-calendar-sync text-6xl"></i>

          this.$buefy.dialog.confirm({
            title: "Confirmar retorno?",
            // message: `<div class="flex items-center justify-center"><div><div class="w-16 h-16 bg-gray-200 rounded-full bg-center bg-cover bg-no-repeat" style="background-image:url(${this.especialista.aq_foto})"></div></div><div class="flex items-center justify-center text-center text-sm font-bold md:text-left md:w-5/6">Dr(a) ${this.especialista.nm_especialista}</div></div>`,
            message: `
                                <div style="display: flex; align-items: center; justify-content: center; flex-direction: column; text-align: center">

                                        <img src=${this.especialista.aq_foto
              } style="height: 80px; width: 80px; border-radius: 40px" />
                                        <p class="font-bold mt-2 ">Dr(a). ${this.especialista.nm_especialista}</p>
                                        <p>${this.$root.dataPorExtenso(
                this.$root.moment(this.data, "DD/MM/YYYY", true).format("YYYY-MM-DD")
              )}</p>
                                        <p >${message}</p>

                                </div>`,
            onConfirm: () => {
              this.waiting.transferirHorario = true;
              this.transferirHorario = true;
              Agendamento.marcarRetorno(
                this.$parent.agendamento.cd_horario_agendamento,
                horario.id,
                this.$parent.agendamento.ie_telemedicina,
                this.$parent.agendamento.especialista,
                this.$parent.agendamento.especialidade,
                this.$parent.agendamento.estabelecimento,
                this.$parent.agendamento.paciente
              ).then(() => {
                this.waiting.transferirHorario = false;
                this.$router.go();
              });
              this.$parent.pesquisarPor = null;
            },
          });
        } else {
          this.$parent.agendamento.horario = horario;
          this.$parent.agendamento.especialista = especialista;
          if (!this.$parent.agendamento.estabelecimento)
            this.$parent.agendamento.estabelecimento = { id: especialista.cd_estabelecimento };
          this.$parent.agendamentoCriado = true;
        }
      }
    },
    formataHorario(horario){
      return horario.replace(/(\d{2}):(\d{2}):(\d{2})/, (match, horas, minutos) => {
        if (minutos === "00") {
          return `${horas}h`
        }
        return `${horas}h${minutos}`
      })
      
    }
  },
};
</script>
