<template lang="pug">
.flex.items-center.justify-center.flex-wrap()
    b-modal(v-if='!medempresa' v-model='dialog.outrasPessoas' has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal :can-cancel="[ 'x' ]")
            template(#default='props')
                OutrasPessoasForm(ref='outrasPessoasComponent' :modalProps='props')
    .max-w-screen-lg.w-full.relative(v-if="!carteira" style='min-height:75vh')

        .absolute.top-0.left-0.p-2(v-if='! waiting.carrinho')
            a.text-3xl(@click='$router.go(-1)')
                i.jam.jam-chevron-left
                
        .py-56(v-if='waiting.pessoas')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.pessoas')
        
        .w-full(v-else)
            .pt-4.pb-3.text-center.w-full
                h1.font-bold.text-medclub-blue-300.text-sm Outros usuários

            .text-center.px-16.py-32(v-if='! pessoas.length')
                p.text-gray-600.text-sm Você não possui nenhuma outra pessoa associada ao seu usuário.
                b-button.mt-8.text-sm(v-if='!medempresa' type='is-primary' @click='dialog.outrasPessoas = true') Adicionar usuário

            .p-2.flex.flex-wrap(v-else)
                    .p-2.relative(class='w-1/2 md:w-1/4' v-for='pessoa in pessoas')
                        .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-100.bg-medclub-blue-100.border
                            .w-full.text-center
                                b-icon.text-4xl.my-4(pack='jam' icon='jam-user')
                                p.text-sm {{ pessoa.nm_pessoa_fisica }}
                                .p-2.w-full(style='display:flex; flex-direction:row; justify-content:space-evenly')
                                    .cursor-pointer(@click='openPessoa(pessoa)' v-if='!medempresa')
                                        v-icon.text-3xl.my-4.text-blue-700(alt='Editar' class='mdi mdi-pencil-circle-outline')
                                        span.text-blue-700(style='font-size:10px') EDITAR
                                    .cursor-pointer(@click='carteira = true, setPessoaCarteira(pessoa)')
                                        v-icon.text-3xl.my-4(class='mdi mdi-card-account-details-outline' )
                                        span(style='font-size:10px')  CARTEIRA
                                    .cursor-pointer(@click='confirmRemover(pessoa)' v-if='!medempresa')
                                        v-icon.text-3xl.my-4.text-red-600(class='mdi mdi-close-circle-outline')
                                        span.text-red-600(style='font-size:10px')  EXCLUIR
                    .p-2(class='w-1/2 md:w-1/4')
                        .flex.flex-wrap.items-center.justify-center.shadow-md.rounded.p-2.w-full.h-100.bg-medclub-blue-100.border.cursor-pointer(@click='openModal()')
                            .w-full.text-center
                                b-icon.text-4xl.my-4(pack='jam' icon='jam-plus')
                                p.text-sm Outra pessoa
    .max-w-screen-lg.mx-auto.w-full(v-else)
        .max-w-screen-lg.w-full.relative
            .absolute.top-0.left-0.p-2(v-if='!waiting.carrinho')
                a.text-3xl(@click='carteira = false')
                    i.jam.jam-chevron-left
        .flex.flex-wrap.justify-center
            .w-full.relative(class='md:w-1/2')
                .textWrapper.pl-6                    
                    h1.uppercase Titular
                    h2.font-bold.uppercase(class='md:text-lg text-xs') {{ $parent.$parent.usuario.nm_pessoa_fisica }}
                    h3.text-lg.mb-2(class='md:text-lg text-xs') {{ $parent.$parent.usuario.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') }}
                    h1.uppercase Dependente
                    h2.font-bold.uppercase(class='md:text-lg text-xs') {{ pessoaCarteira.nm_pessoa_fisica }}
                    h3.text-lg(class='md:text-lg text-xs' v-if="pessoaCarteira.nr_cpf") {{ pessoaCarteira.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') }}
                img(src='@/assets/img/carteira-medclub/novo.png')

</template>
<style lang="scss" scoped>
    .qrcodeCarteiraWrapper {
        position: absolute;
        padding: 2.2%;
        width: 25%;
        top: 16%;
        right: 5.4%;
        > img {
            width: 100% !important;
            height: auto !important;
        }
    }
    .textWrapper {
        position: absolute;
        top: 10%;
        left: 0;
    }
</style>

<script>
    import { OutrasPessoas } from './../../middleware'
    import OutrasPessoasForm from './OutrasPessoasForm'
    import moment from 'moment'; moment.locale('pt-br')
    export default {
        components: { OutrasPessoasForm },
        created () {
            this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            if (! this.$parent.$parent.usuario) this.$router.push({ name: 'home' })
            else this.getPessoas()
        },
        mounted () { this.dialog.outrasPessoas = false },
        watch: {
            'dialog.outrasPessoas': function () {
                if (this.$refs.outrasPessoasComponent && !this.dialog.outrasPessoas)
                    this.$refs.outrasPessoasComponent.model.reset()
            }
        },
        data () {
            return {
                pessoas: [],
                carteira: false,
                pessoaCarteira: {},
                waiting: { pessoas: false, },
                dialog: { outrasPessoas: true },
                medempresa: false,
            }
        },
        methods: {
            openModal(){
                window.scrollTo(0,0);
                this.dialog.outrasPessoas = true;
            },
            getPessoas () {
                this.waiting.pessoas = true
                let params  = {
                    ie_atende_medclub_empresa: this.medempresa
                }

                OutrasPessoas.getOutrasPessoas(params).then(response => {
                    this.waiting.pessoas = false
                    if (response.status === 200) this.pessoas = response.data
                })
            },
            setPessoaCarteira(pessoa){
                this.pessoaCarteira = pessoa
                
            },
            confirmRemover (pessoa) {
                this.$buefy.dialog.confirm({
                    message: `Deseja remover o usuário <b>${ pessoa.nm_pessoa_fisica }</b>?`,
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    confirmText: 'Remover',
                    onConfirm: () => this.remover(pessoa)
                })
            },
            remover (pessoa) {
                this.waiting.pessoas = true
                let body = { id: pessoa.id }
                OutrasPessoas.deleteOutraPessoa(body).then(response => {
                    if (response.status === 200) {
                        this.$buefy.toast.open('Usuário <b>removido</b>')
                        this.getPessoas()
                    }
                })
            },
            openPessoa (pessoa) {               
                let model = this.$refs.outrasPessoasComponent.model
                model.id = pessoa.id
                model.nm_pessoa_fisica = pessoa.nm_pessoa_fisica
                model.nr_telefone = this.$root.applyTelefoneMask(pessoa.nr_telefone)
                model.dt_nascimento = moment(pessoa.dt_nascimento, 'YYYY-MM-DD', true).format('DD/MM/YYYY')
                model.ie_sexo = pessoa.ie_sexo
                this.dialog.outrasPessoas = true
            }
        }
    }
</script>