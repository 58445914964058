var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modulo modulo_como_funciona como_funciona_container",attrs:{"id":"como_funciona"}},[_c('div',{staticClass:"container flex items-center justify-center flex-col"},[_vm._m(0),_c('div',{staticClass:"carousel slide como-funciona-carousel",attrs:{"id":"carouselExampleControls","data-ride":"carousel"}},[(_vm.windowWidth > 1280)?_c('div',{staticClass:"carousel-inner"},[_vm._m(1),_vm._m(2),_vm._m(3)]):(_vm.windowWidth > 768)?_c('div',{staticClass:"carousel-inner"},[_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)]):_c('div',{staticClass:"carousel-inner"},[_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17)]),_vm._m(18),_vm._m(19)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-10"},[_c('h1',[_vm._v("Como Funciona?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/quem-somos.png")}}),_c('h3',[_vm._v("Quem Somos")]),_c('p',[_vm._v(" Plataforma digital para oferecer saúde de qualidade a preços acessíveis. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/mensalidade.png")}}),_c('h3',[_vm._v("Sem mensalidade")]),_c('p',[_vm._v(" Não cobramos taxa de adesão, mensalidade ou anuidade. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/valores.png")}}),_c('h3',[_vm._v("Valores reduzidos")]),_c('p',[_vm._v(" Agende consultas, exames, odontologia com valores reduzidos. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/facilidade.png")}}),_c('h3',[_vm._v("Facilidade no agendamento")]),_c('p',[_vm._v(" Agende e pague seus serviços pelo site, aplicativo, totem e guichês do medclub. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/voce.png")}}),_c('h3',[_vm._v("Para você, familiares e amigos ")]),_c('p',[_vm._v(" Cadastro grátis! ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/locais.png")}}),_c('h3',[_vm._v("Locais de atendimento")]),_c('p',[_vm._v(" Tenha acesso as melhores clínicas e hospitais. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/cirurgias.png")}}),_c('h3',[_vm._v("Cirurgia")]),_c('p',[_vm._v(" Ofertamos cirurgias com valores diferenciados, acompanhamos você durante todo o processo. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/vantagens.png")}}),_c('h3',[_vm._v("Clube de Vantagens")]),_c('p',[_vm._v(" Descontos exclusivos em locais parceiros. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/atendimento.png")}}),_c('h3',[_vm._v("Atendimento médico 24 horas")]),_c('p',[_vm._v(" Com o medclub você conta com prontos socorros 24 horas caso apareça alguma emergência. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/quem-somos.png")}}),_c('h3',[_vm._v("Quem Somos")]),_c('p',[_vm._v(" Plataforma digital para oferecer saúde de qualidade a preços acessíveis. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/mensalidade.png")}}),_c('h3',[_vm._v("Sem mensalidade")]),_c('p',[_vm._v(" Não cobramos taxa de adesão, mensalidade ou anuidade. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/valores.png")}}),_c('h3',[_vm._v("Valores reduzidos")]),_c('p',[_vm._v(" Agende consultas, exames, odontologia com valores reduzidos. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/facilidade.png")}}),_c('h3',[_vm._v("Facilidade no agendamento")]),_c('p',[_vm._v(" Agende e pague seus serviços pelo site, aplicativo, totem e guichês do medclub. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/voce.png")}}),_c('h3',[_vm._v("Para você, familiares e amigos ")]),_c('p',[_vm._v(" Cadastro grátis! ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/locais.png")}}),_c('h3',[_vm._v("Locais de atendimento")]),_c('p',[_vm._v(" Tenha acesso as melhores clínicas e hospitais. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/cirurgias.png")}}),_c('h3',[_vm._v("Cirurgia")]),_c('p',[_vm._v(" Ofertamos cirurgias com valores diferenciados, acompanhamos você durante todo o processo. ")])])]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/vantagens.png")}}),_c('h3',[_vm._v("Clube de Vantagens")]),_c('p',[_vm._v(" Descontos exclusivos em locais parceiros. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-32"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/atendimento.png")}}),_c('h3',[_vm._v("Atendimento médico 24 horas")]),_c('p',[_vm._v(" Com o medclub você conta com prontos socorros 24 horas caso apareça alguma emergência. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item active"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/quem-somos.png")}}),_c('h3',[_vm._v("Quem Somos")]),_c('p',[_vm._v(" Plataforma digital para oferecer saúde de qualidade a preços acessíveis. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/mensalidade.png")}}),_c('h3',[_vm._v("Sem mensalidade")]),_c('p',[_vm._v(" Não cobramos taxa de adesão, mensalidade ou anuidade. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/valores.png")}}),_c('h3',[_vm._v("Valores reduzidos")]),_c('p',[_vm._v(" Agende consultas, exames, odontologia com valores reduzidos. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/facilidade.png")}}),_c('h3',[_vm._v("Facilidade no agendamento")]),_c('p',[_vm._v(" Agende e pague seus serviços pelo site, aplicativo, totem e guichês do medclub. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/voce.png")}}),_c('h3',[_vm._v("Para você, familiares e amigos ")]),_c('p',[_vm._v(" Cadastro grátis! ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/locais.png")}}),_c('h3',[_vm._v("Locais de atendimento")]),_c('p',[_vm._v(" Tenha acesso as melhores clínicas e hospitais. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/cirurgias.png")}}),_c('h3',[_vm._v("Cirurgia")]),_c('p',[_vm._v(" Ofertamos cirurgias com valores diferenciados, acompanhamos você durante todo o processo. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/vantagens.png")}}),_c('h3',[_vm._v("Clube de Vantagens")]),_c('p',[_vm._v(" Descontos exclusivos em locais parceiros. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"carousel-item"},[_c('div',{staticClass:"row m-2"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card_como_funciona"},[_c('img',{staticClass:"img-card",attrs:{"src":require("@/assets/img/como-funciona/atendimento.png")}}),_c('h3',[_vm._v("Atendimento médico 24 horas")]),_c('p',[_vm._v(" Com o medclub você conta com prontos socorros 24 horas caso apareça alguma emergência. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"carousel-control-prev",attrs:{"href":"#carouselExampleControls","role":"button","data-slide":"prev"}},[_c('span',{staticClass:"carousel-control-prev-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Anterior")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"carousel-control-next",attrs:{"href":"#carouselExampleControls","role":"button","data-slide":"next"}},[_c('span',{staticClass:"carousel-control-next-icon",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"sr-only"},[_vm._v("Próximo")])])
}]

export { render, staticRenderFns }