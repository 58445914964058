<template>
<div class="modulo_menu" id="menu_site">
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light" id="navbar_site">
    <div class="container flex">
      <div class="flex justify-between">
        <router-link :to="{ name: 'home' }" class="mt-4 mb-2">
          <img v-if='medempresa' width="120px" class="inline" src='./../assets/img/logo-medclub-empresa-app.png'>
          <img v-if='!medempresa' class="w-32 inline mt-4 mb-2 mr-4" src='./../assets/img/logo-nova.png'>
          <!-- <div v-if='!medempresa' class="pt-2 px-1 text-gray-700" style='font-size:.75rem'>
            <div class="whitespace-nowrap">Melhor da medicina. Melhor preço.</div>
          </div> -->
        </router-link>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSistema" aria-controls="navbarSistema" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div class="collapse navbar-collapse justify-content-end" id="navbarSistema">
        <ul class="navbar-nav">
          <div class="border-t my-2 minha-conta-mobile" v-if="usuario" ></div>

          <li class="nav-item mx-auto my-0 minha-conta-mobile" v-if="usuario">
            <span>Olá, <b>{{ usuario.nm_apelido ? usuario.nm_apelido : usuario.first_name }}</b>!</span>
          </li>
          <li class="nav-item mx-auto my-0 minha-conta-mobile" v-if="usuario">
              Crédito Medclub: R$ 0,00
          </li>

          <div class="border-t my-2 minha-conta-mobile" v-if="usuario"></div>

          <li class="nav-item">
            <router-link to="/inicio">
              <a class="nav-link"><span class="jam jam-home"></span> Inicio</a>
            </router-link>
          </li>

          <li class="nav-item" v-if="!usuario">
            <router-link to="/cadastrar">
              <a class="nav-link">Cadastre-se</a>
            </router-link>
          </li>
          <li class="nav-item" v-else>
            <router-link to="/meus-agendamentos">
              <a class="nav-link"><span class="jam jam-clock"></span> Minha Agenda</a>
            </router-link>
          </li>

          <li class="nav-item" @click="$emit('openLocalidade')" aria-role="listitem">
            <div class="flex localidade-container">
              <b-icon pack="jam" icon="jam-map-marker" class="icon-localidade" />
              <a class="nav-link localidade-text">{{ cidade }}</a>
            </div>
          </li>
          
          <li class="nav-item" v-if="usuario">
            <router-link :to="{ name: 'carrinho' }" class="flex  p-2">
              <b-icon class="text-2xl text-gray-700" pack='jam' icon='jam-shopping-cart'></b-icon>
              <div class="w-4 h-4 overflow-hidden rounded-full flex justify-center items-center self-center	" :class="{ 'bg-red-600': carrinho.length > 0, 'bg-gray-600': carrinho.length == 0 }">
                <p class="text-xs text-white">{{ carrinho.length }}</p>
              </div>
            </router-link>
          </li>

          <li class="nav-item" v-else @click="$emit('openLogin')">
            <a class="flex p-2">
              <b-icon class="text-2xl text-gray-700" pack='jam' icon='jam-shopping-cart'></b-icon>
              <div class="w-4 h-4 overflow-hidden rounded-full flex justify-center items-center self-center	bg-gray-600">
                <p class="text-xs text-white">0</p>
              </div>
            </a>
          </li>

          <li class="nav-item menu_active_custom my-0 w-100 minha-conta-desktop" v-if="!usuario">
            <a class="nav-link mx-auto" @click="$emit('openLogin')"><span class="jam jam-padlock-f"></span> Entrar</a>
          </li>
            <b-button type="is-primary" v-if="!usuario" class="minha-conta-mobile">
                <a class="nav-item menu_active_custom flex items-center nav-link text-white" @click="$emit('openLogin')"><span class="jam jam-padlock-f"></span> Entrar</a>
            </b-button>

          <!-- Botões que aparecem no menu mobile--> 

          <!-- <div class="border-t my-2 minha-conta-mobile" v-if="usuario" ></div>

          <li class="nav-item mx-auto my-0 minha-conta-mobile" v-if="usuario">
             <span>Olá, <b>{{ usuario.nm_apelido ? usuario.nm_apelido : usuario.first_name }}</b>!</span>
          </li>
          <li class="nav-item mx-auto my-0 minha-conta-mobile" v-if="usuario">
              Crédito Medclub: R$ 0,00
          </li>

          <div class="border-t my-2 minha-conta-mobile" v-if="usuario"></div> -->

          <li class="nav-item minha-conta-mobile" v-if="usuario">
            <router-link :to="{ name: 'editarPerfil' }" class="nav-link" data-toggle="collapse" data-target="#navbarSistema">
                <b-icon pack='jam' icon='jam-write'></b-icon>
                <a>Editar Perfil</a>
            </router-link>
          </li>

          <li class="nav-item minha-conta-mobile" v-if="usuario" data-toggle="collapse" data-target="#navbarSistema">
            <router-link :to="{ name: 'meusCartoes' }" class="nav-link">
                <b-icon pack='jam' icon='jam-credit-card-f'></b-icon>
                <span>Cartões de crédito</span>
            </router-link>
          </li>

          <li class="nav-item minha-conta-mobile" v-if="usuario" data-toggle="collapse" data-target="#navbarSistema">
            <router-link :to="{ name: 'carteiraMedclub' }" class="nav-link">
                <b-icon pack='jam' icon='jam-id-card'></b-icon>
                <span>Carteira Medclub</span>
            </router-link>
          </li>
         
          <!-- <li class="nav-item minha-conta-mobile" v-if="usuario" data-toggle="collapse" data-target="#navbarSistema">
            <router-link :to="{ name: 'areaAmigoMedclub' }" class="nav-link">
                <v-icon class="mdi mdi-handshake mdi-20px ml-1" style="margin-right: 0.4rem !important"></v-icon>
                <span>Amigo Medclub</span>
            </router-link>
          </li> -->

          <li class="nav-item minha-conta-mobile" v-if="usuario" data-toggle="collapse" data-target="#navbarSistema">
            <router-link :to="{ name: 'meusCreditos' }" class="nav-link">
                <b-icon pack='jam' icon='jam-coin'></b-icon>
                <span>Meus créditos</span>
            </router-link>
          </li>

          <li class="nav-item minha-conta-mobile" v-if="usuario" data-toggle="collapse" data-target="#navbarSistema">
            <router-link :to="{ name: 'outrasPessoas' }" class="nav-link">
                <b-icon pack='jam' icon='jam-users'></b-icon>
                <span>Dependentes</span>
            </router-link>
          </li>

          <div class="border-t my-2 minha-conta-mobile" ></div>

          <li class="nav-item mx-auto my-0 minha-conta-mobile" @click="$emit('encerrar')" v-if="usuario" data-toggle="collapse" data-target="#navbarHome">
            <a class="flex items-center nav-link">
              <v-icon style="font-size: 18px; margin-right: 4px;">mdi-exit-to-app</v-icon>
              <span class="logout-text">Sair</span>
            </a>
          </li>

          <!-- Dropdown que aparece no desktop -->
          <!-- <b-dropdown
            position="is-bottom-left"
            append-to-body
            aria-role="menu"
            class="minha-conta-mobile"f
            Ff
            v-if="usuario">ff 
            <li sclass="nav-item menu_active_custom flex  items-center" slot="trigger" >
                <a class="nav-link">Minha conta</a>
            </li>

            <b-dropdown-item custom aria-role="menuitem" v-if="usuario">
                Olá, <b>{{ usuario.nm_apelido ? usuario.nm_apelido : usuario.first_name }}</b>!
            </b-dropdown-item>
            <b-dropdown-item aria-role="menuitem" v-if="usuario">
                Créditos Medblub: R$ 0,00
            </b-dropdown-item>
            <hr class="dropdown-divider">
            <router-link :to="{ name: 'editarPerfil' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-write'></b-icon>
                <span>Editar Perfil</span>
              </b-dropdown-item>
            </router-link>

            <router-link :to="{ name: 'meusCartoes' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-credit-card-f'></b-icon>
                <span>Cartões de crédito</span>
              </b-dropdown-item>
            </router-link>

            <router-link :to="{ name: 'carteiraMedclub' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-id-card'></b-icon>
                <span>Carteira Medclub</span>
              </b-dropdown-item>
            </router-link> -->
            <!-- <router-link :to="{ name: 'areaAmigoMedclub' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <v-icon class="mdi mdi-handshake mdi-20px ml-1" style="margin-right: 0.4rem !important"></v-icon>
                <span>Amigo Medclub</span>
              </b-dropdown-item>
            </router-link> -->

            <!-- <router-link :to="{ name: 'meusCreditos' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-coin'></b-icon>
                <span>Meus créditos</span>
              </b-dropdown-item>
            </router-link>

            <router-link :to="{ name: 'outrasPessoas' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-users'></b-icon>
                <span>Dependentes</span>
              </b-dropdown-item>
            </router-link>

            <div class="border-t my-2"></div>

            <b-dropdown-item class="text-gray-600" aria-role="listitem" @click="$emit('encerrar')">
              <b-icon pack='jam' icon='jam-log-out'>
              </b-icon>
              <span>Encerrar</span>
            </b-dropdown-item>
          </b-dropdown> -->
        </ul>
      </div>
    </div>
  </nav>
</div>
</template>
<style lang="scss" scoped>
@media (min-width: 1001px) {
  .minha-conta-mobile {
    display: none;
  }
  .bg-medempresa {
    background-color: #ffba01 !important;
  }
  .navbar-institucional {
    position: fixed;
    z-index: 1;
    width: 100%
  }

  .fundo-nav {
    width: 100%;
    height: 5rem;
  }
}

.localidade-container {
  align-items: center;
  display: flex;
}

.icon-localidade {
  font-size: 1.2rem;
  padding-left: 1rem;
}

.localidade-text {
  padding-left: 0;
  text-decoration: none;
  margin: 0;
}

@media (max-width: 768px) {
  .localidade-container {
    flex-direction: row;
    align-items: center;
  }
  
  .icon-localidade {
    padding-left: 0.5rem;
  }

  .localidade-text {
    font-size: 14px;
  }

  .nav-item {
    margin: 0.5rem 0;
  }
}

@media (max-width: 1000px) {
  .minha-conta-desktop {
    display: none;
  }
}

*:not(.decorated-text) {
  text-decoration: none !important;
}

.nav-item a{
  white-space: nowrap;
  font-size: 13px;
  font-weight: bold;
}

.nav-item a{
  white-space: nowrap;
  font-size: 13px;
  font-weight: bold;
}
</style>
<script>
export default {
  props: ['cidade', 'usuario', 'carrinho'],
  created () {
    this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
  },
  data() {
    return {
      medempresa: false,
      dialog: { login: false },
    }
  }
}
</script>