<template lang="pug">
.cadastrar
    .flex.justify-center.bg-medclub-blue-100.pt-10.pb-8.hidden(class='md:flex' v-if='!isModal')
        .max-w-screen-lg
            h1.text-2xl.text-gray-700.text-center(class='md:text-4xl') Criar conta

    .flex.justify-center.bg-medclub-blue-100
        .w-full(:class="{ 'max-w-screen-lg': !isModal }")
            .m-4(v-if='!isModal')
                router-link.underline(:to="{ name: 'home' }") Início
                i.jam.jam-chevron-right.mx-2.text-lg
                span Criar conta
            .flex.bg-white.p-4.shadow-md.justify-center.w-full(class='md:rounded' :class="{ 'md:mb-16': !isModal }")
                form.mb-8.pt-5.w-full(class='md:w-2/3 md:pt-0' @submit.prevent='handleSubmit()')

                    b-steps(size='is-large' class='md:my-8' :has-navigation='false' :mobile-mode='null')

                        b-step-item.cadastro-form.m-auto(class='w-full md:w-9/12' label='Dados pessoais' icon='account' clickable)
                            h1.my-8 Preencha os campos abaixo para se cadastrar.

                            //- O campo de código aparece apenas quando a pessoa foi redirecionada da tela de convite
                            b-field(:label='dsCode ? "Código do convite:" : "Código do convite:"' :class='{"field-dsCode": ! $route.params.ds_code}')
                                div(v-if='$route.params.ds_code')
                                    b-input(:value='dsCode' disabled rounded)
                                div(v-else)
                                    b-input(v-model='dsCode' rounded)

                            b-field(label='CPF:' :message="model_message().nr_cpf"
                                :type="{ 'is-danger': model.submitted && $v.model.nr_cpf.$error }")
                                b-input(v-model='$v.model.nr_cpf.$model' type='tel' v-mask="'###.###.###-##'" rounded)

                            b-field.field-nomeCompleto(label='Nome completo:' :message="model_message().nm_pessoa_fisica"
                                :type="{ 'is-danger': model.submitted && $v.model.nm_pessoa_fisica.$error }")
                                b-input(v-model='$v.model.nm_pessoa_fisica.$model' rounded :readonly="should_validate_cpf")

                            b-field(label='Como quer ser chamado?')
                                b-input(v-model='model.nm_apelido' rounded)

                            b-field(label='Telefone:' :message="model_message().nr_telefone"
                                :type="{ 'is-danger': model.submitted && $v.model.nr_telefone.$error }")
                                masked-input.input.is-rounded(v-model='$v.model.nr_telefone.$model' type='tel' :mask="phoneMask"
                                    :class="{ 'is-danger': model.submitted && $v.model.nr_telefone.$error }" :guide='false')

                            b-field(label='Data de nascimento:' :message="model_message().dt_nascimento"
                                :type="{ 'is-danger': model.submitted && $v.model.dt_nascimento.$error }")
                                b-input(v-model='$v.model.dt_nascimento.$model' type='tel' v-mask="'##/##/####'" placeholder='dd/mm/aaaa' rounded)

                            b-field(label='Sexo:')
                                b-radio(class='w-1/2'
                                    v-for='options in options.ie_sexo'
                                    :key='options.value'
                                    v-model='$v.model.ie_sexo.$model'
                                    :native-value='options.value'
                                    name='sexo') {{ options.label }}
                            .text-xs.text-red-500.w-full.flex.items-center.justify-end(v-if='model.submitted && $v.model.ie_sexo.$error')
                                i.mdi.mdi-alert-circle.mdi-24px.mr-1
                                span {{ model_message().ie_sexo }}

                        b-step-item.m-auto(class='w-full md:w-9/12' label='Endereço' icon='map-marker' clickable)
                            h1.my-8 Preencha os campos abaixo para se cadastrar.

                            b-field(label='CEP:' :message="model_message().nr_cep"
                                :type="{ 'is-danger': model.submitted && $v.model.nr_cep.$error }")
                                b-input(v-model='$v.model.nr_cep.$model' v-mask="'#####-###'" :loading='waiting.buscaCEP'
                                    placeholder='99999-999' rounded @blur='buscaCEP')

                            b-field(label='Estado:')
                                b-select(placeholder='Selecione um estado' v-model='model.cd_estado'
                                    rounded expanded :disabled='waiting.buscaCEP || options.estados.length === 0' @input='getMunicipiosByEstadoId')
                                    option(v-for='option in options.estados' :value='option.id' :key='option.id') {{ option.nm_estado }}

                            b-field(label='Município:' :message="model_message().cd_cidade"
                                :type="{ 'is-danger': model.submitted && $v.model.cd_cidade.$error }")
                                b-select(placeholder='Selecione um município' v-model='$v.model.cd_cidade.$model' :loading='waiting.municipios'
                                    rounded expanded :disabled='waiting.buscaCEP || options.municipios.length === 0 || waiting.municipios')
                                    option(v-for='option in options.municipios' :value='option.id' :key='option.id') {{ option.nm_cidade }}

                            b-field.field-logradouro(label='Logradouro:' :message="model_message().nm_rua"
                                :type="{ 'is-danger': model.submitted && $v.model.nm_rua.$error }")
                                b-input(v-model='$v.model.nm_rua.$model' rounded :disabled='waiting.buscaCEP')

                            b-field(label='Número:' :message="model_message().nr_numero"
                                :type="{ 'is-danger': model.submitted && $v.model.nr_numero.$error }")
                                b-input(v-model='$v.model.nr_numero.$model' rounded :disabled='waiting.buscaCEP')

                            b-field(label='Bairro:' :message="model_message().nm_bairro"
                                :type="{ 'is-danger': model.submitted && $v.model.nm_bairro.$error }")
                                b-input(v-model='$v.model.nm_bairro.$model' rounded :disabled='waiting.buscaCEP')

                            b-field(label='Complemento:')
                                b-input(v-model='model.ds_complemento' rounded)

                        b-step-item.m-auto(class='w-full md:w-9/12' label='Criar senha' icon='key-variant' clickable)
                            h1.my-8 Preencha os campos abaixo para se cadastrar.

                            b-field(label='E-mail:' :message="model_message().ds_email"
                                :type="{ 'is-danger': model.submitted && $v.model.ds_email.$error }")
                                b-input(v-model='$v.model.ds_email.$model' rounded)

                            .relative

                                b-field(label='Senha:' :message="model_message().ds_senha"
                                    :type="{ 'is-danger': model.submitted && $v.model.ds_senha.$error }")
                                    b-input(v-model='$v.model.ds_senha.$model' type='password' rounded password-reveal)

                                .flex.absolute.w-full.top-0.right-0.pt-1
                                    p.text-right.text-xs.mr-2.font-semibold(class='w-3/4') Força da senha:
                                    password(class='w-1/4' v-model='$v.model.ds_senha.$model' :strength-meter-only='true')

                            b-field(label='Confirmar senha:' :message="model_message().ds_senha_rep"
                                :type="{ 'is-danger': model.submitted && $v.model.ds_senha_rep.$error }")
                                b-input(v-model='$v.model.ds_senha_rep.$model' type='password' rounded password-reveal)

                            b-field.mt-8
                                b-checkbox(v-model='euConcordo')
                                    p.text-sm
                                        span Eu li e aceito os&nbsp;
                                        a.underline(style='color:blue' href='/termos-e-condicoes' target='_blank') termos de uso
                                        span .

                        template(slot='navigation' slot-scope='{ previous, next }')    
                            .text-center
                                b-button.mt-8.mx-4(class='w-1/4 md:hidden' v-if='!previous.disabled' type='is-secondary' rounded @click.prevent='previous.action')
                                    i.text-2xl.jam.jam-chevron-left
                                b-button.mt-8(class='w-1/2 md:w-9/12' :disabled='!euConcordo' native-type='submit' v-if='next.disabled' type='is-primary' rounded :loading='waiting.criarUsuario') Finalizar
                                b-button.mt-8(class='w-1/2 md:w-9/12' v-else type='is-primary' rounded @click.prevent='next_step(next)') Próximo
        
        </template>

<style lang="scss">
.cadastro-form {
    .field label .__note {
        font-size: .8em;
        font-weight: 300;
        color: #999;
    }
    .help {
        color: red !important;
        font-size: 1rem !important;
    }
}
</style>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'
import onlyNumbers, { validateCpf, validateFone, phoneMask, hasNumber } from './../../utils'
import moment from 'moment'; moment.locale('pt-br')
import Password from 'vue-password-strength-meter'
import { Usuario, Utils } from '../../middleware'

const addNoteToField = (className, text) => {
    const aux = document.createElement('span')
    aux.classList.add('__note')
    aux.innerHTML = ` (${text})`
    const field = document.querySelector(`.field.${className} label`)
    field.appendChild(aux)
}

export default {
    components: { Password },
    watch: {
        showTermosDeUso: function (val) {
            if (val) {
                window.setTimeout(() => {
                    let el = document.getElementById('iframeTermosDeUso')
                    el.height = window.innerHeight - 40
                })
            }
        },
        'model.nr_cpf': {
            handler: async function (newVal) {
                if (newVal.length === 14 && this.should_validate_cpf) {
                    const cpf_response = await Utils.getCPFInfo({
                        cpf: onlyNumbers(newVal)
                    })
                    if (!cpf_response.done) {
                        this.$buefy.toast.open({
                            type: 'is-danger', position: 'is-bottom', duration: 4000,
                            message: cpf_response.message
                        })
                        this.model.nm_pessoa_fisica = '';
                        this.model.dt_nascimento = '';

                        return
                    }

                    const parsedDate = moment(cpf_response.data.nascimento, 'DDMMYYYY');
                    const year = parsedDate.format('YYYY');
                    const formattedDate = parsedDate.format(`DD/MM/${year}`);

                    this.serpro_cpf = {
                        dt_nascimento: formattedDate,
                        nm_pessoa_fisica: cpf_response.data.nome
                    }
                    this.model.nm_pessoa_fisica = cpf_response.data.nome
                }
            },
            deep: true
        }
    },
    created() {
        Utils.getUFs().then(response => {
            if (response.status === 200) this.options.estados = response.data
        })

        Utils.getInformation().then(response => {
            if (response.status === 200) {
                this.should_validate_cpf = response.data.should_validate_cpf

                addNoteToField('field-nomeCompleto', this.should_validate_cpf ? 'Digite seu CPF para preencher automático' : 'conforme escrito no documento de identificação')
            }
        })

        //Verifica se a solicitação de cadastro veio de indicação do amigo medclub
        this.dsCode = this.$route.params.ds_code ? this.$route.params.ds_code : ''
    },
    mounted() {
        if (!this.$route.params.ds_code) addNoteToField('field-dsCode', 'caso tenha sido indicado por um amigo')
        addNoteToField('field-logradouro', 'Rua, Quadra, Avenida, etc.')
    },
    props: ['isModal'],
    data() {

        const model = {
            nm_pessoa_fisica: null,
            nm_apelido: null,
            nr_cpf: null,
            nr_telefone: null,
            dt_nascimento: null,
            ie_sexo: null,
            nr_cep: null,
            cd_estado: null,
            cd_cidade: null,
            nm_rua: null,
            nr_numero: null,
            nm_bairro: null,
            ds_complemento: null,
            ds_email: null,
            ds_senha: null,
            ds_senha_rep: null,
            // ds_device_id: fcm_token,
            // ds_plataforma: Platform.OS,
            submitted: false,
            reset() {
                Object.keys(this).forEach(key => {
                    if (!['reset'].includes(key)) this[key] = null
                    this.submitted = false
                })
            }
        }

        return {
            model,
            waiting: { criarUsuario: false, buscaCEP: false, municipios: false },
            serpro_cpf: null,
            should_validate_cpf: false,
            euConcordo: false,
            showTermosDeUso: false,
            phoneMask,
            options: {
                ie_sexo: [
                    { value: 'M', label: 'Masculino' },
                    { value: 'F', label: 'Feminino' }
                ],
                estados: [],
                municipios: []
            },
            dsCode: ''
        }
    },
    validations() {
        let v = {
            model: {
                nm_pessoa_fisica: {
                    required,
                    minLength: minLength(8),
                    fullName: val => val ? val.includes(' ') : false,
                    hasNumber: val => !hasNumber(val)
                },
                nr_cpf: { required, validateCpf },
                nr_telefone: { required, validateFone },
                dt_nascimento: {
                    required, minLength: minLength(10),
                    validated_serpro: val => {
                        if (!this.should_validate_cpf) return true
                        return this.serpro_cpf ? val === this.serpro_cpf.dt_nascimento : false
                    },
                    dateValid: val => moment(val, 'DD/MM/YYYY').isValid()
                },
                ie_sexo: { required },
                nr_cep: { required, minLength: minLength(9) },
                cd_cidade: { required },
                nm_rua: { required, minLength: minLength(4) },
                nr_numero: { required },
                nm_bairro: { required, minLength: minLength(4) },
                ds_email: { required },
                ds_senha: { required, minLength: minLength(6) },
                ds_senha_rep: { sameAs: sameAs('ds_senha') },
            }
        }
        return v
    },
    methods: {
        next_step(next) {
            if (this.should_validate_cpf && !this.serpro_cpf || this.should_validate_cpf && this.serpro_cpf && this.model.dt_nascimento !== this.serpro_cpf.dt_nascimento) {
                return
            }
            next.action()
        },
        model_message() {

            let result = {
                nm_pessoa_fisica: '', nr_cpf: '', nr_telefone: '', dt_nascimento: '', ie_sexo: '', nr_cep: '',
                cd_cidade: '', nm_rua: '', nr_numero: '', nm_bairro: '', ds_email: '', ds_senha: ''
            }

            if (!this.model.submitted) {

                if (!this.$v.model.nm_pessoa_fisica.required) result.nm_pessoa_fisica = this.should_validate_cpf ? '' : 'Digite o nome'
                else if (!this.$v.model.nm_pessoa_fisica.minLength) result.nm_pessoa_fisica = 'Nome deve conter pelo menos 8 caracteres'
                else if (!this.$v.model.nm_pessoa_fisica.fullName) result.nm_pessoa_fisica = 'Digite o nome completo'
                else if (!this.$v.model.nm_pessoa_fisica.hasNumber) result.nm_pessoa_fisica = 'O nome não pode conter números'

                if (!this.$v.model.nr_cpf.required) result.nr_cpf = 'Digite o CPF'
                else if (!this.$v.model.nr_cpf.validateCpf) result.nr_cpf = 'Digite um CPF válido'

                if (!this.$v.model.nr_telefone.required) result.nr_telefone = 'Digite o telefone'
                else if (!this.$v.model.nr_telefone.validateFone) result.nr_telefone = 'Digite um telefone nacional válido'

                if (!this.$v.model.dt_nascimento.required) result.dt_nascimento = 'Digite a data de nascimento'
                else if (!this.$v.model.dt_nascimento.minLength) result.dt_nascimento = 'Digite uma data válida'
                else if (!this.$v.model.dt_nascimento.dateValid) result.dt_nascimento = 'Digite uma data válida'
                else if (!this.$v.model.dt_nascimento.validated_serpro) result.dt_nascimento = 'Data de nascimento não confere com CPF'

                if (!this.$v.model.ie_sexo.required) result.ie_sexo = 'Selecione o sexo'

                if (!this.$v.model.nr_cep.required) result.nr_cep = 'Digite o CEP'
                else if (!this.$v.model.nr_cep.minLength) result.nr_cep = 'Digite um CEP válido'

                if (!this.$v.model.cd_cidade.required) result.cd_cidade = 'Selecione o município'

                if (!this.$v.model.nm_rua.required) result.nm_rua = 'Digite a rua'
                else if (!this.$v.model.nm_rua.minLength) result.nm_rua = 'Logradouro deve conter pelo menos 4 caracteres'

                if (!this.$v.model.nr_numero.required) result.nr_numero = 'Digite o número'

                if (!this.$v.model.nm_bairro.required) result.nm_bairro = 'Digite o bairro'
                else if (!this.$v.model.nm_bairro.minLength) result.nm_bairro = 'Bairro deve conter pelo menos 4 caracteres'

                if (!this.$v.model.ds_email.required) result.ds_email = 'Digite o e-mail'

                if (!this.$v.model.ds_senha.required) result.ds_senha = 'Digite a senha'
                else if (!this.$v.model.ds_senha.minLength) result.ds_senha = 'Senha de ter pelo menos 6 dígitos'

                if (!this.$v.model.ds_senha_rep.sameAs) result.ds_senha_rep = 'As senhas digitadas devem ser as mesmas'

            }
            return result
        },
        buscaCEP() {
            let cep = this.model.nr_cep ? this.model.nr_cep.match(/\d+/g).join('') : ''
            if (cep.length === 8) {
                this.waiting.buscaCEP = true
                Utils.getCEP(cep).then(response => {
                    if (response.status === 200) {
                        let estado = this.options.estados.find(estado => estado.ds_sigla === response.data.uf)
                        if (find) {
                            this.model.cd_estado = estado.id
                            this.model.nm_rua = response.data.logradouro
                            this.model.nm_bairro = response.data.bairro
                            this.model.ds_complemento = response.data.complemento
                            this.getMunicipiosByEstadoId(response.data.localidade)
                        }
                        this.waiting.buscaCEP = false
                    }
                })
            }
        },
        getMunicipiosByEstadoId(refLocalidade) {
            this.waiting.municipios = true
            this.options.municipios = []
            Utils.getMunicipiosByEstadoId(this.model.cd_estado).then(response => {
                this.waiting.municipios = false
                if (response.status === 200) {
                    this.options.municipios = response.data
                    if (refLocalidade) {
                        let find = this.options.municipios.find(municipio => municipio.nm_cidade === refLocalidade)
                        if (find) this.model.cd_cidade = find.id
                    }
                }
            })
        },
        handleSubmit() {
            this.model.submitted = true
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$buefy.toast.open({
                    type: 'is-danger', position: 'is-bottom', duration: 4000,
                    message: 'Você ainda não preencheu todos os campos'
                })
                return 0
            }
            if (!this.euConcordo) {
                this.$buefy.toast.open({
                    type: 'is-danger', position: 'is-bottom', duration: 4000,
                    message: 'Você precisa concordar com os termos de uso'
                })
                return 0
            }

            this.waiting.criarUsuario = true
            let dataSend = Object.assign({}, this.model)

            let clean = ['ds_senha_rep', 'cd_estado', 'reset', 'submitted']
            clean.forEach(i => delete dataSend[i])

            dataSend.dt_nascimento = moment(dataSend.dt_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
            let onlyNumbers = ['nr_cpf', 'nr_telefone', 'nr_cep']
            onlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })

            dataSend.nr_telefone = `+55${dataSend.nr_telefone}`

            //Cadastro do usuário
            Usuario.save(dataSend).then(response => {

                //Após o cadastro é feito login
                if ([200, 201, 204].includes(response.status)) {

                    Usuario.login(dataSend.ds_email, dataSend.ds_senha).then(response => {

                        this.waiting.criarUsuario = false
                        if (response.usuario) {

                            this.$gtag.event('cadastro_realizado', { id: response.usuario.id, cpf: response.usuario.nr_cpf })
                            this.$analytics.fbq.event('cadastro_realizado', { id: response.usuario.id, cpf: response.usuario.nr_cpf })

                            this.$buefy.toast.open({
                                type: 'is-success', position: 'is-bottom',
                                message: 'Usuário <b>cadastrado</b>'
                            })

                            let $parent = this.$parent
                            while ($parent) {
                                if ($parent.hasOwnProperty('usuario')) $parent.usuario = response.usuario // eslint-disable-line
                                if ($parent.hasOwnProperty('$parent')) $parent = $parent.$parent // eslint-disable-line
                            }

                            //Antes de redirecionar para a home, é adicionado o código de convite no registro do usuário na API
                            Usuario.addCodigoIndicador(response.usuario.id, this.dsCode)


                            if (!this.isModal) this.$router.push({ name: 'home' })

                        } else {
                            if (response.status === 500)
                                this.$buefy.toast.open({
                                    type: 'is-danger', position: 'is-bottom',
                                    message: 'Sem conexão com o servidor'
                                })
                        }
                    })

                } else {
                    if (response.status === 400) {
                        if (typeof response.data === 'object') {
                            Object.values(response.data).forEach(message => {
                                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', duration: 4000, message: message })
                            })
                        }
                    }
                    this.waiting.criarUsuario = false
                }
            })
        }
    }
}
</script>