<template lang="pug">
    //- O componente Exames é utilizado tanto para mostrar exames quanto procedimentos e chekups

    .w-full
        .absolute.top-0.left-0.p-2
            a.text-3xl(
                v-if="$parent.agendamento.is_odonto === true",
                @click="$parent.agendamento.tipoConsulta = null"
            )
                i.jam.jam-chevron-left
            a.text-3xl(
                v-else-if="mostrarSelecionados",
                @click="mostrarSelecionados=false"
            )
                i.jam.jam-chevron-left
            a.text-3xl(
                v-else-if="!(isMultiSelect && filter.nm_procedimento) && !mostrarSelecionados",
                @click="back()"
            )
                i.jam.jam-chevron-left
        div(v-if="mostrarLoading").flex.flex-wrap.align-center.mt-20.justify-center
            <lottie-animation path="lottie/checkloading.json" :loop='false' style="width: 15rem;"></lottie-animation>
            p-1.w-full.text-center
                p.font-bold.w-full Agora é hora de revisar os examos escolhidos!
        .w-full(v-else)
            .pt-4.pb-3.text-center.w-full
                .px-8.mb-2
                    span.text-gray-600.text-xs(
                        v-if="$parent.agendamento.ie_tipo_agenda === 'C'"
                    ) {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
                h1.font-bold.text-medclub-blue-300.text-sm(
                    v-if="['PP', 'PO'].includes($parent.agendamento.ie_tipo_agenda)"
                ) Escolha o procedimento
                h1.font-bold.text-medclub-blue-300.text-sm(v-else-if='!mostrarSelecionados') Escolha {{ isMultiSelect ? 'os exames' : 'o exame' }}
                h1.font-bold.text-medclub-blue-300.text-sm(v-else) Revisar {{ isMultiSelect ? 'exames' : 'exame' }}

            .p-1.relative(
                v-if="!mostrarSelecionados && $parent.agendamento.ie_tipo_agenda !== 'CK'"
            )
                b-input(
                    :placeholder="['PP', 'PO'].includes($parent.agendamento.ie_tipo_agenda) ? 'Pesquisar por procedimento' : 'Pesquisar por exame'",
                    icon="magnify",
                    id='search-exames'
                    v-model="searchQuery",
                    @input='isTyping = true'
                )
                .absolute.top-0.right-0.p-2
                    .cursor-pointer.py-1.px-2.bg-opacity-75.rounded-full.flex.items-center.bg-medclub-blue-300.text-white.t-0.r-0(v-if='searchQuery' @click='limpar()')
                        b-icon(pack='jam' icon='jam-trash')
                        span.text-xs Limpar

            .p-3(v-if='list.length && isMultiSelect')
                .w-full.flex.items-center
                    b-switch(v-model='mostrarSelecionados')
                        p.text-xs Mostrar apenas os itens selecionados


            .py-56(v-if="waiting.exames")
                b-notification.p-8.bg-white(:closable="false")
                    b-loading(:is-full-page="false", v-model="waiting.exames")

            .py-56(v-if="loadingResult")
                b-notification.p-8.bg-white(:closable="false")
                    b-loading(:is-full-page="false", v-model="loadingResult")

            div(v-else)
                .flex.flex-wrap.pb-4
                    .p-1.w-full.text-center(v-if="!list.length && !loadingResult && !waiting.exames")
                        p.text-sm.my-6 Nenhum {{ $parent.agendamento.ie_tipo_agenda === 'PP' ? 'procedimento' : $parent.agendamento.ie_tipo_agenda === 'CK' ? 'checkup' : 'exame' }} {{ mostrarSelecionados ? 'selecionado' : 'encontrado' }}.

                    .p-1.w-full(v-else-if="mostrarSelecionados")

                        div(v-if='selected.length')
                                .w-full.shadow-md.border-b(v-for='exame in selected' :key='exame.id')
                                    .flex.items-center.bg-white.border.border-b-0.cursor-pointer(@click='select(exame.id, $event)')
                                        .wrapper.p-2.w-full.flex.items-center
                                            b-checkbox(v-model='selected' :id="`selected${ exame.id }`" :native-value='exame')
                                                span.text-sm.text-gray-700 {{ exame.label }}

                        .p-2(v-else)
                            p.text-sm.text-center.text-gray-600 Você ainda não selecionou nenhum item.

                    .p-1.w-full(v-else)
                        .w-full.shadow-md.border-b.my-1(
                            v-for="exame in list",
                            :key="exame.id"
                        )
                            .flex.items-center.bg-white.border.border-b-0.cursor-pointer(
                                class="hover:bg-gray-100",
                                @click="select(exame.id, $event)"
                            )
                                .wrapper.p-2.w-full.flex.items-center
                                    b-checkbox.b-checkbox-exames.w-full(
                                        v-if="isMultiSelect",
                                        v-model="selected",
                                        :id="`selected${exame.id}`",
                                        :native-value="exame"
                                    )
                                        .w-full.flex.flex-wrap.justify-between.items-center
                                            .w-full
                                                p.font-bold.w-full {{ exame.label }}
                                                span.text-xs(v-if="exame.ds_sinonimia") Sinônimos: 
                                                    span.mt-1.w-full.text-xs(v-if="exame.ds_sinonimia") {{ exame.ds_sinonimia }}
                                                p.text-xs.mt-2.font-bold.w-full
                                                    span a partir de
                                                    span.text-black-700(style='font-size: 0.95em; color:#3C60BA' ) &nbsp;{{ $root.formatPrice(exame.nr_valor) }}
                                            //- .w-full.mt-2(v-if="exame.ds_sinonimia")
                                            //-     a.text-blue-700(
                                            //-         class="hover:text-blue-700 hover:underline",
                                            //-         href="javascript:;",
                                            //-         @click="toggleSinonimo(exame)"
                                            //-     )
                                            //-         span(
                                            //-             v-if="mostrarSinonimos === exame.id"
                                            //-         ) Ocultar
                                            //-         span(v-else) Exibir
                                            //-         span &nbsp;sinônimos
                                            //-     p.mt-2.w-full.text-xs(
                                            //-         v-if="mostrarSinonimos.includes(exame.id)"
                                            //-     ) {{ exame.ds_sinonimia }}

                                    .w-full.flex.flex-wrap.justify-between.items-center(
                                        v-else
                                    )
                                        .w-full
                                            p.font-bold.w-full {{exame.label}}
                                            span.text-xs(style="font-weight: 500"
                                            v-if="exame.ds_sinonimia") Sinônimos: 
                                                span.mt-1.w-full.text-xs(v-if="exame.ds_sinonimia") {{ exame.ds_sinonimia }}
                                            p.text-xs.mt-1.font-bold.w-full
                                                span a partir de
                                                span.text-blue-700(style='font-size: 0.95em; color:#3C60BA') &nbsp;{{ $root.formatPrice(exame.nr_valor) }}
                                        //- .w-full.mt-1(v-if="exame.ds_sinonimia")
                                        //-     a.text-sm.text-black-700(
                                        //-         class="hover:text-blue-700 hover:underline",
                                        //-         href="javascript:;",
                                        //-         @click="$event.stopPropagation(); toggleSinonimo(exame)"
                                        //-     )
                                        //-         span(
                                        //-             v-if="mostrarSinonimos === exame.id"
                                        //-         ) Ocultar
                                        //-         span(v-else) Exibir
                                        //-         span &nbsp;sinônimos
                                        //-     p.mt-1.w-full.text-xs(
                                        //-         v-if="mostrarSinonimos.includes(exame.id)"
                                        //-     ) {{ exame.ds_sinonimia }}

                    .py-2.w-full.flex.justify-center(v-if="waiting.nextPage")
                        b-notification.p-8.bg-white(:closable="false")
                            b-loading(
                                :is-full-page="false",
                                v-model="waiting.nextPage"
                            )

                    .w-full.bottom-0.left-0.right-0.bg-white.p-4.text-center.sticky(v-if='selected.length' style='z-index:1')
                        b-button.w-full.rounded.p-2.h-auto.relative(style='z-index:99' type='is-primary' class='md:max-w-xs' @click='concluir()')
                            .flex.items-center
                                .text-sm CONTINUAR
                                .ml-2.w-8.h-8.leading-8.rounded-full.bg-red-700.text-xs.text-center {{ selected.length }}
</template>

<style lang="scss">
.b-checkbox-exames .control-label {
    width: 100%;
}
</style>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import { Agendamento } from "../../../middleware";
const _ = require('lodash');
export default {
    components: { LottieAnimation },
    created() {
        this.$parent.$parent.$parent.hideFooter = false;
        if (this.$parent.agendamento._selectedExames) {
            this.selected = this.$parent.agendamento._selectedExames;
            delete this.$parent.agendamento._selectedExames;
        }

        const searchParam = new URLSearchParams(window.location.search).get('search')
        this.getProcedimentos(undefined, searchParam);
    },
    watch: {
        selected: function (val) {
            if (val.length) this.$parent.$parent.$parent.hideFooter = true;
            else this.$parent.$parent.$parent.hideFooter = false;
        },
        searchQuery: _.debounce(function (val) {
            this.isTyping = false;
            const setSearchParam = (newValue) => {
                this.$router.replace({
                    query: { search: newValue }
                })
            }
            val ? setSearchParam(val) : setSearchParam('')
        }, 500),
        isTyping: function (value) {
            if (!value) {
                this.loadingResult = true;
                this.marcadorLista = 30;
                this.page = 1;
                this.lockPage = false;
                this.filter.nm_procedimento = this.searchQuery;
                this.getProcedimentos();
            }
        }
    },
    mounted() {
        window.onscroll = () => {
            if (
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight - 200
            ) {
                if (
                    !this.waiting.nextPage && !this.lockPage
                    // this.$parent.agendamento.ie_tipo_agenda === "EL" &&
                    // !this.filter.nm_procedimento
                ) {
                    if (this.list.length == this.marcadorLista) {
                        this.page++;
                        this.marcadorLista += 30;
                        this.getProcedimentos(this.page);
                    }

                }
            }
        };
    },
    data() {
        return {
            list: [],
            selected: [],
            searchQuery: "",
            lockPage: false,
            isTyping: false,
            loadingResult: false,
            mostrarSelecionados: false,
            waiting: { exames: true, nextPage: false },
            filter: { nm_procedimento: null },
            page: 1,
            mostrarLoading: false,
            mostrarSinonimos: [],
            marcadorLista: 30,
        };
    },
    computed: {
        //Caso o componente permita selecionar mais de um procedimento ao mesmo tempo
        isMultiSelect() {
            return ["EL"].includes(
                this.$parent.agendamento.ie_tipo_agenda
            );
        },
    },
    methods: {
        back() {
            if (this.$route.path.includes('procedimentos'))
                this.$router.push({ name: "home" })
            else this.$router.push({ name: "agendarExames" })
        },
        loading() {
            setTimeout(() => {
                this.mostrarLoading = false
                this.mostrarSelecionados = true
            }, 2000);
        },
        remove(id) {
            for (var i in this.selected) {
                if (this.selected[i].id == id) {
                    this.selected.splice(i, 1)
                    break
                }
            }
        },
        getProcedimentos(page, search) {
            const searchParam = this.filter.nm_procedimento ? this.filter.nm_procedimento : search;
            this.searchQuery = searchParam

            if (this.mostrarSelecionados) return 0;
            if (!searchParam && this.lockPage) return 0;
            if (searchParam && this.lockPage) return 0;
            if (this.page === 1) {
                // this.waiting.exames = true;
            } else {
                this.waiting.nextPage = true;
            }

            let params = {
                ie_tipo_procedimento: this.$parent.agendamento.ie_tipo_agenda,
                page: this.page,
                cd_cidade: this.$store.state.cdCidade,
            };
            if (JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] = JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))



            if (searchParam) {
                params.nm_procedimento = searchParam;
                params.page = page ? page : 1;
                this.page = page ? page : 1;
                this.lockPage = false;
            }

            Agendamento.getProcedimentos(params).then((response) => {
                this.waiting.exames = false;
                this.loadingResult = false;
                this.waiting.nextPage = false;

                const appendSubgrupos = (data) => {
                    return data.map(exame => ({
                        ...exame,
                        label: `${exame.ds_procedimento ? exame.ds_procedimento : exame.nm_procedimento} ${exame.nm_subgrupo ? `(${exame.nm_subgrupo})` : ''}`.toUpperCase(),
                        id: exame.nm_subgrupo ?  `${exame.id}.${exame.cd_subgrupos}` : exame.id.toString(),
                    }))
                }
                if (response.status === 200) {
                    if (
                        response.data.length
                    ) {
                        if (this.page === 1) {
                            this.list = appendSubgrupos(response.data)
                            if (this.$parent.agendamento?.ie_tipo_agenda === 'EC') {
                                if (this.$parent.$route.params.procedimento) {
                                    let exame = this.list.filter((exame) => exame.id === parseInt(this.$parent.$route.params.procedimento));
                                    this.$parent.agendamento.exames = exame;
                                    this.$parent.link_url = {
                                        procedimento: exame[0].id,
                                        slug: exame[0].ds_procedimento.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, "").replace(/(\s\s)|(\s)/g, "-"),
                                    };
                                }
                            }
                        } else {
                            this.list = this.list.concat(
                                appendSubgrupos(response.data)
                            );
                        }

                        this.selected = _.filter(this.list, e => _.some(this.selected, {id: e.id}));

                    } else {
                        this.list = [];
                        this.waiting.nextPage = false;
                        this.lockPage = true;
                    }
                }
            });
        },
        alert(exames, property, title) {
            const helpButtonMobile = document.getElementById('helpButtonMobile')
            var listaExames = ''
            if (helpButtonMobile) helpButtonMobile.classList.add('hidden')
            for (const exame in this.selected) {
                var index = parseInt(exame) + 1
                listaExames += String(index) + ' - ' + this.selected[exame][property]
                listaExames += '\n'
            }
            this.$buefy.dialog.confirm({
                title,
                size: 'is-small',
                scroll: 'keep',
                message: `<div class='whitespace-pre-wrap text-sm'>${listaExames}</div>`,
                confirmText: 'Sim',
                cancelText: 'Não',
                onConfirm: () => {
                    const helpButtonMobile = document.getElementById('helpButtonMobile')
                    if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')
                    this.concluir()
                }
            })
        },
        select(id, ev) {
            if (this.isMultiSelect) {
                if (ev.target.classList.contains("wrapper")) {
                    let el = document.getElementById(`selected${id}`);
                    el.click();
                }
            } else {
                let exame = this.list.find((exame) => exame.id === id);
                if (this.$parent.agendamento.ie_tipo_agenda === "PP")
                    this.$gtag.event("procedimento_selecionado", {
                        procedimento: exame,
                    });
                else this.$gtag.event("outros_exames_selecionado", { exame });
                this.$parent.agendamento.exames = [exame];
                if (this.$parent.agendamento?.ie_tipo_agenda === 'EC') {
                    this.$parent.link_url = {
                        procedimento: exame.id,
                        slug: exame.ds_procedimento.toLowerCase().replace(/[^a-zA-Z0-9\s]/g, "").replace(/(\s\s)|(\s)/g, "-"),
                    }
                }
            }
        },
        concluir() {
            this.$parent.$parent.$parent.hideFooter = false;
            this.$gtag.event("exames_laboratoriais_selecionados", {
                exames: this.selected,
            });
            
            this.$router.push({ params: { slug: this.selected.map(exam => exam.id).join(',') } });

            this.$parent.agendamento._selectedExames = this.selected;
            this.$parent.agendamento.exames = this.selected;
        },
        toggleSinonimo(exame) {
            if (this.mostrarSinonimos.includes(exame.id))
                this.mostrarSinonimos = this.mostrarSinonimos.filter(
                    (id) => id !== exame.id
                );
            else this.mostrarSinonimos.push(exame.id);
        },
        limpar() {
            this.searchQuery = null
            this.filter.nm_procedimento = null
            var searchField = document.getElementById("search-exames");
            searchField.focus()
            this.getProcedimentos()

        }
    },
};
</script>