import VuexPersist from 'vuex-persist'
const vuexLocalStorage = new VuexPersist({
    key: 'vuex', storage: window.localStorage
})

export default {
    state: {
        consultaPorVideo: null,
		cdCidade: null,
        cdEstado: null,
        // Estados utilizados para que quando aparecer o modal de lembrete de 
        // avaliação, e o usuário clicar em X, o modal não apareça mais
        avaliou: false,
        buscaEspecialista: null,
    },
    mutations: {
        iniciarConsultaPorVideo (state, payload) {
            state.consultaPorVideo = payload
        },
        finalizarConsultaPorVideo (state) {
            state.consultaPorVideo = null
        },
		setCidade (state, payload) {
			state.cdCidade = payload
		},
        setEstado(state, payload) {
			state.cdEstado= payload
		},
        setAvaliou(state, payload) {
            state.avaliou = payload
        },
        setBuscaEspecialista(state, payload) {
            state.buscaEspecialista = payload
        },
    },
    getters: {
        consultaPorVideo: state => {
            return state.consultaPorVideo
        },
		cdCidade: state => {
			return state.cdCidade
		},
        avaliou: state => {
            return state.avaliou
        },
        buscaEspecialista: state => {
            return state.buscaEspecialista
        }
    },
    plugins: [ vuexLocalStorage.plugin ]
}