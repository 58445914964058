import axios from "axios";
import configs from "../configs";
import GenericDAO from "./../generic-dao";

const GET_INFORMATION_CACHE = "GET_INFORMATION_CACHE";

const getUFs = () => GenericDAO.find(`/global/estado/`);
const getMunicipiosByUF = (idUF) =>
  GenericDAO.find(`/global/cidade/`, { ds_uf: idUF });
const getMunicipiosByEstadoId = (idEstado) =>
  GenericDAO.find(`/global/cidade/`, { cd_estado: idEstado });
const getMotivoErro = () =>
  GenericDAO.find(`/global/motivo-erro/`, null, { auth: true });
const sendReportarErro = (body) =>
  GenericDAO.save(`/global/reportar-erro/`, body, { auth: true });
const getPerguntasFrequentes = () =>
  GenericDAO.find(`/appuse/perguntas-frequentes/`);
const sendFaleConosco = (body) =>
  GenericDAO.save(`/appuse/fale-conosco/`, body);
const getOrcamento = (body) =>
  GenericDAO.find(`/appuse/orcamento-procedimentos/`, body); // Utiliza filtro localidade    OK
const getOrcamentoEstabelecimentos = (body) =>
  GenericDAO.find(`/appuse/orcamento-estabelecimentos/`, body); // Utiliza filtro localidade    OK
const getParceiros = () => GenericDAO.find(`/appuse/parceiros-medclub/`);
const getRedeCredenciada = (body) =>
  GenericDAO.find(`/appuse/rede-credenciada/`, body); // Utiliza filtro localidade  OK
const getCidades = () => GenericDAO.find(`/appuse/cidade-estabelecimento`);
const getCategoriasPerguntas = () =>
  GenericDAO.find(`/appuse/perguntas-categoria`);

const getCEP = (cep) => {
  cep = cep.match(/\d+/g).join("");
  return axios
    .get(`https://viacep.com.br/ws/${cep}/json`)
    .then((response) => response)
    .catch(configs.errorHandler);
};

const getInformation = async () => {
  const inCache = sessionStorage.getItem(GET_INFORMATION_CACHE);
  if (inCache) return JSON.parse(inCache);

  return axios
    .get(configs.firebaseFunctions + "/getMenu")
    .then((response) => {
      sessionStorage.setItem(GET_INFORMATION_CACHE, JSON.stringify(response));
      return response;
    })
    .catch(configs.errorHandler);
};

function encrypt(senha, chave) {
  let outValue = '';
  for (let i = 0; i < senha.length; i++) {
    outValue += String.fromCharCode(~(senha.charCodeAt(i) - chave));
  }
  return outValue;
}

const getCPFInfo = async ({ cpf }) => {
  const response = await fetch(`${configs.baseUrl}/appuse/valida-cpf/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ nr_cpf: encrypt(cpf, 132) }),
  });
  if (response.status === 400) {
    return { done: false, message: "CPF não encontrado." };
  }
  if (response.status !== 200) {
    return { done: false, message: "Erro ao validar CPF." };
  }
  return { done: true, data: await response.json() };
};

export default {
  getUFs,
  getMunicipiosByUF,
  getMunicipiosByEstadoId,
  getCEP,
  getPerguntasFrequentes,
  sendFaleConosco,
  getOrcamento,
  getOrcamentoEstabelecimentos,
  getInformation,
  getMotivoErro,
  sendReportarErro,
  getParceiros,
  getRedeCredenciada,
  getCidades,
  getCategoriasPerguntas,
  getCPFInfo,
};
