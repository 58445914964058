<template lang="pug">
    .agendamento
        .flex.items-center.justify-center.flex-wrap
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')

                div(v-if='! agendamentoCriado')
                    .w-full
                        SelecionarExames(v-if='! agendamento.exames')
                        SelecionarEstabelecimento(v-else)

                div(v-else)
                    SelecionarPaciente
</template>

<script>
import SelecionarExames from "./SelecionarExames";
import SelecionarEstabelecimento from "./EstabelecimentoExames";
import SelecionarPaciente from "./Paciente";
export default {
  components: { SelecionarExames, SelecionarEstabelecimento, SelecionarPaciente },
  created() {
    if (this.$route.params.slug) {
      const examesIds = String(this.$route.params.slug)
        .split(",")
        .map((id) => id.trim())
        .map((id) => ({
          id
        }));
      this.agendamento._selectedExames = examesIds;
      this.agendamento.exames = examesIds;
    }
  },
  data() {
    return {
      agendamento: {
        ie_tipo_agenda: "EL",
        exames: null,
        estabelecimento: null,
        agenda: null,
        paciente: null,
      },
      agendamentoCriado: false,
      link_url: null,
    };
  },
};
</script>
