<template>

    <div>
        <Banner id="topo"/>
        <div class="container-perfil ">
            <div class="card_perfil">
                <img src="@/assets/img/logo-medclub.png" class="img-card">
                <span style='font-size: 20px; font-weight: bold; text-align: end; width: 200px; margin-top: -10px; color:#DF3537  '>pessoal</span>
                <button v-if='!usuario' @click="salvarIntencaoEmpresa(false)">
                    <span v-if='!medempresa'><b>Veja mais</b></span>
                    <span v-if='medempresa'><b>Clique aqui</b></span>
                </button>
                <button v-else @click='medempresa ? setTipoUsuario(false) : $router.push("#como_funciona")'>
                    <a v-if='!medempresa' href="#como_funciona" style='text-decoration: none'><b>Veja mais</b></a>
                    <span v-if='medempresa'><b>Clique aqui</b></span>
                </button>
            </div>
            <div class="divider" >
            </div>
            <div class="card_perfil">
                <img src="@/assets/img/logo-medclub.png" class="img-card">
                <span style='font-size: 20px; font-weight: bold; text-align: end; width: 200px; margin-top: -10px; color: #322c66'>empresa</span>
                <button v-if='!usuario' @click="salvarIntencaoEmpresa(true)" style="background-color: #ffba01; color: #0e5fd6">
                    <span v-if='medempresa'><b>Veja mais</b></span>
                    <span v-if='!medempresa'><b>Clique aqui</b></span>
                </button>
                <button v-else @click='!medempresa ? setTipoUsuario(true) : $router.replace({name: "paraEmpresas"})' style="background-color: #ffba01; color: #0e5fd6">
                    <span v-if='medempresa'><b>Veja mais</b></span>
                    <span v-if='!medempresa'><b>Clique aqui</b></span>
                </button>
            </div>
        </div>
        <div>
        </div>
        <b-modal style='z-index: 999' v-model="modalInfoMedEmpresa" :width="400" scroll="keep" aria-label="Confirmar Pedidos">
            <div class="card">
                <div class="card-content">
                    <!-- <img src="@/assets/img/empresabanner.png" width="100px" class="img-card"> -->
                    <div class="content flex items-center justify-center flex-wrap">
                        <p>O plano de saúde da sua empresa lhe trata mal e cobra caro? Pois mude para o MEDCLUB EMPRESA. Aqui você ganha muito e gasta pouco. E ainda faz conta poupança. Saúde com poupança é Medclub Empresa. Vamos conversar? 
                        <br/>
                        <b>Cerqueira:</b> (86) 99981-1696<br/>
                        <b>Ilana:</b> (86) 98148-2005<br/>
                        <b>Aldo Giordano:</b> (86) 99828-1488<br/></p>
                    </div>
                </div>
    
                <footer class="flex justify-center w-full">
                    <div class="p-2 w-1/4">
                        <b-button class="w-full rounded p-0" type="is-primary" @click="modalInfoMedEmpresa = false">
                            <div class="flex items-center">
                                <span class="text-xs">Ok!</span>
                            </div>
                        </b-button>
                    </div>
                </footer>
            </div>
        </b-modal>


        <Servicos :saldo="saldo"/>
 
        <ComoFunciona/>

        <PergunteAoDoutor/>

        <div class="modulo modulo_noticias mt-10 sm:mt-0" id="noticias" v-if='!waitingNoticia && noticias.length > 2'>
            <div class="container container-whatsapp">
                <a href="https://api.whatsapp.com/send?phone=558631311881" class="float" target="_blank">
                    <i class="jam jam-whatsapp my-float"></i>
                </a>
            <div>
                <h1 class="text-center m-10">Notícias</h1>
            </div>
            </div>
            <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <router-link :to="`/noticia/${ noticiaDestaque.id }/`">
                <img
                    class="img-fluid img-principal"
                    v-if='noticiaDestaque.aq_imagem_capa'
                    :src='noticiaDestaque.aq_imagem_capa'
                    alt="Título da notícia aqui"
                />
                <div class="chamada_principal">
                    <h4 class="chamada_categoria">{{ noticiaDestaque.ds_categoria }}</h4>
                    <div class="categoria_separador" />
                    <a class="chamada_principal_link">
                        <p class="chamada_texto">
                            {{ noticiaDestaque.nm_titulo }}
                        </p>
                    </a>
                    <p class="chamada_data d-flex justify-content-start align-items-center"> <span class="jam jam-calendar"></span>{{ noticiaDestaque.dt_publicacao }}</p>
                </div>
                </router-link>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">

                <div v-for='(noticia, index) in noticias' :key='noticia.id'>
                <router-link :to="`/noticia/${ noticia.id }/`">
                <div v-if='index < 3' class="row chamada_noticia">
                    <div class="col-md-6">
                    <img
                        class="img-fluid img-secundaria"
                        v-if='noticia.aq_imagem_capa'
                        :src="noticia.aq_imagem_capa"
                        alt="Título da notícia aqui"
                    />
                    </div>
                    <div class="col-md-6">
                    <h4 class="chamada_categoria">{{ noticia.ds_categoria }}</h4>
                    <div class="categoria_separador" />
                    <a>
                        <p class="chamada_texto">
                            {{ noticia.nm_titulo }}
                        </p>
                    </a>
                    <p class="chamada_data d-flex justify-content-start align-items-center">
                        <span class="jam jam-calendar"></span>{{ noticia.dt_publicacao }}
                    </p>
                    </div>
                </div>
                </router-link>

                </div>
                </div>

            </div>
            </div>

            <div class="container">
            <div class="flex justify-center">
                <router-link to="/todas-as-noticias">
                    <a class="btn btn-primary btn_custom btn_mais_noticias">
                        <div class="text-uppercase text-white">Mais notícias</div>
                    </a>
                </router-link>
            </div>
            </div>
        </div>

        <div class="modulo modulo_locais_atendimento container_locais" id="locais_atendimento">
            <div class="container">
                <div>
                    <h1 class="header-locais text-center">
                        <b>
                            Locais de Atendimento
                        </b>
                    </h1>
                </div>
                <b-field>
                    <b-input placeholder="Pesquisar"
                             icon-right="magnify"
                             :loading="waitingLocais"
                             v-model="nm_estabelecimento"
                             @input="isTypingLocal = true"

                            >
                    </b-input>
                </b-field>
            </div>
            <div  class="container pt-4">
                <div v-if="listTest.length && !waitingLocais" class="columns is-multiline is-gapless">
                    <div v-for="item in listTest" :key="item.id" class="column is-half w-full">
                        <a :href="`./local/${item.id}`">
                            <div class="list-item w-full">
                                <div class="img_local img-local-new" :style="`background-image: url('${ item.foto }')`"></div>
                                <div class="content_local">
                                    <h4 class="item-title">{{item.local}}</h4>
                                    <p class="item-endereco">{{item.endereco}}, {{item.numero}}, {{item.bairro}}</p>
                                    <p class="item-endereco">{{item.cep.slice(0, 5)}}-{{item.cep.slice(5, 8)}}</p>
                                    <a v-if="item.whatsapp != ''" class="item-telefone" :href="`https://wa.me/${item.whatsapp}`"><span style="color: green; padding-right: 2px" class="jam jam-whatsapp"></span>{{formatPhoneNumber(item.whatsapp)}}<br/></a>
                                    <a class="item-telefone" :href="`tel:${item.telefone}`">{{formatPhoneNumber(item.telefone)}}</a>
                                    <a class="item-link-mapa" :href="`https://www.google.com/maps/place/${item.coordenadas.lat},${item.coordenadas.lng}`" target="_blank"><span class="jam jam-map-marker-f"></span>Ver no Mapa</a>
                                    <p  v-for="horario in item.hr_atendimento" :key='horario' class="item-endereco">{{ horario }}</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div v-else-if="waitingLocais" class="columns is-multiline">
                    <article class="column flex flex-row is-half w-full media" v-for="i in 6" :key="i">
                        <figure class="media-left">
                            <p class="image">
                                <b-skeleton circle width="120px" height="120px"></b-skeleton>
                            </p>
                        </figure>
                        <div class="media-content">
                            <div class="content">
                                <p>
                                    <b-skeleton active></b-skeleton>
                                    <b-skeleton height="80px"></b-skeleton>
                                </p>
                            </div>

                        </div>
                    </article>
                </div>
                <div class="" v-else>
                    <p class="pt-6 align-center">Nenhum estabelecimento encontrado.</p>
                </div>
<!--                <ul class="list">
                    <li class="list-item"></li>
                </ul>-->
                <div class="list-footer">
                    <button class="btn" id="prev-page" >
                        <span  class="jam jam-arrow-left"></span>
                    </button>
                    <div>
                        <span id="current-page">{{ currentPage }}</span> / <span id="total-pages">{{ totalPages }}</span>
                    </div>
                    <button class="btn" id="next-page">
                        <span  class="jam jam-arrow-right"></span>
                    </button>
                </div>
            </div>
        </div>
        <div class="modulo modulo_duvidas " id="duvidas">
            <div class="container">
            <div>
                <h1 class="text-center">Dúvidas</h1>
            </div>
            </div>

            <div class="container">
            <div class="row" id="">

                <button class="accordion">
                É um plano de saúde?
                </button>
                <div class="panel">
                <p>
                    Não. No Medclub você só paga quando usa, um valor acessível.
                </p>
                </div>

                <button class="accordion">
                O que é o Medclub?
                </button>
                <div class="panel">
                <p>
                    ✅ Somos uma Plataforma Digital, que oferece serviços de assistência médica de alta qualidade a custos acessíveis.
                    Temos parceria com as principais clínicas e hospitais, para oferecer-lhe o melhor da excelência médica.<br/><br/>

                    * Não cobramos mensalidades ou taxas de adesão. Com o Medclub, você só paga quando usa.
                </p>
                </div>

                <button class="accordion">
                O Medclub é uma Clínica ?
                </button>
                <div class="panel">
                <p>
                    ➡️ Não somos uma clínica, mas temos parcerias com as melhores clínicas e Hospitais, para que nossos clientes tenham acesso ao Melhor da medicina, pelo melhor preço. Intermediamos a sua compra.
                </p>
                </div>

                <button class="accordion">
                Quais as formas de pagamento?
                </button>
                <div class="panel">
                <p>
                    ✅ Pelo Aplicativo e Site: <br/>

                    1- Cartão de crédito * Exceto Credishop .  **Dividimos a partir R$ 100,00 e em até 3x sem juros e a partir de 4x com juros.<br/>
                    2- Créditos Medclub (Paciente gera um boleto no valor de sua escolha, paga e aguarda compensar. Depois de compensado é gerado crédito no app, que poderá ser usado para efetuar pagamentos das consultas e exames, etc.<br/>
                    3- PIX <br/><br/>

                    ✅  Presencialmente (no balcão do Medclub): <br/>

                    1-Balcão > Todos os Cartões de crédito, Débito e Espécie.<br/>
                    2-No totem de autoatendimento: com Cartão de crédito (exceto Credishop) e Cartão de débito.<br/><br/>

                    **Dividimos em até 12X com juros.<br/>
                </p>
                </div>
                <button class="accordion">
                O que é o Medclub Empresa ?
                </button>
                <div class="panel">
                <p>
                    O Medclub não é um plano de saúde. No plano de saúde você paga uma mensalidade, que sofre altos reajustes todos os anos. Todo mês tem que pagar, mesmo que você passe anos sem usar nada. E o dinheiro nunca volta para seu bolso. No plano de saúde o contrato diz que você terá consultas, exames e internações em hospitais, sempre que precisar. Mas não é isso que acontece. E não acontece porque o contrato entre as 2 partes - você e o plano de saúde - é cruel para ambos os atores, que logo passam a competir um contra o outro.<br/>
                    De um lado está o plano, que já recebeu pagamento antecipado e faz tudo para ter mais lucros, dificultando acesso aos serviços solicitados. E quando tem que entregar serviços, o plano escolhe aqueles mais baratos e portanto, de menor qualidade. Do outro lado está você, o cliente, que adota um comportamento imediatista, guiado pelo impulso de pensar: -“Ora, estou pagando, logo tenho que usar muito”. E passa a se deixar levar pela propaganda, consumindo consultas, exames e cirurgias, sem parar.<br/>
                    E assim, em virtude do completo desacordo de propósitos do atual modelo, clientes e médicos sonham com um nova forma de relacionamento que elimine desperdícios e conflitos. A solução para tal cenário se chama Medclub.
                    Com o Medclub Empresas o funcionário deposita, no início da cada mês, numa conta de aplicação financeira junto à Caixa Economica, um valor definido em reunião coletiva. O valor da soma de todos os depósitos feitos pelos funcionários será imediatamente aplicado em investimento financeiro, com rendimento diário.<br/>
                    Cada funcionário terá acesso direto ao extrato do seu investimento pessoal. No final de cada mês, o Medclub enviará à empresa uma fatura com o nome de cada funcionário que usou o Medclub nos últimos 30 dias, listando o que ele usou e quanto custou. Esse valor é descontado da aplicação, para pagar os médicos, clinicas e hospitais. O restante do dinheiro continuará na conta bancaria, como investimento. Quando o funcionário pedir demissão, for demitido ou falecer, o dinheiro dos depósitos, somado aos ganhos da aplicação, será devolvido a ele, ou aos seus herdeiros. É como um segundo FGTS. Como o Medclub já possui mais de 200 mil clientes, consegue grandes descontos em preços de consultas, exames e cirurgias.<br/>
                    Além disso, no Medclub, somente médicos com título de especialista e comprovada competência podem atender nossos clientes. E precisamos, também, lhe apresentar o maravilhoso Clube de Vantagens Medclub, que permite grandes descontos nas melhores farmácias, academias, fornecedores de aparelhos médicos, salões de beleza, etc.
                    </p>
                </div>
                <button class="accordion">
                Como usar o Medclub Empresa?
                </button>
                <div class="panel">
                <p>
                    Ao contrário dos planos de saude, onde a burocracia é enorme, com longas carências e muitas proibições, no Medclub tudo é muito ágil.<br/>
                    Precisa de uma consulta, ou exame? Bastará que você ligue para nosso CallCenter (86) 3131-1881 esclarecendo duvidas e agendando suas consultas e exames. Se você preferir seu celular, tudo poderá ser feito baixando e usando o aplicativo Medclub. No aplicativo Medclub você encontrará toda a ajuda que precisa, podendo também falar com médicos, mandando suas perguntas pelo “Pergunte ao Doutor”, que já conta com mais de 35 milhões de acessos.<br/>
                    Ah, mais um detalhe importante: nos planos de saude você nem sabe quem são os donos, você não os conhece e eles não querem falar com você. No Medclub, você conhece os donos. E pode falar com eles, na hora que desejar. Experimente e nos ajude a melhorar.
                    </p>
                </div>
            </div>
            </div>

            <div class="container">
            <div class="row flex justify-content-center">
                <router-link to="/duvidas-frequentes">
                    <a class="btn btn-primary btn_custom mt-5" style="color: white;">
                        <div class="text-uppercase">Mostrar todas</div>
                    </a>
                </router-link>
            </div>
            </div>
        </div>
    <!--
        <div class="modulo modulo_app_medclub" id="app_medclub">
            <div class="container">
            <div class="row">
                <div class="col-md-6">
                <div class="alinhamento">
                    <h1>Marque suas consultas e exames sem dificuldades!</h1>

                    <div class="titulo_separador" />

                    <h4>Baixe nosso aplicativo</h4>

                    <div class="row">
                    <div class="col-md-6">
                        <a href="https://apps.apple.com/br/app/medclub/id1508392465"
                        ><img
                            class="img-fluid mb-3"
                            src="/img/btn_app_store.png"
                            alt="App Store"
                        /></a>
                    </div>
                    <div class="col-md-6">
                        <a href="https://play.google.com/store/apps/details?id=com.medclub"
                        ><img
                            class="img-fluid mb-5"
                            src="/img/btn_google_play.png"
                            alt="Google Play"
                        /></a>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-md-6">
                <img class="img-fluid" src="img/mobile.png" alt="App MedClub" />
                </div>
            </div>
            </div>
        </div>
    -->
        <div v-if="!medempresa"  class="modulo modulo_area_doutor" id="area_doutor">
            <div class="container">
            <div >
                <h1 class="text-center font-weight-bold">Área do Doutor</h1>
                <p class="mb-5 text-center">Quer atender pelo MedClub?</p>
            </div>
            </div>

            <div class="container">
            <div class="row">
                <div class="col-md-8">
                <img
                    class="img-fluid mt-5"
                    src="img/doctors.png"
                    alt="Área do Doutor"
                />
                </div>
                <div class="col-md-4">
                <div class="alinhamento">
                    <p class="text-start">
                    Doutor se você deseja fazer parte do MedClub, clique no botão
                    abaixo
                    </p>
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSdNBQgKyW5pMhG2kJE5dd4aEXjGBY34zcKxMX6ti_4eEl3eQw/viewform" class="btn btn-primary btn_custom" target="_blank">
                        <div class="text-uppercase">Atenda pelo medclub</div>
                    </a>
                </div>
                </div>
            </div>
            </div>
        </div>

        <FaleConosco/>
        </div>
</template>

<style lang="scss" scoped>
        *:not(.decorated-text) {
            text-decoration: none !important;
        }
        .container-perfil {
            background-color: #f4f7fe;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: -30px;
        }
        .img-card {
            width: 200px;
        }
        .divider {
            width: 30px;
        }
        .card_perfil {
            background-color: white;
            width: 400px;
            height: 189px;
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 20px;
            box-shadow: #0E5FD6;
            border-width: 1px;
            z-index: 2;
            padding: 20px;
            button {
                padding: 8px;
                width: 40%;
                margin-top: 10px;
                background-color: #0E5FD6;
                color: #FFFFFF;
                border-radius: 20px;
            }
        } 
        .home {
            .raised-button {
                margin-bottom: 1px;
                &:hover { margin-bottom: 0; margin-top: 1px; box-shadow: none; }
            }
            .mainMenuButtonIcon {
                width: 22%
            }
            .mainMenuButtonText {
                width: 46%
            }
            @media all and (max-width: 640px) {
                .mainMenuButtonIcon {
                    width: 65%
                }
                .mainMenuButtonText {
                    width: 100%
                }
            }
        }

        * {
            box-shadow: 0 0 0 gold;
        }

        .modulo_duvidas {
            padding: 50px
        }

        .img-principal {
            height: 600px;
            width: 600px;
            object-fit: cover;
        }

        .img-secundaria {
            height: 180px;
            width: 290px;
            object-fit: cover;
        }
        .img-local-new {
            margin: 0;
            margin-right: 20px;
            margin-top: 6px;
            min-width: 120px;
        }
        .content-new {
            max-height: 100px;
        }

        @media (max-width: 991px) {
            .img-principal {
                height: 180px;
                width: 290px;
            }
        }
        @media only screen and (max-width: 769px) {
            .container-perfil {
                display: none;
            } 
            .container-whatsapp {
                display: none;
            }
            div {
                background-color: #f4f7fe;
            }
        }

</style>

<script>
        //import axios from 'axios'
        import ComoFunciona from './ComoFunciona.vue'
        import Noticias from '../../middleware/controllers/Noticias';
        // import configs from '../../middleware/configs';
        import moment from 'moment';
        import PergunteAoDoutor from './PergunteAoDoutor.vue';
        import Servicos from './Servicos.vue';
        import Banner from './Banner.vue';
        import FaleConosco from './FaleConosco.vue';
        // import NovaAPI from './../../middleware/controllers/NovaAPI.js';
        import {Utils, Pagamento} from "@/middleware";
        import { isEqual, sortBy } from 'lodash';

        const _ = require('lodash');

        export default {
            components: {ComoFunciona, PergunteAoDoutor, Servicos, Banner, FaleConosco},
            created() {
                //  NovaAPI.authenticateApp(configs.novaAPI.applicationId, configs.novaAPI.applicationToken)
                // .then(response => {
                    // this.getNoticias(response);
                // });
                this.getNoticias()
                this.usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
                this.medempresa =  JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                if(this.usuario) this.getSaldoCreditos()
                this.$store.commit("setBuscaEspecialista", null);

            },
            methods: {
                openLogin() {
                    this.$parent.$parent.dialog.login = true
                },
                salvarIntencaoEmpresa(valor){
                    localStorage.setItem('MEDCLUB_TYPE_USER_INTENTION', valor)
                    this.openLogin()
                },
                getNoticias() {
                    const params = {paginacao: true, page: 1, per_page: 5}
                    Noticias.getTodasAsNoticias(params).then(response => {
                        if (response.status === 200) {
                            const noticiasData = response.data.results
                            noticiasData.forEach(element => {
                                element.dt_publicacao = moment(element.dt_publicacao, 'YYYY-MM-DD').format('DD/MM/YYYY');

                                if(element.ie_destaque) this.noticiaDestaque = element;
                                else this.noticias.push(element);
                            });

                            if (!this.noticiaDestaque?.id) this.noticiaDestaque = this.noticias.shift();
                            this.waitingNoticia = false;
                        }
                    });
                },
                getSaldoCreditos () {
                    this.waiting.saldo = true
                    var medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
                    Pagamento.getSaldoCreditos({ie_associado_med_empresa: medempresa}).then(response => {
                        this.waiting.saldo = false
                        if (response.status === 200) this.saldo = response.data.saldo_empresa ? response.data.saldo_empresa[0].nr_saldo : response.data.saldo
                    })
                },
                formatPhoneNumber(str) {
                  if (str) {
                    if (str[0] == '+')
                      str = str.substr(1)
                    if (str.substr(0, 2) == '55')
                      str = str.substr(2)
                    let regex = str.length == 10 ? /(\d{2})(\d{4})(\d{4})/ : /(\d{2})(\d{5})(\d{4})/
                    return str.replace(regex, '($1) $2-$3')
                  } else return ''
                },
                setTipoUsuario(valor) {
                    let usuario = JSON.parse(localStorage.getItem("MEDCLUBSITE_USER"));
                    if (valor && usuario.ie_associado_med_empresa)
                        localStorage.setItem('MEDCLUB_TYPE_USER', usuario.ie_associado_med_empresa)
                    if(!valor)
                        localStorage.setItem('MEDCLUB_TYPE_USER', valor)
                    if(!usuario.ie_associado_med_empresa && valor)
                        this.alertMedclubEmpresa()
                    else 
                        this.$router.go();
                        
                },
                alertMedclubEmpresa() {
                    //this.modalInfoMedEmpresa = true
                    
                    this.$buefy.dialog.alert({
                    title: 'Medclub Empresa',
                    message: `<div>
                        <div style="justify-content: center; align-items: center; display: flex;">
                            <img src="${require('../../assets/img/empresabanner.png')}" width="200px"/>
                        </div>
                        <p>O plano de saúde da sua empresa lhe trata mal e cobra caro? Pois mude para o <b>MEDCLUB EMPRESA</b>. <br/>Aqui você ganha muito e gasta pouco. E ainda faz conta poupança. Saúde com poupança é Medclub Empresa. Vamos conversar?
                        <br/>
                        <br/>
                        <b>Cerqueira:</b> (86) 99981-1696<br/>
                        <b>Ilana:</b> (86) 98148-2005<br/>
                        <b>Aldo Giordano:</b> (86) 99828-1488<br/></p>
                        </div>`,
                    confirmText: 'OK',
                   
                    })
                    
                },
                itemsFiltered () {
                    this.waitingLocais = true;
                    var params ={
                        cd_cidade: this.$store.state.cdCidade,
                        nm_fantasia: this.nm_estabelecimento
                    }
                    if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))


                    Utils.getRedeCredenciada(params)
                        .then(response => {
                            this.currentPage = 1;
                            let body = response.data;
                            this.items = [];
                            this.items = body.map(item => {
                                return {
                                    id: item.id,
                                    local: item.nm_fantasia,
                                    endereco: item.nm_rua,
                                    bairro: item.nm_bairro,
                                    whatsapp: item.nr_whatsapp_cliente,
                                    telefone: item.nr_telefone,
                                    foto: item.aq_foto ? item.aq_foto : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfBzJTCIu_0zRt72_OPWCdrb2OEf-dh0pk1A&usqp=CAU',
                                    cep: item.nr_cep,
                                    numero: item.nr_estabelecimento,
                                    coordenadas: { lat: item.nr_latitude, lng: item.nr_longitude },
                                    hr_atendimento: this.listHrFuncionamento(body.filter(i => i.id == parseInt(item.id))[0].hr_funcionamento).content
                                }
                            })
                            this.listTest = this.items
                                .slice(0, this.itemsPerPage)

                            // this.list.innerHTML = this.items
                            //     .slice(0, this.itemsPerPage)
                            //     .map((item) => this.createListItem(item))
                            //     .join('');
                            this.numPages = Math.ceil(this.items.length / this.itemsPerPage);
                            this.totalPages = Math.ceil(this.items.length / this.itemsPerPage);
                            //this.currPage = this.currentPage;
                            this.waitingLocais = false;
                        })

                },
                createListItem(item) {
                    if(! item.foto)
                        item.foto = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfBzJTCIu_0zRt72_OPWCdrb2OEf-dh0pk1A&usqp=CAU'

                    return `<a href="./local/${item.id}">
                        <li class="list-item">
                            <div class="img_local" style="background-image: url(${item.foto});"></div>
                            <div class="content_local">
                                <h4 class="item-title">${item.local}</h4>
                                <p class="item-endereco">${item.endereco}, ${item.numero}</p>
                                <p class="item-endereco">${item.bairro}</p>
                                <p class="item-endereco">${item.cep.slice(0, 5)}-${item.cep.slice(5, 8)}</p>
                                <a class="item-telefone" href="tel:${item.telefone}">${this.formatPhoneNumber(item.telefone)}</a>
                            </div>
                        </li>
                    </a>`;
                },
                nextPage() {
                    if (this.currentPage === this.numPages) return;

                    this.currentPage++;
                    this.currentIndex = (this.currentPage - 1) * this.itemsPerPage;
                    let newIndex = this.currentIndex + this.itemsPerPage;
                    this.listTest = this.items
                        .slice(this.currentIndex, newIndex)

                   /* this.list.innerHTML = this.items
                        .slice(this.currentIndex, newIndex)
                        .map((item) => this.createListItem(item))
                        .join('');*/
                    this.currPage.innerHTML = this.currentPage;
                },
                prevPage() {
                    if (this.currentPage === 1) return;

                    this.currentPage--;
                    this.currentIndex = (this.currentPage - 1) * this.itemsPerPage;
                    let newIndex = this.currentIndex + this.itemsPerPage;
                    this.listTest = this.items
                        .slice(this.currentIndex, newIndex)
                   /* this.list.innerHTML = this.items
                        .slice(this.currentIndex, newIndex)
                        .map((item) => this.createListItem(item))
                        .join('');*/
                    this.currPage.innerHTML = this.currentPage;
                },
                capitalizeFirstLetter(string) {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                },
                showFirstNameOfDay(day) {
                    const str = moment()
                        .day(Number(day))
                        .format('dddd');

                    if (str.includes('-')) {
                        return str.split('-')[0];
                    }

                    return str;
                },
                listHrFuncionamento(horarios) {
                    const horarios_map = this.transformDatesListToMap(horarios);

                    const its_full_time_open = this.checkIfIsFullTimeOpen(horarios_map)

                    if (its_full_time_open) {
                        return {isFullTime: true, content: 'Todos os dias - 24 horas'}
                    
                    }

                    const hrrs_text_list = this.horariosMapToLabels(horarios_map)
                    return {isFullTime: false, content: hrrs_text_list}
                },
                transformDatesListToMap(hrFuncionamentos) {
                    const hr_atendimento_transformed = {}; // to format {[dia]: [{hr_inicio, hr_final}] }
                    hrFuncionamentos?.forEach(hr_funcionamento => {
                        const hours = hr_funcionamento.ar_dias_semana;
                        if (hours.length > 0) {
                        for (const hour of hours) {
                            if (!hr_atendimento_transformed[hour]) hr_atendimento_transformed[hour] = [];
                            hr_atendimento_transformed[hour].push({
                            hr_inicio: hr_funcionamento.hr_inicio,
                            hr_fim: hr_funcionamento.hr_fim
                            });
                        }
                        }
                    });
                return hr_atendimento_transformed;
                },
                checkIfIsFullTimeOpen(horarios) {
                    const values = Object.values(horarios)

                    const itsFullDayOpen = (hrrs) => hrrs.length === 1 && hrrs[0].hr_inicio === '00:00:00' && hrrs[0].hr_fim === '23:59:00'

                    return values.length === 7 && values.every(val => itsFullDayOpen(val))
                },
                horariosMapToLabels(horarios) {
                    // shoud find sequential days with the same hrr: 2 or more duplicated 
                    const horarios_entries = Object.entries(horarios)
                    const days_with_same_hrr = this.extractDaysWithSameHrr(horarios)
                    const labels = []

                    const alreadyMarkedDays = []

                    const hrrToLabel = (hrr, index, hrrs_size) =>{
                        var openingTime = moment(hrr.hr_inicio, 'HH:mm:ss').format('HH:mm');
                        var closingTime = moment(hrr.hr_fim, 'HH:mm').format('HH:mm');

                        var hora1 = openingTime.split(":")[0].replace(/^0+/, '') + 'h';
                        var min1 = openingTime.split(":")[1]
                        if(min1 == "00") min1 = ''
                        openingTime = hora1 + min1
                        
                        var hora2 = closingTime.split(":")[0].replace(/^0+/, '') + 'h';
                        var min2 = closingTime.split(":")[1]
                        if(min2 == "00") min2 = ''
                        closingTime = hora2 + min2

                        if (index === 0) return `: ${openingTime} às ${closingTime}`
                        if (index === (hrrs_size - 1)) return ` e ${openingTime} às ${closingTime}`
                        return `, ${openingTime} às ${closingTime}`
                    }

                    for (const day_horarios of horarios_entries) {
                        const [day, horarios] = day_horarios

                        if(alreadyMarkedDays.includes(day)) continue

                        // check if day has group
                        const dayGroup = days_with_same_hrr.find(dayStats => dayStats.days.includes(Number(day)))

                        if(dayGroup && dayGroup.sequential){
                        const hrrsInStr = sortBy(horarios, ['hr_inicio']).map((hrr, index) => hrrToLabel(hrr, index, horarios.length))
                        labels.push(`${ this.capitalizeFirstLetter(this.showFirstNameOfDay(day))} à ${this.showFirstNameOfDay(dayGroup.days[dayGroup.days.length - 1])} ${hrrsInStr.join('')}`)

                        alreadyMarkedDays.push(...dayGroup.days.map(d=>String(d)))
                        continue
                        }


                        const hrrsInStr = sortBy(horarios, ['hr_inicio']).map((hrr, index) => hrrToLabel(hrr, index, horarios.length))
                        labels.push(`${this.capitalizeFirstLetter(this.showFirstNameOfDay(day))} ${hrrsInStr.join('')}`)
                        alreadyMarkedDays.push(day)
                        continue
                    }

                    return labels
                },
                extractDaysWithSameHrr(horarios) {
                    const horarios_entries = Object.entries(horarios)
                    const days_with_same_hrr=[]

                    for (const day_horarios of horarios_entries) {
                        if (days_with_same_hrr.map(daysItem => daysItem.days).flat(1).includes(Number(day_horarios[0]))) continue// if was included already

                        const allDaysWithSameHrr = this.getDaysByContent(horarios, day_horarios[1])

                        if (allDaysWithSameHrr.length < 2) continue

                        // if days are not sequentials ignore
                        if (!this.isThisSequential(allDaysWithSameHrr.map(day => Number(day)))) {
                        days_with_same_hrr.push({
                            sequential: false,
                            days: allDaysWithSameHrr.map(d => Number(d))
                        })
                        continue
                        }

                        days_with_same_hrr.push({
                        sequential: true,
                        days: allDaysWithSameHrr.map(d => Number(d))
                        })
                    }
                    return days_with_same_hrr
                },
                isThisSequential(arr) {
                    const differenceAry = arr.slice(1).map(function (n, i) { return n - arr[i]; })
                    const isDifference = differenceAry.every(value => value == 1)
                    return isDifference

                },
                getDaysByContent(obj, value) {
                    return Object.keys(obj).filter((key) => isEqual(obj[Number(key)], value));
                },
            },
            data () {
                return {
                    homeButtons: [
                        { icon: 'jam jam-medical', label: 'Consultas', action: () => { this.$router.push({ name: 'agendarConsulta' }) } },
                        { icon: 'jam jam-activity', label: 'Exames', action: () => { this.$router.push({ name: 'agendarExames' }) } },
                        { icon: 'jam jam-task-list', label: 'Check-up', action: () => { this.$router.push({ name: 'agendarCheckup' }) } },
                        { icon: 'jam jam-bandage', label: 'Procedimentos', action: () => { this.$router.push({ name: 'agendarProcedimentos' }) } },
                        { icon: 'jam jam-cutter', label: 'Cirurgias', action: () => { this.$router.push({ name: 'agendarCirurgia' }) } },
                        { label: 'odonto', action: () => { this.$router.push({ name: 'agendarOdonto' }) }},
                        { label: 'amigo-medclub', action: () => { this.$router.push({ name: 'areaAmigoMedclub' }) } },
                        { label: 'pronto-socorro', action: () => { this.$router.push({ name: 'prontoSocorro' }) } },
                        { label: 'medclub-vantagens', action: () => { this.$router.push({ name: 'medclubVantagens' }) } },
                        { icon: 'jam jam-coin', label: 'Ver preços', action: () => { this.$router.push({ name: 'orcamento' }) } },
                        { icon: 'jam jam-message', label: 'Pergunte ao Doutor', action: () => { window.open('https://oiaqui.com', '_blank') } },
                        { icon: 'jam jam-help', label: 'Dúvidas?', action: () => { this.$router.push({ name: 'duvidasFrequentes' }) } },
                        { label: 'lcreateListItemocais-atendimento', action: () => { this.$router.push({ name: 'locaisAtendimento' }) } },
                    ],
                    storeButtons: [
                        { icon: 'jam jam-apple', label: 'App Store', link: 'https://apps.apple.com/br/app/medclub/id1508392465' },
                        { icon: 'jam jam-google-play', label: 'Google Play', link: 'https://play.google.com/store/apps/details?id=com.medclub' }
                    ],
                    noticiaDestaque: {
                        nm_titulo: '',
                        ds_subtitulo: '',
                        id: '',
                        dt_publicacao: '',
                        ie_categoria: []
                    },
                    saldo: 0,
                    medempresa: false,
                    usuario: null,
                    modalInfoMedEmpresa: false,
                    waiting: { saldo: false},
                    isTypingLocal: false,
                    noticias: [],
                    waitingNoticia: true,
                    waitingLocais: false,
                    list: '',
                    listTest: [],
                    currPage: '',
                    totalPages: '',
                    buttonPrev: '',
                    buttonNext: '',
                    items: [],
                    nm_estabelecimento: "",
                    nameItem: '',
                    currentPage: 1,
                    currentIndex: 0,
                    itemsPerPage: 6,
                    numPages: 0,
                    acc: '',
                    dialogMedEmpresa: false,
                }
            },
            watch: {
                nm_estabelecimento: _.debounce(function() {
                    this.isTypingLocal = false;
                }, 500),
                isTypingLocal: function(value) {
                    if (!value) {
                        this.itemsFiltered()
                    }
                }
            },
            mounted() {
                this.acc = document.getElementsByClassName("accordion");

                let i;

                for (i = 0; i < this.acc.length; i++) {
                this.acc[i].addEventListener("click", function() {
                    this.classList.toggle("active_question");
                    let panel = this.nextElementSibling;
                    if (panel.style.maxHeight) {
                    panel.style.maxHeight = null;
                    } else {
                    panel.style.maxHeight = panel.scrollHeight + "px";
                    }
                });
                }

                this.list = document.querySelector('.list');
                //this.currPage = document.querySelector('#current-page');
                //this.totalPages = document.querySelector('#total-pages');
                this.buttonPrev = document.querySelector('#prev-page');
                this.buttonNext = document.querySelector('#next-page');

                this.buttonPrev.addEventListener('click', this.prevPage);
                this.buttonNext.addEventListener('click', this.nextPage);

                //this.currPage.innerHTML = this.currentPage;
                this.itemsFiltered();
                /* axios.get(`${configs.baseUrl}/appuse/rede-credenciada/`)
                    .then(response => {
                        var body = response.data;
                        for(var i in body) {
                                // console.log(body[i]);
                                this.items.push({
                                    id: body[i].id,
                                    local: body[i].nm_fantasia,
                                    endereco: body[i].nm_rua,
                                    bairro: body[i].nm_bairro,
                                    telefone: body[i].nr_telefone,
                                    foto: body[i].aq_foto,
                                    cep: body[i].nr_cep,
                                    numero: body[i].nr_estabelecimento
                                });
                            }
                            this.list.innerHTML = this.items
                            .slice(0, this.itemsPerPage)
                            .map((item) => this.createListItem(item))
                            .join('');
                            this.numPages = Math.ceil(this.items.length / this.itemsPerPage);
                            this.totalPages.innerHTML = Math.ceil(this.items.length / this.itemsPerPage);
                    })*/

            }
        }
</script>
<style scoped>
    .float{
        position:fixed;
        width:60px;
        height:60px;
        bottom:40px;
        right:40px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        padding-bottom: center;
        font-size:30px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
    }

    .my-float{
        margin-top:16px;
        color: white;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .list-item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        border-top: 1px solid lightblue;
    }

    .content_local {
        text-align: start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-variant-caps: all-petite-caps;
    }

    .content_local .item-title {
        font-size: large;
    }

    .content_local .item-link-mapa {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #6f6f6f;

    }

    .content_local .item-link-mapa:hover {
        color: #363636;
    }
    .header-locais {
        font-size: x-large;
        margin-bottom: 5px;
    }
</style>