<template>

<div class="modulo_menu" id="menu_home">
  <info-bar :usuario="usuario" @openLogin="$emit('openLogin')"/>
  <nav
    class="navbar navbar-expand-lg navbar-light bg-light navbar-institucional"
    id="navbar_home"
  >
    <div class="container flex">
      <div class="flex justify-between">
        <router-link :to="{ name: 'home' }" class="mt-2 mb-2 mr-4">
          <img v-if='medempresa' width="120px" class="inline" src='./../assets/img/logo-medclub-empresa-app.png'>
          <img v-if='!medempresa' class="w-40 inline mt-1 mr-4" src='./../assets/img/logo-nova.png'>
          
          <!-- <div class="pt-2 px-1 text-gray-700" style='font-size:.75rem'>
            <span class="whitespace-nowrap">Melhor da medicina. Melhor preço.</span>
          </div> -->
        </router-link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHome" aria-controls="navbarHome" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div
        class="collapse navbar-collapse"
        id="navbarHome"
      >
        <ul class="navbar-nav">
          <b-button type="is-primary" v-if="!usuario" class="minha-conta-mobile custom-mobile-button">
            <a class="nav-item menu_active_custom flex items-center nav-link text-white" @click="$emit('openLogin')"><span class="jam jam-padlock-f"></span> Entrar</a>
          </b-button>

          <!-- LOCALIZACAO --> 
          <li class="nav-item minha-conta-mobile justify-between items-center custom-dropdown-item" @click="$emit('openLocalidade')" aria-role="listitem">
            <div class="flex items-center">
              <b-icon pack="jam" icon="jam-map-marker"/>
              <span>{{ cidade }}</span>
            </div>
            <b-icon pack="jam" icon="jam-refresh" class="icon-refresh" />
          </li>

          <li v-if="!usuario || windowWidth>640" class="nav-item">
            <a class="nav-link" href="#servicos" @click="scrollToSection($event, '#servicos')">Serviços</a>
          </li>
          <li v-if="!usuario || windowWidth>640" class="nav-item dropdown">
            <a class="nav-link" href="para-empresas">Para empresas</a>
          </li>
          <li v-if="!usuario || windowWidth>640" class="nav-item ">
            <a class="nav-link" href="#como_funciona" @click="scrollToSection($event, '#como_funciona')">Como Funciona</a>
          </li>
          <li v-if="!usuario || windowWidth>640" class="nav-item hidden-mobile">
            <a class="nav-link" href="#pergunte_ao_doutor" @click="scrollToSection($event, '#pergunte_ao_doutor')"
              >Pergunte ao Doutor</a
            >
          </li>
          <li v-if="!usuario || windowWidth>640" class="nav-item ">
            <a class="nav-link" href="#noticias" @click="scrollToSection($event, '#noticias')">Notícias</a>
          </li>
          <li v-if="!usuario || windowWidth>640" class="nav-item ">
            <a class="nav-link" href="#locais_atendimento" @click="scrollToSection($event, '#locais_atendimento')"
              >Locais de Atendimento</a
            >
          </li>
          <li v-if="!usuario || windowWidth>640" class="nav-item ">
            <a class="nav-link" href="#duvidas" @click="scrollToSection($event, '#duvidas')">Dúvidas</a>
          </li>
        <!--  <li class="nav-item menu_active_custom">
            <a class="nav-link" href="#app_medclub">App MedClub</a>
          </li> -->
          <li v-if="!usuario || windowWidth>640" class="nav-item ">
            <a class="nav-link" href="#area_doutor" @click="scrollToSection($event, '#area_doutor')">Área do Doutor</a>
          </li>
          
          <!-- <li id="popcorn" class="nav-item menu_active_custom mx-auto my-0 w-100" v-if="!usuario">
            <a class="nav-link" @click="$emit('openLogin')"><span class="jam jam-padlock-f"></span> Entrar</a>
            <div id="tooltip" role="tooltip">
              <div>Popcorn</div>
              <div>New Item</div>
              <div id="arrow" data-popper-arrow></div>
            </div>
          </li> -->

          <!-- <li class="nav-item menu_active_custom mx-auto my-0 w-100" v-if="!usuario">
            <a class="nav-link" @click="$emit('openLogin')"><span class="jam jam-padlock-f"></span> Entrar</a>
          </li> -->

          <div @mouseover="onOver" @mouseleave="onLeave">
                  <!-- <b-button type="is-primary" v-if="!usuario" class="minha-conta-mobile">
                      <a class="nav-item menu_active_custom flex items-center nav-link text-white" @click="$emit('openLogin')"><span class="jam jam-padlock-f"></span> Entrar</a>
                  </b-button> -->
              <b-dropdown
              id ="dropdown-menu"
                position="is-bottom-left"
                aria-role="menu"
                ref="dropdown"
                class="minha-conta-desktop"
                v-if="!usuario">
                <li class="nav-item menu_active_custom flex items-center" slot="trigger" >
                    <a class="nav-link" @click="$emit('openLogin')"><span class="jam jam-padlock-f"></span> Entrar</a>
                </li>

                <!-- <hr class="dropdown-divider"> -->
                <!-- MEUS AGENDAMENTOS -->
                <router-link :to="{ name: 'meusAgendamentos' }" data-toggle="collapse">
                  <b-dropdown-item class="text-gray-600" aria-role="listitem">
                    <b-icon pack='jam' icon='jam-clock'></b-icon>
                    <span @click="$emit('openLogin')">Minha Agenda</span>
                  </b-dropdown-item>
                </router-link>

                <router-link :to="{ name: 'meusAgendamentos' }" data-toggle="collapse">
                  <b-dropdown-item class="text-gray-600" aria-role="listitem">
                    <b-icon pack='jam' icon='jam-id-card'></b-icon>
                    <span @click="$emit('openLogin')">Carteira Medclub</span>
                  </b-dropdown-item>
                </router-link>

                <router-link :to="{ name: 'meusAgendamentos' }" data-toggle="collapse">
                  <b-dropdown-item class="text-gray-600" aria-role="listitem">
                    <b-icon pack='jam' icon='jam-coin'></b-icon>
                    <span @click="$emit('openLogin')">Meus Créditos</span>
                  </b-dropdown-item>
                </router-link>




                <!-- <div class="border-t my-2"></div> -->

                <!-- <b-dropdown-item class="text-gray-600" aria-role="listitem" @click="$emit('encerrar')">
                  <b-icon pack='jam' icon='jam-log-out'>
                  </b-icon>
                  <span>Encerrar</span>
                </b-dropdown-item> -->
              </b-dropdown>
          </div>

          <li class="nav-item" v-if="usuario">
            <router-link :to="{ name: 'carrinho' }" class="flex p-2">
              <b-icon class="text-2xl text-gray-700" pack='jam' icon='jam-shopping-cart'></b-icon>
              <div class="w-4 h-4 overflow-hidden rounded-full flex justify-center items-center self-center	" :class="{ 'bg-red-600': carrinho.length > 0, 'bg-gray-600': carrinho.length == 0 }">
                <p class="text-xs text-white">{{ carrinho.length }}</p>
              </div>
            </router-link>
          </li>

          <!-- Opções de Minha Conta no menu mobile -->
          <hr class="dropdown-divider minha-conta-mobile" v-if="usuario">

          <li class="nav-item mx-auto my-0 minha-conta-mobile " v-if="usuario">
            <span>Olá, <b>{{ usuario.nm_apelido ? usuario.nm_apelido : usuario.first_name }}</b>!</span>
          </li>
          <li class="nav-item mx-auto my-0 minha-conta-mobile " v-if="usuario">
            <span>Crédito Medclub: <b>{{ $root.formatPrice(saldo) }} </b></span>
          </li>

          <hr class="dropdown-divider minha-conta-mobile" v-if="usuario">

          <!-- MEUS AGENDAMENTOS --> 
          <!-- <router-link :to="{ name: 'meusAgendamentos' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <b-icon pack='jam' icon='jam-clock'></b-icon>
              <span>Minha Agenda</span>
            </li>
          </router-link> -->

          <router-link :to="{ name: 'editarPerfil' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <b-icon pack='jam' icon='jam-write'></b-icon>
              <span>Editar Perfil</span>
            </li>
          </router-link>

          <router-link :to="{ name: 'outrasPessoas' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <b-icon pack='jam' icon='jam-users'></b-icon>
              <span>Dependentes</span>
            </li>
          </router-link>

          <router-link :to="{ name: 'meusCartoes' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <b-icon pack='jam' icon='jam-credit-card-f'></b-icon>
              <span>Cartões de crédito</span>
            </li>
          </router-link>

          <router-link :to="{ name: 'carteiraMedclub' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <b-icon pack='jam' icon='jam-id-card'></b-icon>
              <span>Carteira Medclub</span>
            </li>
          </router-link>
          <!-- <router-link :to="{ name: 'areaAmigoMedclub' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <v-icon class="mdi mdi-handshake mdi-20px ml-1" style="margin-right: 0.4rem !important"></v-icon>
              <span>Amigo Medclub</span>
            </li>
          </router-link> -->
          <router-link :to="{ name: 'meusCreditos' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <b-icon pack='jam' icon='jam-coin'></b-icon>
              <span>Meus créditos</span>
            </li>
          </router-link>
          <router-link :to="{ name: 'duvidasFrequentes' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <v-icon class="mdi mdi-help mdi-20px ml-1" style="margin-right: 0.4rem !important"></v-icon>
              <span>Dúvidas Frenquentes</span>
            </li>
          </router-link>
          <router-link :to="{ name: 'termosECondicoes' }" data-toggle="collapse" data-target="#navbarSistema" v-if="usuario">
            <li class="nav-item mx-auto my-0 minha-conta-mobile">
              <b-icon pack='jam' icon='jam-document'></b-icon>
              <span>Termos e Condições</span>
            </li>
          </router-link>
          
          <router-link :to="{ name: 'carrinho' }" class="flex p-2">
            <!-- <li class="nav-item mx-auto my-0 minha-conta-mobile" v-if="!usuario">
              <b-icon class="text-2xl text-gray-700" pack='jam' icon='jam-shopping-cart'></b-icon>
              <div class="w-4 h-4 overflow-hidden rounded-full flex justify-center items-center self-center	" :class="{ 'bg-red-600': carrinho.length > 0, 'bg-gray-600': carrinho.length == 0 }">
                <p class="text-xs text-white">{{ carrinho.length }}</p>
              </div>
            </li> -->
          </router-link>
          <!-- <li class="nav-item minha-conta-mobile justify-left" v-if="usuario">
            <router-link :to="{ name: 'carrinho' }" class="flex p-2">
              <b-icon class="text-2xl text-gray-700" pack='jam' icon='jam-shopping-cart'></b-icon>
              <div class="w-4 h-4 overflow-hidden rounded-full flex justify-center items-center self-center	" :class="{ 'bg-red-600': carrinho.length > 0, 'bg-gray-600': carrinho.length == 0 }">
                <p class="text-xs text-white">{{ carrinho.length }}</p>
              </div>
            </router-link>
          </li> -->
          <hr class="dropdown-divider minha-conta-mobile" v-if="usuario">

          <li class="nav-item mx-auto my-0 minha-conta-mobile" @click="$emit('encerrar')" v-if="usuario" data-toggle="collapse" data-target="#navbarHome">
            <a class="flex items-center nav-link">
              <v-icon style="font-size: 18px; margin-right: 4px;">mdi-exit-to-app</v-icon>
              <span class="logout-text">Sair</span>
            </a>
          </li>

          <!-- Dropdown que aparece no desktop -->
          <div @mouseover="onOver" @mouseleave="onLeave">
          <b-dropdown
            position="is-bottom-left"
            aria-role="menu"
            ref="dropdown"
            class="minha-conta-desktop"
            :class="{'bg-medempresa': medempresa}"
            v-if="usuario">
            <li class="nav-item flex items-center" slot="trigger" :class="{'menu_active_custom': !medempresa}" >
                <a class="nav-link">Minha conta</a>
            </li>

            <b-dropdown-item custom aria-role="menuitem" v-if="usuario">
                Olá, <b>{{ usuario.nm_apelido ? usuario.nm_apelido : usuario.first_name }}</b>!
            </b-dropdown-item>
            <b-dropdown-item custom aria-role="menuitem" v-if="usuario">
                Crédito Medclub: <br/><b>{{ $root.formatPrice(saldo) }} </b>        
              </b-dropdown-item>

            <hr class="dropdown-divider">

            <!-- LOCALIZACAO --> 
            <b-dropdown-item class="text-gray-600 flex justify-between items-center custom-dropdown-item" @click="$emit('openLocalidade')" aria-role="listitem">
              <div class="flex items-center">
                <b-icon pack="jam" icon="jam-map-marker"/>
                <span>{{ cidade }}</span>
              </div>
              <b-icon pack="jam" icon="jam-refresh" class="icon-refresh" />
            </b-dropdown-item>

            <!-- MEUS AGENDAMENTOS --> 
            <router-link :to="{ name: 'editarPerfil' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-write'></b-icon>
                <span>Editar Perfil</span>
              </b-dropdown-item>
            </router-link>

            <router-link :to="{ name: 'meusAgendamentos' }" data-toggle="collapse" v-if="usuario">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-clock'></b-icon>
                <span>Minha Agenda</span>
              </b-dropdown-item>
            </router-link>
            <router-link :to="{ name: 'outrasPessoas' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-users'></b-icon>
                <span>Dependentes</span>
              </b-dropdown-item>
            </router-link>

            
            <router-link :to="{ name: 'meusCartoes' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-credit-card-f'></b-icon>
                <span>Cartões de crédito</span>
              </b-dropdown-item>
            </router-link>

            <router-link :to="{ name: 'carteiraMedclub' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
              <b-icon pack='jam' icon='jam-id-card'></b-icon>
                <span>Carteira Medclub</span>
              </b-dropdown-item>
            </router-link>
<!--
            <router-link :to="{ name: 'areaAmigoMedclub' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <v-icon class="mdi mdi-handshake mdi-20px ml-1" style="margin-right: 0.4rem !important"></v-icon>
                <span>Amigo Medclub</span>
              </b-dropdown-item>
            </router-link>
-->

            <router-link :to="{ name: 'meusCreditos' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-coin'></b-icon>
                <span>Meus créditos</span>
              </b-dropdown-item>
            </router-link>
            <router-link :to="{ name: 'duvidasFrequentes' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <v-icon class="mdi mdi-help mdi-20px ml-1" style="margin-right: 0.4rem !important"></v-icon>
                <span>Dúvidas Frequentes</span>
              </b-dropdown-item>
            </router-link>
            <router-link :to="{ name: 'termosECondicoes' }">
              <b-dropdown-item class="text-gray-600" aria-role="listitem">
                <b-icon pack='jam' icon='jam-document'></b-icon>
                <span>Termos e Condições</span>
              </b-dropdown-item>
            </router-link>

            

            <div class="border-t my-2"></div>

            <b-dropdown-item class="text-gray-600" aria-role="listitem" @click="$emit('encerrar')">
              <a class="flex items-center">
                <v-icon style="font-size: 18px; margin-right: 4px;">mdi-exit-to-app</v-icon>
                    <span class="logout-text">Sair</span>
              </a>
            </b-dropdown-item>
          </b-dropdown>
          </div>

        </ul>
      </div>
    </div>
  </nav>

  <!-- Existe um fundo branco do tamanho da navbar, já que ela é fixada no topo, para "empurrar" os outros elementos para baixo -->
  <div class="fundo-nav"/>
</div>
</template>

<style>
#dropdown-menu .dropdown-menu {
  margin:0;
  border: 0;
}

</style>
<style lang="scss" scoped>
@media (min-width: 1001px) {
  .bg-medempresa {
    background-color: #ffba01 !important;
  }
  .minha-conta-mobile {
    display: none;
  }
  .navbar-institucional {
    position: fixed;
    z-index: 1;
    width: 100%
  }

  .fundo-nav {
    width: 100%;
    height: 5rem;
  }
}

.custom-dropdown-item {
  padding-right: 0.5rem !important;
  padding-left: 1rem;
  width: 100%;
  cursor: pointer;

  @media (max-width: 768px) {
    padding: 0;
    border-width: 1px;
    border-color: #ccc;
    border-radius: 8px
  }
}

.logout-text {
  text-transform: none;
}

.icon-refresh {
  transition: transform 0.8s ease-in-out;
}

.custom-dropdown-item:hover .icon-refresh {
  transform: rotate(360deg);
}

#servicos {
  padding-top: 80px;
  margin-top: -80px;
}

#tooltip {
  display: inline-block;
  background: #ffffff;
  color: #643045;
  font-weight: bold;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 4px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -4px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -4px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -4px;
}



@media (min-width: 1280px) {
  #navbar_home .container {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  #navbar_home .container {
    max-width: 100%;
  }
}

@media (min-width: 768px) {
  #navbar_home .container {
    max-width: 100%;
  }
}

@media (min-width: 640px) {
  #navbar_home .container {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .custom-mobile-button {
    width: 150px !important;
    height: 40px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    margin-bottom: 10px;
  }

  .hidden-mobile {
    display: none;
  }
}

#menu_home .navbar>.container {
  justify-content: center;
}

#menu_home .navbar-collapse {
  flex-grow: 0;
}

@media (max-width: 1000px) {
  .minha-conta-desktop {
    display: none;
  }
}

*:not(.decorated-text) {
  text-decoration: none !important;
}

.nav-item a{
  white-space: nowrap;
  font-size: 13px;
  font-weight: bold;
}
</style>
<script>
// import { createPopper } from '@popperjs/core';

import InfoBar from "@/components/InfoBar";

export default {
  props: ['cidade', 'usuario', 'carrinho', 'saldo'],
  components: {InfoBar},
  created () {
    this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
  },
  watch: {
            windowWidth(newWidth, oldWidth) {
                this.txt = `it changed to ${newWidth} from ${oldWidth}`;
            }
        },

        mounted() {
            this.$nextTick(() => {
                window.addEventListener('resize', this.onResize);
            })
        },

        beforeDestroy() { 
            window.removeEventListener('resize', this.onResize); 
        },

  data() {
    return {
      windowWidth: window.innerWidth,
      extrato: null,
      medempresa: false,
      waiting: { saldo: false, adicionarCreditos: false },
      dialog: { localidade: false },
    }
  },
  methods: {
    scrollToSection(event, selector) {
      event.preventDefault();

      const section = document.querySelector(selector);
      if (section) {
        const headerHeight = document.querySelector('.navbar-institucional')?.offsetHeight;
        const marginTop = parseInt(getComputedStyle(section).marginTop, 10);
        const extraOffset = window.scrollY === 0 ? 30 : 0;

        const position = section.offsetTop - headerHeight - marginTop - extraOffset;

        window.scrollTo({
          top: position,
          behavior: 'smooth'
        });
      }
    },
    onOver() {
      this.$refs.dropdown.isActive = true;
    },
    onLeave() {
      this.$refs.dropdown.isActive = false;
    },
    onResize() {
                this.windowWidth = window.innerWidth
    },
    handleLocalidade() {
      this.dialog.localidade = true
    }
  },
  // mounted() {
  //   // const popcorn = document.querySelector('#popcorn');
  //   // const tooltip = document.querySelector('#tooltip');

  //   // createPopper(popcorn, tooltip, {
  //   //   placement: 'bottom',
  //   //   title: "hover",
  //   //   modifiers: [
  //   //     {
  //   //       name: 'offset',
  //   //       options: {
  //   //         offset: [0, 8],
  //   //       },
  //   //     },
  //   //   ],
  //   // });
  // }
}
</script>