<template lang="pug">
    .max-w-screen-lg.mx-auto.w-full.px-4.py-8.pt-20

        .py-32(v-if='waiting.carteiraData')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.carteiraData')

        .flex.flex-wrap.justify-center(v-else-if='carteiraData')
            .w-full.relative(class='md:w-1/2')
                //.qrcodeCarteiraWrapper
                //    img(:src="`data:image/png;base64, ${ carteiraData.qrcode }`")
                .textWrapper.p-4
                    h1.font-bold.uppercase(class='md:text-lg text-xs') {{ carteiraData.nm_pessoa_fisica }}
                    h2.text-lg(class='md:text-lg text-xs') {{ carteiraData.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') }}
                img(v-if='medempresa' src='@/assets/img/carteira-medclub/empresa.png')
                img(v-else src='@/assets/img/carteira-medclub/novo.png')
</template>

<style lang="scss" scoped>
    .qrcodeCarteiraWrapper {
        position: absolute;
        padding: 2.2%;
        width: 25%;
        top: 16%;
        right: 5.4%;
        > img {
            width: 100% !important;
            height: auto !important;
        }
    }
    .textWrapper {
        position: absolute;
        top: 25%;
        left: 0;
    }
</style>

<script>
    import { Usuario } from '../../middleware' // eslint-disable-line
    export default {
        created () {

            let usuario = this.$root.findParentProperty(this, 'usuario')
            this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            if (! usuario.length) return this.$router.push({ name: 'home' })
            this.waiting.carteiraData = true

            let imgFrente = new Image()
            imgFrente.src = require('@/assets/img/carteira-medclub/empresa.png')

            /*let imgVerso = new Image()
            imgVerso.src = require('@/assets/img/carteira-medclub/verso.png')*/

            imgFrente.onload = () => {

                    Usuario.getCarteira(usuario[0].id).then(response => {
                        this.waiting.carteiraData = false
                        if (response.status === 200) {
                            this.carteiraData = response.data
                        }
                    })
            }

        },
        data () {
            return {
                carteiraData: null,
                medempresa: false,
                waiting: { carteiraData: false }
            }
        }
    }
</script>