<template lang="pug">
    .meus-agendamentos.relative

        .py-56(v-if='waiting.agendamentos')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.agendamentos')

        div(v-else-if="agendamento")
            div(v-if="agendamento.ie_tipo_agenda === 'C'")
                SelecionarHorario
            div(v-else-if="agendamento.ie_tipo_agenda === 'L'")
                EstabelecimentoExames
            div(v-else)
                EstabelecimentoOutrosExames

        .flex.items-center.justify-center.flex-wrap(v-else)
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')

                .pt-4.pb-3.text-center.w-full
                    h1.font-bold.text-medclub-blue-300.text-sm Minha Agenda

                b-tabs.mb-6(v-model='activeTab')
                    b-tab-item(label='PENDENTES')

                        div(v-if='proximos.length')
                            .p-1.w-full(v-for="agendamento, idx in proximos" :key='idx')
                                AgendamentoCard(:agendamento='agendamento')
                            .py-2.w-full.flex.justify-center(v-if="waiting.nextPage_A")
                                b-notification.p-8.bg-white(:closable="false")
                                    b-loading(
                                        :is-full-page="false",
                                        v-model="waiting.nextPage_A"
                                    )
                        p.text-sm.text-center.text-gray-600.my-4(v-else) Você ainda não possui nenhum agendamento pendente.

                    b-tab-item(label='ANTERIORES')
                    
                        div(v-if='anteriores.length')
                            .p-1.w-full(v-for="agendamento, idx in anteriores" :key='idx')
                                AgendamentoCard(:agendamento='agendamento')
                            .py-2.w-full.flex.justify-center(v-if="waiting.nextPage_EX")
                                b-notification.p-8.bg-white(:closable="false")
                                    b-loading(
                                        :is-full-page="false",
                                        v-model="waiting.nextPage_EX")
                        p.text-sm.text-center.text-gray-600.my-4(v-else) Você ainda não possui nenhum agendamento anterior.
</template>

<style lang="scss">
    .tabs ul{
        justify-content: center !important;
    }
    .tabs {
        padding: 10px;
        margin: 10px;    
    }
</style>

<script>
    import { Agendamento } from '../../middleware'
    import AgendamentoCard from './MeusAgendamentos-card'
    import SelecionarHorario from './Etapas/HorarioConsulta'
    import EstabelecimentoExames from './Etapas/EstabelecimentoExames';
    import EstabelecimentoOutrosExames from './Etapas/EstabelecimentoData'

    export default {
        components: { AgendamentoCard, SelecionarHorario, EstabelecimentoExames, EstabelecimentoOutrosExames },
        created () {
            var medclubempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
            if (! this.$parent.$parent.usuario) this.$router.push({ name: 'home' })
            else {
                this.waiting.agendamentos = true
                Agendamento.getHistorico({ ie_associado_med_empresa: medclubempresa}).then(response => {
                    this.waiting.agendamentos = false
                    if (response.status === 200)
                        this.agendamentos = {
                            proximos: response.data.filter(a => a.ie_status !== 'EX'),
                            anteriores: response.data.filter(a => a.ie_status === 'EX')
                        }
                        this.proximos = response.data.filter(a => a.ie_status !== 'EX').slice(0, 5)
                        this.anteriores = response.data.filter(a => a.ie_status === 'EX').slice(0, 5)
                })
            }
        },
        mounted() {
        window.onscroll = () => {
            if (
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight - 200
            ) {
                if(!this.lockPage_A && !this.waiting.nextPage_A) {
                    if(this.activeTab == 0) {
                        this.waiting.nextPage_A = true;
                        this.page_A++;
                        this.marcador_A += 5;
                        setTimeout(()=> {
                            this.paginationGo('A')
                            this.waiting.nextPage_A = false;
                        }, "1000");
                    }
                }
                if(!this.lockPage_EX && !this.waiting.nextPage_EX) {
                    if(this.activeTab != 0) {
                        this.waiting.nextPage_EX = true;
                        this.page_EX++;
                        this.marcador_EX += 5;
                        setTimeout(()=> {
                            this.paginationGo('EX')
                            this.waiting.nextPage_EX = false;
                        }, "1000");
                        
                    }              
                }
            }
        };
        },
        data () {
            return {
                agendamentos: { proximos: [], anteriores: [] },
                waiting: { agendamentos: false, nextPage_A: false, nextPage_EX: false },
                proximos: [],
                anteriores: [],
                activeTab: 0,
                lockPage_A: false,
                lockPage_EX: false,
                page_A: 1,
                marcador_A: 5,
                marcador_EX: 5,
                page_EX: 1,
                // REAGENDAMENTO
                agendamento: null,
                pesquisarPor: ''
            }
        },
        methods: {
            paginationGo(status) {
                if(status === 'A') {
                    this.proximos = this.agendamentos.proximos.slice(0, (5+this.marcador_A))
                    if(this.proximos.length == this.agendamentos.proximos.length)
                        this.lockPage_A = true
                }
                if(status === 'EX') {
                    this.anteriores = this.agendamentos.anteriores.slice(0, (5+this.marcador_EX))
                    if(this.anteriores.length == this.agendamentos.anteriores.length)
                        this.lockPage_EX = true
                }
            },
            reagendar(agendamento) {
                this.pesquisarPor = "reagendamento"
                this.agendamento = agendamento

            },
            retornar(agendamento) {
                this.pesquisarPor = "retorno"
                this.agendamento = agendamento
            }
        }
    }
</script>