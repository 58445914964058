import moment from 'moment'; moment.locale('pt-br')
import configs from './middleware/configs'

export default [{
    methods: {
        moment,
        formatPrice (val) {
            return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
        },
        applyTelefoneMask (telefone) {
            if (telefone) {
                if (telefone[0] == '+')
                    telefone = telefone.substr(1)
                if (telefone.substr(0, 2) == '55')
                    telefone = telefone.substr(2)
                let regex = telefone.length == 10 ? /(\d{2})(\d{4})(\d{4})/ : /(\d{2})(\d{5})(\d{4})/
                return telefone.replace(regex, '($1) $2-$3')
            } else return ''
        },
        dataPorExtenso (date) { // YYYY-MM-DD
            date = moment(date)._d
            let weekday = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'][date.getDay()]
            let day = date.getDate()
            let month = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'][date.getMonth()]
            let year = date.getFullYear()
            return `${weekday}, ${day} de ${month} de ${year}`
        },
        textCapitalize (text) {
            return `${ text.charAt(0).toUpperCase() }${ text.slice(1) }`
        },
        getRecaptchaKeys () {
            return configs.gRecaptcha
        },
        getHelpNumber () {
            return '5586988101140'
        },
        getCirurgiasNumber () {
            return '558688571290'
        },
        setParentProperty (component, property, value) {
            let parent = component.$parent
            while (parent) {
                if (parent.hasOwnProperty(property)) parent[property] = value // eslint-disable-line
                if (parent.hasOwnProperty('$parent')) parent = parent.$parent // eslint-disable-line
            }
        },
        findParentProperty (component, property) {
            let parent = component.$parent
            let result = []
            while (parent) {
                if (parent.hasOwnProperty(property)) result.push(parent[property]) // eslint-disable-line
                if (parent.hasOwnProperty('$parent')) parent = parent.$parent // eslint-disable-line
            }
            return result
        }
    }
}]