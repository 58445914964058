<template lang="pug">
    .agendamento

        b-modal(v-model='dialog.cadastrar' :can-cancel="[ 'x' ]")
            FormCadastro(:isModal='true')

        b-modal(v-model='dialog.logar' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal)
            template(#default='props')
                Login(ref='loginComponent' :modalProps='props' :cadastro='false' :redirecionar='false')

        .flex.items-center.justify-center.flex-wrap
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')
                div
                    .flex.mx-2.mt-4.justify-between.items-center(class="md:mt-6")
                        a.text-3xl( @click='$router.go(-1)')
                            i.jam.jam-chevron-left
                        .flex.items-center(v-if='! filter.ds_descricao')
                            p.font-bold.mr-2 Não encontrou uma cirurgia? 
                            b-button.text-sm(type='is-primary' @click="redirectWhatsapp()") Solicitar

                    b-tabs#cirurgiaTabs.mt-12(v-model='activeTab')

                        b-tab-item(label='Cirurgias')

                            .p-1.relative
                                b-input(id='search-cirurgia' placeholder='Pesquisar por cirurgia' icon='magnify' v-model='filter.ds_descricao')
                                .absolute.top-0.right-0.p-2
                                    .cursor-pointer.py-1.px-2.bg-opacity-75.rounded-full.flex.items-center.bg-medclub-blue-300.text-white.t-0.r-0(v-if='filter.ds_descricao' @click='limpar()')
                                        b-icon(pack='jam' icon='jam-trash')
                                        span.text-xs Limpar

                            .py-56(v-if='waiting.cirurgias')
                                b-notification.p-8.bg-white(:closable='false')
                                    b-loading(:is-full-page='false' v-model='waiting.cirurgias')

                            .w-full.p-2(v-else-if='! list.length')
                                p.text-center.text-gray-600.text-sm.my-4 Nenhuma cirurgia encontrada.
                                .flex.flex-col.bg-white.items-center.p-4.shadow-lg.rounded-lg.max-w-screen-sm.mx-auto.my-4.text-center
                                    p.font-bold.text-sm Não encontrou o que procurava? Tente:
                                    .flex.flex-col.align-items-center.gap-x-2.gap-y-1.my-4(class='md:flex-row')
                                        b-button.text-md(type='is-primary' @click="redirectOrcamento()") Exames e Procedimentos
                                        p.font-bold.text-sm ou
                                        button.button-whatsapp-c(@click="redirectWhatsapp()") 
                                            <img  src='./../../assets/img/whatsapp.png' width="23px">
                                            <div class="linha-vertical"></div>
                                            span Entrar em Contato

                            .w-full.p-2(v-else)
                                .flex.flex-wrap.border.p-2.mb-2.shadow-md(v-for='item, idx in list' :key='idx' class='hover:bg-gray-100')
                                    
                                    div(class='md:w-3/4')
                                        .p-2.pb-0
                                            p.font-bold.text-gray-700.uppercase {{ item.ds_descricao }}
                                        .text-sm.text-gray-700
                                            p
                                                b-icon.text-lg.align-middle(pack='jam' icon='jam-building')
                                                span {{ item.cd_estabelecimento.nm_fantasia }}
                                            p
                                                b-icon.text-lg.align-middle(pack='jam' icon='jam-map-marker')
                                                span {{ item.cd_estabelecimento.nm_rua }}
                                                span , {{ item.cd_estabelecimento.nr_estabelecimento }}
                                                span , {{ item.cd_estabelecimento.nm_bairro }}

                                        .w-full.text-sm.p-2(v-if='item.ds_observacao_app')
                                            .text-medclub-blue-400.font-bold Observações
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-html='item.ds_observacao_app')
                                            
                                        .w-full.text-sm.p-2                                            
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_anestesista') <strong>Anestesista: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_anestesista.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_exames') <strong>Exames: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_exames.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_fisioterapeuta') <strong>Fisioterapeuta: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_fisioterapeuta.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_hemoderivados') <strong>Hemoderivados: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_hemoderivados.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_honorario') <strong>Hemoderivados: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_honorario.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_hospital') <strong>Hospital: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_hospital.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_material_cirurgico') <strong>Material Círurgico: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_material_cirurgico.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_perfusao') <strong>Perfusão: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_perfusao.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_repasse') <strong>Valor Repasse: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_repasse.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_taxa') <strong>Taxa: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_taxa.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id] && item.cd_cirurgia_procedimento_estabelecimento[0].ie_mostrar_opme') <strong>OPME: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_opme.replace('.', ',') }}
                                            .whitespace-pre-wrap.text-sm.text-gray-700(v-if='ver_mais[item.id]') <strong>Demais Valores: </strong> {{ item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_total_outros.replace('.', ',') }}

                                            div(v-if='parseInt(item.cd_cirurgia_procedimento_estabelecimento[0].nr_valor_total_outros) !== item.nr_valor_total')
                                                .text-medclub-blue-400.font-bold.cursor-pointer(v-if='!ver_mais[item.id]' @click='verMais(item)') Ver Mais
                                                .text-medclub-blue-400.font-bold.cursor-pointer(v-if='ver_mais[item.id]' @click='verMais(item)') Ocultar


                                    .w-full(class='md:w-1/4 md:text-right')

                                        .mt-2(class='md:hidden')

                                        .flex.flex-wrap
                                            .p-1(v-if="item.ie_tipo_preco && item.ie_tipo_preco !== 'N'" class='w-1/2 md:w-full')
                                                .rounded.border.p-1.border-medclub-blue-400.text-center
                                                    p.text-sm.text-medclub-blue-400(v-if="item.ie_tipo_preco === 'A'") A PARTIR DE
                                                    p.text-sm.text-medclub-blue-400(v-if="item.ie_tipo_preco === 'F'") VALOR
                                                    p.font-bold.text-medclub-blue-400.text-xl {{ $root.formatPrice(item.nr_valor_total) }}

                                            .p-1(class='md:w-full' :class="{ 'w-1/2': item.ie_tipo_preco && item.ie_tipo_preco !== 'N', 'w-full': ! item.ie_tipo_preco || item.ie_tipo_preco === 'N' }")
                                                .text-sm.border.rounded.p-2.border-medclub-blue-400.text-center.bg-medclub-blue-400.text-white.cursor-pointer(@click='select(item)')
                                                    div(class='md:hidden')
                                                        span SOLICITAR
                                                        br(v-if="item.ie_tipo_preco && item.ie_tipo_preco !== 'N'")
                                                        span(v-else v-html="'&nbsp;'")
                                                        span ORÇAMENTO
                                                    div.hidden(class='md:block') SOLICITAR<br/>ORÇAMENTO

                                .py-2.w-full.flex.justify-center(v-if='waiting.nextPage')
                                    b-notification.p-8.bg-white(:closable='false')
                                        b-loading(:is-full-page='false' v-model='waiting.nextPage')

                        b-tab-item(label='Meus orçamentos')

                            .w-full(v-if='usuario')

                                .py-56(v-if='waiting.orcamentos')
                                    b-notification.p-8.bg-white(:closable='false')
                                        b-loading(:is-full-page='false' v-model='waiting.orcamentos')

                                .w-full.p-2(v-else-if='! listOrcamentos.length')
                                    p.text-center.text-gray-600.text-sm.my-4 Nenhum orçamento realizado.

                                .w-full.p-2(v-else)
                                    .flex.flex-wrap.border.p-2.mb-2.shadow-md(v-for='item, idx in listOrcamentos' :key='idx' class='hover:bg-gray-100')
                                        div(class='w-8/12')
                                            p.font-bold.text-gray-700 {{ item.ds_descricao }}
                                            .text-sm.text-gray-600
                                                p
                                                    b-icon.text-lg.align-middle(pack='jam' icon='jam-building')
                                                    span {{ item.cd_estabelecimento.nm_fantasia }}
                                                p
                                                    b-icon.text-lg.align-middle(pack='jam' icon='jam-map-marker')
                                                    span {{ item.cd_estabelecimento.nm_rua }}
                                                    span , {{ item.cd_estabelecimento.nr_estabelecimento }}
                                                    span , {{ item.cd_estabelecimento.nm_bairro }}
                                                // p {{ $root.applyTelefoneMask(item.cd_estabelecimento.nr_telefone) }}
                                        .text-right.h-full(v-if="item.ie_situacao !== 'R'" class='w-4/12')
                                            p.font-bold.text-medclub-blue-400.text-xl {{ $root.formatPrice(item.nr_valor_total) }}
                                        .pt-2(class='w-8/12' v-if="options.ie_situacao.find(op => op.value === item.cd_orcamento_situacao.ds_sigla)")
                                            .inline.text-sm.p-2.rounded-full.font-bold(
                                                :style="{\
                                                    backgroundColor: `${ options.ie_situacao.find(op => op.value === item.cd_orcamento_situacao.ds_sigla).bgColor }`,\
                                                    color: `${ options.ie_situacao.find(op => op.value === item.cd_orcamento_situacao.ds_sigla).color }`\
                                                }") {{ item.cd_orcamento_situacao.ds_situacao }}
                                        .flex.justify-end(v-if="item.ie_situacao !== 'R'" class='w-4/12')
                                            b-button.rounded-full.text-sm(type='is-primary' @click='imprimir(item.id)' :loading='waiting.imprimir === item.id') Imprimir

                            .w-full(v-else)

                                .my-10.p-6.text-center
                                    h1.font-bold.text-md Cadastre-se ou entre na sua conta
                                    p.text-sm.mt-4.mx-auto(class='w-5/6') Para continuar, você precisa se cadastrar ou entrar na sua conta. Não se preocupe, o cadastro é grátis.
                                .flex.flex-wrap.justify-center.px-10
                                    b-button.w-full.rounded-full(class='md:w-1/3' type='is-primary' @click="dialog.cadastrar = true") Cadastrar grátis
                                    p.my-4.w-full.text-center ou
                                    b-button.w-full.rounded-full(class='md:w-1/3' type='is-info' @click='dialog.logar = true') Já tenho uma conta
            
</template>

<style lang="scss" scoped>
    #cirurgiaTabs > .tabs {
        &.cirurgiaTabsFixed {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 5;
            background-color: white;
            a {
                padding: 1em;
            }
            ul {
                max-width: 1024px;
                margin: 0 auto;
            }
        }
    }

    .button-whatsapp-c {
        max-width: 100%;
        padding: 9px 10px;
        background-color: #007F2D;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 10px;
        border-radius: 5px;
        .linha-vertical{
            height: 20px;
            color: white;
            margin-right: 8px;
            margin-left: 8px;
            border-left: 1px solid;
            }
        span {
            color: white;
        }
    }
</style>

<script>
    import { Agendamento } from '../../middleware'
    import FormCadastro from './../Cadastro/Form'
    import Login from './../Cadastro/Login'
    export default {
        components: { FormCadastro, Login },
        created () {
            if (this.$route.params.ds_descricao ) {
                this.filter.ds_descricao = this.$route.params.ds_descricao;
            } 
            let find = this.$root.findParentProperty(this, 'usuario')
            if (find.length) this.usuario = find[0]
            this.getCirurgias()
        },
        watch: {
            usuario: function (usuario) {
                if (usuario) {
                    this.dialog.cadastrar = false
                    this.dialog.logar = false
                    this.getOrcamentos()
                    this.$forceUpdate()
                }
            },
            'dialog.logar': function (opened) {
                if (this.$refs.loginComponent) this.$refs.loginComponent.model.reset()
                this.toggleHelpButton(opened)
            },
            'dialog.cadastrar': function (opened) {
                this.toggleHelpButton(opened)
            },
            'filter.ds_descricao': function (val) {
                if (val) {
                    if (val.length > 3) this.getCirurgias()
                } else this.getCirurgias()
            }
        },
        mounted () {
            window.onscroll = () => {
                if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 800) {
                    if (! this.waiting.nextPage && ! this.filter.ds_descricao) {
                        this.pagination.page++
                        this.getCirurgias()
                    }
                }
                let cirurgiaTabs = document.querySelector('#cirurgiaTabs > .tabs')
                if (cirurgiaTabs) {
                    let maxDistance = 80
                    if (window.scrollY - cirurgiaTabs.offsetTop > maxDistance) {
                        cirurgiaTabs.classList.add('cirurgiaTabsFixed')
                    } else {
                        cirurgiaTabs.classList.remove('cirurgiaTabsFixed')
                    }
                }
            }
        },
        data () {
            return {
                list: [],
                listOrcamentos: [],
                usuario: null,
                activeTab: 0,
                ver_mais: {},
                options: {
                    ie_situacao: [
                        { value: 'PA', bgColor: '#DAF2C2', color: '#397300' },
                        { value: 'EX', bgColor: '#00BDFF', color: '#0021B7' },
                        { value: 'EE', bgColor: '#EAE9E9', color: '#4B4B4B' },
                        { value: 'PP', bgColor: '#FFF1CC', color: '#4D3800' },
                        { value: 'CA', bgColor: '#FD6161', color: '#440000' },
                        { value: 'SU', bgColor: '#F158FE', color: '#9A0694' },
                    ]
                },
                dialog: { cadastrar: false, logar: false },
                filter: { ds_descricao: null },
                waiting: { cirurgias: false, orcamentos: false, nextPage: true, imprimir: false },
                pagination: { page: 1, lock: false }
            }
        },
        methods: {
            select (item) {
                let valor = ''
                if (item.ie_tipo_preco && item.ie_tipo_preco !== 'N') {
                    if (item.ie_tipo_preco === 'A') valor = ` (a partir de ${ this.$root.formatPrice(item.nr_valor_total) })`
                    if (item.ie_tipo_preco === 'F') valor = ` (valor ${ this.$root.formatPrice(item.nr_valor_total) })`
                }
                let textStr = `Olá! Gostaria de ter mais informações sobre a cirurgia de *${ item.ds_descricao }${ valor }*.`
                this.redirectWhatsapp(encodeURI(textStr))
            },
            verMais(item) {
                if(this.ver_mais[item.id]) {
                    this.$set(this.ver_mais, item.id, false)
                } else {
                    this.$set(this.ver_mais, item.id, true)
                }
            },
            redirectWhatsapp (text) {
                let phone = this.$root.getCirurgiasNumber()
                let url = `https://api.whatsapp.com/send?phone=${ phone }`
                if (text) url += `&text=${ text }`

                this.$buefy.dialog.confirm({
                    title: 'Atenção!',
                    message: `
                        <div class='text-center mb-4'><i class='text-6xl text-green-600 jam jam-whatsapp'></i></div>
                        <p>Você será redirecionado para falar com nosso WhatsApp de orçamentos de cirurgia.</p>`,
                    confirmText: 'Continuar',
                    cancelText: 'Cancelar',
                    onConfirm: () => window.open(url, '_blank')
                })
            },
            getCirurgias () {

                if (! this.filter.ds_descricao && this.pagination.lock) return 0
                if (this.pagination.page === 1) this.waiting.cirurgias = true
                else this.waiting.nextPage = true

                let params = { paginacao: true }

                if (this.filter.ds_descricao) {
                    params.ds_descricao = this.filter.ds_descricao
                    this.pagination.page = 1
                    this.pagination.lock = false
                }
                
                params.page = this.pagination.page
                params.cd_cidade = this.$store.state.cdCidade
                Agendamento.getCirurgias(params).then(response => {

                    // Ignore delayed result
                    if (this.filter.ds_descricao) {
                        if (this.filter.ds_descricao !== params.ds_descricao) return 0
                    } else if (params.ds_descricao) return 0
                    //----------------------

                    if (response.status === 200) {

                        if (this.pagination.page === 1) this.list = response.data.results
                        else this.list = this.list.concat(response.data.results)

                    } else if (response.status === 400) this.pagination.lock = true

                    this.waiting.cirurgias = false
                    this.waiting.nextPage = false
                })
            },
            getOrcamentos () {
                if (this.usuario) {
                    this.waiting.orcamentos = true
                    let params
                    
                    if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))

                    Agendamento.getOrcamentos(params).then(response => {
                        this.waiting.orcamentos = false
                        if (response.status === 200) this.listOrcamentos = response.data
                    })
                }
            },
            toggleHelpButton (trigger) {
                if (trigger) {
                    const helpButtonMobile = document.getElementById('helpButtonMobile')
                    if (helpButtonMobile) helpButtonMobile.classList.add('hidden')
                } else {
                    const helpButtonMobile = document.getElementById('helpButtonMobile')
                    if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')
                }
            },
            imprimir (id) {
                
                if (this.waiting.imprimir) return 0

                this.waiting.imprimir = id
                Agendamento.getOrcamentoPDF(id).then(response => {
                    this.waiting.imprimir = false
                    if (response.status === 200) {

                        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

                            const file = new Blob([ response.data ], { type: 'application/pdf; charset=utf-8' })
                            const fileURL = (window.URL || window['webkitURL']).createObjectURL(file)
                            const fileName = `CIRURGIA-ORCAMENTO-${ id }.PDF`

                            const downloadLink = document.createElement('a')
                            downloadLink.href = fileURL
                            downloadLink.download = fileName
                            document.body.appendChild(downloadLink)
                            downloadLink.click()
                            document.body.removeChild(downloadLink)
                            
                        } else {

                            let blob = new Blob([ response.data ], { type: 'application/pdf; charset=utf-8' })
                            let fileURL = URL.createObjectURL(blob)
                            window.open(fileURL)

                        }

                    }
                })
            },
            limpar() {
                this.filter.ds_descricao = null
                var searchField = document.getElementById("search-cirurgia");
                searchField.focus()
                this.getCirurgias()
            },
            redirectOrcamento() {
                let nm_procedimento = this.filter.ds_descricao
                this.$router.push({name: 'orcamento', params: { nm_procedimento }})
            }
        }
    }
</script>