<template lang="pug">
    .agendamento
        .py-56(v-if='waiting.page')
            b-notification.p-8.bg-white(:closable='false')
                b-loading(:is-full-page='false' v-model='waiting.page')
        .flex.items-center.justify-center.flex-wrap(v-else)
            .max-w-screen-lg.w-full.relative(style='min-height:75vh')
                div(v-if='! agendamentoCriado')
                    .w-full
                        SelecionarExames(v-if='! agendamento.exames')
                        SelecionarEstabelecimento(v-else)

                div(v-else)
                    SelecionarPaciente
</template>

<script>
import SelecionarExames from "./SelecionarExames";
import SelecionarEstabelecimento from "./EstabelecimentoData";
import SelecionarPaciente from "./Paciente";
import { Agendamento } from "../../../middleware";
export default {
  components: { SelecionarExames, SelecionarEstabelecimento, SelecionarPaciente },
  data() {
    return {
      agendamento: {
        ie_tipo_agenda: "EC",
        exames: null,
        estabelecimento: null,
        agenda: null,
        paciente: null,
        _date: null,
      },
      agendamentoCriado: false,
      link_url: null,
      waiting: {
        page: false,
      },
    };
  },
  created() {
    if (this.$route.params.slug) {
      this.waiting.page = true;
      let params = {
        ie_tipo_procedimento: this.agendamento.ie_tipo_agenda,
        page: 1,
        cd_cidade: this.$store.state.cdCidade,
      };

      Agendamento.getProcedimentos(params)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.length) {
              if (this.agendamento.ie_tipo_agenda === "EC") {
                if (this.$route.params.procedimento) {
                  const exame = response.data.filter((exame) => exame.id === parseInt(this.$route.params.procedimento));
                  this.agendamento.exames = exame;
                  this.agendamento._date = this.$route.params.query ?? null;
                  this.link_url = {
                    procedimento: exame[0].id,
                    slug: exame[0].ds_procedimento
                      .toLowerCase()
                      .replace(/[^a-zA-Z0-9\s]/g, "")
                      .replace(/(\s\s)|(\s)/g, "-"),
                  };
                }
              }
            }
          }
        })
        .finally(() => {
          this.waiting.page = false;
        });
    }
  },
};
</script>
