<template>
	<div id="#app">
		<MaintenanceMode v-if="maintenanceMode" />
		<router-view v-else />
	</div>
</template>


<script>

import MaintenanceMode from './components/MaintenanceMode.vue'
import configs from './middleware/configs';

export default {
	name: 'App',
	components: {
		MaintenanceMode
	},
	data() {
		return {
			maintenanceMode: false
		}
	},
	async created() {
		fetch(`${ configs.firebaseFunctions }/getStatusConfig?nm_so=site`)
			.then((response) => response.json())
			.then((data) => {
				this.maintenanceMode = data.in_maintenance
			});
	},
}

</script>


<style>
#app {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>