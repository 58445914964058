import { render, staticRenderFns } from "./ProntoSocorro.vue?vue&type=template&id=0a675a1b&scoped=true"
import script from "./ProntoSocorro.vue?vue&type=script&lang=js"
export * from "./ProntoSocorro.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a675a1b",
  null
  
)

export default component.exports