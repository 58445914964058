import GenericDAO from './../generic-dao'
var medclubempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))

const getProcedimentos = (params) => GenericDAO.find(`/appuse/procedimento/`, params)   // Utiliza filtro localidade    OK
const getEspecialistas = (params) => GenericDAO.find(`/appuse/especialista/`, params)
const getEspecialistasCompleto = (params) => GenericDAO.find(`/appuse/especialista-novo-calendario/`, params)
const getEspecialistas2 = (params) => GenericDAO.find(`/appuse/fluxo-dois/especialista/`, params)   // Utiliza filtro localidade    OK
const getEspecialistasDatas = (params) => GenericDAO.find(`/appuse/especialista/datas/`, params)
const getAreadeAtuacao = (params)=> GenericDAO.find(`/appuse/tag-area-atuacao/`, params)
const getEstabelecimentos = (params) => GenericDAO.find(`/appuse/estabelecimento/`, params)     // Retorna horários de estabelecimentos e utiliza filtro localidade
const getEstabelecimentosDisponiveis = (params) => GenericDAO.find(`/appuse/listar_estabelecimentos/`, params)     // GetEstabelecimentos que retorna os  dados de estabelecimentos disponíveis
const getEstabelecimentosDisponiveisCompleto = (params) => GenericDAO.find(`/appuse/listar_estabelecimentos_completo/`, params)     // GetEstabelecimentos que retorna os  dados de estabelecimentos disponíveis
const getEstabelecimentos2 = (params) => GenericDAO.find(`/appuse/fluxo-dois/estabelecimento/`, params) // Utiliza filtro localidade    OK
const getEstabelecimentosProcedimentos = (params) => GenericDAO.find(`/appuse/estabelecimentos/procedimentos/`, params)
const getEstabelecimentosProcedimentosCalendario = (params) => GenericDAO.find(`/appuse/estabelecimentos/procedimentos/calendario/`, params)
const getCalendario = (params) => GenericDAO.find(`/appuse/montar-calendario/`, params)
const getHistorico = (params) => GenericDAO.find(`/appuse/historico/`, params, { auth: true })
const getGuiaEletronicaPDF = (cdTransacaoFinanceira) => GenericDAO.find(`/appuse/guia-eletronica/horario-agendamento/imprimir/`, { cd_transacao_financeira: cdTransacaoFinanceira }, { auth: true })
const getTiposCheckup = (params) => GenericDAO.find(`/appuse/checkup-agendamento/`, params)   // Utiliza filtro localidade  OK
const getTipoCheckup = (id, params) => GenericDAO.find(`/appuse/checkup-agendamento/${ id }/`, params)  // Utiliza filtro localidade    OK
const getCirurgias = (params) => GenericDAO.find(`/appuse/cirurgia/`, params)   // Utiliza filtro localidade    OK
const getOrcamentos = () => GenericDAO.find(`/appuse/orcamento/meus-orcamentos/`, null, { auth: true })
const getOrcamentoPDF = (idOrcamento) => GenericDAO.find(`/appuse/orcamento/${ idOrcamento }/`, { pdf: true }, { auth: true })
const transferirHorario = (idHorarioAntigo, idHorarioNovo) => GenericDAO.save(`/appuse/agendamento/${ idHorarioAntigo }/transferir/`, { cd_horario_novo: idHorarioNovo, ie_associado_med_empresa: medclubempresa}, { method: 'PATCH' })
const marcarRetorno = (idHorarioAntigo, idHorarioNovo, telemedicina, especialista, especialidade, estabelecimento, paciente) => GenericDAO.save(`/appuse/consulta/retorno/${ idHorarioNovo }/?ie_telemedicina=${telemedicina}`, {cd_pessoa_fisica: paciente.id,
    cd_especialista: especialista.id,
    cd_especialidade: especialidade.id,
    cd_estabelecimento: estabelecimento.id,
    cd_horario_antigo:  idHorarioAntigo,
    ie_reservar: 'R'}, { method: 'PATCH' })
const getPerguntasAvaliacao = () => GenericDAO.find(`/appuse/pesquisa/pergunta/?order=nr_ordem`, null, { auth: true })
const avaliar = (body) => GenericDAO.save(`/appuse/pesquisa/resposta/`, body, { auth: true })
const validaUsuarioExame = (params) => GenericDAO.find(`/appuse/valida_acesso_dexlab/`, params)
const validaUsuarioExameShift = (params) => GenericDAO.save(`/appuse/valida_acesso_shift/`, params)

export default {
    getEspecialistas,
    getEspecialistasCompleto,
    getEspecialistas2,
    getEspecialistasDatas,
    getAreadeAtuacao,
    getProcedimentos,
    getEstabelecimentos,
    getEstabelecimentosDisponiveis,
    getEstabelecimentosDisponiveisCompleto,
    getEstabelecimentos2,
    getEstabelecimentosProcedimentos,
    getEstabelecimentosProcedimentosCalendario,
    getCalendario,
    getHistorico,
    getGuiaEletronicaPDF,
    getTiposCheckup,
    getTipoCheckup,
    getCirurgias,
    getOrcamentos,
    getOrcamentoPDF,
    transferirHorario,
    marcarRetorno,
    getPerguntasAvaliacao,
    avaliar,
    validaUsuarioExame,
    validaUsuarioExameShift
}