<template>
    <div class="h-screen">
        <b-modal v-model='dialog.app' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
            <template>
                <div class="modal-card">
                    <header class="modal-card-head flex justify-end">
                        <button class="delete" type="button" @click="dialog.app = false"></button>
                    </header>
                    <section class="modal-card-body flex flex-col items-center  rounded-b-lg">
                        <p class="text-center text-lg mb-4">Instale o aplicativo na sua loja para se cadastrar no Medclub:</p>

                        <b-button
                        class="w-8/12 sm:w-4/12 rounded-lg mb-2"
                        type="is-primary"
                        tag="a"
                        @click="openStore"
                        >
                            Abrir o App
                        </b-button>
                    </section>
                </div>
            </template>
        </b-modal>


        <div v-if="waiting" class="py-56">
            <b-notification class="p-8 bg-white" :closable="false">
                <b-loading
                    :is-full-page="true"
                    v-model="waiting"
                    :can-cancel="true"
                ></b-loading>
            </b-notification>
        </div>

        <div
            v-else
            class="
                h-full
                w-full
                mx-auto
                flex flex-col
                justify-between
                items-center
            "
        >
            <header class="mt-4 mb-2">
                <div class="w-4/12 sm:w-3/12 mx-auto">
                    <img
                        src="./../../assets/img/logo-nova.png"
                        class="mx-auto"
                    />
                </div>
                <div class="text-center pt-2 px-1 text-xs text-gray-700">
                    Melhor da medicina. Melhor preço.
                </div>
            </header>

            <section>
                <div class="w-8/12 sm:w-4/12 mx-auto mb-4">
                    <img
                        src="./../../assets/img/amigo-medclub.png"
                        class="mx-auto"
                    />
                </div>
                <h1
                    class="
                        w-8/12
                        sm:w-4/12
                        mx-auto
                        font-bold
                        text-blue-700 text-center
                        mb-3
                        text-lg
                    "
                >
                    Você foi convidado por {{ name }}
                </h1>
                <p
                    class="
                        w-8/12
                        sm:w-4/12
                        mx-auto
                        font-medium
                        text-sm text-justify
                        mb-4
                    "
                >
                    Clique no botão abaixo para copiar o código e insira ele ao se cadastrar no site ou aplicativo do Medclub.
                </p>
                <div
                    class="
                        mx-auto
                        w-8/12
                        sm:w-4/12
                        bg-gray-200
                        rounded-lg
                        mb-3
                        flex
                        justify-between
                        box-convite
                        cursor-pointer
                    "
                    v-on:click="copyCode"
                >
                    <button
                        class="p-3 bg-blue-500 rounded-l-lg copy-button"                 
                    >
                        <img
                            class="w-6 mr-1 inline"
                            src="./../../assets/img/amigo-medclub/copy.png"
                        />
                    </button>
                    <div class="p-2">
                        <div class="text-center">Código de Convite:</div>
                        <div class="text-center">
                            <strong>{{ dsCode }}</strong>
                        </div>
                    </div>
                    <div class="invisible">.</div>
                </div>

                <footer id="desktop-footer" class="flex justify-center">
                    <b-button
                        class="w-8/12 sm:w-4/12 rounded-lg"
                        type="is-primary"
                        tag="router-link"
                        :to="`/cadastrar/${dsCode}`"
                    >
                        Continuar
                    </b-button>
                </footer>

                <footer id="mobile-footer" class="flex-col items-center">
                    <b-button
                        class="w-8/12 sm:w-4/12 rounded-lg mb-2"
                        type="is-primary"
                        tag="a"
                        @click="deepLink"
                    >
                    <span style="color: white !important;">
                        Abrir o App
                    </span>
                    </b-button>
                    <div class="mb-2">
                        <router-link
                            :to="`/cadastrar/${dsCode}`"
                            class="text-sm"
                            >Continuar no site</router-link
                        >
                    </div>

					<div class="flex flex-col justify-center items-center">
						<div v-for="button in storeButtons" :key="button.label" class="w-40 m-1 p-3 border rounded-full border-gray-700 cursor-pointer">
							<a :href="button.link" target='_blank' class="flex justify-center">
								<i :class="'text-4xl ' + button.icon"></i>

								<div>
									<p class="text-xs md:text-sm">
										Disponível na
									</p>
									<p class="font-bold text-sm md:text-base">
										{{ button.label }}
									</p>
								</div>
							</a>
						</div>
					</div>
                </footer>
            </section>
            <div class="invisible">.</div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
//Quando estiver no mobile irá aparecer o botão de ir pro App e o link de continuar no site
//Caso esteja no desktop irá aparecer apenas a opção de ir pro site

#desktop-footer {
    @media all and (max-width: 768px) {
        display: none;
    }
}

#mobile-footer {
    display: none;

    @media all and (max-width: 768px) {
        display: flex;
    }
}

.copy-button {
    &:active {
        outline: none;
    }

    &:focus {
        outline: none;
    }
}

.box-convite:active {
    filter: brightness(0.8);

}
</style>

<script>
import { Usuario } from "./../../middleware";

export default {
    name: "Convite",
    data() {
        return {
            imgUrl: "./img/boleto-icon.png",
            dsCode: "",
            name: "",
            waiting: true,
			storeButtons: [
				{ icon: 'jam jam-apple', label: 'App Store', link: 'https://apps.apple.com/br/app/medclub/id1508392465' },
				{ icon: 'jam jam-google-play', label: 'Google Play', link: 'https://play.google.com/store/apps/details?id=com.medclub' }
			],
            dialog: {
                app: false
            }
        };
    },
    methods: {
        copyCode() {
            //Insere o código na área de transferência
            const el = document.createElement("textarea");
            el.value = this.dsCode;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);

            //Exibe o toast de código copiado
            this.$buefy.toast.open({
                type: "is-success",
                position: "is-bottom",
                message: "Código copiado<br/>para a área de transferência",
            });
        },
		deepLink() {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) ) {
				window.location.href = `medclub://invite/${this.dsCode}`;
			} else if( userAgent.match( /Android/i ) ) {
				window.location.href = `intent://convite/${this.dsCode}/#Intent;scheme=medclub;package=com.medclub;end`;
			}
			
            setTimeout(() => {
                this.dialog.app = true
            }, 2000)
		},
        openStore() {
            var userAgent = navigator.userAgent || navigator.vendor || window.opera;
			if( userAgent.match( /iPad/i ) || userAgent.match( /iPhone/i ) || userAgent.match( /iPod/i ) ) {
				window.location.href = 'https://apps.apple.com/br/app/medclub/id1508392465';
			} else if( userAgent.match( /Android/i ) ) {
				window.location.href = 'https://play.google.com/store/apps/details?id=com.medclub';
			}

            this.dialog.app = true
        }
    },
    created() {
        this.dsCode = this.$route.params.code;
        Usuario.getDadosIndicador(this.dsCode).then((response) => {
            this.name = response.data.nm_pessoa_fisica;
            this.waiting = false;
        });
    },
};
</script>