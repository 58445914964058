<template>
    <div v-if="showInfo" class="flex flex-wrap justify-center bg-bar  h-10" :class="{'bg-bar-medempresa': medempresa}">
        <div class="flex flex-wrap w-auto  ">
            <div class="flex flex-row ">
                <div class="flex flex-wrap  content-center">
                    <b-button
                        class="bg-button border-0  bg-button"
                        :class="{'text-empresa': medempresa, 'text-white': !medempresa}"
                        icon-pack="jam"
                        icon-left="jam-viber"
                    >
                        (86) 3131-1881
                    </b-button>
                </div>
                <div class="flex flex-wrap pl-3 content-center desktop">
                    <b-button
                        class="bg-button border-0 bg-button"
                        :class="{'text-empresa': medempresa, 'text-white': !medempresa}"
                        icon-pack="jam"
                        icon-left="jam-envelope-f"
                    >
                        contato@medclub.com.br
                    </b-button>

                </div>
            </div>
            <div v-if="usuario" class="flex flex-wrap content-center ">
                <b-button
                    class="bg-button bg-button-agenda border-0 "
                    :class="{'text-empresa': medempresa, 'text-white': !medempresa}"
                    icon-pack="jam"
                    tag="router-link"
                    :to="{ path:'/meus-agendamentos' }"
                    icon-left="jam-clock-f"
                    >
                    Agenda
                </b-button>
            </div>
            <div v-if="!usuario" class="flex flex-wrap content-center ">
                <b-button
                    class="bg-button-agenda mr-2 border-0 "
                    :class="{'text-empresa': medempresa, 'text-white': !medempresa}"
                    icon-pack="jam"
                    icon-left="jam-clock-f"
                    @click="$emit('openLogin')"
                >
                    Agenda
                </b-button>
            </div>
            <div class="flex flex-wrap content-center">
                <b-button
                    class="bg-button-resultados text-white border-0 "
                    tag="router-link"
                    icon-pack="jam"
                    icon-left="jam-task-list"
                    :to="{ path:'/agendar/resultados' }"
                    >
                    Resultados
                </b-button>
            </div>
            <div class="flex flex-row medium pl-6">
                <ul class="footer_redes_sociais">
                    <li class="flex flex-wrap content-center">
                        <a href="https://www.facebook.com/medclubApp"><span class="jam jam-facebook" :class="{'text-empresa': medempresa, 'text-white': !medempresa}"></span></a>
                    </li>
                    <li class="flex flex-wrap content-center">
                        <a href="https://www.instagram.com/medclub.app/"><span class="jam jam-instagram" :class="{'text-empresa': medempresa, 'text-white': !medempresa}"></span></a>
                    </li>
                    <li class="flex flex-wrap content-center">
                        <a href="https://api.whatsapp.com/send?phone=558631311881"><span class="jam jam-whatsapp" :class="{'text-empresa': medempresa, 'text-white': !medempresa}"></span></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "InfoBar",
    props: ['usuario'],
    data() {
        return {
            medempresa: false,
            showInfo: true
        }
    },
    created () {
         this.medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll () {
            if (window.scrollY > 0) {
                setTimeout(() => {
                this.showInfo = false;
            }, 60); 
            }else {
                setTimeout(() => {
                this.showInfo = true;
            }, 70); 
            }
        }
    }
}
</script>

<style scoped>
.bg-button {
    background-color: transparent;
}
.bg-button-resultados {
    background-color: #0ca948;
    height: 30px;
}
.bg-button-agenda {
    background-color: #372c9a;
    height: 30px;
    color: white
}
.bg-bar {
    background-color: #0e5fd6;
}
.text-empresa {
    color:#0e5fd6 !important;
    font-weight: bold;
}
.bg-bar-medempresa {
    color: #0e5fd6;
    background-color: #ffba01;
}
@media only screen and (max-width: 820px) {
    .desktop {
        display: none;
    }
}
@media only screen and (max-width: 1322px) {
    .medium {
        display: none;
    }
}
@media only screen and (max-width: 1028px) {
    .smallScreen{
        display: none;
    }
}
</style>