<template lang="pug">

    .p-4.shadow-md.rounded.border
        //- Modal da Avaliacao
        FormularioAvaliacao(v-if="dialog.avaliacao" @close="closeAvaliacaoDialog" :horario="agendamento")

        //- Modal do termo do Pronto Socorro
        b-modal(v-model='dialog.termo' has-modal-card trap-focus :destroy-on-hide='false' aria-role="dialog" aria-modal)
            template(#default='props')
                .modal-card.p-4(class='md:p-0')
                    header.modal-card-head
                        p.modal-card-title.text-xl.font-bold Termos e Condições
                        button.delete(type='button' @click='props.close()')
                    section.modal-card-body
                        .text-gray-700.whitespace-pre-wrap(v-html='agendamento.cd_agenda.ds_termo_pronto_socorro')
                    footer.modal-card-foot.flex.flex-wrap.justify-center.p-4
                        b-button.text-sm.rounded-full.p-4(type='is-primary' @click="props.close()") Sair
            //- Modal do termo do Pronto Socorro

        b-modal(v-model='dialog.retorno' has-modal-card trap-focus :destroy-on-hide='false' aria-role="dialog" aria-modal)
            template(#default='props')
                .modal-card.p-4(class='md:p-0')
                    header.modal-card-head
                        p.modal-card-title.text-xl.font-bold Como gostaria de realizar seu retorno?
                        button.delete(type='button' @click='props.close()')
                    section.modal-card-body
                        .flex.flex-wrap.justify-center.align-center
                            .w-full.text-center.mb-4
                                img.mx-auto.mb-4(style='width:200px' src='./../../assets/img/graphics/videocall.png')
                        .text-gray-700.whitespace-pre-wrap.text-xl.text-center.font-bold {{agendamento.cd_agenda.cd_especialista.ie_sexo == 'M' ? "O Dr. " : "A Dra. "}} {{ agendamento.cd_agenda.cd_especialista.nm_especialista}}
                        .text-gray-700.whitespace-pre-wrap.text-xl.text-center também atende por vídeo. Experimente realizar seu retorno sem sair de casa.
                        .flex.justify-center.mt-2.w-full
                            b-button.text-sm.font-bold(class="w-full sm:w-1/2" type='is-info' @click='criarRetorno(agendamento, true)') Retorno por Vídeo
                        .flex.justify-center.mt-2.w-full
                            b-button.text-sm.font-bold(class="w-full sm:w-1/2" type='is-danger' @click='criarRetorno(agendamento, false)') Retorno Presencial


        b-modal(v-model='dialog.anexos' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal)
            template(#default='props')
                .modal-card.p-4(class='md:p-0')

                    header.modal-card-head
                        p.modal-card-title Anexos
                        button.delete(type='button' @click='props.close()')

                    section.modal-card-body.rounded-b-lg

                        .py-10(v-if='waiting.anexos')
                            b-notification.p-8.bg-white(:closable='false')
                                b-loading(:is-full-page='false' v-model='waiting.anexos')

                        div(v-else-if='anexos.length')
                            h1.p-2.pt-0 Anexos da consulta em <b>{{ agendamento.cd_especialidade.nm_especialidade }}</b> com <b>Dr(a) {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}</b>.
                            div
                                b-button.text-sm.m-2(v-for='anexo, idx in anexos' :key='idx' type='is-primary' @click='openAnexo(anexo)') {{ idx + 1 }}) {{ anexo.label }}

                        .text-center(v-else)
                            p.text-gray-600 Você não possui nenhum anexo para essa consulta.

        .mb-4
            //- p.text-xs.text-gray-600.mt-2 LOCAL DE ATENDIMENTO
            .flex.mt-1
                .w-10.h-10.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat(
                    :style="`background-image: url('${ agendamento.cd_estabelecimento.aq_foto }')`")
                .ml-2(class='w-5/6')
                    p.text-sm.text-medclub-blue-400.font-semibold {{ agendamento.cd_estabelecimento.nm_fantasia }}
                    p.text-sm.text-gray-600
                        span {{ agendamento.cd_estabelecimento.nm_rua }}
                        span , {{ agendamento.cd_estabelecimento.nr_estabelecimento }}
                        span &nbsp;- {{ agendamento.cd_estabelecimento.nm_bairro }}<br/>
                        span {{ agendamento.cd_estabelecimento.ds_complemento }}<br/>
                        span {{ $root.applyTelefoneMask(agendamento.cd_estabelecimento.nr_telefone) }}
        div
            .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') PACIENTE
            p.text-gray-600.minha-conta-desktop PACIENTE
            p.text-sm.text-medclub-blue-400.font-semibold {{ agendamento.cd_paciente.nm_pessoa_fisica }}

        .flex
            div(class='w-1/2 md:w-1/3' v-if="!medclubempresa")
                p.text-xs.text-gray-600.mt-4.minha-conta-desktop SENHA
                .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') SENHA
                .flex.items-center.text-sm.text-medclub-blue-400.font-semibold
                    p.text-red-600 {{ agendamento.ds_rash }}
            div(class='w-1/2 md:w-1/3')
                p.text-xs.text-gray-600.mt-4.minha-conta-desktop GUIA
                .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') GUIA
                .flex.items-center.text-sm.text-medclub-blue-400.font-semibold
                    p.text-red-600(v-if='agendamento.guiaeletronica_set[0]') {{agendamento.guiaeletronica_set[0].cd_transacao_financeira_med_empresa ? agendamento.guiaeletronica_set[0].cd_transacao_financeira_med_empresa : agendamento.guiaeletronica_set[0].cd_transacao_financeira}}
                    p(v-else style='color: rgb(255, 186, 1)')  PROCESSANDO
            

        .flex.justify-center.mt-2(v-if="agendamento.ie_status === 'EX' && agendamento.cd_agenda.ie_telemedicina")
            b-button.bg-turquoise.text-sm.max-w-md(type='is-primary' expanded @click='showAnexosDialog()') Receita / Pedido de Exame / Atestado

        .flex.justify-center.mt-2(v-if="agendamento.ie_status === 'EX' && agendamento.ie_avaliou === false")
            b-button.bg-green.text-sm.max-w-md.upperCaseButton(type='is-primary' expanded @click='showAvaliacaoDialog()') Avaliar Atendimento

        div(v-if="agendamento.cd_agenda.ie_tipo_agenda === 'C' && agendamento.cd_agenda.cd_especialista")
            p.text-xs.text-gray-600.mt-4.minha-conta-desktop ESPECIALISTA
            .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') ESPECIALISTA
            .flex.mt-1
                .w-10.h-10.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat(
                    :style="`background-image: url('${ agendamento.cd_agenda.cd_especialista.aq_foto }')`")
                .ml-2(class='w-5/6')
                    p.text-sm.text-medclub-blue-400.font-semibold {{ agendamento.cd_agenda.cd_especialista && agendamento.cd_agenda.cd_especialista.ie_sexo == 'M' ? 'Dr.' : 'Dra.'}} {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}
                    p.text-sm.text-gray-600
                        span {{ agendamento.cd_agenda.cd_especialista.nm_conselho }} &nbsp
                        span {{ agendamento.cd_agenda.cd_especialista.ds_uf_conselho}} -&nbsp
                        span {{ agendamento.cd_agenda.cd_especialista.nr_conselho }}&nbsp;
                        span / {{ agendamento.cd_especialidade.nm_especialidade }}

        div(v-else)
            p.text-xs.text-gray-600.mt-4.minha-conta-desktop PROCEDIMENTO
            .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') PROCEDIMENTO
            p.text-sm.text-medclub-blue-400.font-semibold {{ `${agendamento.cd_procedimento.ds_procedimento ? agendamento.cd_procedimento.ds_procedimento : agendamento.cd_procedimento.nm_procedimento} ${agendamento.nm_subgrupo ? `(${agendamento.nm_subgrupo})` : ''}`.toUpperCase() }}
        div(v-if="agendamento.cd_agenda.ie_tipo_agenda !== 'C' && agendamento.cd_agenda.cd_especialista")
            p.text-xs.text-gray-600.mt-4 ESPECIALISTA
            .flex.mt-1
                .w-10.h-10.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat(
                    :style="`background-image: url('${ agendamento.cd_agenda.cd_especialista.aq_foto }')`")
                .ml-2(class='w-5/6')
                    p.text-sm.text-medclub-blue-400.font-semibold {{ agendamento.cd_agenda.cd_especialista && agendamento.cd_agenda.cd_especialista.ie_sexo == 'M' ? 'Dr.' : 'Dra.'}} {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}
                    p.text-sm.text-gray-600
                        span {{ agendamento.cd_agenda.cd_especialista.nm_conselho }} &nbsp
                        span {{ agendamento.cd_agenda.cd_especialista.ds_uf_conselho}} -&nbsp
                        span {{ agendamento.cd_agenda.cd_especialista.nr_conselho }}&nbsp;
                        span(v-if='agendamento.cd_especialidade.nm_especialidade !== null')/ {{ agendamento.cd_especialidade.nm_especialidade }}
                        .flex.items-center(v-if="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao")
                            star-rating(
                                :rating="agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao"
                                :read-only="true"
                                :show-rating="false"
                                :round-start-rating="false"
                                :star-size="20")
                            .text-sm.align-center ({{ agendamento.cd_agenda.cd_especialista.avaliacao.nr_avaliacao }})
        div.flex

            div(class='w-1/2 md:w-1/3' v-if='! agendamento.cd_agenda.ie_agenda_presencial')
                p.text-xs.text-gray-600.mt-4.minha-conta-desktop DATA
                .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') DATA
                .flex.items-center.text-sm.text-medclub-blue-400.font-semibold
                    b-icon(pack='jam' icon='jam-calendar')
                    p.text-medclub-blue-400 {{ $root.moment(agendamento.dt_agenda, 'YYYY-MM-DD', true).format('DD [de] MMM') }}
            .hidden(class='md:block w-1/3')
                p.text-xs.text-gray-600.mt-4.minha-conta-desktop PREÇO
                .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') PREÇO
                .flex.items-center.text-sm.text-medclub-blue-400.font-semibold
                    v-icon(class="mdi mdi-wallet mdi-24px ml-1  text-green-600")
                    p.text-green-600 {{ agendamento.ie_retorno && agendamento.ie_status == 'EX'? $root.formatPrice(0) : $root.formatPrice(agendamento.nr_valor) }}

            div(class='md:hidden')
                p.text-xs.text-gray-600.mt-4.minha-conta-desktop PREÇO
                .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') PREÇO
                .flex.items-center.text-sm.text-medclub-blue-400.font-semibold
                    v-icon(class="mdi mdi-wallet mdi-20px ml-1 text-green-600" )
                    p.text-md.font-semibold.text-green-600 {{ $root.formatPrice(agendamento.nr_valor) }}
                div(v-if="agendamento.cd_especialidade.id === 57")
        div.flex
            div(v-if='! agendamento.cd_agenda.ie_agenda_presencial && ! agendamento.cd_agenda.ie_hora_marcada && Object.values(agendamento.cd_dia_atendimento).length' class='w-full md:w-1/3')
                p.text-gray-600.mt-4.minha-conta-desktop POR ORDEM DE CHEGADA
                .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') PREÇO POR ORDEM DE CHEGADA
                .flex.items-center.text-sm.text-medclub-blue-400.font-semibold
                    b-icon(pack='jam' icon='jam-clock' style='color: red')
                    p.text-red-600(v-if='agendamento.cd_dia_atendimento')
                        span(v-if='agendamento.cd_dia_atendimento.hr_inicio') A partir de {{ agendamento.cd_dia_atendimento.hr_inicio.substr(0,5) }}
                    p(v-else) -
            div(v-else-if='agendamento.cd_agenda.ie_hora_marcada' class='w-1/2 md:w-1/3')
                p.text-xs.text-gray-600.mt-4.minha-conta-desktop HORÁRIO
                .text-gray-600.minha-conta-mobile.text-conta-mobile(style='font-size: 10px; font-weight: 500') HORÁRIO
                .flex.items-center.text-sm.text-medclub-blue-400.font-semibold
                    b-icon(pack='jam' icon='jam-clock')
                    p.text-red-600(v-if='agendamento.hr_agenda') {{ agendamento.hr_agenda.substr(0,5) }}
                    p.text-red-60(v-else) -


           

        
        .flex.justify-center.w-full.flex-wrap.mt-4(v-if="agendamento.ie_status === 'EX' && agendamento.cd_agenda.ie_tipo_agenda === 'C'")
            .mt-2.mb-4.text-xs.bg-yellow-200.p-2.border.border-yellow-300.w-full(v-if='agendamento.ie_retorno && agendamento.cd_agenda.ie_agenda_presencial') <b>Atenção!</b> É necessário agendar seu retorno com a clínica.
            .p-1(v-if='agendamento.ie_retorno && !agendamento.cd_agenda.ie_agenda_presencial' class='w-full md:w-auto')
                b-button.text-sm.w-full.upperCaseButton(v-if="agendamento.ie_retornar_por_video == true" icon-pack='jam' icon-left='jam-refresh' type='is-primary' 
                @click="mostrarModalRetorno") Agendar Retorno
                b-button.text-sm.w-full.upperCaseButton(v-if="agendamento.ie_retornar_por_video == false" icon-pack='jam' icon-left='jam-refresh' type='is-primary' 
                @click="criarRetorno(agendamento, agendamento.cd_agenda.ie_telemedicina)") Agendar Retorno
               
        .flex.justify-center.w-full.flex-wrap.mt-4(v-if="agendamento.ie_status === 'EX' && ['E', 'L'].includes(agendamento.cd_agenda.ie_tipo_agenda)")
            b-button.text-sm.w-full.upperCaseButton(type='is-danger' 
                @click="$router.push({ name: 'resultadoExames'})") RESULTADOS - EXAMES
        .flex.justify-center.w-full.flex-wrap.mt-4(v-if="agendamento.ie_status === 'A'")
            .p-1(class='w-full md:w-auto' v-if="agendamento.cd_especialidade.id === 57")
                b-button.text-sm.w-full.upperCaseButton(type='is-primary' icon-pack='jam' icon-left='jam-document'  style="background: #164461" @click='mostrarModalTermo') Ver termos e condições
            .p-1(class='w-full md:w-auto')
                b-button.text-sm.w-full.upperCaseButton(v-if="['C', 'E', 'L'].includes(agendamento.cd_agenda.ie_tipo_agenda) && !agendamento.ie_consulta_retorno" icon-pack='jam' icon-left='jam-refresh' type='is-primary' 
                    :loading='waiting.guia' @click='criarReagendamento(agendamento)') Reagendar
            .p-1(v-if="agendamento.cd_agenda.ds_observacao && agendamento.cd_agenda.ds_observacao !== 'AGENDA_ABERTA'" class='w-full md:w-auto')
                b-button.text-sm.w-full.upperCaseButton(icon-pack='jam' icon-left='jam-document' type='is-primary' style='background-color:#1aa6a5 !important'
                    @click="alert('Observações', agendamento.cd_procedimento.nm_procedimento, agendamento.cd_agenda.ds_observacao)" ) Ver observações
            .p-1(v-if='agendamento.ds_preparo' class='w-full md:w-auto')
                b-button.text-sm.w-full.upperCaseButton(icon-pack='jam' icon-left='jam-document' type='is-primary'
                    @click="alert('Preparo', agendamento.cd_procedimento.nm_procedimento, agendamento.ds_preparo)" ) Ver preparo do exame
            .p-1(v-if="agendamento.cd_agenda.ie_tipo_agenda === 'C' && agendamento.cd_agenda.ie_telemedicina" class='w-full md:w-auto')
                b-button.text-sm.w-full.upperCaseButton(type='is-primary' icon-pack='jam' icon-left='jam-video-camera-vintage' 
                    @click='startConsultaPorVideo(agendamento)') Entrar na sala de espera
            .p-1(class='w-full md:w-auto')
                b-button.text-sm.w-full.upperCaseButton(icon-pack='jam' icon-left='jam-printer' type='is-info'  v-if='agendamento.guiaeletronica_set[0]'
                    :loading='waiting.guia' @click='getGuiaEletronicaPDF(agendamento.guiaeletronica_set[0] ? agendamento.guiaeletronica_set[0].cd_transacao_financeira : null)') Ver guia


</template>


<style lang="scss" scoped>
    .upperCaseButton {
        text-transform: uppercase;
        font-weight: bold;
    }
    @media (min-width: 768px) {
        .minha-conta-mobile {
            display: none;
        }
        .text-conta-mobile {
            font-size: 9px;
        }
    }
    @media (max-width: 768px) {
        .minha-conta-desktop {
            display: none;
        }
    }
    
</style>

<script>
    import { Agendamento, ConsultaPorVideo } from '../../middleware';
    import FormularioAvaliacao from './../Avaliacao/FormularioAvaliacao';
    import StarRating from "vue-star-rating";
    export default {
        components: { FormularioAvaliacao, StarRating },
        props: [ 'agendamento' ],
        data () {
            return {
                anexos: [],
                medclubempresa: false,
                dialog: { anexos: false, termo: false, avaliacao: false, retorno: false },
                waiting: { guia: false, anexos: false }
            }
        },
        created () {
            this.medclubempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
        },
        methods: {
            mostrarModalTermo() {
                this.dialog.termo = true
            },
            mostrarModalRetorno() {
                this.dialog.retorno = true
            },
            getGuiaEletronicaPDF (cdTransacaoFinanceira) {
                this.waiting.guia = true
                Agendamento.getGuiaEletronicaPDF(cdTransacaoFinanceira).then(response => {
                    this.waiting.guia = false
                    if (response.status === 200) {

                        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {

                            const file = new Blob([ response.data ], { type: 'application/pdf; charset=utf-8' })
                            const fileURL = (window.URL || window['webkitURL']).createObjectURL(file)
                            const fileName = `GUIA-${ cdTransacaoFinanceira }.PDF`

                            const downloadLink = document.createElement('a')
                            downloadLink.href = fileURL
                            downloadLink.download = fileName
                            document.body.appendChild(downloadLink)
                            downloadLink.click()
                            document.body.removeChild(downloadLink)

                        } else {

                            let blob = new Blob([ response.data ], { type: 'application/pdf; charset=utf-8' })
                            let fileURL = URL.createObjectURL(blob)
                            window.open(fileURL)

                        }

                    }
                })
            },
            alert (title, subtitle, text) {

                const helpButtonMobile = document.getElementById('helpButtonMobile')
                if (helpButtonMobile) helpButtonMobile.classList.add('hidden')

                this.$buefy.dialog.alert({
                    title,
                    message: `<div class='mb-4'><p class='font-bold text-lg'>${ subtitle }</p></div><div class='whitespace-pre-wrap text-sm'>${ text }</div>`,
                    confirmText: 'Entendi',
                    onConfirm: () => {
                        const helpButtonMobile = document.getElementById('helpButtonMobile')
                        if (helpButtonMobile) helpButtonMobile.classList.remove('hidden')
                    }
                })
            },
            showAnexosDialog () {
                this.dialog.anexos = true
                this.anexos = []
                this.waiting.anexos = true
                ConsultaPorVideo.getAnexos(this.agendamento.guiaeletronica_set[0].id, this.agendamento.cd_paciente.id).then(response => {
                    this.waiting.anexos = false
                    if (response.status === 200) {
                        this.anexos = []
                        this.anexos = response.data.filter(a => ['RE', 'AT', 'PE', 'TA', 'TC'].includes(a.ie_tipo_anexo))
                        this.anexos.forEach(anexo => {
                            if (anexo.ie_tipo_anexo === 'RE') anexo.label = 'RECEITA'
                            if (anexo.ie_tipo_anexo === 'AT') anexo.label = 'ATESTADO'
                            if (anexo.ie_tipo_anexo === 'PE') anexo.label = 'PEDIDO DE EXAME'
                            if (anexo.ie_tipo_anexo === 'TA') anexo.label = 'TERMO DE AVALIAÇÁO ANESTÉSICA'
                            if (anexo.ie_tipo_anexo === 'TC') anexo.label = 'TERMO DE CONSENTIMENTO PRÉ-ANESTÉSICA'
                        })
                    }
                })
            },
            showAvaliacaoDialog () {
                this.dialog.avaliacao = true;
            },
            closeAvaliacaoDialog () {
                this.dialog.avaliacao = false;
                this.agendamento.ie_avaliou = true;
            },
            openAnexo (anexo) {
                window.open(anexo.aq_anexo)
            },
            startConsultaPorVideo () {
                this.$store.commit('iniciarConsultaPorVideo', this.agendamento)
                this.$router.push({ name: 'consultaPorVideo' })
            },
            criarReagendamento (agendamento) {
                const reagendamento = {
                    agenda: null,
                    especialidade: agendamento.cd_agenda.ie_tipo_agenda === 'C' ? agendamento.cd_especialidade : null,    // verificar
                    especialista: agendamento.cd_agenda.cd_especialista,
                    estabelecimento: agendamento.cd_estabelecimento,
                    horario: null,
                    dt_agenda: agendamento.dt_agenda,
                    guiaeletronica_set: agendamento.guiaeletronica_set.length ? agendamento.guiaeletronica_set[0] : null,
                    ie_hora_marcada: agendamento.ie_hora_marcada,
                    ie_telemedicina: agendamento.cd_agenda.ie_telemedicina,
                    ie_tipo_agenda: agendamento.cd_agenda.ie_tipo_agenda,
                    cd_horario_agendamento: agendamento.id,
                    paciente: null
                }

                if(['L', 'E'].includes(agendamento.cd_agenda.ie_tipo_agenda)) {
                    reagendamento.cd_procedimento = agendamento.cd_procedimento.id
                }

                //if(agendamento.cd_agenda.ie_tipo_agenda)

                let find = this.$root.findParentProperty(this, 'reagendar')
                if (find.length) {

                    let reagendar = find[0]
                    reagendar(reagendamento)
                }
            },
            criarRetorno (agendamento, ie_telemedicina_) {
                const retorno = {
                    especialidade: agendamento.cd_agenda.ie_tipo_agenda === 'C' ? agendamento.cd_especialidade : null,    // verificar
                    especialista: agendamento.cd_agenda.cd_especialista,
                    estabelecimento: agendamento.cd_estabelecimento,
                    horario: null,
                    dt_agenda: agendamento.dt_agenda,
                    guiaeletronica_set: agendamento.guiaeletronica_set.length ? agendamento.guiaeletronica_set[0] : null,
                    ie_hora_marcada: agendamento.cd_agenda.ie_hora_marcada,
                    ie_telemedicina: agendamento.ie_retornar_por_video ? ie_telemedicina_ : agendamento.cd_agenda.ie_telemedicina,
                    ie_tipo_agenda: agendamento.cd_agenda.ie_tipo_agenda,
                    cd_horario_agendamento: agendamento.id,
                    paciente: agendamento.cd_paciente,
                    retonar_por_video: agendamento.ie_retornar_por_video
                }

                let find = this.$root.findParentProperty(this, 'retornar')
                if (find.length) {
                    let retornar = find[0]
                    retornar(retorno)
                }
            }
        }
    }
</script>