<template lang="pug">
    form(@submit.prevent='handleSubmit()')
        .modal-card.max-w-sm.p-4(class='md:p-0')
            header.modal-card-head
                p.modal-card-title Relatar problema
                button.delete(type='button' @click='modalProps.close()')
            section.modal-card-body

                b-field(label='Problema:' :message="model_message().cd_motivo_erro" :type="{ 'is-danger': model.submitted && $v.model.cd_motivo_erro.$error }")
                    b-select(placeholder='Selecione um motivo' v-model='$v.model.cd_motivo_erro.$model' :loading='waiting.motivos' :disabled='waiting.motivos' rounded expanded)
                        option(v-for='option in options.motivos' :value='option.id' :key='option.id') {{ option.ds_motivo }}

                b-field(label='Descrição:' :message="model_message().ds_observacao" :type="{ 'is-danger': model.submitted && $v.model.ds_observacao.$error }")
                    b-input(maxlength="200" type="textarea" placeholder='Descreva seu problema' v-model='$v.model.ds_observacao.$model')

            footer.modal-card-foot
                .text-center.w-full
                    b-button(class='w-2/3' type='is-primary' native-type='submit' rounded :loading='waiting.enviar') Enviar
</template>

<script>
    import { required } from 'vuelidate/lib/validators'
    import { Utils } from '../../middleware'
    export default {
        props: [ 'modalProps' ],
        created () {
            this.model.cd_horario_agendamento = this.$store.state.consultaPorVideo.id
            this.waiting.motivos = true
            Utils.getMotivoErro().then(response => {
                this.waiting.motivos = false
                if (response.status === 200) this.options.motivos = response.data
            })
        },
        data () {

            const model = {
                cd_horario_agendamento: null,
                cd_motivo_erro: null,
                ds_observacao: null,
                submitted: false,
                reset () {
                    Object.keys(this).forEach(key => {
                        if (!['reset'].includes(key)) this[key] = null
                        this.submitted = false
                    })
                }
            }
            
            return {
                model,
                options: { motivos: [] },
                waiting: { enviar: false, motivos: false }
            }
        },
        validations () {
            let v = {
                model: {
                    cd_motivo_erro: { required },
                    ds_observacao: { required }
                }
            }
            return v
        },
        methods: {
            model_message () {
                let result = { cd_motivo_erro: '', ds_observacao: '' }
                if (this.model.submitted) {

                    if (! this.$v.model.cd_motivo_erro.required) result.cd_motivo_erro = 'Selecione um motivo'
                    if (! this.$v.model.ds_observacao.required) result.ds_observacao = 'Descreva seu problema'

                }
                return result
            },
            handleSubmit () {
                this.model.submitted = true
                this.$v.$touch(); if (this.$v.$invalid) return 0
                
                let dataSend = Object.assign({}, this.model)
                delete dataSend.reset
                delete dataSend.submitted

                this.waiting.enviar = true
                Utils.sendReportarErro(dataSend).then(response => {
                    this.waiting.enviar = true
                    if ([200, 201, 204].includes(response.status)) {
                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: 'Mensagem enviada ao nosso suporte'
                        })
                        this.modalProps.close()
                    }
                })
            },
        }
    }
</script>