import axios from 'axios'
import middlewareConfigs from './configs'

const find = (pathUrl, params, configs) => {
    let url = `${ middlewareConfigs.baseUrl }${ pathUrl }`
    let headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))

    if (! params) params = {}
    if (! configs) configs = {}

    url = `${ url }?${ new URLSearchParams(params).toString() }`
    if (configs.auth) headers['Authorization'] = `${ usuario.token_type } ${ usuario.access_token }`

    return axios.get(url, { headers }
    )
        .then(response => response)
        .catch(middlewareConfigs.errorHandler)
}

const save = (pathUrl, body, configs) => {
    if (! body) body = {}
    if (! configs) configs = {}
    let headers = {}
    let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
    
    if (body.id || configs.method === 'PATCH') {
        headers['Authorization'] = `${ usuario.token_type } ${ usuario.access_token }`
        let url = configs.method === 'PATCH' ? `${ middlewareConfigs.baseUrl }${ pathUrl }` :
            `${ middlewareConfigs.baseUrl }${ pathUrl }${ body.id }/`
        return axios.patch(url , body, { headers })
            .then(response => response)
            .catch(middlewareConfigs.errorHandler)
    } else {
        if (configs.auth) headers['Authorization'] = `${ usuario.token_type } ${ usuario.access_token }`
        return axios.post(`${ middlewareConfigs.baseUrl }${ pathUrl }`, body, { headers })
            .then(response => response)
            .catch(middlewareConfigs.errorHandler)
    }
}

const remove = (pathUrl, params, body, configs) => {
    let url = `${ middlewareConfigs.baseUrl }${ pathUrl }`
    let headers = body ? {} : { 'Content-Type': 'application/x-www-form-urlencoded' }
    
    if (! params) params = {}
    url = `${ url }?${ new URLSearchParams(params).toString() }`

    let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
    if (! configs) configs = {}
    if (configs.auth) headers['Authorization'] = `${ usuario.token_type } ${ usuario.access_token }`

    return axios.delete(url, { headers, body })
        .then(response => response)
        .catch(middlewareConfigs.errorHandler)
}

export default {
    find,
    save,
    remove
}