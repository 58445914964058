<template>
  <div class="modulo modulo-fale-conosco">
    <div class="container">
      <h1 class="text-center font-weight-bold">Fale Conosco</h1>
      <p class="mb-5 text-center">Tire dúvidas e envie sugestões.</p>
    </div>

    <div class="container">
      <div class="row justify-content-md-center m-1">
        <form class="col col-sm-80" v-on:submit.prevent="handleSubmit">
          <div class="row mb-5">
            <label for="name" class="form-label">Nome:</label>
            <input type="text" class="form-control" id="name" v-model="form.name" required>
          </div>
          <div class="row mb-5">
            <label for="email" class="form-label">E-mail:</label>
            <input type="email" class="form-control" id="email" v-model="form.email" required>
          </div>
          <div class="row mb-5">
            <label for="phone" class="form-label">Telefone:</label>
            <masked-input type="tel" :mask="phoneMask" class="input form-control" id="phone" v-model="form.phone" required></masked-input>
            <!-- <input type="text" class="form-control" id="phone" v-model="form.phone" required> -->
          </div>
          <div class="row mb-5">
            <label for="message" class="form-label">Mensagem:</label>
            <textarea class="form-control" id="message" rows="5" v-model="form.message" required></textarea>
          </div>
            <vue-recaptcha
            sitekey="6Le9OycdAAAAAP4JcD1IiVuDkMRcGGL2Mt1AVwpu"
            :loadRecaptchaScript="true"
            @verify="handleCaptcha"
            >
            </vue-recaptcha>
            <div class="captcha-nao-respondido" :class="{'block': form.submitted, 'hidden': ! form.submitted}" v-if="! captchaResolvido">Resolva o Captcha!</div>
          <div class="row mb-57">
            <button type="submit" class="btn btn-primary btn_custom"><div class="text-uppercase font-bold">Enviar</div></button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.captcha-nao-respondido {
  color: red;
  font-size: 0.8rem;
}

.modulo-fale-conosco {
  background-image: url('../../assets/img/fale-conosco.png');
  background-position: right top;
  background-size: cover;
}

.text-uppercase {
  color: #fff;
}
</style>

<script>
import VueRecaptcha from 'vue-recaptcha';
import NovaAPI from '../../middleware/controllers/NovaAPI.js'
import Email from '../../middleware/controllers/Email.js'
import configs from '../../middleware/configs.js'
import { phoneMask } from './../../utils'

export default {
  components: { VueRecaptcha },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        message: '',
        submitted: false
      },
      captchaResolvido: false,
      phoneMask
    }
  },
  methods: {
    handleSubmit() {
      this.form.submitted = true;

      if(this.captchaResolvido) {
        const data = {
          subject: 'Fale Conosco',
          to: ['faleconosco@medclub.com.br'],
          text: `
            Nome: ${this.form.name}
            Email: ${this.form.email}
            Telefone: ${this.form.phone}
            Mensagem: ${this.form.message}
          `
        }

        NovaAPI.authenticateApp(configs.novaAPI.applicationId, configs.novaAPI.applicationToken)
        .then(appToken => {
          Email.sendEmail(data, appToken)
          .then(
            this.form = {
              name: '',
              email: '',
              phone: '',
              message: '',
              submitted: false
            },
            this.$children[1].reset(),
            this.$buefy.toast.open({ type: 'is-success', position: 'is-bottom', message: 'Mensagem enviada! Aguarde o retorno da nossa equipe.' })
          )
        })
      }
    },
    handleCaptcha() {
      this.captchaResolvido = true;
      // Enviar para a API
    }
  }
}
</script>