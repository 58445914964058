<template lang="pug">
    form(@submit.prevent='handleSubmit()')
        .modal-card.max-w-sm.p-4(class='md:p-0')
            header.modal-card-head
                p.modal-card-title Adicionar cartão
                button.delete(type='button' @click='modalProps.close()')
            section.modal-card-body

                .flex.flex-wrap

                    b-field.w-full.px-1.text-sm(label='Nome:' :message="model_message().card_holder_name"
                        :type="{ 'is-danger': model.submitted && $v.model.card_holder_name.$error }")
                        b-input.text-sm(icon-pack='jam' icon='jam-user'
                            v-model='$v.model.card_holder_name.$model'
                            placeholder='Nome (conforme escrito no cartão)'
                            @input='$v.model.card_holder_name.$model = $v.model.card_holder_name.$model.toUpperCase()')

                    b-field.w-full.px-1.text-sm(label='Número do cartão:' :message="model_message().card_number"
                        :type="{ 'is-danger': model.submitted && $v.model.card_number.$error }")
                        .control.text-sm.has-icons-left.is-clearfix
                            masked-input.input(v-model='$v.model.card_number.$model' type='tel' :mask="creditCardMask" :guide='false'
                                :class="{ 'is-danger': model.submitted && $v.model.card_number.$error }" placeholder='Número do cartão')
                            span.icon.is-left
                                i.jam.jam-credit-card-f

                    b-field.px-1.text-sm(class='w-1/2' label='Vencimento:' :message="model_message().card_expiration_date"
                        :type="{ 'is-danger': model.submitted && $v.model.card_expiration_date.$error }")
                        b-input.text-sm(icon-pack='jam' icon='jam-calendar' type='tel' v-model='$v.model.card_expiration_date.$model' placeholder='MM/AA' v-mask="'##/##'")

                    b-field.px-1.text-sm(class='w-1/2' label='CVV:' :message="model_message().card_cvv"
                        :type="{ 'is-danger': model.submitted && $v.model.card_cvv.$error }")
                        b-input.text-sm(icon-pack='jam' icon='jam-credit-card' type='tel' v-model='$v.model.card_cvv.$model' placeholder='CVV' v-mask="'###?#'")

                    b-field.w-full.px-1.text-sm(label='CPF:' :message="model_message().nr_cpf"
                        :type="{ 'is-danger': model.submitted && $v.model.nr_cpf.$error }")
                        b-input.text-sm(icon-pack='jam' icon='jam-id-card' type='tel' v-model='$v.model.nr_cpf.$model' placeholder='CPF' v-mask="'###.###.###-##'")

            footer.modal-card-foot
                .text-center.w-full
                    b-button.text-sm(class='w-2/3' type='is-primary' native-type='submit' :loading='waiting.cadastrar') Adicionar
                    p
                        b-icon(pack='jam' icon='jam-shield-check-f')
                        span.text-xs Protegido com tecnologia de criptografia SSL.
</template>


<script>
    import { required, minLength } from 'vuelidate/lib/validators'
    import { validateCpf, creditCardMask } from './../../utils'
    import moment from 'moment'; moment.locale('pt-br')
    import { Pagamento } from '../../middleware'
    export default {
        props: [ 'modalProps' ],
        data () {

            const model = {
                card_holder_name: null,
                card_number: null,
                card_expiration_date: null,
                card_cvv: null,
                nr_cpf: null,
                submitted: false,
                reset () {
                    Object.keys(this).forEach(key => {
                        if (!['reset'].includes(key)) this[key] = null
                        this.submitted = false
                    })
                }
            }
            
            return {
                model,
                creditCardMask,
                waiting: { cadastrar: false }
            }
        },
        validations () {
            let v = {
                model: {
                    card_holder_name: { required },
                    card_number: {
                        required,
                        creditCard: val => {
                            if (! val) return false
                            else return parseInt(val[0]) === 3 ?
                                val.match(/\d+/g).join('').length >= 14 :
                                val.match(/\d+/g).join('').length >= 16
                        }
                    },
                    card_expiration_date: { required, minLength: minLength(5),
                        dateValid: val => moment(val, 'MM/YY').isValid() },
                    card_cvv: { required, minLength: minLength(3) },
                    nr_cpf: { required, validateCpf }
                }
            }
            return v
        },
        methods: {
            model_message () {
                let result = { card_holder_name: '', card_number: '' }
                if (this.model.submitted) {

                    if (! this.$v.model.card_holder_name.required) result.card_holder_name = 'Digite o nome do proprietário'

                    if (! this.$v.model.card_number.required) result.card_number = 'Digite o número do cartão'
                    else if (! this.$v.model.card_number.creditCard) result.card_number = 'Número do cartão inválido'

                    if (! this.$v.model.card_expiration_date.required) result.card_expiration_date = 'Digite o vencimento do cartão'
                    else if (! this.$v.model.card_expiration_date.minLength) result.card_expiration_date = 'Digite uma data válida'
                    else if (! this.$v.model.card_expiration_date.dateValid) result.card_expiration_date = 'Digite uma data válida'

                    if (! this.$v.model.card_cvv.required) result.card_cvv = 'Digite o CVV do cartão'
                    else if (! this.$v.model.card_cvv.minLength) result.card_cvv = 'Número do CVV inválido'

                    if (! this.$v.model.nr_cpf.required) result.nr_cpf = 'Digite o CPF'
                    else if (! this.$v.model.nr_cpf.validateCpf) result.nr_cpf = 'Digite um CPF válido'

                }
                return result
            },
            handleSubmit () {
                this.model.submitted = true
                this.$v.$touch(); if (this.$v.$invalid) return 0

                let dataSend = Object.assign({}, this.model)
                let clean = ['reset', 'submitted']
                clean.forEach(i => delete dataSend[i])

                let onlyNumbers = ['card_expiration_date', 'card_number', 'nr_cpf']
                onlyNumbers.forEach(i => { if (dataSend[i]) dataSend[i] = dataSend[i].match(/\d+/g).join('') })

                dataSend.card_holder_name = dataSend.card_holder_name.toUpperCase()

                this.waiting.cadastrar = true
                Pagamento.addCartao(dataSend).then(response => {
                    this.waiting.cadastrar = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: 'Cartão <b>adicionado</b>'
                        })
                        this.$parent.close()
                        if (this.$parent.$parent.getCartoes) this.$parent.$parent.getCartoes()
                    }
                })
            }
        }
    }
</script>