<template>
    <div class="flex justify-center items-center flex-wrap">
        <div class="max-w-screen-lg w-full relative" style="min-height:75vh">
            <div class="px-4 pb-8 md:py-36">
                <div class="absolute top-0 left-0 p-2">
                    <a class="text-3xl" @click="$router.go(-1)">
                        <i class="jam jam-chevron-left"></i>
                    </a>
                </div>

                <div class="flex flex-wrap justify-center">
                    <div class="w-full text-center" style="height: 20rem;">
                        <lottie-animation path="lottie/vprecos.json" :loop='false' style="width: 20rem;"></lottie-animation>
                    </div>
                    <section class="mx-auto w-full md:w-1/3">
                        <h1 class="text-2xl text-gray-700 font-semibold text-center">O que você procura?</h1>
                        <div class="p-2 w-full">
                            <b-button class="w-full bg-red-600" size='is-medium'  style="color: #FFF" rounded @click="$router.push({name: 'orcamento'})">
                                <div class="text-xl">
                                    <span class="jam jam-activity"></span>
                                    Exames e Procedimentos
                                </div>
                            </b-button>
                        </div>
                        <div class="p-2 w-full">
                            <b-button class="w-full " style="color: #FFF" type="is-primary" size='is-medium' rounded @click="$router.push({name: 'agendarCirurgia'})">
                                <div class="text-xl">
                                    <span class="jam jam-luggage-f"></span>
                                    Cirurgias
                                </div>
                            </b-button>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'

export default {
    name: 'Escolher',
    components: { LottieAnimation }
}
</script>