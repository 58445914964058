import { DialogProgrammatic as Dialog, ToastProgrammatic as Toast } from 'buefy'
import { Usuario } from './../middleware'
import router from './../router'

const env = 'prod'

const environments = {
	local: {
		baseUrl: 'http://127.0.0.1:8000/v1',
		firebaseFunctions: 'http://127.0.0.1:5001/medclub-6e133/us-central1'
	},
	dev: {
		baseUrl: 'https://apihom.medclub.com.br/dev/v1',
		firebaseFunctions: 'https://us-central1-medclub-6e133.cloudfunctions.net'
	},
	homolog: {
		baseUrl: 'https://apihom.medclub.com.br/v1',
		firebaseFunctions: 'https://us-central1-medclub-6e133.cloudfunctions.net'
	},
	prod: {
		baseUrl: 'https://api.medclub.com.br/v1',
		firebaseFunctions: 'https://us-central1-medclub-6e133.cloudfunctions.net'
	}
}
let checkConnection = true

const novaAPI = {
	dev: {
        BASE_URL: 'https://med-api.medclub.com.br',
        applicationId: '$2b$1232ou',
        applicationToken: '$2b$10$KP0C6OX16t8YnOP8B232ou',
        username: 'newsCreator',
        password: 'newsCreator'
    },
    homolog: {
        BASE_URL: 'https://med-api.medclub.com.br',
        applicationId: '$2b$1232ou',
        applicationToken: '$2b$10$KP0C6OX16t8YnOP8B232ou',
        username: 'newsCreator',
        password: 'newsCreator'
    },
    prod: {
        BASE_URL: 'https://med-api.medclub.com.br',
        applicationId: '$2b$1232ou',
        applicationToken: '$2b$10$KP0C6OX16t8YnOP8B232ou',
        username: 'newsCreator',
        password: 'newsCreator'
    }
}

export default {
	env,
	baseUrl: environments[env].baseUrl,
	firebaseFunctions: environments[env].firebaseFunctions,
	novaAPI: novaAPI[env],
	showEnv: process.env.NODE_ENV === 'development',
	clientId:
		env === 'prod' ? 'UejR9XJc9ZHUMwfEQnuyJCIF2vdEP05me9hAbHqp' : 'ByGUYI57lCKoCOY8ElHgW4wSFi08KRBzNHNTZXhr',
	clientSecret:
		env === 'prod' ? 'EHMQr5aBwIiW7RQ9seNbcWbAAZSRVT2NcImw6dSXv4uaF387tepd9uNhmqUHpBCB0j3lOoeZPBsxnKcDOntdN7zKqYLJAVrhH05QnQUAbxddS84abPRGroWMFvNWCdVC' :
			'vEGjIHuwiv7esDnApwEDVfi1R9q7i4R72DerfTum4sa609b3sGpfrQ2ckIu4e5JxwAEJ3sd1b6y9pJkHF8wJQvJzASmSDQ2LS1UXUgOhZoXAAgjQjvAXRpCqk3b3pQrE',
	failed: new Promise(resolve => { resolve(false) }),
	gRecaptcha: {
		siteKey: '6LfF_vMZAAAAAEILzWBHYVTzm2QK4foGAv_DTlhf',
		secretKey: '6LfF_vMZAAAAAON8yxsb4anNv8WGDLrBwO9OrwBU'
	},
	errorHandler: err => {
		if (err.response) {
			checkConnection = true
			if (([401]).includes(err.response.status)) {
				let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
				if (usuario) {
					if (! usuario.sessionWarning) {
						usuario.sessionWarning = true
						localStorage.setItem('MEDCLUBSITE_USER', JSON.stringify(usuario))
						Dialog.prompt({
							title: 'Sua sessão expirou',
							message: `<div class='text-sm p-2'>Digite sua senha para continuar navegando.</div><div class='text-sm p-2'><b>Email:</b> ${ usuario.ds_email }</div>`,
							inputAttrs: {
								type: 'password',
								placeholder: 'Sua senha'
							},
							confirmText: 'Continuar',
							cancelText: 'Encerrar sessão',
							trapFocus: true,
							canCancel: [ 'button' ],
							closeOnConfirm: false,
							onConfirm: (value) => {
								let buttons = document.querySelectorAll('.modal-card-foot .button')
								buttons.forEach(button => { button.style.display = 'none' })
								Usuario.login(usuario.ds_email, value).then(response => {
									if (response.usuario) {
										Toast.open({
											type: 'is-success', position: 'is-bottom',
											message: 'Usuário <b>autenticado</b>'
										})
										router.go(0)
									} else {
										buttons.forEach(button => { button.style.display = 'inline' })
										if (response.status === 500)
											Toast.open({
												type: 'is-danger', position: 'is-bottom',
												message: 'Sem conexão com o servidor'
											})
										else
											Toast.open({
												type: 'is-danger', position: 'is-bottom',
												message: 'Senha inválida'
											})
									}
								})
							},
							onCancel: () => {
								localStorage.removeItem('MEDCLUBSITE_USER')
								localStorage.setItem('MEDCLUB_TYPE_USER', false)
								router.go(0)
							}
						})
					}
				}
			} else if (([403]).includes(err.response.status)) {
				Toast.open({
					type: 'is-danger', position: 'is-bottom',
					message: 'Você não tem permissão'
				})
			} else if (err.response.status == 500) {
				Toast.open({
					type: 'is-danger', position: 'is-bottom', duration: 5000,
					message: 'Erro interno no servidor, tente novamente em instantes'
				})
			}
			return err.response
		} else if (checkConnection) {
			checkConnection = false
			Toast.open({
				type: 'is-danger', position: 'is-bottom',
				message: 'Sem conexão com o servidor'
			})
			return false
		}
	}
}
