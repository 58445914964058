import genericDao from '../generic-dao'

const getTodasAsNoticias = (params) => genericDao.find(`/appuse/noticias/`, params)
const getNoticias = (id) => genericDao.find(`/appuse/noticias/${id}/`, { auth: true })


export default {
    getNoticias,
    getTodasAsNoticias,
}
