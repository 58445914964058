import { render, staticRenderFns } from "./Procedimentos.vue?vue&type=template&id=c0a79a92&lang=pug"
import script from "./Procedimentos.vue?vue&type=script&lang=js"
export * from "./Procedimentos.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports