<template lang="pug">
    .esqueciMinhaSenha
    
        .flex.justify-center.bg-medclub-blue-100.pt-10.pb-8.hidden(class='md:flex')
            .max-w-screen-lg
                h1.text-2xl.text-gray-700.text-center(class='md:text-4xl') Redefinição de senha

        .flex.justify-center.bg-medclub-blue-100
            .max-w-screen-lg.w-full
                .m-4
                    router-link.underline(:to="{ name: 'home' }") Início
                    i.jam.jam-chevron-right.mx-2.text-lg
                    span Redefinição de senha
                    
                .flex.bg-white.p-4.shadow-md.justify-center.w-full(class='md:rounded md:mb-16')
                    form.mb-8.pt-5.w-full(class='md:w-1/3 md:pt-0' @submit.prevent='handleSubmit()')

                        .my-4.text-left
                            p.text-md Digite seu e-mail para solicitar a redefinição de senha.

                        b-field(:message="model_message().ds_email"
                            :type="{ 'is-danger': model.submitted && $v.model.ds_email.$error }")
                            b-input(v-model='$v.model.ds_email.$model' placeholder='Seu e-mail' rounded)
                            
                        .text-center
                            b-button.g-recaptcha.w-full(native-type='submit' type='is-primary' rounded :loading='waiting.enviar') Enviar
</template>
<script>
    import { required, email } from 'vuelidate/lib/validators'
    import { Usuario } from '../../middleware'
    import { load } from 'recaptcha-v3'
    export default {
        created () {
            load(this.$root.getRecaptchaKeys().siteKey).then((recaptcha) => {
                recaptcha.execute('esqueci_minha_senha').then(token => {
                    this.model.g_recaptcha_token = token
                })
            })
        },
        data () {

            const model = {
                ds_email: null,
                g_recaptcha_token: null,
                submitted: false
            }

            return {
                model,
                token: null,
                recaptcha: null,
                waiting: { enviar: false }
            }
        },
        validations () {
            let v = {
                model: {
                    ds_email: { required, email }
                }
            }
            return v
        },
        methods: {
            model_message () {

                let result = { ds_email: '' }

                if (this.model.submitted) {

                    if (! this.$v.model.ds_email.required) result.ds_email = 'Digite o e-mail'
                    else if (! this.$v.model.ds_email.email) result.ds_email = 'Digite um e-mail válido'

                }
                return result

            },
            handleSubmit () {
                this.model.submitted = true
                this.$v.$touch(); if (this.$v.$invalid) return 0
                
                this.waiting.enviar = true
                
                let dataSend = Object.assign({}, this.model)
                let clean = ['submitted']
                clean.forEach(i => delete dataSend[i])
                
                Usuario.redefinirSenha(dataSend.ds_email).then(response => {
                    this.waiting.enviar = false
                    this.model.submitted = false
                    if (response.status === 200) {
                        this.$buefy.toast.open({
                            type: 'is-success', position: 'is-bottom',
                            message: response.data.info
                        })
                        this.$router.push({ name: 'home' })
                    } else {
                        if (response.status === 500)
                            this.$buefy.toast.open({
                                type: 'is-danger', position: 'is-bottom',
                                message: 'Sem conexão com o servidor'
                            })
                        else if (response.data.detail)
                            this.$buefy.toast.open({
                                type: 'is-danger', position: 'is-bottom',
                                message: response.data.detail
                            })
                        else this.$buefy.toast.open({
                            type: 'is-danger', position: 'is-bottom',
                            message: 'Erro de conexão'
                        })
                    }
                })
            }
        }
    }
</script>