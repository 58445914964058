<template>
<body
    data-bs-spy="scroll"
    data-bs-target="#navbar_site"
    data-bs-offset="100"
    class="scrollspy-example"
    tabindex="0"
  >
    <!-- Modal com o lembrete de avaliacao -->
    <LembreteAvaliacao v-if="dialog.notificarAvaliacao" :consulta="ultimaConsulta" @avaliar="avaliar" @close="closeLembrete"/>

    <!-- Modal com o formulario de avaliacao -->
    <FormularioAvaliacao v-if="dialog.formularioAvaliacao" @close="closeFormularioAvaliacao" :horario="ultimaConsulta"/>

    <!-- Modal para o usuario selecionar a localidade que deseja atendimento -->
    <b-modal v-model='dialog.localidade' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal v-if="! waiting.carrinho">
      <template #default='props'>
        <div class="modal-card max-w-md md:p-0">
          <header class="modal-card-head flex justify-between text-2xl">
            <a v-if="passoModalLocalidade === 2" @click="passoModalLocalidade = 1">
              <i class="jam jam-chevron-left"></i>
            </a>
            <p class="modal-card-title text-center text-base">Onde gostaria de ser atendido?</p>
            <button class="delete" type='button' @click='props.close()'></button>
          </header>

          <section class="modal-card-body p-6">
            <div v-if="passoModalLocalidade === 1">
              <div v-for="localidade in localidadesUnicas" :key="localidade.cd_estado">
                <b-field>
                  <b-radio v-model="cdEstadoEscolhido" :native-value="localidade.cd_estado">
                    {{ localidade.nm_estado }}
                  </b-radio>
                </b-field>
              </div>
            </div>

            <div v-else>
              <div v-for="localidade in localidades.filter(elem => elem.cd_estado === cdEstadoEscolhido)" :key="localidade.cd_cidade">
                <b-field>
                  <b-radio v-model="cdCidadeEscolhida" :native-value="localidade.cd_cidade">
                    {{ localidade.nm_cidade }}
                  </b-radio>
                </b-field>
              </div>
            </div>
          </section>

          <footer class="modal-card-foot">
            <b-button class="mx-auto w-2/3" type='is-primary' native-type='submit' rounded @click="passoModalLocalidade = 2; cdCidadeEscolhida = cdCidadeEscolhidaSelected" v-if="passoModalLocalidade === 1">
              Próximo
            </b-button>

            <b-button class="mx-auto w-2/3" type='is-primary' native-type='submit' rounded @click="selecionarLocalidade" v-else>
              Selecionar
            </b-button>
          </footer>
        </div>
      </template>
    </b-modal>

    <!-- Modal de Login -->
    <b-modal v-model='dialog.login' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="false" aria-role="dialog" aria-modal>
      <template #default='props'>
        <Login ref='loginComponent' :modalProps='props' :cadastro='true' :redirecionar='true'></Login>
      </template>
    </b-modal>

    <!-- Menu institucional -->
    <navbar-home
    v-if="$route.name === 'home'"
    @openLogin="dialog.login = true"
    @openLocalidade="dialog.localidade = true"
    @encerrar="encerrar()"
    :cidade="nomeLocalidadeEscolhida"
    :usuario="usuario"
    :carrinho="carrinho"
    :saldo="saldo"
    />

    <!-- Menu sistema -->
    <navbar-sistema v-else
    @openLocalidade="dialog.localidade = true"
    @openLogin="dialog.login = true"
    @encerrar="encerrar()"
    :saldo='saldo'
    :cidade="nomeLocalidadeEscolhida"
    :usuario="usuario"
    :carrinho="carrinho"
    />
    <!-- Conteúdo da página -->
    <div style="min-height: 50vh;">
        <vue-page-transition class="animate__animated animate__fadeIn" v-show='! waiting.carrinho' name='fade'>
        <router-view ref='routerView'/>
      </vue-page-transition>
    </div>

    <div class="modulo_footer footer-solution mb-5">
      <div class="container">
        <footer class="pt-5 pb-3">
          <div class="row">
            <div class="col-md-6">
              <div class="w-1/2 md:w-1/3 md:text-left mb-3">
                <router-link :to="{ name: 'home' }">
                  <img class="w-32 inline" src='./../assets/img/logo-medclub.png'>
                  <div class="pt-2 px-1 text-gray-700" style='font-size:.75rem'>
                    <span>Você pode confiar</span>
                  </div >
                </router-link>
              </div>
              <ul>
                <li>
                  <span style="font-size: 14px">MEDCLUBE ADMINISTRADORA DE CARTÕES LTDA</span>
                </li>
                <li>
                  <span class="jam jam-map-marker-f"></span>MEDCLUB 1, Rua Paissandu,
                  1819 - Centro , Teresina - PI, 64001-120 - <br> CNPJ - 22.750.430/0001- 63
                </li>
                <li>
                  <span class="jam jam-map-marker-f"></span>MEDCLUB 2, Rua São Pedro, 2265, (Balcão)  - Centro , Teresina - PI, 64001-260 - <br> CNPJ - 22.750.430/0002-44
                </li>
                <li><span class="jam jam-whatsapp"></span>(86) 3131-1881</li>
<!--                <li><span class="jam jam-whatsapp"></span>(86) 98810-1140</li>-->
                <!-- <li>
                  <span class="jam jam-envelope-f"></span>contato@medclub.com.br
                </li> -->
               
              </ul>
              
            </div>

            <div class="col-md-3">
              <h5 class="mt-5">Links úteis</h5>
              <ul>
                <li><a href="/public/termos-e-condicoes.pdf">Termos e condições</a></li>
                  <li><a href="/public/politica-de-privacidade.pdf">Política de Privacidade</a></li>
                <li>
                <router-link :to="{ name: 'duvidasFrequentes' }">
                  <a>Dúvidas frequentes</a>
                </router-link>
                </li>

                <li>
                <router-link :to="{ name: 'quemSomos' }">
                  <a>Quem Somos</a>
                </router-link>
                </li>
              </ul>
            </div>

            <div class="col-md-3">
              <h5 class="mt-5">Redes Sociais</h5>
              <ul class="footer_redes_sociais">
                <li>
                  <a href="https://www.facebook.com/medclubApp"><span class="jam jam-facebook"></span></a>
                </li>
                <li>
                  <a href="https://www.instagram.com/medclub.app/"><span class="jam jam-instagram"></span></a>
                </li>
                <li>
                  <a href="https://api.whatsapp.com/send?phone=558631311881"><span class="jam jam-whatsapp"></span></a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </div>

    <!-- Aviso de cookies -->

    <div id="cookiesWarning" class="fixed right-0 left-0 p-4" v-if='! cookiesConfirmation' style='background-color:rgba(0,0,0,.5);bottom:-44%'>
      <div class="flex flex-wrap bg-white rounded shadow-md items-center p-2 max-w-screen-lg mx-auto">
        <div class="w-full p-2 text-justify md:w-5/6">
          <p class="text-sm">
            Nós usamos cookies e outras tecnologias semelhantes para melhorar sua experiência em nossos serviços. Ao utilizar nossos serviços, você concorda com a nossa <a class='link-primary' href='/termos-e-condicoes' target='_blank'>Política de Privacidade</a>.
          </p>
        </div>

        <div class="w-full p-2 md:w-1/6">
          <b-button class="rounded text-lg" type='is-primary' expanded @click='confirmCookies()'>Entendi</b-button>
        </div>
      </div>
    </div>

  </body>
</template>

<style scoped>
*:not(.decorated-text) {
  text-decoration: none !important;
}

#helpButtonMobile {
  transition: 0.25s;
}
#cookiesWarning {
  transition: 0.25s;
}
.footer-solution {
  width: 100%;
  position: absolute;
  z-index: -1;
}
</style>

<script>
import { Pagamento } from "./../middleware";
// import Login from "./Cadastro/Login";
import presentJson from "@/../public/lottie/present.json"; // eslint-disable-line
import { Utils } from "../middleware";
import _ from "lodash"; // eslint-disable-line
import Login from "./Cadastro/Login";
import Noticias from '../middleware/controllers/Noticias';
import configs from '../middleware/configs';
// import NovaAPI from '../middleware/controllers/NovaAPI.js';
import NavbarHome from './NavbarHome';
import NavbarSistema from './NavbarSistema';
import { Agendamento } from '../middleware';
import LembreteAvaliacao from './Avaliacao/LembreteAvaliacao';
import FormularioAvaliacao from './Avaliacao/FormularioAvaliacao';

export default {
  components: { Login, LembreteAvaliacao, FormularioAvaliacao, NavbarHome, NavbarSistema},
  watch: {
    usuario: function(usuario) {
      this.dialog.login = false;
      if (usuario){
        this.getCarrinho();
        this.getSaldoCreditos();
      } 
      this.$forceUpdate();
    },
    "dialog.login": function(opened) {
      if (this.$refs.loginComponent) this.$refs.loginComponent.model.reset();
      this.toggleHelpButton(opened);
    },
    "dialog.sidebar": function(opened) {
      if (opened) {
        let elems = document.querySelectorAll(".b-sidebar a.is-active");
        if (elems.length) {
          elems.forEach((e) => {
            e.classList.remove("is-active");
            e.classList.remove("is-expanded");
          });
        }
        let elem = document.getElementById(`sidebarbtn_${this.$route.name}`);
        if (elem) {
          elem.classList.add("is-active");
          elem.classList.add("is-expanded");
        }
      }
      this.toggleHelpButton(opened);
    },
  },
  created() {
    this.$analytics.fbq.event("ViewContent", { content_name: "View content" });

    let usuario = JSON.parse(localStorage.getItem("MEDCLUBSITE_USER"));
    if (usuario) {
      this.usuario = usuario;
      this.getCarrinho();
    }

    Utils.getCidades().then((response) => {
      this.localidades = response.data;
      if (this.$store.state.cdEstado) {
        this.cdEstadoEscolhido = this.$store.state.cdEstado;
        this.cdCidadeEscolhida = this.$store.state.cdCidade;
      } else {
        this.getLocation();
      }
    });

    // Os endpoints utilizam o estado do Vuex, caso a pessoa feche o Modal e não
    // selecione a localização, o store deve ser preenchido com o valor default
    this.$store.commit("setCidade", this.cdCidadeEscolhida);
    this.$store.commit("setEstado", this.cdEstadoEscolhido);

    // NovaAPI.authenticateApp(configs.novaAPI.applicationId, configs.novaAPI.applicationToken)
    // .then(response => {
    //     this.find(response);
    // })
  },
  mounted() {
    window.setTimeout(() => {
      let cookiesWarning = document.getElementById("cookiesWarning");
      if (cookiesWarning) cookiesWarning.style.bottom = 0;
    }, 1000);

    window.setInterval(() => {
      const helpButtonMobile = document.getElementById("helpButtonMobile");
      if (helpButtonMobile) {
        if (
          window.innerHeight + window.scrollY >=
          document.body.offsetHeight - 50
        ) {
          helpButtonMobile.style.bottom = "110px";
        } else {
          helpButtonMobile.style.bottom = "20px";
        }
      }
    });

    // Verifica se existe um usuário logado, e caso exista, deve ser consultada
    // a última consulta dele, para verificar se ele avaliou. Caso não tenha 
    // avaliado, deve ser exibido um pop-up pedindo para avaliar.

    //if(this.usuario && ! this.$store.state.avaliou) {
    if(this.usuario && ! this.$store.state.avaliou) {
      var medclubempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
      Agendamento.getHistorico({ie_associado_med_empresa: medclubempresa}).then(response => {
        // Deve ser consultada a ultima guia executada
        const historico = response.data.filter(a => a.ie_status === 'EX');
        const ultimaConsulta = historico[0];
        if(! ultimaConsulta?.ie_avaliou && historico.length !== 0) {
          this.ultimaConsulta = ultimaConsulta;
          this.dialog.notificarAvaliacao = true;
        }
      })
    }
  },
  data() {
    return {
      dialog: {
        login: false,
        sidebar: false,
        localidade: false,
        notificarAvaliacao: false,
        formularioAvaliacao: false
      },
      waiting: { carrinho: false, saldo: false},
      carrinho: [],
      carrinhoInfo: null,
      usuario: null,
      saldo: 0,
      hideFooter: false,
      cookiesConfirmation: localStorage.getItem(
        "MEDCLUBSITE_COOKIESCONFIRMATION"
      ),
      localidades: [],
      cdEstadoEscolhido: 9,
      cdCidadeEscolhida: 1735,
      passoModalLocalidade: 1,
      quantidadeNoticias: 0,
      ultimaConsulta: {},
      configs
    };
  },
  computed: {
    nomeLocalidadeEscolhida: function() {
      return this.localidades.filter(
        (localidade) => localidade.cd_cidade === this.cdCidadeEscolhida
      )[0]?.nm_cidade;
    },
    localidadesUnicas: function() {
      return _.uniqBy(this.localidades, "cd_estado");
    },
    cdCidadeEscolhidaSelected: function() {
      return this.localidades.filter(
        (elem) => elem.cd_estado === this.cdEstadoEscolhido
      )[0].cd_cidade;
    },
  },
  methods: {
    getNoticias() {
      Noticias.getTodasAsNoticias()
      .then(response => {
        this.quantidadeNoticias = response.rows.length;
      })
    },
    getSaldoCreditos () {
      this.waiting.saldo = true
      var medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
      Pagamento.getSaldoCreditos({ie_associado_med_empresa: medempresa}).then(response => {
          this.waiting.saldo = false
          if (response.status === 200) this.saldo = response.data.saldo_empresa ? response.data.saldo_empresa[0].nr_saldo : response.data.saldo
      })
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.getPosition,
          this.modalLocalizacao
        );
      } else {
        this.passoModalLocalidade = 1;
        this.dialog.localidade = true;
      }
    },
    getPosition(position) {
      this.localidadeMaisPerto({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    },
    modalLocalizacao(error) {
      //Caso não seja possível obter a localização do usuário, é exibido um modal para o próprio usuário escolher a localidade
      if (
        [
          error.PERMISSION_DENIED,
          error.POSITION_UNAVAILABLE,
          error.TIMEOUT,
          error.UNKNOWN_ERROR,
        ].includes(error.code)
      ) {
        this.passoModalLocalidade = 1;
        this.dialog.localidade = true;
      }
    },
    selecionarLocalidade() {
      this.$store.commit("setCidade", this.cdCidadeEscolhida);
      this.$store.commit("setEstado", this.cdEstadoEscolhido);

      this.dialog.localidade = false;
    },
    localidadeMaisPerto(localidadeAtual) {
      const { latitude, longitude } = localidadeAtual;
      let menorDistancia = 0;
      let localidadeProxima = {};

      this.localidades.forEach((localidade) => {
        let distancia = Math.sqrt(
          (localidade.cidade_nr_latitude - latitude) ** 2 +
            (localidade.cidade_nr_longitude - longitude) ** 2
        );

        localidadeProxima =
          distancia > menorDistancia ? localidade : localidadeProxima;
      });

      this.$store.commit("setCidade", localidadeProxima.cd_cidade);
      this.$store.commit("setCidade", localidadeProxima.cd_estado);

      this.cdCidadeEscolhida = localidadeProxima.cd_cidade;
      this.cdEstadoEscolhido = localidadeProxima.cd_estado;
    },
    toggleHelpButton(trigger) {
      if (trigger) {
        const helpButtonMobile = document.getElementById("helpButtonMobile");
        if (helpButtonMobile) helpButtonMobile.classList.add("hidden");
      } else {
        const helpButtonMobile = document.getElementById("helpButtonMobile");
        if (helpButtonMobile) helpButtonMobile.classList.remove("hidden");
      }
    },
    goTo(routeName) {
      this.dialog.sidebar = false;
      if (this.$route.name !== routeName)
        this.$router.push({ name: routeName });
    },
    getCarrinho() {
      this.waiting.carrinho = true;
      var medempresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
      Pagamento.getCarrinho({novo: true, ie_associado_med_empresa: medempresa}).then((response) => {
        this.waiting.carrinho = false;
        if (response.status === 200) {
          this.carrinho = response.data.carrinho;
          this.carrinhoInfo = response.data;
          delete this.carrinhoInfo.carrinho;
        }
      });
    },
    showHelpTag() {
      return [
        "agendarConsulta",
        "agendarConsultaCompartilhar",
        "agendarExamesLaboratoriais",
        "agendarOutrosExames",
        "agendarProcedimentos",
        "carrinho",
        "orcamento",
        "meusAgendamentos",
        "pagamento",
      ].includes(this.$route.name);
    },
    encerrar() {
      this.dialog.sidebar = false;
      this.$buefy.dialog.confirm({
        title: "Encerrar",
        message: "Deseja encerrar sua sessão?",
        type: "is-info",
        hasIcon: true,
        icon: "account-arrow-right-outline",
        iconPack: "mdi",
        ariaRole: "alertdialog",
        ariaModal: true,
        cancelText: "Continuar navegando",
        confirmText: "Encerrar",
        onConfirm: () => {
          localStorage.removeItem("MEDCLUBSITE_USER");
          localStorage.setItem("MEDCLUB_TYPE_USER", false)
          this.usuario = null;
          this.carrinho = [];
          this.$buefy.toast.open({
            type: "is-info",
            position: "is-bottom",
            message: "Sessão <b>encerrada</b>",
          });
          this.$router.go();
        },
      });
    },
    confirmCookies() {
      localStorage.setItem("MEDCLUBSITE_COOKIESCONFIRMATION", "1");
      let cookiesWarning = document.getElementById("cookiesWarning");
      cookiesWarning.style.bottom = `-${cookiesWarning.offsetHeight}px`;
      window.setTimeout(() => {
        this.cookiesConfirmation = false;
      }, 250);
    },
    scrollToTop() {
      window.scrollTo(0,0);
    },
    avaliar() {
      // O modal do lembrete de avaliacao deve ser fechado e o formulário
      // deve ser aberto
      this.dialog.notificarAvaliacao = false;
      this.dialog.formularioAvaliacao = true;
    },
    closeLembrete() {
      // O modal de lembrete de avaliacao deve ser fechado e deve ser registrado que o
      // usuário já avaliou
      this.dialog.notificarAvaliacao = false;

      this.$store.commit('setAvaliou', true);
    },
    closeFormularioAvaliacao() {
      // O modal de lembrete de avaliacao deve ser fechado e deve ser registrado que o
      // usuário já avaliou
      this.dialog.formularioAvaliacao = false;

      this.$store.commit('setAvaliou', true);
    }
  },
};
</script>
