<template lang="pug">
    div

        b-modal(v-model='dialog.relatarProblema' :can-cancel="[ 'x' ]" has-modal-card trap-focus :destroy-on-hide="true" aria-role="dialog" aria-modal)
            template(#default='props')
                RelatarProblema(ref='relatarProblemaComponent' :modalProps='props')

        .border-b.shadow-md
            .max-w-screen-lg.w-full.mx-auto.py-4.px-4
                .flex.items-end.w-full
                    div(class='w-1/2')
                        img.w-32.inline(src='./../../assets/img/logo-medclub.png')
                        .pt-2.px-1.text-gray-700(style='font-size:.75rem')
                            span Melhor da medicina. Melhor preço.
                    .text-right(class='w-1/2')
                        h1.font-bold.text-md.text-medclub-blue-300(class='md:text-lg') Consulta por Vídeo

        .max-w-screen-lg.w-full.mx-auto.py-4.px-4.mb-4

            .py-2(v-if='! consultaIniciada')

                .relative
                    h1.text-center.text-md.font-bold.text-medclub-blue-400 Detalhes da consulta
                    .absolute.top-0.left-0(style='margin-top:-10px')
                        a.text-3xl(@click='voltarParaMeusAgendamentos()')
                            i.jam.jam-chevron-left
                
                .border.my-4.rounded.text-md
                    .flex.items-center.p-4.border-b.bg-gray-100
                        div
                            .w-16.h-16.bg-gray-300.rounded-full.bg-center.bg-cover.bg-no-repeat(
                                :style="`background-image: url('${ agendamento.cd_agenda.cd_especialista.aq_foto }')`")
                        .pl-4
                            p.font-bold {{ agendamento.cd_agenda.cd_especialista.nm_especialista }}
                            p.text-sm {{ agendamento.cd_especialidade.nm_especialidade }} / {{ agendamento.cd_agenda.cd_especialista.nm_conselho }} {{ agendamento.cd_agenda.cd_especialista.ds_uf_conselho}} - {{ agendamento.cd_agenda.cd_especialista.nr_conselho }}
                    .p-4
                        p <b>Data:</b> {{ $root.moment(agendamento.dt_agenda).format('DD/MM/YYYY') }}
                        p <b>Horário:</b> {{ agendamento.hr_agenda }}
                        p.whitespace-pre-wrap.mt-4.font-bold {{ agendamento.cd_agenda.ds_observacao }}

                    .p-4
                        .border.rounded
                            h1.my-2.text-center.font-bold Anexos
                            .py-16(v-if='loadingAnexos')
                                b-notification.p-8.bg-white(:closable='false')
                                    b-loading(:is-full-page='false' v-model='loadingAnexos')
                            
                            div(v-else)
                                .text-center.p-2(v-if='! anexos.length')
                                    h1.text-sm.my-4 Você ainda não possui nenhum anexo.
                                .flex.flex-wrap.mt-4(v-else)
                                    .p-2(class='w-1/2 md:w-1/6' v-for='anexo, idx in anexos' :key='idx')
                                        .relative
                                            a.block.p-2.bg-gray-100.border.rounded.cursor-pointer.text-center(target='_blank' :href='anexo.aq_anexo')
                                                .mx-auto.my-2.w-12.h-12.flex.items-center.justify-center.bg-white.border.rounded-full
                                                    b-icon.text-2xl(pack='jam' icon='jam-attachment')
                                                p.text-xs.font-bold ANEXO {{ idx + 1 }}
                                            a.block.rounded-tr.rounded-bl.absolute.top-0.right-0.bg-red-400(@click='removeAnexo(anexo.id)')
                                                b-icon.text-md.text-white(pack='jam' icon='jam-close')
                                .p-2
                                    FilePond.filepond.cursor-pointer(
                                        ref='documentoAnexo'
                                        name='aq_anexo'
                                        accepted-file-types="application/pdf, image/png, image/jpeg"
                                        maxFileSize='10MB'

                                        label-idle='Enviar anexo...'
                                        labelFileProcessing='Enviando'
                                        labelTapToCancel='clique para cancelar'
                                        labelTapToRetry='clique para tentar novamente'
                                        labelFileProcessingError='Erro durante o envio'
                                        labelFileTypeNotAllowed='Arquivo inválido'
                                        fileValidateTypeLabelExpectedTypes='Deve ser formato PDF'
                                        labelMaxFileSizeExceeded='Arquivo muito grande'
                                        labelMaxFileSize='O tamanho máximo é {filesize}'
                                        labelFileProcessingComplete='Envio concluído'
                                        labelTapToUndo='Clique para remover'
                                        
                                        :allow-multiple="true"
                                        :allowFileSizeValidation='true'
                                        :instantUpload='true'
                                        :server='filePondServer'
                                        )

                .flex.flex-wrap.justify-center.my-4
                    .p-1(class='w-full md:w-auto')
                        b-button.w-full(type='is-primary' @click='iniciarConsulta()' icon-pack='jam' icon-left='jam-play' rounded) Iniciar consulta por vídeo

            div(v-else)

                .py-56.relative(v-show='loading')

                    b-notification.p-8.bg-white(:closable='false')
                        b-loading(:is-full-page='false' v-model='loading')
                    .absolute.top-0.left-0(style='margin-top:-10px')
                        a.text-3xl(@click='desconectar()')
                            i.jam.jam-chevron-left

                .relative(v-show='! loading && ! dialog.relatarProblema')
                    #teleconsultaContainer.my-4
                    .w-full.flex.justify-center.absolute(style='bottom:1em;z-index:9999')
                        b-button.w-12.h-12.flex.items-center.justify-center.bg-red-600.rounded-full.border-0(@click='confirmDesconectar()')
                            b-icon.text-2xl.font-bold.text-white(pack='jam' icon='jam-power')
                .text-right.w-full(v-show='! loading && ! dialog.relatarProblema')
                    b-button.inline-block.bg-red-600.text-white.py-2.px-4.bg-opacity-75.cursor-pointer.rounded-full.shadow-md.help-tag(@click='dialog.relatarProblema = true' type='button' icon-left='account-voice') Estou com problemas!
</template>

<style lang="scss">
    #teleconsultaContainer .dd_isomeet {
        top: 0;
        right: 0;
        height: auto;
    }
    .filepond {
        .filepond--root {
            margin-bottom: 0;
        }
        .filepond--credits {
            display: none;
        }
        .filepond--drop-label.filepond--drop-label label {
            font-size: .88em;
            font-weight: 600;
        }
    }
</style>

<script>
    import 'filepond/dist/filepond.min.css'
	import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
	import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
	import vueFilePond from 'vue-filepond'
    const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize)
    import wsConfigs from './../../middleware/configs'
    import { ConsultaPorVideo } from '../../middleware'
    import RelatarProblema from './RelatarProblema'

    export default {
        components: { FilePond, RelatarProblema },
        created () {
            let usuario = this.$root.findParentProperty(this, 'usuario')
            if (! usuario[0]) {
                this.$store.commit('finalizarConsultaPorVideo')
                this.$router.push({ name: 'home' })
            }
            if (! this.$store.state.consultaPorVideo || ! usuario.length) {
                this.$buefy.toast.open({ type: 'is-danger', position: 'is-bottom', message: 'Consulta por vídeo inválida' })
                this.$router.push({ name: 'home' })
            } else {
                this.agendamento = this.$store.state.consultaPorVideo
                usuario = usuario[0]

                this.filePondServer = {
					process: {
						url: `${ wsConfigs.baseUrl }/appuse/anexo/${ this.agendamento.guiaeletronica_set[0].id }/${ this.agendamento.cd_paciente.id }/`,
						method: 'POST',
						headers: {
							'Authorization': `${ usuario.token_type } ${ usuario.access_token }`,
						},
						ondata: (formData) => {
							formData.append('ie_tipo_anexo', 'FT')
							return formData
						},
						onload: () => {
                            this.$buefy.toast.open({ type: 'is-success', position: 'is-bottom', message: 'Anexo enviado' })
                            this.getAnexos()
                        }
					},
					revert: (uniqueFileId, load) => {
                        let result = JSON.parse(uniqueFileId)
						ConsultaPorVideo.removeAnexo(this.agendamento.guiaeletronica_set[0].id, this.agendamento.cd_paciente.id, result.id).then(response => {
							if (response.status == 200) this.$buefy.toast.open({ position: 'is-bottom', message: 'Anexo removido' })
							load()
						})
					}
                }

                this.getAnexos()
            }
        },
        beforeRouteEnter (_to, _from, next) {
            next()
        },
        beforeRouteLeave (_to, _from, next) {
            this.$store.commit('finalizarConsultaPorVideo')
            if (this.agendamento) ConsultaPorVideo.atualizarStatusGuia(this.agendamento.id, 'user_ended')
            next()
        },
        data () {
            return {
                consultaIniciada: false,
                loading: true,
                loadingAnexos: true,
                agendamento: null,
                isomeetScript: null,
                filePondServer: null,
                anexos: [],
                dialog: { relatarProblema: false }
            }
        },
        methods: {
            iniciarConsulta () {

                this.$buefy.dialog.alert({
                    message: `<p>Ao iniciar, será socilicitado que você habilite as funções de <b>microfone</b> e <b>câmera</b> do seu navegador.</p>`,
                    confirmText: 'Entendi',
                    onConfirm: () => {

                        this.consultaIniciada = true
                        this.loading = true

                        ConsultaPorVideo.executarGuia(this.agendamento.id/*, { ie_status_chamada: 'PE' }*/).then(response => {
                            if (response.status === 200)
                                ConsultaPorVideo.atualizarStatusGuia(this.agendamento.id, 'user_waiting').then(response => {
                                    if (response.status === 200)
                                    
                                        window.setTimeout(() => {
                                            let elContainer = document.getElementById('teleconsultaContainer')
                                            let teleconsulta = document.createElement('DIV')
                                            teleconsulta.id = 'teleconsulta'
                                            elContainer.appendChild(teleconsulta)

                                            this.isomeetScript = document.createElement('script')
                                            this.isomeetScript.setAttribute('src', 'https://meeting.medclub.com.br/widget.js?key=medclubapp&channel=1')
                                            this.isomeetScript.onload = () => {

                                                abrirReuniao(this.agendamento.ds_url_zoom, 'teleconsulta') // eslint-disable-line

                                                // let iframe = document.querySelector('#dd_isomeet iframe')
                                                // let iframeDoc = iframe.contentWindow || iframe.contentDocument
                                                // if (iframeDoc.document) iframeDoc = iframeDoc.document

                                                this.$gtag.event('iniciou_consulta_por_video', { agendamento_id: this.agendamento.id })
                                                this.$analytics.fbq.event('iniciou_consulta_por_video', { agendamento_id: this.agendamento.id })

                                                this.loading = false
                                            }
                                            document.body.appendChild(this.isomeetScript)
                                        })

                                })
                        })

                    }
                })				
            },
            confirmDesconectar () {
                this.$buefy.dialog.confirm({
                    message: `<p>Deseja <b>encerrar</b> a consulta por vídeo?</p>`,
                    confirmText: 'Encerrar',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    onConfirm: () => { this.desconectar() }
                })
            },
            desconectar () {
                this.consultaIniciada = false
                ConsultaPorVideo.atualizarStatusGuia(this.agendamento.id, 'user_ended')
            },
            voltarParaMeusAgendamentos () {
                this.loading = true
                window.setTimeout(() => {
                    this.$store.commit('finalizarConsultaPorVideo')
                    this.$router.push({ name: 'meusAgendamentos' })
                }, 250)
            },
            getAnexos () {
                this.loadingAnexos = true
                ConsultaPorVideo.getAnexos(this.agendamento.guiaeletronica_set[0].id, this.agendamento.cd_paciente.id).then(response => {
                    this.loadingAnexos = false
                    if (response.status === 200) this.anexos = response.data.filter(anexo => anexo.ie_tipo_anexo === 'FT')
                })
            },
            removeAnexo (id) {
                this.$buefy.dialog.confirm({
                    message: `<p>Deseja <b>remover</b> o anexo selecionado?</p>`,
                    confirmText: 'Remover',
                    cancelText: 'Cancelar',
                    type: 'is-danger',
                    onConfirm: () => {
                        this.loadingAnexos = true
                        ConsultaPorVideo.removeAnexo(this.agendamento.guiaeletronica_set[0].id, this.agendamento.cd_paciente.id, id).then(response => {
                            if ([200, 201, 204].includes(response.status)) this.$buefy.toast.open({ position: 'is-bottom', message: 'Anexo removido' })
                            this.getAnexos()
                        })
                    }
                })
            }
        }
    }
</script>