<template lang="pug">
.w-full
  .absolute.top-0.left-0.p-2
    a.text-3xl(@click='back()')
      i.jam.jam-chevron-left

  .py-56(v-if='waiting.estabelecimentos')
    b-notification.p-8.bg-white(:closable='false')
      b-loading(:is-full-page='false' v-model='waiting.estabelecimentos')

  .w-full(v-show='! waiting.estabelecimentos && !waiting.estabelecimentos')

    .py-24.px-4(v-if='!list.length && ! waiting.estabelecimentos')

      .bg-yellow-200.border.border-yellow-300.flex.max-w-xl.mx-auto.items-center.rounded
        .text-center(class='w-1/6')
          b-icon(icon='alert')
        .p-2(class='w-5/6')
          span.text-sm Não há nenhuma data disponível para <b>{{ $parent.agendamento.ie_telemedicina ? 'consulta por vídeo' : 'consulta presencial' }} </b>
          span.text-sm(v-if='$parent.agendamento.especialista') com o profissional <b>{{ $parent.agendamento.especialista.nm_especialista }}</b>
          span.text-sm(v-else-if='$parent.agendamento.especialidade') na especialidade <b>{{ $parent.agendamento.especialidade.nm_especialidade }}</b>
          span.text-sm .

    div(v-else)

      .w-full.flex.flex-wrap.justify-end.px-2(class='py-4 md:py-5')
        .text-right(class='w-4/6 md:w-full')
          h1.text-gray-700.text-sm
            span {{ $parent.agendamento.ie_telemedicina ? 'Consulta por vídeo' : 'Consulta presencial' }}
            span(v-if='$parent.agendamento.especialidade') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialidade.nm_especialidade }}
            span(v-if='$parent.agendamento.especialista') <b>&nbsp;<i class='jam jam-chevron-right text-lg'></i></b> {{ $parent.agendamento.especialista.nm_especialista }}

      div(v-if='$parent.agendamento.especialidade.id !== 57')
        .pb-3.text-center.w-full.flex.justify-content-center
          .flex.align-items-center
            h1.font-bold.text-gray-700.text-md.mr-1 Escolha data e local

      .p-2.pb-16(v-if='! waiting.estabelecimentos')
        h1.px-2.text-gray-700(v-if='list.length') Selecione o local:
        .p-1.w-full.text-center(v-if='! list.length')
          p.text-sm.my-6 Nenhum horário encontrado.
        .p-1.w-full(v-else v-for='estabelecimento, idx in estabelecimentos' :key='idx')
          .p-4.shadow-md.rounded.border(
            :class="{'cursor-pointer': $parent.pesquisarPor == 'data' }"
            @click=" if ($parent.pesquisarPor == 'data') selectEstabelecimento(estabelecimento) ")

            .flex.items-center.p-1

              .p-1
                .w-10.h-10.bg-gray-200.rounded-full.bg-center.bg-cover.bg-no-repeat(class='md:w-20 md:h-20'
                  :style="`background-image: url('${ estabelecimento.aq_foto }'); border-radius: 10%`")

              .p-1(class='w-10/12 s' :class="{ 'w-11/12': $parent.pesquisarPor !== 'data' || estabelecimento.ie_hora_marcada }")
                .flex.flex-wrap.w-full
                  div(class='w-4/6')
                    p.text-sm.font-bold.text-gray-700(class='md:text-lg') {{ estabelecimento.nm_estabelecimento }}
                    p.text-xs.text-gray-600.my-1(v-if='!$parent.agendamento.ie_telemedicina')
                      span {{ estabelecimento.nm_rua }}
                      span(v-if='estabelecimento.id')
                        span , {{ estabelecimento.nr_estabelecimento }}
                        span &nbsp;- {{ estabelecimento.nm_bairro }}
                        span(v-if='estabelecimento.ds_complemento') &nbsp;- {{ estabelecimento.ds_complemento }}
                    p.text-xs.text-gray-600.my-1(v-else)
                      span Consultório Virtual - Chamada de vídeo
                    
                    p.text-xs.text-gray-600 {{ $root.applyTelefoneMask(estabelecimento.nr_telefone) }}
                    .flex.items-center(v-if="estabelecimento.nr_avaliacao")
                      star-rating(
                        :rating="estabelecimento.nr_avaliacao"
                        :read-only="true"
                        :show-rating="false"
                        :round-start-rating="false"
                        :star-size="20")
                      .text-sm.align-center ({{ estabelecimento.nr_avaliacao }})
                    .p-1.w-full(v-if='!$parent.agendamento.ie_telemedicina')
                      map-button(:query='estabelecimento.nm_estabelecimento + " " + estabelecimento.nm_rua + " " + estabelecimento.nr_estabelecimento + " " + estabelecimento.nm_bairro')
                  .text-right(:class="{ 'hidden': $parent.pesquisarPor !== 'medico' }" class='md:block w-2/6')
                    p.text-xs.text-gray-700 A PARTIR DE
                    p.font-bold.text-medclub-blue-400.text-xl(class='md:text-2xl') {{ $root.formatPrice(estabelecimento.nr_valor) }}
                    // p.font-bold.text-xs.text-red-600(v-if="$route.name !== 'prontoSocorro' && !estabelecimento.ie_agenda_presencial") {{ estabelecimento.qtd_vaga }} VAGA{{ estabelecimento.qtd_vaga === 1 ? '' : 'S' }}

              //text-rig.ht(v-if="$parent.pesquisarPor !== 'medico'" class='w-1/12 md:text-center')
              //    b-icon.text-3xl(pack='jam' icon='jam-chevron-right')

            .flex.items-center.w-full.p-2.border-t.mt-2(v-if="$parent.pesquisarPor !== 'medico'" class='md:hidden')
              .font-bold.text-sm.text-red-600(class='w-1/2' v-if="$route.name !== 'prontoSocorro' && !estabelecimento.ie_agenda_presencial") {{ estabelecimento.qtd_vaga }} VAGA{{ estabelecimento.qtd_vaga === 1 ? '' : 'S' }}
              .text-right(class='w-1/2')
                p.text-xs.text-gray-700 A PARTIR DE
                p.font-bold.text-medclub-blue-400.text-xl(class='md:text-2xl') {{ $root.formatPrice(estabelecimento.nr_valor) }}

            .mt-2.mb-4.text-xs.bg-yellow-200.p-2.border.border-yellow-300.w-full(v-if="$parent.pesquisarPor !== 'data' && estabelecimento.ie_agenda_presencial") <b>Atenção!</b> É necessário agendar com a clínica.
            div(v-if="$parent.pesquisarPor !== 'data'")
              //.w-full.border-t.mt-2.p-2(v-if="!estabelecimento.ie_agenda_presencial && estabelecimento.ie_hora_marcada")
              //  p.text-sm.text-medclub-blue-300.font-bold Escolha o horário:
              //    .pr-1.pt-1.inline-block(v-for='horario, idx in estabelecimento.hr_horario' :key='idx')
              //      b-tag.p-4.font-bold.cursor-pointer.bg-turquoise(type='is-primary' @click.native='selectEstabelecimento(estabelecimento, horario)') {{ horario.hr_agenda }}
              //.w-full.border-t.mt-2.p-2(v-if='!estabelecimento.ie_hora_marcada')
              //  p.text-xs.text-gray-600(v-if='!estabelecimento.ie_agenda_presencial') POR ORDEM DE CHEGADA
              //  p.text-medclub-blue-400.font-bold.text-sm(v-if='!estabelecimento.ie_agenda_presencial') A partir de {{ estabelecimento.hr_inicio }}
              //  .p-4.pt-0(class='w-full') 

              .flex.flex-wrap.justify-center(class='w-full')
                .p-1(v-if="estabelecimento.ds_observacao && $parent.agendamento.ie_tipo_agenda !== 'CK'" :class="{ 'w-full': ! estabelecimento.ds_orientacao, 'w-1/2': estabelecimento.ds_orientacao }" class='md:w-56')
                  b-button.w-full.rounded.p-0(type='is-info' style='background-color:#1aa6a5 !important' @click="alert(estabelecimento, 'ds_observacao', 'Observações')")
                    .flex.items-center
                      b-icon.text-xl(pack='jam' icon='jam-document')
                      span.text-sm Observações

                .p-1.w-full(class='md:w-56' v-if="!agenda[estabelecimento.id]")
                  b-button.w-full.rounded.p-0(type='is-primary')
                    .flex.items-center
                      b-icon.text-xl(pack='jam' icon='jam-circle')
                      span.text-sm Carregando...

                .p-1.w-full(class='md:w-56' v-if="agenda[estabelecimento.id] && agenda[estabelecimento.id].tipo === 'agenda_presencial'")
                  b-button.w-full.rounded.p-0(type='is-primary'  @click='selectEstabelecimento(estabelecimento, {id: agenda[estabelecimento.id].horarios[0], dt_agenda: $root.moment().format("YYYY-MM-DD")})')
                    .flex.items-center(v-if="show_calendar[estabelecimento.id] === false")
                      b-icon.text-xl(pack='jam' icon='jam-shopping-cart')
                      span.text-sm Adicionar ao carrinho

                .p-1.w-full(class='md:w-56' v-if="agenda[estabelecimento.id] && agenda[estabelecimento.id].tipo === 'hora_marcada'")
                  b-button.w-full.rounded.p-0(type='is-primary'  @click='toggleCalendar(estabelecimento.id)')
                    .flex.items-center(v-if="show_calendar[estabelecimento.id] === false")
                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-down')
                      span.text-sm Ver horários
                    .flex.items-center(v-if="show_calendar[estabelecimento.id] === true")
                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-up')
                      span.text-sm Ocultar horários
                    .flex.items-center(v-if="show_calendar[estabelecimento.id] === 'loading'")
                      b-icon.text-xl(pack='jam' icon='jam-circle' spin)
                      span.text-sm Carregando horários...


                .p-1.w-full(class='md:w-56' v-if="agenda[estabelecimento.id] && agenda[estabelecimento.id].tipo === 'ordem_de_chegada'")
                  b-button.w-full.rounded.p-0(type='is-primary'  @click='toggleCalendar(estabelecimento.id)')
                    .flex.items-center(v-if="show_calendar[estabelecimento.id] === false")
                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-down')
                      span.text-sm Ver horários
                    .flex.items-center(v-if="show_calendar[estabelecimento.id] === true")
                      b-icon.text-xl(pack='jam' icon='jam-arrow-circle-up')
                      span.text-sm Ocultar horários
                    .flex.items-center(v-if="show_calendar[estabelecimento.id] === 'loading'")
                      b-icon.text-xl(pack='jam' icon='jam-circle' spin)
                      span.text-sm Carregando horários...
                transition
                  .flex.w-full.overflow-x-auto.px-1.py-2.shadow-sm.rounded.border(v-if="show_calendar[estabelecimento.id] === true" style="background-color:#fff;")
                    body.bg-gray-100.w-full
                      h2.text-center.font-semibold.mb-4 Escolha a data e horário:
                      div.carousel.slide.como-funciona-carousel(data-ride="carousel", data-interval="false", v-bind:id=`'carouselExampleControls_' + estabelecimento.id` )
                        ol.carousel-indicators
                          li(v-for="(chunk, index) in estabelecimentos_horarios_chunked[estabelecimento.id]", :class="{'active':index === 0}",
                          v-bind:data-target=`'carouselExampleControls_' + estabelecimento.id`, :data-slide-to="index", style="background-color:#aaa;")
                        div.carousel-inner
                          div.carousel-item(v-for='items, idx in estabelecimentos_horarios_chunked[estabelecimento.id]' :class="{ active: idx === 0 }")
                            div.row.m-32(style="margin:80px !important;")
                              div.col(v-for='horarios, idx in items' :key='idx', style="min-height: 120px;")
                                div.card_como_funciona
                                  .flex-1.text-center
                                    .inline-block(:class="{'mx-2': horarios_chunks_size > 1}")
                                      p.font-bold.uppercase {{ $root.moment(horarios[0]).format('ddd') }}
                                      p {{ $root.moment(horarios[0]).format('DD/MM') }}
                                      p.text-red-500.text-sm(v-if="agenda[estabelecimento.id].tipo === 'ordem_de_chegada'") {{horarios[2].find(h => h.qtd_vagas).qtd_vagas }} VAGAS
                                      .flex.justify-center
                                        hr(style="border: 0.5px solid #515151;width:50%")

                                      .bg-teal-500.text-white.rounded-full.px-4.py-1.mt-2.cursor-pointer(v-for='h in !collapse_horarios?horarios[1]:horarios[2]', v-if="agenda[estabelecimento.id].tipo === 'ordem_de_chegada'")(@click="selectEstabelecimento(estabelecimento,{...h, dt_agenda: horarios[0] })") A partir das {{formataHorario(h.hr_inicio)}}

                                      .bg-teal-500.text-white.rounded-full.px-4.py-1.mt-2.cursor-pointer(v-for='h in !collapse_horarios?horarios[1]:horarios[2]', v-if="agenda[estabelecimento.id].tipo === 'hora_marcada'")(@click="selectEstabelecimento(estabelecimento,{...h, dt_agenda: horarios[0] })") {{formataHorario(h.hr_agenda)}}
                              .col-12.mt-4(v-if="items.find(h => h[1].length > 3)") 
                                .flex-1.mb-4.text-center.font-semibold.cursor-pointer(style="color:#5A688D;" @click="collapse_horarios=!collapse_horarios")  Exibir todos os horários
                            .row


                        a.carousel-control-prev(v-bind:href=`'#carouselExampleControls_' + estabelecimento.id` role="button" data-slide="prev")(v-if="estabelecimentos_horarios_chunked[estabelecimento.id].length > 1")
                          span.carousel-control-prev-icon(aria-hidden="true")
                          span.sr-only Anterior

                        a.carousel-control-next(v-bind:href=`'#carouselExampleControls_' + estabelecimento.id` role="button" data-slide="next")(v-if="estabelecimentos_horarios_chunked[estabelecimento.id].length > 1")
                          span.carousel-control-next-icon(aria-hidden="true")
                          span.sr-only Próximo

                      //.flex.justify-between.items-center.mb-4
                        button.text-xl &lt;
                        .flex-1.text-center
                          .inline-block.mx-2(v-for='horarios, idx in agenda[estabelecimento.id].horarios' :key='idx')
                            p.text-sm {{ $root.moment(idx).format('ddd') }}
                            p.font-bold {{ $root.moment(idx).format('DD/MM') }}
                            // p.text-red-500.font-bold {{ horario.horarios.length }} VAGAS
                            .bg-teal-500.text-white.rounded-full.px-4.py-1.mt-2(v-for='h in horarios') De {{h.hr_inicio}} às {{h.hr_fim}}
                        button.text-xl &gt;
                      //.text-center.mb-4
                      //  a.text-blue-600(href="#") Exibir todos os horários
                      //.flex.justify-center.gap-x-2.mt-1
                        button.bg-gray-300.text-gray-800.py-2.px-4.rounded-md Ver observações
                        button.bg-blue-600.text-white.py-2.px-4.rounded-md Adicionar ao carrinho

                    //.p-1(v-for='horario, idx in Object.keys(agenda[estabelecimento.id].horarios)' :key='idx')
                    //.w-16.rounded.overflow-hidden.border.cursor-pointer.bg-white(@click='data = date; getEstabelecimentos()'
                    //  :class="{ 'estabelecimento-data--date-selected': $root.moment(date).format('YYYY-MM-DD') === $root.moment(data).format('YYYY-MM-DD') }")
                    //  .w-full.bg-red-600.text-center
                    //    div.text-white.text-xs {{ $root.moment(date).format('MMM') }}
                    //  .w-full.text-center.pt-1
                    //    div.text-md.font-bold {{ $root.moment(date).format('DD') }}
                    //  .w-full.text-center
                    //    div.text-xs {{ $root.moment(date).format('ddd') }}      
</template>

<style scoped>
/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.text-primary {
  color: #5A688D !important;
}
</style>

<script>
import { Agendamento } from "../../../middleware";
import StarRating from "vue-star-rating";
import MapButton from "../../global/MapButton.vue";
// eslint-disable-next-line no-unused-vars
import { chunk } from "lodash";
// eslint-disable-next-line no-unused-vars
import { orderBy } from "lodash";
// eslint-disable-next-line no-unused-vars
import moment from "moment";

export default {
  components: { StarRating, MapButton },
  created() {
    this.setup();

    /* const params = {
      cd_especialidade: this.$parent.agendamento.especialidade.id,
      ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
      ie_tipo_agenda: this.$parent.agendamento.ie_tipo_agenda,
      ie_atende_medclub_empresa: localStorage.getItem("MEDCLUB_TYPE_USER") === "true",
    };

    if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;
    this.waiting.datas = true;

    Agendamento.getCalendario(params).then((response) => {
      this.waiting.datas = false;
      if (response.status === 200) {
        let datas = response.data;

        datas.forEach((data) => {
          this.dateList.push(this.$root.moment(data, "YYYY-MM-DD", true)._d);
        });

        this.data = this.$parent.agendamento._data ? this.$parent.agendamento._data : this.dateList[0];
      }
      this.getEstabelecimentos();
    }); */
  },
  computed: {
    estabelecimentos() {
      const estabelecimentosGrupo = this.list.filter((element) => {
        if (element.nr_ordem_listagem) return element;
      });

      const estabelecimentosOutros = this.list.filter((element) => {
        if (!element.nr_ordem_listagem) return element;
      });
      return estabelecimentosGrupo.concat(estabelecimentosOutros);
    },
    estabelecimentos_horarios_chunked: function () {
      let formattedData = {};

      for (const id in this.agenda) {
        formattedData[id] = [];
        for (const date in this.agenda[id].horarios) {
          const arrayHorariosOrderedByTime = orderBy(this.agenda[id].horarios[date], [(item) => {
            return item.hr_inicio
          }], ['asc'])
          formattedData[id].push([date, arrayHorariosOrderedByTime, arrayHorariosOrderedByTime.slice(0, 3)]);
        }

        // sort by date
        formattedData[id] = orderBy(formattedData[id], [(item) => {
          return moment(item[0]).format('YYYY-MM-DD')
        }], ['asc'])

        // chunks of 3 for carrousel pagination
        formattedData[id] = chunk(formattedData[id], this.horarios_chunks_size)
      }

      console.log('formattedData', formattedData)
      return formattedData
    },
    horarios_chunks_size: function () {
      return this.windowWidth < 768 ? 1 : 3
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDetroy() {
    window.removeEventListener('resize', this.onResize);
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      list: [],
      data: null,
      collapse_horarios: true,
      dateList: [],
      statusEmpresa: false,
      waiting: { estabelecimentos: true, datas: true },
      dialog: {},
      show_calendar: {},
      agenda: {}, // {estabelecimento_id: [date, ...]}
    };
  },
  methods: {
    setup() {
      this.dateList = []
      this.list = []
      this.data = null
      this.dialog.maisFiltros = false

      const params = {
        cd_especialidade: this.$parent.agendamento.especialidade.id,
        ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
        ie_tipo_agenda: this.$parent.agendamento.ie_tipo_agenda,
      };

      if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;
      this.waiting.datas = true;

      params.modo_novo_calendario = true;
      this.getEstabelecimentos().then(() => {
        this.waiting.datas = false;
      });
      Agendamento.getCalendario(params).then((response) => {
        if (response.status === 200) {
          if (response.status === 200) {
            const data = response.data

            for (const estab_id in data) {
              const tipo_agenda = data[estab_id].tipo // ordem_de_chegada, hora_marcada, agenda_presencial
              const horarios = data[estab_id].agenda

              this.agenda[estab_id] = {
                tipo: tipo_agenda,
                horarios
              }
              this.$forceUpdate()
            }
          }
        }

        /*  this.getEstabelecimentos(); */
      });
    },
    back() {
      this.$parent.agendamento.ie_hora_marcada = null;
      this.$parent.agendamentoCriado = false;
      if (this.$parent.pesquisarPor === "data") this.$parent.agendamento.especialidade = null;
      if (this.$parent.pesquisarPor === "especialidade") this.$parent.agendamento.especialista = null;
      if (this.$parent.pesquisarPor === "medico") {
        this.$parent.agendamento.especialidade = null;
        this.$parent.agendamento.especialista = null;
      }
      if (this.$parent.pesquisarPor === "sintoma") {
        this.$parent.agendamento.especialidade = null;
        this.$parent.agendamento.especialista = null;
      }
      //O botão de voltar muda caso seja usado pelo componente Odonto.vue
      if (this.$parent.agendamento.is_odonto) {
        this.$router.push({ name: "agendarOdonto" });
      } else {
        //Para o fluxo de prontoSocorro, a tela atual é a primeira, logo se voltar deve ir para o início
        if (this.$route.name === "prontoSocorro") this.$router.push({ name: "home" });
        else if (this.$route.name !== "agendarConsulta") this.$router.push({ name: "agendarConsulta" });
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    alert(estabelecimento, property, title) {
      const helpButtonMobile = document.getElementById("helpButtonMobile");
      if (helpButtonMobile) helpButtonMobile.classList.add("hidden");

      this.$buefy.dialog.alert({
        title,
        message: `<div class='whitespace-pre-wrap text-sm'>${estabelecimento[property]}</div>`,
        confirmText: "Entendi",
        onConfirm: () => {
          const helpButtonMobile = document.getElementById("helpButtonMobile");
          if (helpButtonMobile) helpButtonMobile.classList.remove("hidden");
        },
      });
    },
    async getEstabelecimentos() {
      let params = {
        cd_especialidade: this.$parent.agendamento.especialidade.id,
        ie_telemedicina: this.$parent.agendamento.ie_telemedicina,
        ie_tipo_agenda: this.$parent.agendamento.ie_tipo_agenda,
        cd_cidade: this.$store.state.cdCidade,
        cd_estado: this.$store.state.cdEstado,
        //ie_atende_medclub_empresa: localStorage.getItem("MEDCLUB_TYPE_USER") === "true",
      };

      // O filtro de cidade deve ser utilizado apenas caso seja consulta presencial
      if (!params.ie_telemedicina) {
        params.cd_cidade = this.$store.state.cdCidade;
        params.cd_estado = this.$store.state.cdEstado;
      }

      if (this.$parent.agendamento.especialista) params.cd_especialista = this.$parent.agendamento.especialista.id;

      // update url slug
      const pesquisar_por = this.$route.params.pesquisar_por;

      const urlParams = new URLSearchParams(window.location.search);
      const param_estabelecimento = urlParams.get('estabelecimento')
      if (param_estabelecimento) {
        params.cd_estabelecimento = param_estabelecimento
      }

      if (pesquisar_por === "especialidade" || pesquisar_por === "medico") {
        const [slug_nm_especialista, slug_especialista, slug_especialidade] = this.$route.params.slug.split("_");


        const ds_telemedicina = this.$route.params.ds_telemedicina;

        let slug =
          slug_nm_especialista +
          "_" +
          slug_especialista +
          "_" +
          slug_especialidade;


        const query = param_estabelecimento ? { estabelecimento: param_estabelecimento } : {}
        if (!this.$parent.agendamento.is_odonto) {
          this.$router.replace({
            name: "agendarConsultaCompartilhar",
            params: { ds_telemedicina, pesquisar_por, slug },
            query
          });
        } else {
          this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug }, query });
        }
      } else {
        const query = param_estabelecimento ? { estabelecimento: param_estabelecimento } : {}
        this.$router.replace({
          query
        });
      }

      this.waiting.estabelecimentos = true;

      await Agendamento.getEstabelecimentosDisponiveisCompleto(params).then((response) => {
        this.waiting.estabelecimentos = false;
        if (response.status === 200) {
          this.list = Object.values(response.data).length ? Object.values(response.data) : [];

          for (const estabelecimento of Object.values(response.data)) {
            this.show_calendar[estabelecimento.id] = false
          }
        }
      });
    },
    async toggleCalendar(id) {
      const toggled = !this.show_calendar[id]
      this.show_calendar[id] = 'loading'
      this.$forceUpdate();

      if (toggled) {
        this.show_calendar[id] = toggled
      } else {
        this.show_calendar[id] = toggled
      }
      this.$forceUpdate();
    },
    selectEstabelecimento(estabelecimento, horario) {
      var usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
      var empresa = JSON.parse(localStorage.getItem('MEDCLUB_TYPE_USER'))
      if(empresa) {
          if(usuario.empresas_associado && usuario.empresas_associado.length && !usuario.empresas_associado[0]?.ie_status) {
              this.$buefy.toast.open({
                duration: 8000,
                position: 'is-bottom',
                message: 'Não foi possível concluir a compra. Por favor, entre em contato com o departamento de Recursos Humanos da sua empresa.',
                type: 'is-danger',
            })
            return
          }
      }
      this.$parent.agendamento.ie_hora_marcada = estabelecimento.ie_hora_marcada;
      this.$parent.agendamento._data = this.data;
      if (
        this.$parent.pesquisarPor !== "medico" ||
        !estabelecimento.ie_hora_marcada ||
        this.$parent.pesquisarPor !== "sintoma"
      ) {
        const ds_telemedicina = this.$route.params.ds_telemedicina;
        const pesquisar_por = this.$route.params.pesquisar_por;
        const cd_especialidade = this.$parent.agendamento.especialidade.id;
        const cd_estabelecimento = estabelecimento.id;
        const estabelecimento_name = String(estabelecimento.nm_estabelecimento)
          .replace(/\s/g, "-")
          .toLowerCase();
          
        const slug =
          "horarios-" + // como é usado essa mesma rota só que com um slug diferente para listagem de especialidades, adiciona esse parametro
          estabelecimento_name +
          "_" +
          cd_especialidade +
          "_" +
          cd_estabelecimento;

        if(this.$route.name !== "prontoSocorro"){
          if (!this.$parent.agendamento.is_odonto) {
            this.$router.replace({
              name: "agendarConsultaCompartilhar",
              params: { ds_telemedicina, pesquisar_por, slug },
            });
          } else {
            this.$router.replace({ name: "agendarOdontoCompartilhar", params: { ds_telemedicina, pesquisar_por, slug } });
          }
        }

        this.$parent.agendamento.estabelecimento = estabelecimento;
        if (this.$parent.agendamento.especialista) {
          if (horario) this.$parent.agendamento.horario = horario;
          else this.$parent.agendamento.horario = estabelecimento.hr_horario[0];
          this.$parent.agendamentoCriado = true;
        }
      } else if (horario) {
        this.$parent.agendamento.estabelecimento = estabelecimento;
        this.$parent.agendamento.horario = horario;

        if (this.$route.name !== "prontoSocorro") this.$parent.agendamentoCriado = true;
      }
    },
    formataHorario(horario){
      return horario.replace(/(\d{2}):(\d{2}):(\d{2})/, (match, horas, minutos) => {
        if (minutos === "00") {
          return `${horas}h`
        }
        return `${horas}h${minutos}`
      })
      
    }
  },
};
</script>
