<template>
    <div>
         <div class="modulo_page_header">
            <div class="container">
                <div class="row">
                    <div class="title_head_interna">
                        <div class="btn_voltar">
                            <a href="javascript:history.back()"><span class="jam jam-arrow-left"></span></a>
                        </div>
                            <div>
                        <h1 class="mb-0" id="nm_estabelecimento">{{ localAtendimento.nm_fantasia }}</h1>
                        <!-- <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p> -->
                        </div>
        
                    </div>
                </div>
            </div>
        </div>

        <div class="page_internas" id="info">
            <div class="container">
                <div v-if="waitingLocal" class="py-56">
                    <b-loading :active="waitingLocal" :full-page="false"></b-loading>
                </div>
                <div v-else-if='!waitingLocal' class="row">
                    <div class="col-md-3 text-center">
                        <img class="img-fluid mb-4" id="img_estabelecimento" :src="localAtendimento.aq_foto" >
                    </div>
                    
                    <div class="col-md-9">
                        <div class="row">
                        <div v-if="localAtendimento.servicos" class="col-md-12">
                            <label class="label_strong mt-2">Serviços:</label> 
                            <p class="whitespace-pre-wrap" v-html="localAtendimento.servicos"></p>
                            <!-- <ul id="servicos">
                                <li>Consultas Eletivas;</li>
                                <li>Exames Laboratoriais e Radiológicos;</li>
                                <li>Procedimentos.</li>
                            </ul> -->
                        </div>
                        <!-- <div class="col-md-12">
                            <label class="label_strong">Onde Pagar:</label> 
                            <ul>
                                <li>Paciente deve efetuar o pagamento no aplicativo, site , ou nos nossos balcões de atendimento localizados: no Hospital Santa Maria e no Térreo da Med Imagem.</li>
                            </ul>
                        </div> -->
                        <!-- <div class="col-md-12">
                            <label class="label_strong">Horário de Funcionamento:</label>
                            <ul>
                                <li>Segunda a Sexta-feira  -  08:00h as 19:00h</li>
                            </ul>

                        </div> -->
                        <div class="col-md-12">
                            <label class="label_strong mt-2">Telefone:</label> 
                            <ul>
                                <li id="telefone"><span class="jam jam-phone"></span><a :href="`tel:${localAtendimento.telefone}`">{{ localAtendimento.telefone }}</a></li>
                            </ul>
                        <label class="label_strong mt-2">Whatsapp:</label>
                        <ul>
                            <li id="whatsapp"><span class="jam jam-whatsapp"></span><a :href="`https://wa.me/${localAtendimento.whatsapp}`">{{ localAtendimento.whatsapp }}</a></li>
                        </ul>
                            <label class="label_strong mt-2">Endereço:</label>
                            <ul>
                                <li id="endereco"><a target="_blank" :href="`https://www.google.com/maps/place/${localAtendimento.nr_latitude},${localAtendimento.nr_longitude}`"><span class="jam jam-map-marker-f"></span>{{ localAtendimento.endereco }}</a></li>
                                <li v-if="localAtendimento.ds_complemento" id="complemento">Complemento: {{ localAtendimento.ds_complemento }}</li>
                            </ul>
                            <div v-if="localAtendimento.hr_funcionamento.length > 0">
                                <label class="label_strong mt-2">Horarios de Funcionamento</label>
                                <div v-for="horario in localAtendimento.hr_funcionamento" :key="horario">
                                    <span>- {{horario}}</span>
                                </div>
                            </div>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import moment from 'moment';
import { Utils } from '../../middleware';
import { isEqual, sortBy } from 'lodash';


export default {
    data() {
        return {
            waitingLocal: true,
            tokenAPI: '',
            localAtendimento: {
                aq_foto: '' 
            }
        }
    },
    created() {
        var id = this.$route.params.id;
        let params
        if(JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))) params['ie_atende_medclub_empresa'] =  JSON.parse(localStorage.getItem("MEDCLUB_TYPE_USER"))


        Utils.getRedeCredenciada(params)
        .then(response => {
            if([200, 201].includes(response.status)) {
                if(id) {
                    var data = response.data;
                    const estabelecimento = data.find(i => i.id === parseInt(id));

                    if (estabelecimento) {
                        const {
                            nm_fantasia,
                            aq_foto,
                            nm_rua,
                            nr_estabelecimento,
                            nm_bairro,
                            ds_observacao,
                            nr_telefone,
                            nr_whatsapp_cliente,
                            hr_funcionamento,
                            nr_latitude,
                            nr_longitude,
                            ds_complemento,
                        } = estabelecimento;

                        this.localAtendimento = {
                            nm_fantasia,
                            aq_foto,
                            alt: nm_fantasia,
                            endereco: `${nm_rua}, ${nr_estabelecimento} - ${nm_bairro}`,
                            servicos: ds_observacao,
                            telefone: this.formatPhoneNumber(nr_telefone),
                            whatsapp: this.formatPhoneNumber(nr_whatsapp_cliente),
                            hr_funcionamento: this.listHrFuncionamento(hr_funcionamento).content,
                            nr_latitude,
                            nr_longitude,
                            ds_complemento,
                        };

                        this.waitingLocal = false;
                    }
                }
            }
        })
    },
    methods: {
        formatPhoneNumber(str) {
          if (str) {
            if (str[0] == '+')
              str = str.substr(1)
            if (str.substr(0, 2) == '55')
              str = str.substr(2)
            let regex = str.length == 10 ? /(\d{2})(\d{4})(\d{4})/ : /(\d{2})(\d{5})(\d{4})/
            return str.replace(regex, '($1) $2-$3')
          } else return ''
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        showFirstNameOfDay(day) {
            const str = moment()
                .day(Number(day))
                .format('dddd');

            if (str.includes('-')) {
                return str.split('-')[0];
            }

            return str;
        },
        listHrFuncionamento(horarios) {
            const horarios_map = this.transformDatesListToMap(horarios);

            const its_full_time_open = this.checkIfIsFullTimeOpen(horarios_map)

            if (its_full_time_open) {
                return {isFullTime: true, content: 'Todos os dias - 24 horas'}
            
            }

            const hrrs_text_list = this.horariosMapToLabels(horarios_map)
            return {isFullTime: false, content: hrrs_text_list}
        },
        transformDatesListToMap(hrFuncionamentos) {
            const hr_atendimento_transformed = {}; // to format {[dia]: [{hr_inicio, hr_final}] }
            hrFuncionamentos?.forEach(hr_funcionamento => {
                const hours = hr_funcionamento.ar_dias_semana;
                if (hours.length > 0) {
                for (const hour of hours) {
                    if (!hr_atendimento_transformed[hour]) hr_atendimento_transformed[hour] = [];
                    hr_atendimento_transformed[hour].push({
                    hr_inicio: hr_funcionamento.hr_inicio,
                    hr_fim: hr_funcionamento.hr_fim
                    });
                }
                }
            });
        return hr_atendimento_transformed;
        },
        checkIfIsFullTimeOpen(horarios) {
            const values = Object.values(horarios)

            const itsFullDayOpen = (hrrs) => hrrs.length === 1 && hrrs[0].hr_inicio === '00:00:00' && hrrs[0].hr_fim === '23:59:00'

            return values.length === 7 && values.every(val => itsFullDayOpen(val))
        },
        horariosMapToLabels(horarios) {
            // shoud find sequential days with the same hrr: 2 or more duplicated 
            const horarios_entries = Object.entries(horarios)
            const days_with_same_hrr = this.extractDaysWithSameHrr(horarios)
            const labels = []

            const alreadyMarkedDays = []

            const hrrToLabel = (hrr, index, hrrs_size) =>{
                var openingTime = moment(hrr.hr_inicio, 'HH:mm:ss').format('HH:mm');
                var closingTime = moment(hrr.hr_fim, 'HH:mm').format('HH:mm');

                var hora1 = openingTime.split(":")[0].replace(/^0+/, '') + 'h';
                var min1 = openingTime.split(":")[1]
                if(min1 == "00") min1 = ''
                openingTime = hora1 + min1
                
                var hora2 = closingTime.split(":")[0].replace(/^0+/, '') + 'h';
                var min2 = closingTime.split(":")[1]
                if(min2 == "00") min2 = ''
                closingTime = hora2 + min2

                if (index === 0) return `de ${openingTime} às ${closingTime}`
                if (index === (hrrs_size - 1)) return ` e de ${openingTime} às ${closingTime}`
                return `, ${openingTime} às ${closingTime}`
            }

            for (const day_horarios of horarios_entries) {
                const [day, horarios] = day_horarios

                if(alreadyMarkedDays.includes(day)) continue

                // check if day has group
                const dayGroup = days_with_same_hrr.find(dayStats => dayStats.days.includes(Number(day)))

                if(dayGroup && dayGroup.sequential){
                const hrrsInStr = sortBy(horarios, ['hr_inicio']).map((hrr, index) => hrrToLabel(hrr, index, horarios.length))
                labels.push(`${ this.capitalizeFirstLetter(this.showFirstNameOfDay(day))} até ${this.showFirstNameOfDay(dayGroup.days[dayGroup.days.length - 1])} ${hrrsInStr.join('')}`)

                alreadyMarkedDays.push(...dayGroup.days.map(d=>String(d)))
                continue
                }


                const hrrsInStr = sortBy(horarios, ['hr_inicio']).map((hrr, index) => hrrToLabel(hrr, index, horarios.length))
                labels.push(`${this.capitalizeFirstLetter(this.showFirstNameOfDay(day))} ${hrrsInStr.join('')}`)
                alreadyMarkedDays.push(day)
                continue
            }

            return labels
            },

        extractDaysWithSameHrr(horarios) {
            const horarios_entries = Object.entries(horarios)
            const days_with_same_hrr=[]

            for (const day_horarios of horarios_entries) {
                if (days_with_same_hrr.map(daysItem => daysItem.days).flat(1).includes(Number(day_horarios[0]))) continue// if was included already

                const allDaysWithSameHrr = this.getDaysByContent(horarios, day_horarios[1])

                if (allDaysWithSameHrr.length < 2) continue

                // if days are not sequentials ignore
                if (!this.isThisSequential(allDaysWithSameHrr.map(day => Number(day)))) {
                days_with_same_hrr.push({
                    sequential: false,
                    days: allDaysWithSameHrr.map(d => Number(d))
                })
                continue
                }

                days_with_same_hrr.push({
                sequential: true,
                days: allDaysWithSameHrr.map(d => Number(d))
                })
            }

            return days_with_same_hrr
        },

        isThisSequential(arr) {
            const differenceAry = arr.slice(1).map(function (n, i) { return n - arr[i]; })
            const isDifference = differenceAry.every(value => value == 1)
            return isDifference

        },
        getDaysByContent(obj, value) {
            return Object.keys(obj).filter((key) => isEqual(obj[Number(key)], value));
        },
    }
}
</script>
