import axios from 'axios'
import qs from 'querystring'
import configs from './../configs'
import GenericDAO from './../generic-dao'

const authenticate = (username, password) => {
    const dataSend = {
        grant_type: 'password',
        client_id: configs.clientId,
        client_secret: configs.clientSecret,
        username, password
    }
    return axios.post(
        `${ configs.baseUrl }/oauth/token/`, qs.stringify(dataSend),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    )
    .then(response => {
        const usuario = {
            id: response.data.id,
            access_token: response.data.access_token,
            token_type: response.data.token_type
        }
        localStorage.setItem('MEDCLUBSITE_USER', JSON.stringify(usuario))
        return response
    })
    .catch(err => err.response)
}

const login = (username, password) => {
    return authenticate(username, password).then(response => {
        let usuario = JSON.parse(localStorage.getItem('MEDCLUBSITE_USER'))
        if (! response) {
            return { usuario: false, status: 500 }
        }
        else if (response.status !== 200)  return response
        else {
            return axios.get(`${ configs.baseUrl }/appuse/pessoa-fisica/${ usuario.id }/`, { headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `${ usuario.token_type } ${ usuario.access_token }`,
                } }
            )
            .then(response => {
                usuario.aq_foto = response.data.aq_foto
                usuario.ds_email = response.data.ds_email
                usuario.ds_email_adicional = response.data.ds_email_adicional
                usuario.dt_nascimento = response.data.dt_nascimento
                usuario.ie_sexo = response.data.ie_sexo
                usuario.nm_pessoa_fisica = response.data.nm_pessoa_fisica
                usuario.nr_cpf = response.data.nr_cpf
                usuario.nr_telefone = response.data.nr_telefone
                usuario.nr_telefone_adicional = response.data.nr_telefone_adicional
                usuario.first_name = usuario.nm_pessoa_fisica.split(' ')[0]
                usuario.nm_apelido = response.data.nm_apelido
                usuario.empresas_associado = response.data.empresas_associado
                usuario.ie_associado_med_empresa = response.data.ie_associado_med_empresa
                localStorage.setItem('MEDCLUBSITE_USER', JSON.stringify(usuario))
                localStorage.setItem('MEDCLUB_TYPE_USER', usuario.ie_associado_med_empresa)
                return { usuario: JSON.parse(localStorage.getItem('MEDCLUBSITE_USER')) }
            })
            .catch(err => err.response)
        }
    })
}

const save = (dataSend) => GenericDAO.save(`/appuse/pessoa-fisica/`, dataSend)
//Utilizar para obter os dados do usuário a partir do Id
const getUser = (idUsuario) => GenericDAO.find(`/appuse/pessoa-fisica/${ idUsuario }/`, null, { auth: true })
const redefinirSenha = (email) => GenericDAO.save(`/appuse/pessoa-fisica/esqueci-minha-senha/`, { ds_email: email })
const getCarteira = (idUsuario) => GenericDAO.find(`/appuse/pessoa-fisica/${ idUsuario }/carteirinha`, null, { auth: true })
//Utilizado para pegar os dados da pessoa que indicou um amigo, a partir do código de indicação (DS Code)
const getDadosIndicador = (dsCode) => GenericDAO.find(`/appuse/pessoa-fisica/dados-indicador/${ dsCode }`, null, { auth: false })
//Atribui ao usuário o código da pessoa que indicou ela
const addCodigoIndicador = (idUsuario, dsCode) => GenericDAO.save(`/appuse/pessoa-fisica/indicador/${idUsuario}/`, { "codigo_indicador" : dsCode }, { auth: true })
//Obtem a lista das pessoas que o usuário indicou
const getMeusIndicados = () => GenericDAO.find('/appuse/amigo-medclub/meus-indicados', null, { auth: true })
const saveContaBancaria = (params, id) => GenericDAO.save(`/appuse/pessoa-fisica/${id}/`, params, { auth: true, method: 'PATCH' })

export default { login, getUser, save, redefinirSenha, getCarteira, getDadosIndicador, addCodigoIndicador, getMeusIndicados, saveContaBancaria }