import GenericDAO from './../generic-dao'

const getOutrasPessoas = (params) => GenericDAO.find(`/appuse/pessoa-fisica/grupo-familiar/`, params, { auth: true })
const saveOutraPessoa = (body) => {
    return body.id ? GenericDAO.save(`/appuse/pessoa-fisica/${ body.id }/grupo-familiar/`, body, { method: 'PATCH' }) :
        GenericDAO.save(`/appuse/pessoa-fisica/grupo-familiar/`, body, { auth: true })
}
const deleteOutraPessoa = (body) => {
    return GenericDAO.remove(`/appuse/pessoa-fisica/${ body.id }/grupo-familiar/`, {}, {}, {auth:true})
}

export default {
    getOutrasPessoas,
    saveOutraPessoa,
    deleteOutraPessoa
}